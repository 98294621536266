import { useContext, useEffect, useState } from 'react';
import { Stack, Spinner, SpinnerSize, IImageProps, ImageFit } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { globalStackTokensGapLarge } from 'globalStyles';
import Package from 'models/package';
import { apiGetPackagesForStore, apiGetPackagesForStoreId } from 'services/Api/packageService';
import { apiRequest } from 'services/Auth/authConfig';
import { TenantOnboarding } from 'models/tenant';
import { apiGetTenantOnboardingState, apiUpdateTenantTrialOnboarding } from 'services/Api/tenantService';
import { apiGetLanguages } from 'services/Api/languageService';
import Language from 'models/language';
import { apiSetSetting } from 'services/Api/settingService';
import { DefLanguageCode, OrgLanguageCodes } from 'models/setting';
import UserLanguage from 'models/userLanguage';
import { apiUpdateUser } from 'services/Api/userService';
import Stepper from 'components/Utils/Stepper';
import TrialWelcomeLanguage from './TrialWelcomeLanguage';
import TrialWelcomeAskIntent from './TrialWelcomeAskIntent';
import TrialWelcomeAskMgMtSystem from './TrialWelcomeAskMgMtSystem';
import { apiGetISONorms } from 'services/Api/isoControlService';
import { ISONorm } from 'models/norm';
import TrialWelcomeAskConfirm from './TrialWelcomeAskConfirm';
import TrialWelcomeAskTimeline from './TrialWelcomeAskTimeline';
import TrialWelcomeNextSteps from './TrialWelcomeNextSteps';
import Config from 'services/Config/configService';
import { apiCopilotFinishOnboardingProgress } from 'services/Api/copilotService';
import { hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';

interface ITrialWelcomeProps {}

export const imagePropsTrialWelcome: IImageProps = {
  src: `${Config.getImageURL()}/trialwelcome.png`,
  imageFit: ImageFit.contain,
  width: 72,
  height: 72,
};

//create steps without labels
const stepperMaxSteps = 4;
const getStepperSteps = Array.from({ length: stepperMaxSteps }, (_, i) => i).map(() => '');

const TrialWelcome = (props: ITrialWelcomeProps) => {
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const [isLoadingDone, setIsLoadingDone] = useState<boolean>(false);
  const [packages, setPackages] = useState<Package[]>([]);
  const [onboardingState, setOnboardingState] = useState<TenantOnboarding>(new TenantOnboarding());
  const [languages, setLanguages] = useState<Language[]>([]);
  const [selectedLang, setSelectedLang] = useState<Language | undefined>(undefined);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [stepperSteps] = useState<string[]>(getStepperSteps);
  const [standards, setStandards] = useState<ISONorm[]>([]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrgLang = (languages: Language[], state: TenantOnboarding): Language => {
    //initially, define the default org language as the user language
    let langCode = appContext.user.language.code;
    if (state.onboardingSteps.languageIsSet === true) {
      //when this step is already completed, get the default language from the settings
      langCode = appContext.globalDataCache.settings.get(DefLanguageCode) as string;
    }

    let language = languages.find((l) => l.code === langCode);
    if (!language) {
      //fallback to the default language
      if (!language) {
        language = new Language();
        language.code = UserLanguage.getFallBack();
        language.languageId = 1;
        language.name = 'English';
      }
    }

    return language;
  };

  const loadData = async () => {
    if (isLoading || isLoadingDone) return;

    try {
      setIsLoading(true);
      appContext.showContentLoader();

      //only managers/admins are allowed to follow the onboarding steps
      if (appContext.user.login.isInTrial() && hasUserFeatureGenericManager(appContext)) {
        const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);

        //get the current on-boarding state
        const onboardingState = await apiGetTenantOnboardingState(accessToken);
        setOnboardingState(onboardingState);

        //get all languages
        const langCol = await apiGetLanguages(accessToken);
        setLanguages(langCol.languages);
        //get the default language based on the on-boarding state and user language
        const orgLang = getOrgLang(langCol.languages, onboardingState);
        setSelectedLang(orgLang);

        //load specific packages
        const packages = await apiGetPackagesForStoreId(
          onboardingState.packageIds.map((id) => Number.parseInt(id)),
          accessToken,
        );

        //load all preview packages
        let previewPackages = await apiGetPackagesForStore(false, accessToken);
        previewPackages = previewPackages.filter((p) => p.isPreview);

        setPackages([...packages, ...previewPackages]);

        //load ISO standards
        const standards = await apiGetISONorms(false, accessToken);
        setStandards(standards);

        //determine the active step
        if (await getIsRunning()) {
          setIsRunning(true);
          setActiveStep(stepperMaxSteps);
        } else {
          setIsRunning(false);
          let nextStep = onboardingState.onboardingSteps.currentStep;
          //When a new trial is started, currentStep is set to 0 by the back-end.
          //When the currentStep is undefined, this is an existing customer that did not started the on-boarding yet.
          //In this case, we want to skip the on-boarding and start at the overview (last step) 
          //where the customer can start the on-boarding (if the trial is active).
          if (nextStep === undefined) {
            nextStep = stepperMaxSteps + 1;
          }
          setActiveStep(nextStep);
        }
      } else {
        setActiveStep(stepperMaxSteps + 1);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
      setIsLoading(false);
      setIsLoadingDone(true);
    }
  };

  const getIsRunning = async (): Promise<boolean> => {
    const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
    const result = await apiCopilotFinishOnboardingProgress(accessToken);

    return (result || 0) > 0;
  };

  const updateOnboardingState = async (onboardingState: TenantOnboarding) => {
    try {
      setOnboardingState(onboardingState);
      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      await apiUpdateTenantTrialOnboarding(onboardingState, accessToken);
    } catch (err) {
      appContext.setError(err);
    }
  };

  const onNext = (state: TenantOnboarding) => {
    let newActiveStep = activeStep;
    if (newActiveStep === 1 && !state.onboardingSteps.isCertified() && !state.onboardingSteps.needNewStandards()) {
      //user answered no to both framework questions: skip and go to the overview
      newActiveStep = getStepperSteps.length + 1;
    } else {
      newActiveStep = activeStep + 1;
    }

    setActiveStep(newActiveStep);

    const newOnboardingState = state.clone();
    newOnboardingState.onboardingSteps.currentStep = newActiveStep;

    updateOnboardingState(newOnboardingState);
  };

  const onPrev = (state: TenantOnboarding) => {
    const newActiveStep = activeStep - 1;
    const newOnboardingState = state.clone();
    newOnboardingState.onboardingSteps.currentStep = newActiveStep;

    setActiveStep(newActiveStep);
    updateOnboardingState(newOnboardingState);
  };

  const updatePackageStateInStore = (updatePack: Package | undefined) => {
    if (!updatePack) return;
    const newPackages = [...packages];
    const existingPack = packages.find((p) => p.packageId === updatePack.packageId);
    if (existingPack) {
      existingPack.state = updatePack.state;
      setPackages(newPackages);
    }
  };

  const setUserLanguage = async (newLang: string): Promise<void | undefined> => {
    try {
      if (!UserLanguage.getSupportedLanguages().has(newLang)) {
        newLang = UserLanguage.getFallBack();
      }

      const newLanguage = languages.find((l) => l.code === newLang);
      setSelectedLang(newLanguage);
      if (appContext.user.language.code === newLang) return;

      //update the user language in the application
      newLang = await appContext.changeUserLanguage(newLang);

      //override the default lanauge of the user
      appContext.user.login.userLanguageCode = newLang;

      //update the user preference in the back-end
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiUpdateUser(appContext.user, accessToken);
    } catch (err) {
      appContext.setError(err);
    }
  };

  const updateOrgLang = async (langCode: string) => {
    try {
      //add the selected language to the organizational language when it is not present
      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const orgLangs = appContext.globalDataCache.settings.get(OrgLanguageCodes) as string[];
      if (!orgLangs.includes(langCode)) {
        orgLangs.push(langCode);
        await apiSetSetting(OrgLanguageCodes, orgLangs, accessToken);
        appContext.globalDataCache.settings.set(OrgLanguageCodes, orgLangs);
      }

      //set the default language setting
      const defLang = appContext.globalDataCache.settings.get(DefLanguageCode) as string;
      if (langCode !== defLang) {
        await apiSetSetting(DefLanguageCode, langCode, accessToken);
        appContext.globalDataCache.settings.set(DefLanguageCode, langCode);
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <TrialWelcomeLanguage
            selectedLang={selectedLang}
            languages={languages}
            onboardingState={onboardingState}
            updateOnboardingState={updateOnboardingState}
            updateOrgLang={(langCode) => updateOrgLang(langCode)}
            updateUserLang={(langCode) => setUserLanguage(langCode)}
            onNext={onNext}
          />
        );
      case 1:
        return (
          <TrialWelcomeAskIntent
            active={true}
            onboardingState={onboardingState}
            onNext={(state) => onNext(state)}
            onPrev={(state) => onPrev(state)}
            standards={standards}
          />
        );
      case 2:
        return (
          <TrialWelcomeAskTimeline
            active={true}
            onboardingState={onboardingState}
            onNext={(state) => onNext(state)}
            onPrev={(state) => onPrev(state)}
          />
        );
      case 3:
        return (
          <TrialWelcomeAskMgMtSystem
            active={true}
            onboardingState={onboardingState}
            onNext={(state) => onNext(state)}
            onPrev={(state) => onPrev(state)}
          />
        );
      case 4:
        return (
          <TrialWelcomeAskConfirm
            onboardingState={onboardingState}
            onNext={(state) => onNext(state)}
            onPrev={(state) => onPrev(state)}
            standards={standards}
            isRunning={isRunning}
          />
        );
      case 5:
        return (
          <TrialWelcomeNextSteps
            onboardingState={onboardingState}
            updateOnboardingState={updateOnboardingState}
            packages={packages}
            updatePackage={updatePackageStateInStore}
            selectedLang={selectedLang}
          />
        );
      default:
        return null;
    }
  };

  //
  // Main render
  //
  if (isLoading || !isLoadingDone) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  return (
    <Stack verticalFill tokens={globalStackTokensGapLarge}>
      {activeStep < getStepperSteps.length && (
        <Stack.Item styles={{ root: { transition: 'top: 0.5s ease' } }}>
          <Stepper activeStep={activeStep} steps={stepperSteps} />
        </Stack.Item>
      )}
      <Stack.Item>{getStepContent(activeStep)}</Stack.Item>
    </Stack>
  );
};

export default TrialWelcome;
