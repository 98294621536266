export enum DocumentColors {
  title = '#4f86ca',
  heading1 = '#7496be',
  heading2 = '#6273aa',
  hyperlink = '#7b7bf0',
  border = '#292929',
  cellShading1 = '#d9e8ff',
  cellShading2 = '#e8e8e8',
}

export enum DocumentFonts {
  main = 'Calibri',
}

export const paragraphStyles = [
  {
    id: 'Heading1',
    name: 'Heading 1',
    run: {
      size: 28,
      bold: true,
      color: DocumentColors.heading1,
      font: DocumentFonts.main,
    },
    paragraph: {
      spacing: {
        before: 200,
        after: 200,
      },
    },
  },
  {
    id: 'Heading2',
    name: 'Heading 2',
    run: {
      size: 28,
      bold: true,
      color: DocumentColors.heading2,
      font: DocumentFonts.main,
    },
    paragraph: {
      spacing: {
        before: 200,
        after: 200,
      },
    },
  },
  //
  // This does not work
  //
  // {
  //   id: 'Hyperlink',
  //   name: 'Hyperlink', 
  //   run: {
  //     size: 22,
  //     font: DocumentFonts.main,
  //     color: DocumentColors.hyperlink,
  //     underline: {
  //       type: 1, //UnderlineType.SINGLE,
  //       color: DocumentColors.hyperlink,
  //     }
  //   },
  // },
  {
    id: 'Normal',
    name: 'Normal',
    run: {
      size: 22,
      font: DocumentFonts.main,
    },
  },
  {
    id: 'Title',
    name: 'Title',
    run: {
      size: 44,
      bold: true,
      color: DocumentColors.title,
      font: DocumentFonts.main,
    },
  },
  {
    id: 'TableHeading',
    name: 'Table Heading',
    run: {
      bold: true,
      size: 18,
      font: DocumentFonts.main,
    },
    paragraph: {
      indent: {
        left: 100,
        right: 100,
      },
      spacing: {
        before: 50,
        after: 50,
      },
    },
  },
  {
    id: 'TableDetail',
    name: 'Table Detail',
    run: {
      size: 18,
      font: DocumentFonts.main,
    },
    paragraph: {
      indent: {
        left: 100,
        right: 100,
      },
      spacing: {
        before: 50,
        after: 50,
      },
    },
  },
  {
    id: 'TableDetailBold',
    name: 'Table Detail Bold',
    run: {
      bold: true,
      size: 18,
      font: DocumentFonts.main,
    },
    paragraph: {
      indent: {
        left: 100,
        right: 100,
      },
      spacing: {
        before: 50,
        after: 50,
      },
    },
  },
];
