import React, { useContext, useEffect, useState } from 'react';
import {
  DetailsListLayoutMode,
  DetailsRow,
  Dropdown,
  getTheme,
  IColumn,
  IDetailsListProps,
  IDetailsRowStyles,
  IDropdownOption,
  Link,
  Persona,
  PersonaSize,
  ScrollablePane,
  ScrollbarVisibility,
  SearchBox,
  SelectionMode,
  Spinner,
  SpinnerSize,
  Stack,
  Image,
  IImageProps,
  ImageFit,
  Text,
  DetailsList,
} from '@fluentui/react';
import { globalStackTokensGapMedium, globalStackTokensGapSmall } from 'globalStyles';
import AppContext from 'App/AppContext';
import { useTranslation } from 'react-i18next';
import {
  graphSharepointLibraryRequest,
  graphSharepointListRequest,
  graphSharepointManageRequest,
  graphSharepointPagesRequest,
} from 'services/Auth/authConfig';
import { graphGetSuggestedSites, graphGetSites } from 'services/Graph/graphService';
import { globalFilterDelay } from 'globalConstants';
import InfoMessage from 'components/Notification/InfoMessage';
import WarningMessage from 'components/Notification/WarningMessage';
import AppError from 'utils/appError';
import { getGraphSharepointErrorMsg } from 'services/Graph/graphErrors';
import { HomeAccount } from 'models/userSetting';
import { ISite } from '../../services/Graph/SharepointInterfaces';
import { getLocalStorageData, LocalStorageKeys, setLocalStorageData } from 'utils/localstorage';
import Config from 'services/Config/configService';
import GenericModal from 'components/Dialogs/GenericModal';

export enum MySiteAuthTypes {
  List = 0,
  Library = 1,
  Page = 2,
  Manager = 3,
}

interface IMySiteProps {
  onSelect: (site: ISite) => void;
  selectedSite: ISite | undefined;
  checkToken: (token: string) => boolean;
  disallowHomeAccount?: boolean;
  authType: MySiteAuthTypes;
  refresh?: boolean;
}

const MySites = (props: IMySiteProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'sharepoint']);
  const theme = getTheme();
  const [sites, setSites] = useState<ISite[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [spError, setSPError] = useState<string | undefined>(undefined);
  const [selectedOrg, setSelectedOrg] = useState<string | undefined>(undefined);
  const [showSiteNotListedInfo, setShowSiteNotListedInfo] = useState<boolean>(false);

  const imagePropsSiteExample: IImageProps = {
    src: `${Config.getImageURL()}/ISOPlanner SharePoint Site Search URL Example.png`,
    imageFit: ImageFit.center,
    width: 600,
    height: 200,
  };

  useEffect(() => {
    initOrgs();
    fetchSites(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initOrgs = () => {
    const homeAccount = appContext.globalDataCache.userSettings.get(HomeAccount) as string;
    if (homeAccount && !props.disallowHomeAccount && appContext.user.tenant.azureTenantId) {
      if (appContext.user.login.isGuest) {
        const currentTenantId = getUserOrg(appContext.user.tenant.azureTenantId);
        setSelectedOrg(currentTenantId);
      }
    } else {
      setSelectedOrg(undefined);
    }
  };

  const getUserOrg = (defaultTenantId: string): string => {
    const val = getLocalStorageData(appContext, LocalStorageKeys.SharepointOrg) || defaultTenantId;

    return val.toString();
  };

  const fetchSites = async (searchText: string | undefined) => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      setSPError(undefined);

      let scopes: string[];
      switch (props.authType) {
        case MySiteAuthTypes.List:
          scopes = graphSharepointListRequest.scopes;
          break;
        case MySiteAuthTypes.Library:
          scopes = graphSharepointLibraryRequest.scopes;
          break;
        case MySiteAuthTypes.Page:
          scopes = graphSharepointPagesRequest.scopes;
          break;
        case MySiteAuthTypes.Manager:
          scopes = graphSharepointManageRequest.scopes;
          break;
      }

      const graphInterface = await appContext.getGraphInterface(scopes, selectedOrg);
      if (!props.checkToken(graphInterface.accessToken)) return;

      let _sites: ISite[];
      if (searchText !== undefined && searchText.trim().length > 0) {
        _sites = await graphGetSites(graphInterface.client, searchText);
      } else {
        _sites = await graphGetSuggestedSites(graphInterface.client);
      }

      if (_sites) {
        _sites.sort((a, b) => (a.displayName ? a.displayName.localeCompare(b.displayName ?? '') : 0));
      } else {
        _sites = [];
      }

      setSites(_sites);
    } catch (err) {
      setSites([]);
      const graphErrorMsg = getGraphSharepointErrorMsg(err as AppError, t);

      if (graphErrorMsg) {
        setSPError(graphErrorMsg);
      } else {
        appContext.setError(err);
      }
    } finally {
      setIsLoading(false);
    } 
  };

  useEffect(() => {
    const timer = setTimeout(() => fetchSites(searchQuery), globalFilterDelay);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, selectedOrg, props.refresh]);

  const getColumns = (): IColumn[] => {
    return [
      {
        key: 'Sites',
        name: 'sites',
        minWidth: 150,
        maxWidth: 150,
        isRowHeader: true,
        isSorted: false,
        isSortedDescending: false,
        isMultiline: true,
        onRender: (_site: ISite, index: number | undefined) => (
          <Stack horizontal verticalAlign="center" tokens={globalStackTokensGapSmall}>
            <Stack.Item>
              <Persona
                text={_site.displayName || 'Unknown'}
                size={PersonaSize.size40}
                hidePersonaDetails={true}
                key={_site.id}
              />
            </Stack.Item>
            <Stack.Item>
              <Text>{_site.displayName || 'Unknown'}</Text>
            </Stack.Item>
          </Stack>
        ),
      },
    ];
  };

  const onRenderRow: IDetailsListProps['onRenderRow'] = (rowProps) => {
    let customStyles: Partial<IDetailsRowStyles> = {};

    if (rowProps?.item.id === props.selectedSite?.id) {
      customStyles = {
        root: {
          backgroundColor: theme.palette.themeSecondary,
          borderRadius: '3px',
          selectors: {
            '&:hover': {
              backgroundColor: theme.palette.themeSecondary,
            },
          },
        },
      };
    }

    if (rowProps) {
      return <DetailsRow {...rowProps} styles={customStyles} />;
    }

    return null;
  };

  const activeItemChanged = (
    item: ISite,
    index: number | undefined,
    ev: React.FocusEvent<HTMLElement> | undefined,
  ): void => {
    if (item.id !== props.selectedSite?.id) {
      item.tenantId = selectedOrg;
      props.onSelect(item);
    }
  };

  const getOrgs = (): IDropdownOption[] => {
    if (!selectedOrg) return [];

    const homeAccount = appContext.globalDataCache.userSettings.get(HomeAccount) as string;
    if (!homeAccount) return [];
    const selectedOrgObj = appContext.user.login.tenants?.find((t) => t.azureTenantId === homeAccount);
    if (!selectedOrgObj || !selectedOrgObj.azureTenantId || !appContext.user.tenant.azureTenantId) return [];

    return [
      {
        key: appContext.user.tenant.azureTenantId,
        text: appContext.user.tenant.name,
      },
      {
        key: selectedOrgObj.azureTenantId,
        text: selectedOrgObj.name,
      },
    ];
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill tokens={globalStackTokensGapMedium} styles={{ root: { minHeight: 200 } }}>
      {selectedOrg && (
        <Stack.Item>
          <Dropdown
            selectedKey={selectedOrg}
            options={getOrgs()}
            calloutProps={{ calloutMaxHeight: 250 }}
            onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
              if (!option) return;
              const tenantId = option.key as string;
              setLocalStorageData(appContext, LocalStorageKeys.SharepointOrg, tenantId);
              setSelectedOrg(tenantId);
            }}
          />
        </Stack.Item>
      )}
      <Stack.Item>
        <SearchBox
          className="redlab-usetiful-sites-filter"
          underlined={false}
          placeholder={t('translation:General.Filter.Placeholder')}
          id="search"
          onChange={(ev, newValue) => {
            setSearchQuery(newValue);
          }}
        />
        <Link onClick={() => setShowSiteNotListedInfo(true)}>{t('sharepoint:Dialogs.SiteNotListed.Link')}</Link>
        <GenericModal
          title={t('sharepoint:Dialogs.SiteNotListed.Title')}
          isOpen={showSiteNotListedInfo}
          onClose={() => setShowSiteNotListedInfo(false)}
          minWidth={650}
          verticalGap={globalStackTokensGapSmall}
        >
          <Text block>{t('sharepoint:Dialogs.SiteNotListed.SubText')}</Text>
          <Text>{t('sharepoint:Dialogs.SiteNotListed.SubSiteInfo')}</Text>
          <Image {...imagePropsSiteExample} />
        </GenericModal>
      </Stack.Item>
      <Stack.Item grow styles={{ root: { position: 'relative' } }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          {spError === undefined && !isLoading && sites.length === 0 && (
            <InfoMessage message={t('translation:General.Search.NoData')} onDismiss={undefined}></InfoMessage>
          )}
          {spError && <WarningMessage message={spError} onDismiss={undefined}></WarningMessage>}
          {isLoading && (
            <Stack verticalFill horizontalAlign="center" verticalAlign="center">
              <Spinner size={SpinnerSize.large} />
            </Stack>
          )}
          {!isLoading && (
            <DetailsList
              items={sites}
              columns={getColumns()}
              compact={true}
              layoutMode={DetailsListLayoutMode.justified}
              selectionMode={SelectionMode.none}
              selectionPreservedOnEmptyClick={true}
              isHeaderVisible={false}
              onActiveItemChanged={activeItemChanged}
              onRenderRow={onRenderRow}
            />
          )}
        </ScrollablePane>
      </Stack.Item>
    </Stack>
  );
};

export default MySites;
