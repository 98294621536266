import { FunctionComponent, useContext, useEffect, useState } from 'react';
import AppContext from 'App/AppContext';
import Task, { TaskTypes } from 'models/tasks/task';
import Assignee from 'models/assignee';
import { IKanbanSortProps, getKanbanSortOrder, updateTaskKanban } from './KanbanBoardSortHelper';
import { Stack, Text, Spinner, SpinnerSize, getTheme, IconButton, List } from '@fluentui/react';
import { globalTextStylesPaddingSmallBold, newIcon } from 'globalStyles';
import User from 'models/user';
import { newGuidNil } from 'utils/guid';
import { ICardTaskProps } from './Cards/KanbanCard';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toInteger } from 'lodash';
import ScrollableStackItem from 'components/Utils/ScrollableStackItem';
import KanbanCardEmptyLane from './Cards/KanbanCardEmptyLane';
import { sortOnDate, sortOnString } from 'utils/sorting';
import KanbanCardEventGeneric from './Cards/KanbanCardEventGeneric';
import KanbanCardTaskGeneric from './Cards/KanbanCardTaskGeneric';
import KanbanCardTaskGenericWithState from './Cards/KanbanCardTaskGenericWithState';
import { SystemTaskTypes } from 'models/tasks/taskType';
import AppError from 'utils/appError';
import { TaskState } from 'models/tasks/taskHelperClasses';

interface IKanbanTaskListProps {
  title: string;
  index: number;
  tasks: Task[];
  onTaskDragStart: (itemData?: Task, fromData?: number, fromIndex?: number) => void;
  onTaskDrop: (fromData?: number, itemData?: Task, fromIndex?: number, toData?: number, toIndex?: number) => void;
  onTaskClick: (task: Task) => void;
  onNewTask?: (listIndex: number, taskList: Task[]) => Promise<void>;
  taskTemplate?: FunctionComponent<ICardTaskProps>;
  allowNew: boolean;
  isCompleted: boolean;
  statusField: 'state' | 'assignee';
}

const KanbanTaskList = (props: IKanbanTaskListProps) => {
  const theme = getTheme();
  const appContext = useContext(AppContext);

  const getTemplate = (task: Task): JSX.Element => {
    if (props.taskTemplate) {
      return <props.taskTemplate key={task.taskId.toString()} task={task} onClick={props.onTaskClick} />;
    } else {
      if (task.taskType === TaskTypes.Event && task.isSeries()) {
        return <KanbanCardEventGeneric key={task.taskId.toString()} task={task} onClick={props.onTaskClick} />;
      } else {
        if (props.statusField === 'state') {
          return <KanbanCardTaskGeneric key={task.taskId.toString()} task={task} onClick={props.onTaskClick} />;
        } else {
          return (
            <KanbanCardTaskGenericWithState key={task.taskId.toString()} task={task} onClick={props.onTaskClick} />
          );
        }
      }
    }
  };

  const onRenderCard = (task?: Task, index?: number | undefined) => {
    if (!task || index === undefined) return null;

    return (
      <Draggable key={task.taskId} draggableId={task.taskId.toString()} index={index}>
        {(provided, snapshot) => {
          return (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={{
                userSelect: 'none',
                margin: '5px',
                minHeight: '50px',
                backgroundColor: snapshot.isDragging
                  ? appContext.useDarkMode
                    ? theme.palette.themeDark
                    : theme.palette.whiteTranslucent40
                  : appContext.useDarkMode
                  ? theme.palette.blueDark
                  : theme.palette.white,
                borderRadius: '3px',
                border: '1px solid lightgrey',
                ...provided.draggableProps.style,
              }}
            >
              {getTemplate(task)}
            </div>
          );
        }}
      </Draggable>
    );
  };

  return (
    <Stack verticalFill styles={{ root: { minWidth: 220 } }}>
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
        <Stack.Item>
          <Text variant="mediumPlus" styles={globalTextStylesPaddingSmallBold}>
            {props.title}
          </Text>
        </Stack.Item>
        <Stack.Item>
          {props.allowNew && props.onNewTask && (
            <IconButton onClick={() => props.onNewTask!(props.index, props.tasks)} iconProps={newIcon} />
          )}
        </Stack.Item>
      </Stack>

      <Droppable droppableId={props.index.toString()} key={props.index}>
        {(provided, snapshot) => {
          return (
            <Stack
              verticalFill
              styles={{
                root: {
                  width: '210px',
                  height: '100%',
                  padding: '5px',
                  background: snapshot.isDraggingOver
                    ? appContext.useDarkMode
                      ? theme.palette.black
                      : theme.palette.themeLight
                    : appContext.useDarkMode
                    ? theme.palette.blackTranslucent40
                    : theme.palette.themeLighterAlt,
                },
              }}
            >
              <ScrollableStackItem>
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {props.tasks.length === 0 && <KanbanCardEmptyLane />}
                  <List items={props.tasks} onRenderCell={onRenderCard} />
                  {provided.placeholder}
                </div>
              </ScrollableStackItem>
            </Stack>
          );
        }}
      </Droppable>
    </Stack>
  );
};

interface IKanbanBoardTasksProps {
  taskStates?: TaskState[];
  statusField: 'state' | 'assignee';
  tasks: Task[];
  allowColumnReorder: boolean;
  isLoading: boolean;
  onTaskClick: (task: Task) => void;
  onNewTask?: (statusIndex: number, taskList: Task[], statusObj: TaskState | Assignee) => Promise<void>;
  taskTemplate?: FunctionComponent<ICardTaskProps>;
  allowNew: boolean;
}

//
// Board
//
const KanbanBoardTasks = (props: IKanbanBoardTasksProps) => {
  const appContext = useContext(AppContext);

  const [taskStates, setTaskStates] = useState<TaskState[]>([]);
  const [assignees, setAssignees] = useState<Assignee[]>([]);
  const [lists, setLists] = useState<Task[][]>([]);
  const [isInitializing, setIsInitializing] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (!isInitialized) {
      initTasks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tasks, props.statusField]);

  const getUniqueAssignees = (tasks: Task[]): Assignee[] => {
    //get all the unique assignees (users and groups) from the task list
    const assigneeList: Assignee[] = [];
    for (const task of tasks) {
      let assignee: Assignee | undefined;
      if (task.user && task.userId) {
        assignee = new Assignee(task.userId, task.user.name, task.user.email, 'user');
      }
      if (
        assignee &&
        assignee.id !== newGuidNil() && //system user
        assigneeList.findIndex((a) => {
          return assignee && assignee.id === a.id;
        }) === -1
      ) {
        assigneeList.push(assignee);
      }
    }

    return assigneeList.sort((a, b) => sortOnString(a.name, b.name));
  };

  const initTasks = () => {
    if (isInitializing) {
      return;
    }

    try {
      setIsInitializing(true);

      const newLists: Task[][] = [];
      const approvalTaskType = appContext.globalDataCache.taskTypes.getTypeOfSystemType(SystemTaskTypes.Approval);
      if (!approvalTaskType) throw new AppError('SystemTaskTypes.Approval not found');

      switch (props.statusField) {
        case 'state':
          if (!props.taskStates) return;
          const statusesTaskState = props.taskStates;
          statusesTaskState.forEach((status: TaskState) => {
            let statusList: Task[] = props.tasks.filter((task: Task) => task.taskStateId === status.taskStateId);
            statusList = statusList.filter(
              (t) => !(t.taskType === TaskTypes.Event && t.taskTypeId === approvalTaskType.taskTypeId),
            );
            statusList.sort((a, b) => {
              const result = a.sortOrder - b.sortOrder;
              if (result !== 0) {
                return result;
              } else {
                return sortOnDate(a.created, b.created);
              }
            });

            newLists.push(statusList);
          });

          setTaskStates(statusesTaskState);
          break;

        case 'assignee':
          const statusesAssignees = getUniqueAssignees(props.tasks);
          statusesAssignees.forEach((status: Assignee) => {
            let statusList: Task[] = props.tasks.filter((task: Task) => task.userId === status.id);
            statusList = statusList.filter(
              (t) => !(t.taskType === TaskTypes.Event && t.taskTypeId === approvalTaskType.taskTypeId),
            );
            statusList.sort((a, b) => {
              return a.sortOrder - b.sortOrder;
            });

            newLists.push(statusList);
          });

          setAssignees(statusesAssignees);
          break;
      }

      setLists(newLists);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsInitializing(false);
      setIsInitialized(true);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onTaskDragStart = (itemData: any, fromData: any, fromIndex: any) => {
    itemData = lists[fromData][fromIndex];
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateTask = (listIndex: any, itemData: any, fromIndex: any, toIndex: any) => {
    const lists1 = lists.slice();
    lists1[listIndex] = reorder(lists1[listIndex], itemData, fromIndex, toIndex);
    setLists(lists1);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onTaskDrop = async (listIndexFrom: any, itemData: any, fromIndex: any, listIndexTo: any, toIndex: any) => {
    if (
      listIndexFrom === undefined ||
      itemData === undefined ||
      fromIndex === undefined ||
      listIndexTo === undefined ||
      toIndex === undefined
    ) {
      return;
    }

    if (listIndexFrom === listIndexTo && fromIndex === toIndex) {
      return;
    }

    const task: Task = itemData;

    switch (props.statusField) {
      case 'state':
        task.taskStateId = taskStates[listIndexTo].taskStateId;
        break;
      case 'assignee':
        const assignee = assignees[listIndexTo];
        if (assignee.type === 'user') {
          task.userId = assignee.id;
          task.user = new User(assignee.id, assignee.email, assignee.name);
        }
        break;
    }

    //update sort order
    const sortProps: IKanbanSortProps<Task> = {
      appContext: appContext,
      itemList: lists[listIndexTo],
      sourceIndex: listIndexFrom === listIndexTo ? fromIndex : -1,
      targetIndex: toIndex,
      updateItem: updateTaskKanban,
    };

    task.sortOrder = await getKanbanSortOrder(sortProps);

    // update the lists
    updateTask(listIndexFrom, itemData, fromIndex, -1);
    updateTask(listIndexTo, itemData, -1, toIndex);

    // update the database async
    updateTaskKanban(appContext, task, props.statusField);

    initTasks();
  };

  const onTaskClick = (task: Task) => {
    props.onTaskClick(task);
  };

  const onNewTask = async (listIndex: number, taskList: Task[]) => {
    if (!props.onNewTask || !props.allowNew) return;
    let obj: TaskState | Assignee;
    switch (props.statusField) {
      case 'state':
        obj = taskStates[listIndex];
        break;
      case 'assignee':
        obj = assignees[listIndex];
        break;
    }

    await props.onNewTask(listIndex, taskList, obj);
  };

  const getList = (tasks: Task[], listIndex: number) => {
    let listTitle: string;
    switch (props.statusField) {
      case 'state':
        if (listIndex >= taskStates.length) {
          return null;
        }
        listTitle = taskStates[listIndex].state;
        break;
      case 'assignee':
        if (listIndex >= assignees.length) {
          return null;
        }
        listTitle = assignees[listIndex].name;
        break;
    }

    return (
      <KanbanTaskList
        key={listTitle}
        title={listTitle}
        index={listIndex}
        tasks={tasks}
        statusField={props.statusField}
        onTaskDragStart={onTaskDragStart}
        onTaskDrop={onTaskDrop}
        onTaskClick={onTaskClick}
        taskTemplate={props.taskTemplate}
        onNewTask={onNewTask}
        allowNew={props.allowNew}
        isCompleted={props.statusField === 'state' ? taskStates[listIndex].completed : false}
      ></KanbanTaskList>
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const reorder = (items: any[], item: any, fromIndex: any, toIndex: any) => {
    if (fromIndex >= 0) {
      items = [...items.slice(0, fromIndex), ...items.slice(fromIndex + 1)];
    }

    if (toIndex >= 0) {
      items = [...items.slice(0, toIndex), item, ...items.slice(toIndex)];
    }

    return items;
  };

  //
  // Main render
  //
  if (props.isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  } else {
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', height: '100%', overflowX: 'scroll' }}>
        <DragDropContext
          onDragEnd={(result) =>
            onTaskDrop(
              toInteger(result.source?.droppableId),
              lists[toInteger(result.source.droppableId)][result.source.index],
              result.source.index,
              toInteger(result.destination?.droppableId),
              result.destination?.index,
            )
          }
          onDragStart={(result) =>
            onTaskDragStart(
              lists[toInteger(result.source?.droppableId)][result.source.index],
              toInteger(result.source?.droppableId),
              result.source.index,
            )
          }
        >
          <Stack horizontal>
            {lists.map((tasks: Task[], listIndex: number) => {
              return <Stack.Item key={listIndex}>{getList(tasks, listIndex)}</Stack.Item>;
            })}
          </Stack>
        </DragDropContext>
      </div>
    );
  }
};

export default KanbanBoardTasks;
