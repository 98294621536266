import { Spinner, SpinnerSize, Stack, Text, IComboBoxOption } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { globalStackTokensGapMedium } from 'globalStyles';
import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { apiRequest } from 'services/Auth/authConfig';
import SingleAudit from './SingleAudit';
import { apiGetAuditActions, apiGetAudits } from 'services/Api/auditService';
import AuditTrailEntry from 'models/auditTrailEntry';
import User from 'models/user';
import AuditTrailAction from 'models/auditTrailAction';
import FilterAuditTrail from './FilterAuditTrail';
import ScrollableStackItem from 'components/Utils/ScrollableStackItem';

interface IAuditTrailProps {
  auditTrailId?: number;
}

const AuditTrail = (props: IAuditTrailProps) => {
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [auditTrails, setAuditTrails] = useState<AuditTrailEntry[]>([]);
  const { t } = useTranslation(['translation', 'auditTrail']);

  const [actionList, setActionList] = useState<IComboBoxOption[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined);
  const [selectedActionID, setSelectedActionID] = useState<number | undefined>(undefined);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [showDialog, setShowDialog] = useState<string>('');

  useEffect(() => {
    loadData(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      if (isLoading || !props.auditTrailId) {
        return;
      }
      setIsLoading(true);

      //load Data
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const auditTrails = await apiGetAudits(
        props.auditTrailId,
        selectedUser?.id,
        undefined,
        startDate,
        endDate,
        accessToken,
      );
      const auditActions = await apiGetAuditActions(accessToken);
      auditActions.sort((a, b) => a.name.localeCompare(b.name));

      //   sort on creation date descending
      auditTrails.entries.sort((a, b) => {
        return a.created > b.created ? -1 : 1;
      });
      setAuditTrails(auditTrails.entries);
      setActionList(
        auditActions.map((audit: AuditTrailAction) => {
          return { key: audit.auditTrailActionId, text: audit.name };
        }),
      );
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const applyFilter = async () => {
    try {
      if (isLoading || !props.auditTrailId) {
        return;
      }
      setIsLoading(true);

      //load Data
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const auditTrails = await apiGetAudits(
        props.auditTrailId,
        selectedUser?.id,
        selectedActionID,
        startDate,
        endDate,
        accessToken,
      );

      //   sort on creation date descending
      auditTrails.entries.sort((a, b) => {
        return a.created > b.created ? -1 : 1;
      });
      setAuditTrails(auditTrails.entries);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (auditTrails) {
      if (selectedUser || selectedActionID || startDate || endDate) {
        applyFilter();
      } else {
        loadData();
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedActionID, selectedUser, startDate, endDate]);

  //Return Statements from here
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  } else if (!props.auditTrailId) {
    return null;
  } else
    return (
      <Stack verticalFill tokens={globalStackTokensGapMedium}>
        <Stack.Item tokens={globalStackTokensGapMedium}>
          <FilterAuditTrail
            selectedUser={selectedUser}
            onSelectedUserChange={setSelectedUser}
            selectedAction={selectedActionID}
            onSelectedActionChange={setSelectedActionID}
            startDate={startDate}
            onStartDateChange={setStartDate}
            endDate={endDate}
            onEndDateChange={setEndDate}
            actionList={actionList}
            showDialog={showDialog}
            onShowDialogChange={setShowDialog}
          />
        </Stack.Item>
        <ScrollableStackItem isOnPanel={appContext.isMobileView} height={80} maxHeight={70}>
          {auditTrails.length === 0 && (
            <Stack verticalFill horizontalAlign="center" verticalAlign="center">
              <Text>{t('auditTrail:NoAudits')}</Text>
            </Stack>
          )}
          {auditTrails.length > 0 &&
            auditTrails.map((audit: AuditTrailEntry) => {
              return <SingleAudit key={audit.auditTrailEntryId} audit={audit} />;
            })}
        </ScrollableStackItem>
      </Stack>
    );
};
export default AuditTrail;
