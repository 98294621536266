export default class AppError {
  code?: string;

  message: string;

  debug?: string;

  stack?: string;

  serverStack?: string;

  fromApi: boolean;

  constructor(message: string, code?: string, debug?: string, stack?: string) {
    this.message = message;
    this.code = code;
    this.debug = debug;
    this.stack = stack;
    this.fromApi = false;
  }

  isEqual(error: AppError | undefined): boolean {
    if (error === undefined) return false;
    if (this.message !== error.message) return false;
    if (this.code !== error?.code) return false;
    if (this.debug !== error?.debug) return false;
    if (this.fromApi !== error.fromApi) return false;

    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromOutlookError(error: any): AppError {
    const ae = new AppError(error.message ? error.message : 'Unknown error');

    ae.code = error.code ? error.code.toString() : error.errorCode ? error.errorCode.toString() : '';
    ae.stack = error.stack ? error.stack : '';
    ae.debug = error.body ? error.body : '';

    return ae;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromGraphError(error: any): AppError {
    const ae = new AppError(error.message ? error.message : 'Unknown error');

    ae.code = error.code ? error.code.toString() : error.errorCode ? error.errorCode.toString() : '';
    ae.stack = error.stack ? error.stack : '';
    ae.debug = error.body ? error.body : '';

    return ae;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromApiError(error: any): AppError {
    const ae = new AppError(error.message ? error.message : 'Unknown error');

    if (error.isAxiosError && error.response) {
      if (error.response.data) {
        if (typeof error.response.data === 'string') {
          ae.debug = error.response.data;
        } else {
          ae.debug = JSON.stringify(error.response.data);
        }
        if (!ae.debug) ae.debug = '';
        const stackIdx = ae.debug.lastIndexOf('StackTrace:');
        if (stackIdx > 0) {
          ae.message = ae.debug.substring(0, stackIdx);
          ae.message = ae.message.replaceAll(/\r?\n|\r/g, ' '); //replace newlines with spaces
          ae.serverStack = ae.debug.substring(stackIdx);
        } else {
          ae.message = ae.debug;
        }
        if (error.response.config) {
          ae.debug = 'Path: ' + error.response.config.url + '; ' + ae.debug;
        }
      }
      ae.code = error.response.status ? error.response.status.toString() : '';
      ae.stack = error.stack ? error.stack : '';
      //403 - not authenticated and 404 - not found
      //these are returned by the API but not logged because ASP.NET blocks the call before we can log.
      //-> treat this as a client-side error so it will be send to the server for logging.
      ae.fromApi = ae.code !== '403' && ae.code !== '404';
    } else {
      ae.code = error.code ? error.code.toString() : '';
      ae.stack = error.stack ? error.stack : '';
      ae.debug = error.debug ? error.debug : '';
      ae.fromApi = error.code ? true : false; //when no code is provided, treat like a client side error
    }

    return ae;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static fromAuthError(error: any): AppError {
    const ae = new AppError(error.message ? error.message : 'Unknown error');

    ae.code = error.code ? error.code.toString() : error.errorCode ? error.errorCode.toString() : '';
    ae.stack = error.stack ? error.stack : '';
    ae.debug = error.body ? error.body : '';

    return ae;
  }
}
