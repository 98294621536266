import { FontIcon, Stack, getTheme, Text } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { checkIcon, globalStackTokensGapSmall, globalTextStylesBold, globalTextStylesBoldWhite } from 'globalStyles';
import { useContext } from 'react';

export interface IStepIconProps {
  mode: StepIconModes;
  index: number | undefined;
  size: StepIconSizes;
  label?: string;
}

export enum StepIconModes {
  NotReached = 0,
  Active = 1,
  Success = 2,
  Error = 3,
}

export enum StepIconSizes {
  Small = 1,
  Medium = 2,
  Large = 3,
}

export const stepIconSizeRatios = [0, 1.5, 2.2, 3];

const StepIcon = (props: IStepIconProps): JSX.Element | null => {
  const appContext = useContext(AppContext);
  const theme = getTheme();

  const getContent = (mode: StepIconModes, index: number | undefined, size: StepIconSizes): JSX.Element => {
    switch (mode) {
      case StepIconModes.Success:
        return (
          <FontIcon
            {...checkIcon}
            style={{ fontSize: 10 * stepIconSizeRatios[size], color: 'white', paddingTop: 4, paddingLeft: 1 }}
          />
        );
      case StepIconModes.NotReached:
      case StepIconModes.Active:
        if (index !== undefined) {
          return (
            <Text
              styles={mode === StepIconModes.NotReached ? globalTextStylesBold : globalTextStylesBoldWhite}
              variant={size === StepIconSizes.Small ? 'small' : size === StepIconSizes.Medium ? 'medium' : 'large'}
            >
              {index}
            </Text>
          );
        } else {
          return (
            <FontIcon
              iconName="LocationDot"
              style={{ fontSize: 10 * stepIconSizeRatios[size], color: 'white', paddingTop: 4, paddingLeft: 1 }}
            />
          );
        }
      case StepIconModes.Error:
        return (
          <FontIcon
            iconName="StatusCircleErrorX"
            style={{ fontSize: 10 * stepIconSizeRatios[size], color: 'orange', paddingTop: 4, paddingLeft: 1 }}
          />
        );
    }
  };

  const getBackgroundColor = (darkMode: boolean): string => {
    if (props.mode === StepIconModes.NotReached) {
      if (darkMode) {
        return theme.palette.neutralSecondary;
      } else {
        return theme.palette.neutralLight;
      }
    } else {
      if (darkMode) {
        return theme.palette.themeTertiary;
      } else {
        return theme.palette.themePrimary;
      }
    }
  };

  return (
    <Stack tokens={globalStackTokensGapSmall} horizontalAlign="center">
      <Stack
        verticalAlign="center"
        horizontalAlign="center"
        styles={{
          root: {
            width: 14 * stepIconSizeRatios[props.size],
            height: 14 * stepIconSizeRatios[props.size],
            borderRadius: 25,
            background: getBackgroundColor(appContext.useDarkMode),
          },
        }}
      >
        <Stack.Item>{getContent(props.mode, props.index, props.size)}</Stack.Item>
      </Stack>
      {props.label && (
        <Stack.Item>
          <Text>{props.label}</Text>
        </Stack.Item>
      )}
    </Stack>
  );
};

export default StepIcon;
