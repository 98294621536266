import Dashboard from "models/dashboard";
import Tag from "models/tag";
import Task from "models/tasks/task";

export class CopilotOnboardingProject {
  tasks: Task[];

  tags: Tag[];

  projectDocument: string | undefined;

  dashboard?: Dashboard;

  previewPackageInstalled: boolean | undefined;

  projectMilestonesCreated: boolean | undefined;

  constructor() {
    this.tasks = [];
    this.tags = [];
  }
}
