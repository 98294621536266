import { Stack } from '@fluentui/react';
import { TFunction } from 'i18next';

//
// Definition
//
export enum CoachTypes {
  Timeline = 1,
}

export class CoachMarkDefinition {
  title: string;

  jsx?: JSX.Element;

  constructor(title: string) {
    this.title = title;
  }
}

//
// Timeline
//
export const getCoachMarkTimeline = (t: TFunction<string[]>) => {
  const cmTimeline = new CoachMarkDefinition(t('coachmarks:Timeline.Title'));
  cmTimeline.jsx = (
    <Stack>
      <Stack.Item>{t('coachmarks:Timeline.Intro')}</Stack.Item>
      <Stack.Item>
        <ul>
          <li>{t('coachmarks:Timeline.Ctrl1')}</li>
          <li>{t('coachmarks:Timeline.Ctrl2')}</li>
          <li>{t('coachmarks:Timeline.Ctrl3')}</li>
          <li>{t('coachmarks:Timeline.Ctrl4')}</li>
          <li>{t('coachmarks:Timeline.Ctrl5')}</li>
          <li>{t('coachmarks:Timeline.Ctrl6')}</li>
        </ul>
      </Stack.Item>
    </Stack>
  );

  return cmTimeline;
};
