import AppContext from 'App/AppContext';
import ResourceLink from 'models/resourceLink';
import { LibraryOpenInModal } from 'models/setting';
import { useContext, useEffect, useState } from 'react';
import { navigateToExternalUrl } from 'utils/url';
import LinkPreviewModal, { ILinkPreviewModalProps } from './LinkPreviewModal';
import Activity, { ActivityPriority, ActivityType } from 'models/activity';
import { EntityTypes } from 'models/entity';
import { apiCreateActivity } from 'services/Api/activityService';
import { apiRequest } from 'services/Auth/authConfig';

export const LinkPreviewModalOrUrl = (props: ILinkPreviewModalProps) => {
  const appContext = useContext(AppContext);
  const [showLinkInModal, setShowLinkInModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.isOpen) {
      loadData(props.link);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.link, props.isOpen]);

  const loadData = async (item: ResourceLink | undefined) => {
    try {
      setShowLinkInModal(false);
      if (item === undefined) return;
      setIsLoading(true);
      const libraryOpenInModal = appContext.globalDataCache.settings.get(LibraryOpenInModal) as boolean;
      if (item.list.isVirtual || libraryOpenInModal) {
        setShowLinkInModal(true);
      } else {
        const url = await item.getWebURL(appContext);
        navigateToExternalUrl(url, '', '');
        props.onClose();
      }
      createReadResponseActivity(item); //do not wait
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const createReadResponseActivity = async (link: ResourceLink) => {
    const activity = new Activity();
    activity.typeOfActivity = ActivityType.LibraryReadResponse;
    activity.entity.typeOfEntity = EntityTypes.Link;
    activity.entity.entityId = link.linkId;
    activity.entity.entityName = link.linkName;
    activity.userId = appContext.user.id;
    activity.priority = ActivityPriority.Normal;

    const accessToken = await appContext.getAccessToken(apiRequest.scopes);
    await apiCreateActivity(activity, false, false, false, accessToken);
  };

  //
  // Main render
  //
  if (!props.isOpen) return null;
  if (isLoading) return null;
  if (!showLinkInModal) return null;

  return (
    <LinkPreviewModal
      {...props}
      isOpen={showLinkInModal}
      onClose={() => {
        setShowLinkInModal(false);
        props.onClose();
      }}
    />
  );
};
