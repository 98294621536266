import { addDateDays, addDateMonths, addDateYears, getAuditStart } from 'utils/datetime';
import { PeriodFilterDataOption } from './PeriodFilter';
import { IAppContext } from 'App/AppContext';

export const getPeriodFilterStartDate = (
  option: PeriodFilterDataOption | undefined,
  selectedAuditYear: number | undefined,
  customStart: Date | undefined,
  appContext: IAppContext,
): Date | undefined => {
  let start: Date | undefined = new Date();

  switch (option) {
    case PeriodFilterDataOption.LastWeek:
      start = addDateDays(start, -7);
      break;
    case PeriodFilterDataOption.LastMonth:
      start = addDateMonths(start, -1);
      break;
    case PeriodFilterDataOption.LastQuarter:
      start = addDateMonths(start, -3);
      break;
    case PeriodFilterDataOption.LastYear:
      start = addDateYears(start, -1);
      break;
    case PeriodFilterDataOption.AuditYear:
      start = getAuditStart(appContext, selectedAuditYear);
      break;
    case PeriodFilterDataOption.Custom:
      start = customStart ?? new Date();
      break;
    default:
      //PeriodFilterDataOption.All:
      start = undefined;
      break;
  }

  return start;
};

export const getPeriodFilterEndDate = (
  option: string | undefined,
  selectedAuditYear: number | undefined,
  customEnd: Date | undefined,
  appContext: IAppContext,
): Date | undefined => {
  let end: Date | undefined = new Date();

  switch (option) {
    case PeriodFilterDataOption.AuditYear:
      const start = getAuditStart(appContext, selectedAuditYear);
      end = addDateYears(start, 1);
      break;
    case PeriodFilterDataOption.Custom:
      end = customEnd ?? new Date();
      break;
    default:
      //PeriodFilterDataOption.All:
      end = undefined;
      break;
  }

  return end;
};
