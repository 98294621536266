import { IPivotItemProps, Pivot, PivotItem, Text } from '@fluentui/react';
import ResourceLink from 'models/resourceLink';
import { overflow } from 'utils/string';
import { globalPaddingScene, globalTextStylesBold } from 'globalStyles';
import '../SharePoint/SharePointPageRender.css';
import { useEffect, useState } from 'react';

//
// Pinned link tabs
//
export interface IPinnedLinkTabsProps {
  links: ResourceLink[];
  selectedLink?: ResourceLink;
  onClick: (link: ResourceLink) => void;
}

const PinnedLinkTabs = (props: IPinnedLinkTabsProps) => {
  const [selectedKey, setSelectedKey] = useState<string | null>(null);

  const handleLinkClick = (item: PivotItem | undefined) => {
    if (item && item.props.itemKey) {
      const link = props.links.find((i) => getKey(i) === item.props.itemKey);
      if (link) {
        props.onClick(link);
      }
    }
  };

  useEffect(() => {
    setSelectedKey(getKey(props.selectedLink));
  }, [props.selectedLink]);

  const getKey = (link: ResourceLink | undefined): string | null => {
    if (!link) return null;

    return 'tab' + link.linkId.toString();
  };

  //remove the bold view when no pivot item is selected
  const renderItem = (
    itemProps?: IPivotItemProps,
    defaultRender?: (itemProps?: IPivotItemProps) => JSX.Element | null,
  ): JSX.Element | null => {
    return (
      <Text styles={itemProps?.itemKey === selectedKey ? globalTextStylesBold : undefined}>
        {itemProps?.headerText}
      </Text>
    );
  };

  //remove the blue underline when no pivot item is selected
  const getPivotStyles = () => {
    return !selectedKey
      ? {
          root: {
            paddingLeft: globalPaddingScene,
          },
          linkIsSelected: {
            selectors: {
              '&:before': { height: 0 },
            },
          },
        }
      : {
          root: {
            paddingLeft: globalPaddingScene,
          },
        };
  };

  return (
    <Pivot
      overflowBehavior="menu"
      headersOnly={true}
      selectedKey={selectedKey}
      onLinkClick={handleLinkClick}
      styles={getPivotStyles()}
    >
      {props.links.map((link) => {
        return (
          <PivotItem
            key={link.linkId}
            headerText={overflow(link.linkName, 25)}
            itemKey={getKey(link) ?? ''}
            onRenderItemLink={renderItem}
          ></PivotItem>
        );
      })}
    </Pivot>
  );
};

export default PinnedLinkTabs;
