import { days, getDayNumeric } from 'utils/datetime';
import 'moment-business-time';
import moment, { WorkingHoursMap } from 'moment';

export default class WorkingHoursPattern {
  private config: WorkingHoursMap;

  daySelect: days[];

  hourStart: number;

  minuteStart: number;

  hourEnd: number;

  minuteEnd: number;

  timer: typeof moment;

  constructor() {
    this.daySelect = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
    this.hourStart = 8;
    this.hourEnd = 17;
    this.minuteStart = 0;
    this.minuteEnd = 0;

    this.timer = moment;
    this.config = this.setConfig();
  }

  getConfig = (): WorkingHoursMap => this.config;

  private setConfig = (): WorkingHoursMap => {
    moment.locale('en', {});

    const numericWorkingDays = this.daySelect.map((_day: days) => getDayNumeric(_day));
    const workinghourConfig = [
      `${this.hourStart < 10 ? 0 : ''}${this.hourStart}:${this.minuteStart < 10 ? 0 : ''}${this.minuteStart}:00`,
      `${this.hourEnd < 10 ? 0 : ''}${this.hourEnd}:${this.minuteEnd < 10 ? 0 : ''}${this.minuteEnd}:00`,
    ];

    const config: WorkingHoursMap = {
      0: numericWorkingDays.includes(0) ? workinghourConfig : null,
      1: numericWorkingDays.includes(1) ? workinghourConfig : null,
      2: numericWorkingDays.includes(2) ? workinghourConfig : null,
      3: numericWorkingDays.includes(3) ? workinghourConfig : null,
      4: numericWorkingDays.includes(4) ? workinghourConfig : null,
      5: numericWorkingDays.includes(5) ? workinghourConfig : null,
      6: numericWorkingDays.includes(6) ? workinghourConfig : null,
    };

    moment.locale('en', {
      workinghours: config,
    });

    return config;
  }

  toJSON(): string {
    const workingHoursPattern = {
      daysOfWeek: this.daySelect,
      hourStart: this.hourStart,
      hourEnd: this.hourEnd,
      minuteStart: this.minuteStart,
      minuteEnd: this.minuteEnd,
    };

    return JSON.stringify(workingHoursPattern);
  }

  fromJSON(patternString: string | undefined) {
    try {
      if (patternString) {
        const patternObject = JSON.parse(patternString);
        this.daySelect = patternObject.daysOfWeek;
        this.hourStart = patternObject.hourStart;
        this.hourEnd = patternObject.hourEnd;
        this.minuteStart = patternObject.minuteStart;
        this.minuteEnd = patternObject.minuteEnd;
        this.config = this.setConfig();
      }
    } catch {
      //invalid input. Ignore and rely on the default values set in the constructor
    }
  }

  clone(): WorkingHoursPattern {
    const output = new WorkingHoursPattern();
    output.daySelect = [...this.daySelect];
    output.hourStart = this.hourStart;
    output.hourEnd = this.hourEnd;
    output.timer = this.timer;

    return output;
  }

  getDeadline = (start: Date, duration: number): Date => {
    return this.timer(start).addWorkingTime(duration, 'hours').toDate();
  };

  getHoursBetween = (start: Date, end: Date): number => {
    return this.timer(start).workingDiff(moment(end), 'hours');
  };

  getNextWorkingTime = (start: Date): Date => {
    return this.timer(start).nextWorkingTime().toDate();
  };
}
