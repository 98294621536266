import TagDTO from 'models/dto/tagDTO';
import Tag from 'models/tag';
import { mapFromEntity } from './activityMapping';

export function mapFromTag(tag: TagDTO): Tag {
  const output = new Tag();
  output.tagId = tag.tagId;
  output.tagName = tag.tagName;
  output.tagValue = tag.tagValue;
  output.tagColor = tag.tagColor;
  output.tagInfo = tag.tagInfo;
  output.tagSystemType = tag.tagSystemType;
  output.tagSystemSubType = tag.tagSystemSubType;
  output.usedCount = tag.usedCount;
  output.entity = tag.entity ? mapFromEntity(tag.entity) : undefined;

  return output;
}

export function mapFromTags(tags: TagDTO[]): Tag[] {
  return tags.map((_tag) => {
    return mapFromTag(_tag);
  });
}
