import React, { useEffect, useContext, useState } from 'react';
import Task from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import {
  DetailsListLayoutMode,
  IColumn,
  Link,
  Persona,
  PersonaSize,
  SelectionMode,
  ShimmeredDetailsList,
  Text,
  TooltipHost,
  Selection,
  Stack,
} from '@fluentui/react';
import { onRenderDetailsHeaderNoPaddingTopGlobal } from 'globalFunctions';
import { sortOnDate, sortOnString } from 'utils/sorting';
import AppContext from 'App/AppContext';
import { overflow } from 'utils/string';
import { toLocaleDateNumeric } from 'utils/datetime';
import { globalTextStylesError } from 'globalStyles';

interface ISubTasksListProps {
  subTasks: Task[];
  isLoading: boolean;
  onSubTaskClick?: (task: Task) => void;
  updateSubTasks: (tasks: Task[]) => void;
  selectionMode?: SelectionMode;
  layoutMode?: DetailsListLayoutMode;
  onChangeSelection?: (tasks: Task[]) => void;
  hideColumns?: string[];
}

const SubTaskList = (props: ISubTasksListProps) => {
  const { t } = useTranslation(['translation', 'tasks']);
  const appContext = useContext(AppContext);
  const [sortLoaded, setSortLoaded] = useState<boolean>(false);

  const selection = new Selection({
    onSelectionChanged: () => {
      if (props.onChangeSelection) {
        const items = selection.getSelection() as Task[];
        props.onChangeSelection(items);
      }
    },
  });

  //
  // Effects
  //
  useEffect(() => {
    if (!props.isLoading && props.subTasks && props.subTasks.length > 0 && !sortLoaded) {
      //apply 1 time sort on changed tasks
      initialSort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.subTasks, props.isLoading]);

  //
  // Columns
  //
  const getColumns = (): IColumn[] => {
    const allColumns: IColumn[] = [
      {
        key: 'assignee',
        name: '@',
        minWidth: 40,
        maxWidth: 40,
        isResizable: false,
        isSorted: false,
        isSortedDescending: false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return null;

          let name: string = '';
          if (item.userId) {
            name = appContext.globalDataCache.users.get(item?.userId).name;
          }

          return (
            <TooltipHost content={name}>
              <Persona size={PersonaSize.size24} text={name} hidePersonaDetails={true}></Persona>
            </TooltipHost>
          );
        },
      },
      {
        key: 'name',
        name: t('tasks:TabAllTasks.Fields.Name'),
        minWidth: 100,
        maxWidth: 250,
        isMultiline: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return;

          if (props.onSubTaskClick) {
            return (
              <TooltipHost content={item?.description}>
                <Text>
                  <Link underline onClick={() => (props.onSubTaskClick ? props.onSubTaskClick(item) : undefined)}>
                    {overflow(item?.name, 100)}
                  </Link>
                </Text>
              </TooltipHost>
            );
          } else {
            return (
              <TooltipHost content={item?.description}>
                <Text>{overflow(item?.name, 100)}</Text>
              </TooltipHost>
            );
          }
        },
      },
      {
        key: 'status',
        name: t('tasks:TabAllTasks.Fields.Status'),
        minWidth: 80,
        maxWidth: 120,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return null;

          const status = appContext.globalDataCache.taskStates.get(item.taskStateId);

          return <Text>{status ? status?.state : ''}</Text>;
        },
      },
      {
        key: 'deadline',
        name: t('tasks:TabAllTasks.Fields.Deadline'),
        minWidth: 70,
        maxWidth: 100,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        isCollapsible: true,
        onRender: (item?: Task, index?: number, column?: IColumn) => {
          if (!item) return null;

          const deadline = item.getDeadline(appContext.globalDataCache);
          let overDeadline = false;
          if (!item.completed) {
            overDeadline = item.isOverDeadline(deadline, new Date());
          }

          return (
            <Text variant="small" styles={overDeadline ? globalTextStylesError : undefined}>
              {toLocaleDateNumeric(deadline)}
            </Text>
          );
        },
      },
    ];

    if (props.hideColumns && props.hideColumns.length > 0) {
      return allColumns.filter((c) => props.hideColumns!.indexOf(c.key) === -1);
    } else {
      return allColumns;
    }
  };

  const [columns, setColumns] = useState<IColumn[]>(getColumns());

  //
  // Sorting
  //
  const initialSort = () => {
    const newItems = copyAndSort(props.subTasks, 'name', false);
    props.updateSubTasks(newItems);
    setSortLoaded(true);
  };

  const onColumnClick = (ev: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void => {
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter((currCol) => column?.key === currCol.key)[0];
    for (const newCol of newColumns) {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = false;
      }
    }

    const newItems = copyAndSort(props.subTasks, currColumn.key, currColumn.isSortedDescending);

    setColumns(newColumns);
    props.updateSubTasks(newItems);
  };

  function copyAndSort(items: Task[], columnKey: string, isSortedDescending?: boolean): Task[] {
    if (columnKey === 'status') {
      return items
        .slice(0)
        .sort((a: Task, b: Task) =>
          (isSortedDescending ? a.taskStateId < b.taskStateId : a.taskStateId > b.taskStateId) ? 1 : -1,
        );
    } else if (columnKey === 'name') {
      return items.slice(0).sort((a: Task, b: Task) => {
        return (isSortedDescending ? -1 : 1) * sortOnString(a.name, b.name);
      });
    } else if (columnKey === 'assignee') {
      return items.slice(0).sort((a: Task, b: Task) => {
        return (isSortedDescending ? -1 : 1) * sortOnString(a.user?.name, b.user?.name);
      });
    } else if (columnKey === 'deadline') {
      return items.slice(0).sort((a: Task, b: Task) => {
        return (
          (isSortedDescending ? -1 : 1) *
          sortOnDate(a.getDeadline(appContext.globalDataCache), b.getDeadline(appContext.globalDataCache))
        );
      });
    }

    return items;
  }

  //
  // Main render
  //
  if (props.subTasks.length === 0 && !props.isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('task:Subtasks.NoItems')}</Text>
      </Stack>
    );
  }

  return (
    <ShimmeredDetailsList
      compact
      items={props.subTasks}
      columns={columns}
      setKey="set"
      isHeaderVisible={true}
      enableShimmer={props.isLoading}
      shimmerLines={4}
      selection={selection}
      selectionMode={props.selectionMode || SelectionMode.none}
      selectionPreservedOnEmptyClick={true}
      layoutMode={props.layoutMode ?? DetailsListLayoutMode.justified}
      onColumnHeaderClick={onColumnClick}
      onRenderDetailsHeader={onRenderDetailsHeaderNoPaddingTopGlobal}
    />
  );
};

export default SubTaskList;
