import { useContext, useLayoutEffect, useState } from 'react';
import { Image, Link, Stack, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import { globalStackTokensGapMedium, globalStackTokensGapSmall } from 'globalStyles';
import Package from 'models/package';
import { TenantOnboarding } from 'models/tenant';
import { globalAppName } from 'globalConstants';
import Language from 'models/language';
import { imagePropsTrialWelcome } from './TrialWelcome';
import {
  freshdeskWidgetOpenTicket,
  freshdeskWidgetSetContactInfo,
  freshdeskWidgetSetTranslations,
  freshdeskWidgetSetSubject,
} from 'globalFunctions';
import TrialWelcomeTimeline from './TrialWelcomeTimeline';
import { RenderDone, RenderExplore, RenderLearn, RenderNextActions } from './TrialWelcomeNextStepActions';
import { hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';

interface ITrialWelcomeNextStepsProps {
  onboardingState: TenantOnboarding;
  updateOnboardingState: (onboardingState: TenantOnboarding) => Promise<void>;
  packages: Package[];
  updatePackage: (updatedPackage: Package) => void;
  selectedLang: Language | undefined;
}

const TrialWelcomeNextSteps = (props: ITrialWelcomeNextStepsProps) => {
  const { t } = useTranslation(['translation', 'dashboard', 'adminCompany']);
  const appContext = useContext(AppContext);
  const [showProject, setShowProject] = useState<boolean>(false);
  const [buttonTop, setButtonTop] = useState<number>(0);

  useLayoutEffect(() => {
    calcButtonTop();
  });

  const calcButtonTop = () => {
    const elm = document.getElementById('button-next-actions');
    if (elm) {
      setButtonTop(elm.clientTop + elm.offsetTop);
    }
  };

  const openTicket = () => {
    freshdeskWidgetOpenTicket();
    freshdeskWidgetSetContactInfo(appContext.user.name, appContext.user.email);
    freshdeskWidgetSetTranslations();
    freshdeskWidgetSetSubject(t('dashboard:TrialWelcome.NextSteps.Info.LinkTicketSubject'));
  };

  const updateProjectDocument = (doc: string | undefined) => {
    const newOnboardingState = props.onboardingState.clone();
    newOnboardingState.onboardingSteps.projectDocument = doc;
    props.updateOnboardingState(newOnboardingState);
  };

  const openHelpPanel = () => {
    appContext.setHelpPanel(true);
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill horizontalAlign="center" styles={{ root: { paddingRight: 20 } }}>
      <Stack.Item>
        <Stack verticalFill tokens={globalStackTokensGapMedium}>
          <Stack.Item>
            <Stack.Item>
              <Stack horizontal tokens={globalStackTokensGapMedium}>
                <Stack.Item>
                  <Image {...imagePropsTrialWelcome}></Image>
                </Stack.Item>
                <Stack.Item>
                  <Stack tokens={globalStackTokensGapSmall}>
                    <Stack.Item>
                      <Text block variant="xLarge">
                        {t('dashboard:TrialWelcome.NextSteps.Title', { appName: globalAppName })}
                      </Text>
                      <Text block variant="mediumPlus">
                        {t('dashboard:TrialWelcome.NextSteps.SubTitle')}
                      </Text>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack.Item>
          <Stack.Item>
            <Text>{t('dashboard:TrialWelcome.NextSteps.Info.Info1')}</Text>
          </Stack.Item>
          {hasUserFeatureGenericManager(appContext) && (
            <Stack.Item>
              <RenderDone onboardingState={props.onboardingState} updateOnboardingState={props.updateOnboardingState} />
            </Stack.Item>
          )}
          <Stack.Item>
            <Stack horizontal wrap tokens={globalStackTokensGapMedium}>
              <Stack.Item>
                <RenderExplore onboardingSteps={props.onboardingState.onboardingSteps} />
              </Stack.Item>
              <Stack.Item id="button-next-actions">
                <RenderNextActions
                  onboardingSteps={props.onboardingState.onboardingSteps}
                  setShowProject={setShowProject}
                />
              </Stack.Item>
              <Stack.Item>
                <RenderLearn onboardingSteps={props.onboardingState.onboardingSteps} />
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Stack.Item
            styles={{
              root: {
                top: buttonTop + 330,
              },
            }}
          >
            <Stack>
              <ul style={{ paddingLeft: 20, marginTop: 0 }}>
                <li>
                  <Text variant="small">{t('dashboard:TrialWelcome.NextSteps.Info.Info2')}</Text>{' '}
                  <Text variant="small">
                    <Link onClick={() => openHelpPanel()}>
                      {t('dashboard:TrialWelcome.NextSteps.Info.LinkHelpMenu')}
                    </Link>
                  </Text>
                </li>
                <li>
                  <Text variant="small">{t('dashboard:TrialWelcome.NextSteps.Info.LinkTicketInfo')}</Text>{' '}
                  <Text variant="small">
                    <Link onClick={() => openTicket()}>{t('dashboard:TrialWelcome.NextSteps.Info.LinkTicket')}</Link>
                  </Text>
                </li>
              </ul>
            </Stack>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      {showProject && (
        <TrialWelcomeTimeline
          isOpen={showProject}
          onClose={() => setShowProject(false)}
          onboardingState={props.onboardingState}
          onUpdateProjectDocument={updateProjectDocument}
        />
      )}
    </Stack>
  );
};

export default TrialWelcomeNextSteps;
