import React, { Fragment, useEffect, useState } from 'react';
import { Stack, Text, PrimaryButton, ChoiceGroup, IChoiceGroupOption, Toggle, DefaultButton } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapMedium, globalStackTokensGapSmall, globalTextStylesBold } from 'globalStyles';
import TrialWelcomeGoalsFrameworks from './TrialWelcomeGoalsFrameworks';
import { TenantOnboarding } from 'models/tenant';
import { isEmpty } from 'utils/string';
import { ISONorm } from 'models/norm';
import WarningMessage from 'components/Notification/WarningMessage';

interface ITrialWelcomeAskIntentProps {
  active: boolean;
  onboardingState: TenantOnboarding;
  onNext: (state: TenantOnboarding) => void;
  onPrev: (state: TenantOnboarding) => void;
  standards: ISONorm[];
}

const maxStandards = 7;

const TrialWelcomeAskIntent: React.FunctionComponent<ITrialWelcomeAskIntentProps> = (
  props: ITrialWelcomeAskIntentProps,
) => {
  const { t } = useTranslation(['translation', 'dashboard']);
  const [isCertifiedAnswer, setIsCertifiedAnswer] = useState<boolean | undefined>(
    props.onboardingState.onboardingSteps.isCertified(),
  );

  const [implementNewStandardsAnswer, setImplementNewStandardsAnswer] = useState<boolean | undefined>(
    props.onboardingState.onboardingSteps.needNewStandards(),
  );

  const [selectedCertifiedFrameworks, setSelectedCertifiedFrameworks] = useState<string[]>(
    props.onboardingState.onboardingSteps.certifiedStandards?.map((s) => s.toString()) ?? [],
  );

  const [selectedNewFrameworks, setSelectedNewFrameworks] = useState<string[]>(
    props.onboardingState.onboardingSteps.newStandards?.map((s) => s.toString()) ?? [],
  );

  const [otherCertifiedFrameworksValue, setOtherCertifiedFrameworksValue] = useState<string>(
    props.onboardingState.onboardingSteps.certifiedOtherStandards || '',
  );

  const [otherNewFrameworksValue, setOtherNewFrameworksValue] = useState<string>(
    props.onboardingState.onboardingSteps.newOtherStandards || '',
  );

  const [wantsToCertify, setWantsToCertify] = useState<boolean | undefined>(
    props.onboardingState.onboardingSteps.wantsToCertify,
  );

  const [showMaxStandardsWarning, setShowMaxStandardsWarning] = useState<boolean>(false);

  useEffect(() => {
    //restrict the maxium of selected frameworks to 7
    if (hasReachedMaxStandards(selectedCertifiedFrameworks.length + selectedNewFrameworks.length)) {
      setShowMaxStandardsWarning(true);
    } else {
      setShowMaxStandardsWarning(false);
    }
  }, [selectedCertifiedFrameworks, selectedNewFrameworks]);

  const hasReachedMaxStandards = (count: number): boolean => {
    return count >= maxStandards;
  };

  const optionsAskCertified: IChoiceGroupOption[] = [
    { key: 'yes', text: t('translation:General.Words.Yes') },
    { key: 'no', text: t('translation:General.Words.No') },
  ];

  const ansersAreNotValid = (): boolean => {
    if (isCertifiedAnswer === undefined || implementNewStandardsAnswer === undefined) return true;

    if (
      isCertifiedAnswer === true &&
      selectedCertifiedFrameworks.length === 0 &&
      isEmpty(otherCertifiedFrameworksValue)
    )
      return true;
    if (implementNewStandardsAnswer === true && selectedNewFrameworks.length === 0 && isEmpty(otherNewFrameworksValue))
      return true;

    return false;
  };

  const applyNewState = (state: TenantOnboarding): TenantOnboarding => {
    if (isCertifiedAnswer === true) {
      state.onboardingSteps.certifiedStandards = selectedCertifiedFrameworks.map((s) => parseInt(s));
    } else if (isCertifiedAnswer === false) {
      state.onboardingSteps.certifiedStandards = [];
    } else {
      state.onboardingSteps.certifiedStandards = undefined;
    }

    state.onboardingSteps.certifiedOtherStandards =
      isCertifiedAnswer === true ? otherCertifiedFrameworksValue : undefined;

    if (implementNewStandardsAnswer === true) {
      state.onboardingSteps.newStandards = selectedNewFrameworks.map((s) => parseInt(s));
    } else if (implementNewStandardsAnswer === false) {
      state.onboardingSteps.newStandards = [];
    } else {
      state.onboardingSteps.newStandards = undefined;
    }

    state.onboardingSteps.newOtherStandards =
      implementNewStandardsAnswer === true ? otherNewFrameworksValue : undefined;
    state.onboardingSteps.wantsToCertify =
      isCertifiedAnswer === true || implementNewStandardsAnswer === true ? wantsToCertify : undefined;

    return state;
  };

  //
  // Main render
  //
  if (!props.active) return null;

  return (
    <Stack verticalFill horizontalAlign="center" styles={{ root: { paddingRight: 20, paddingBottom: 20 } }}>
      <Stack verticalFill tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <Text variant="xLarge" styles={globalTextStylesBold}>
            {t('dashboard:TrialWelcome.Goals.AskCertifiedSection')}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant="large" styles={globalTextStylesBold}>
            {t('dashboard:TrialWelcome.Goals.AskCertified')}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <ChoiceGroup
            selectedKey={isCertifiedAnswer === true ? 'yes' : isCertifiedAnswer === false ? 'no' : undefined}
            options={optionsAskCertified}
            onChange={(ev, option) => {
              if (option) {
                if (option.key === 'yes') {
                  setIsCertifiedAnswer(true);
                } else {
                  setIsCertifiedAnswer(false);
                  setSelectedCertifiedFrameworks([]);
                }
              }
            }}
          />
        </Stack.Item>
        {isCertifiedAnswer === true && (
          <TrialWelcomeGoalsFrameworks
            standards={props.standards}
            allowDowngrade={true}
            hasReachedMaxStandards={showMaxStandardsWarning}
            selectedFrameworks={selectedCertifiedFrameworks}
            excludeFrameworks={selectedNewFrameworks}
            otherFrameworks={otherCertifiedFrameworksValue}
            updateFrameworks={setSelectedCertifiedFrameworks}
            updateOtherFrameworks={(val) => {
              setOtherCertifiedFrameworksValue(val);
            }}
            placeholder={t('dashboard:TrialWelcome.Goals.SelectCertifiedFrameworksPlaceholder')}
          />
        )}
        {isCertifiedAnswer !== undefined && (
          <Fragment>
            <Stack.Item styles={{ root: { paddingTop: 30 } }}>
              <Text variant="xLarge" styles={globalTextStylesBold}>
                {t('dashboard:TrialWelcome.Goals.AskNewFrameworksSection')}
              </Text>
            </Stack.Item>
            <Stack.Item>
              <Text variant="large" styles={globalTextStylesBold}>
                {t('dashboard:TrialWelcome.Goals.AskNewFrameworks')}
              </Text>
            </Stack.Item>
            <Stack.Item>
              <ChoiceGroup
                selectedKey={
                  implementNewStandardsAnswer === true
                    ? 'yes'
                    : implementNewStandardsAnswer === false
                    ? 'no'
                    : undefined
                }
                options={optionsAskCertified}
                onChange={(ev, option) => {
                  if (option) {
                    if (option.key === 'yes') {
                      setImplementNewStandardsAnswer(true);
                      setSelectedNewFrameworks([]);
                      setWantsToCertify(true);
                    } else {
                      setImplementNewStandardsAnswer(false);
                      setWantsToCertify(undefined);
                    }
                  }
                }}
              />
            </Stack.Item>
          </Fragment>
        )}
        {isCertifiedAnswer !== undefined && implementNewStandardsAnswer === true && (
          <TrialWelcomeGoalsFrameworks
            standards={props.standards}
            hasReachedMaxStandards={showMaxStandardsWarning}
            allowDowngrade={false}
            selectedFrameworks={selectedNewFrameworks}
            excludeFrameworks={selectedCertifiedFrameworks}
            otherFrameworks={otherNewFrameworksValue}
            updateFrameworks={setSelectedNewFrameworks}
            updateOtherFrameworks={setOtherNewFrameworksValue}
            placeholder={t('dashboard:TrialWelcome.Goals.SelectNewFrameworksPlaceholder')}
          />
        )}
        {isCertifiedAnswer === false && implementNewStandardsAnswer === false && (
          <Stack.Item>
            <WarningMessage message={t('dashboard:TrialWelcome.Goals.HowCanWeHelp')} />
          </Stack.Item>
        )}
        {isCertifiedAnswer !== undefined &&
          implementNewStandardsAnswer !== undefined &&
          !(isCertifiedAnswer === false && implementNewStandardsAnswer === false) && (
            <Stack.Item>
              <Toggle
                checked={wantsToCertify === true}
                onChange={(ev, checked) => setWantsToCertify(checked)}
                label={
                  <Stack>
                    <Text styles={globalTextStylesBold}>{t('dashboard:TrialWelcome.Goals.AskWantsToCertify')}</Text>
                    <Text variant="small">{t('dashboard:TrialWelcome.Goals.AskWantsToCertifyInfo')}</Text>
                  </Stack>
                }
                onText={t('translation:General.Words.Yes')}
                offText={t('translation:General.Words.No')}
              />
            </Stack.Item>
          )}
        {showMaxStandardsWarning && (
          <Stack.Item>
            <WarningMessage message={t('dashboard:TrialWelcome.Goals.MaxStandardsReached', { count: maxStandards })} />
          </Stack.Item>
        )}
        <Stack horizontal tokens={globalStackTokensGapMedium} styles={{ root: { paddingTop: 30 } }}>
          <Stack.Item>
            <DefaultButton
              text={t('dashboard:TrialWelcome.Goals.ButtonPrev')}
              onClick={() => props.onPrev(applyNewState(props.onboardingState))}
            />
          </Stack.Item>
          <Stack.Item>
            <PrimaryButton
              disabled={ansersAreNotValid()}
              text={t('dashboard:TrialWelcome.Goals.ButtonNext')}
              onClick={() => props.onNext(applyNewState(props.onboardingState))}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TrialWelcomeAskIntent;
