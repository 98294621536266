import React, { useContext } from 'react';
import { ComboBox, IComboBox, IComboBoxOption, Image, PrimaryButton, Stack, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import { globalStackTokensGapMedium, globalStackTokensGapSmall, globalTextStylesBold } from 'globalStyles';
import { TenantOnboarding } from 'models/tenant';
import { globalAppName, supportedUILanguages } from 'globalConstants';
import Language from 'models/language';
import { hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';
import { imagePropsTrialWelcome } from './TrialWelcome';

interface ITrialWelcomeLanguageProps {
  onboardingState: TenantOnboarding;
  updateOnboardingState: (onboardingState: TenantOnboarding) => void;
  updateOrgLang: (langCode: string) => void;
  updateUserLang: (langCode: string) => void;
  languages: Language[];
  selectedLang: Language | undefined;
  onNext: (state: TenantOnboarding) => void;
}

const TrialWelcomeLanguage = (props: ITrialWelcomeLanguageProps) => {
  const { t } = useTranslation(['translation', 'dashboard', 'adminCompany']);
  const appContext = useContext(AppContext);

  const getLanguagesOptions = (): IComboBoxOption[] => {
    return props.languages
      .filter((l) => supportedUILanguages.has(l.code))
      .map((l) => {
        return {
          key: l.code,
          text: l.name,
        };
      });
  };

  const applyNewState = (state: TenantOnboarding): TenantOnboarding => {
    if (!props.selectedLang) return state;
    const newOnboardingState = state.clone();
    newOnboardingState.onboardingSteps.languageIsSet = true;
    props.updateOrgLang(props.selectedLang.code);

    return newOnboardingState;
  };

  //
  // Main render
  //
  return (
    <Stack
      verticalFill
      tokens={globalStackTokensGapMedium}
      horizontalAlign="center"
      styles={{ root: { paddingRight: 20, paddingBottom: 20 } }}
    >
      <Stack.Item>
        <Stack horizontal tokens={globalStackTokensGapMedium}>
          <Stack.Item>
            <Image {...imagePropsTrialWelcome}></Image>
          </Stack.Item>
          <Stack.Item>
            <Stack tokens={globalStackTokensGapSmall}>
              <Stack.Item>
                <Text block variant="xLarge">
                  {t('dashboard:TrialWelcome.Info1', { appName: globalAppName })}
                </Text>
                <Text block variant="mediumPlus">
                  {t('dashboard:TrialWelcome.Info2')}
                </Text>
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </Stack.Item>
      <Stack.Item>
        <iframe
          src={`https://player.vimeo.com/video/1039459553?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
          width={appContext.isMobileView ? 350 : 500}
          height={appContext.isMobileView ? 180 : 250}
          allow="autoplay; fullscreen; picture-in-picture"
        />
      </Stack.Item>
      <Stack.Item>
        <Stack verticalFill tokens={globalStackTokensGapMedium}>
          <Stack.Item></Stack.Item>
          <Stack.Item>
            <Text block variant="large" styles={globalTextStylesBold}>
              {t('dashboard:TrialWelcome.Language.Title')}
            </Text>
          </Stack.Item>
          <Stack.Item>
            <ComboBox
              className="redlab-usetiful-welcome-language"
              disabled={!hasUserFeatureGenericManager(appContext)}
              allowFreeInput
              options={getLanguagesOptions()}
              selectedKey={props.selectedLang?.code}
              onChange={(event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
                if (!option) return;
                props.updateUserLang(option.key as string);
              }}
              styles={{
                root: {
                  maxWidth: 300,
                },
                optionsContainer: {
                  maxHeight: 350,
                },
              }}
              useComboBoxAsMenuWidth
            />
          </Stack.Item>
          <Stack.Item>
            <PrimaryButton
              disabled={!props.selectedLang}
              text={t('dashboard:TrialWelcome.Goals.ButtonNext')}
              onClick={() => props.onNext(applyNewState(props.onboardingState))}
            />
          </Stack.Item>
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default TrialWelcomeLanguage;
