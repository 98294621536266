import {
  CopilotConversionItemType,
  CopilotManagerGeneralPromptType,
  CopilotManagerPromptType,
} from 'models/copilot/copilotConversation';

export class CopilotConversationDTO {
  id?: string;

  request?: CopilotConversationItemDTO;

  thread: CopilotConversationItemDTO[];

  constructor() {
    this.thread = [];
  }
}

export class CopilotConversationBaseItemDTO {
  question?: string;

  context?: string;

  promptType?: CopilotManagerPromptType;

  generalPromptType?: CopilotManagerGeneralPromptType;

  response?: string;
}

export class CopilotConversationItemDTO extends CopilotConversationBaseItemDTO {
  id?: string;

  type: CopilotConversionItemType;

  date?: string;

  userId?: string;

  constructor() {
    super();
    this.type = CopilotConversionItemType.User;
  }
}
