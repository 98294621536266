import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nav, INavLink, INavStyles, INavLinkGroup } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import AppContext from 'App/AppContext';
import { ModuleAssets, ModuleControls, ModuleOrg, ModuleRisks, ModuleStandards, ModuleThemes } from 'models/setting';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';

export interface INavMainProps {
  collapsed: boolean;
  selectedKey?: string | undefined;
  expandedOnHover: boolean;
}

const NavMain = (props: INavMainProps) => {
  const { t } = useTranslation(['translation']);
  const history = useHistory();
  const appContext = useContext(AppContext);
  const [isExpandedAdmin, setIsExpandedAdmin] = useState<boolean>(false);
  const [isExpandedTenantAdmin, setIsExpandedTenantAdmin] = useState<boolean>(false);
  const [isExpandedPartnerAdmin, setIsExpandedPartnerAdmin] = useState<boolean>(false);
  const [selectedNavKey, setSelectedNavKey] = useState<string | undefined>('start');

  useEffect(() => {
    const locationArray = window.location.pathname.split('/');
    switch (locationArray[1]) {
      case 'dashboard':
        locationArray[1] = 'start';
        break;
      case 'control':
        locationArray[1] = 'controls';
        break;
      case 'asset':
        locationArray[1] = 'assets';
        break;
      case 'risk':
        locationArray[1] = 'risks';
        break;
      case 'objective':
      case 'process':
        locationArray[1] = 'organization';
        break;
      case 'theme':
        locationArray[1] = 'themes';
        break;
      case 'admin':
        setIsExpandedAdmin(true);
        locationArray[1] = locationArray[1] + '-' + locationArray[2];
        break;
      case 'tenant':
        setIsExpandedTenantAdmin(true);
        locationArray[1] = 'tenantadmin-' + locationArray[2];
        break;
      case 'partner':
        setIsExpandedPartnerAdmin(true);
        locationArray[1] = 'partneradmin-' + locationArray[2];
        break;
      case 'schedule':
        locationArray[1] = 'schedule';
        break;
      case 'standards':
        locationArray[1] = 'standards';
        break;
      case 'library':
        locationArray[1] = 'library';
        break;
      case 'tasks':
        locationArray[1] = 'tasks';
        break;
      default:
        break;
    }
    setSelectedNavKey(locationArray[1]);
    // eslint-disable-next-line
  }, [window.location.pathname]);

  const navStyles: Partial<INavStyles> = {
    root: {
      boxSizing: 'border-box',
      overflowY: 'auto',
    },
    link: {
      paddingLeft: props.collapsed ? 10 : undefined,
      whiteSpace: 'normal',
      lineHeight: 'inherit',
    },
    linkText: {
      paddingLeft: props.collapsed ? 10 : undefined,
    },
    chevronIcon: {
      paddingLeft: props.collapsed && !props.expandedOnHover ? 6 : undefined,
    },
    groupContent: {
      marginBottom: 10,
    },
  };

  //
  // Base main group that is always visible
  //
  const startGroup: INavLinkGroup = {
    links: [
      {
        name: t('translation:NavMain.Start'),
        url: '',
        link: '/',
        key: 'start',
        iconProps: { iconName: 'GoToDashboard', className: 'redlab-usetiful-nav-main-start' },
      },
    ],
  };

  //
  // Define groups that can be turned on/off via module settings or permissions
  //
  const annualPlanGroup: INavLink = {
    name: t('translation:NavMain.Schedule'),
    url: '',
    link: '/schedule',
    key: 'schedule',
    iconProps: { iconName: 'CalendarAgenda', className: 'redlab-usetiful-nav-main-schedule' },
  };

  const tasksGroup: INavLink = {
    name: t('translation:NavMain.Tasks'),
    url: '',
    link: '/tasks',
    key: 'tasks',
    iconProps: { iconName: 'TaskManager', className: 'redlab-usetiful-nav-main-tasks' },
  };

  const libraryGroup: INavLink = {
    name: t('translation:NavMain.Library'),
    url: '',
    link: '/library',
    key: 'library',
    iconProps: { iconName: 'DocumentSet', className: 'redlab-usetiful-nav-main-library' },
  };

  const assetGroup: INavLink = {
    name: t('translation:NavMain.Assets'),
    url: '',
    link: '/assets',
    key: 'assets',
    iconProps: { iconName: 'FormLibrary', className: 'redlab-usetiful-nav-main-assets' },
  };

  const riskGroup: INavLink = {
    name: t('translation:NavMain.Risks'),
    url: '',
    link: '/risks',
    key: 'risks',
    iconProps: { iconName: 'Warning12', className: 'redlab-usetiful-nav-main-risks' },
  };

  const controlGroup: INavLink = {
    name: t('translation:NavMain.Controls'),
    url: '',
    link: '/controls',
    key: 'controls',
    iconProps: { iconName: 'Bullseye', className: 'redlab-usetiful-nav-main-controls' },
  };

  const themesGroup: INavLink = {
    name: t('translation:NavMain.Requirements'),
    url: '',
    link: '/themes',
    key: 'themes',
    iconProps: { iconName: 'ButtonControl', className: 'redlab-usetiful-nav-main-requirements' },
  };

  const orgGroup: INavLink = {
    name: t('translation:NavMain.Organization'),
    url: '',
    key: 'organization',
    link: '/organization',
    iconProps: { iconName: 'SplitObject', className: 'redlab-usetiful-nav-main-org' },
  };

  const standardsGroup: INavLink = {
    name: t('translation:NavMain.Norms'),
    url: '',
    link: '/standards',
    key: 'standards',
    iconProps: { iconName: 'MapLayers', className: 'redlab-usetiful-nav-main-standards' },
  };

  const baseAdminGroup: INavLink[] = [
    {
      name: t('translation:NavManagement.Reports'),
      url: '',
      link: '/admin/reports',
      key: 'admin-reports',
      iconProps: { iconName: 'ReportLibrary', className: 'redlab-usetiful-nav-main-admin-reports' },
    },
    {
      name: t('translation:NavManagement.Store'),
      url: '',
      link: '/admin/store',
      key: 'admin-store',
      iconProps: { iconName: 'OfficeStoreLogo', className: 'redlab-usetiful-nav-main-admin-store' },
    },
    {
      name: t('translation:NavManagement.Norms'),
      url: '',
      link: '/admin/standards',
      key: 'admin-standards',
      iconProps: { iconName: 'ProductList', className: 'redlab-usetiful-nav-main-admin-standards' },
    },
    {
      name: t('translation:NavManagement.Settings'),
      url: '',
      link: '/admin/settings',
      key: 'admin-settings',
      iconProps: { iconName: 'Settings', className: 'redlab-usetiful-nav-main-admin-settings' },
    },
    {
      name: t('translation:NavManagement.Groups'),
      url: '',
      link: '/admin/groups',
      key: 'admin-groups',
      iconProps: { iconName: 'AddGroup', className: 'redlab-usetiful-nav-main-admin-groups' },
    },
  ];

  //
  // The admin group is available for every user with a manager, consultant or admin role
  //
  const getAdminGroup = (): INavLinkGroup => {
    const adminGroup: INavLinkGroup = {
      links: [
        {
          name: props.collapsed && !props.expandedOnHover ? '' : t('translation:NavAdmin.Title'),
          isExpanded: isExpandedAdmin,
          data: 2,
          url: '',
          links: [],
        },
      ],
    };

    if (hasUserFeature(appContext, FeatureTypes.Admin)) {
      adminGroup.links[0].links?.push(...baseAdminGroup);
    }

    if (hasUserFeature(appContext, FeatureTypes.AdminAuth)) {
      adminGroup.links[0].links?.push({
        name: t('translation:NavAdmin.Authorization'),
        url: '',
        link: '/admin/users',
        key: 'admin-auth',
        icon: 'FabricUserFolder',
        iconProps: { iconName: 'FabricUserFolder', className: 'redlab-usetiful-nav-main-auth' },
      });
    }

    if (hasUserFeature(appContext, FeatureTypes.AdminSubscription)) {
      adminGroup.links[0].links?.push({
        name: t('translation:NavAdmin.Subscription'),
        url: '',
        link: '/admin/subscription',
        key: 'admin-subscription',
        icon: 'Subscribe',
        iconProps: { iconName: 'Subscribe', className: 'redlab-usetiful-nav-main-subscription' },
      });
    }

    //License managers can show the Admin group but cannot manage organizational units
    if (hasUserFeature(appContext, FeatureTypes.AdminOrgUnits)) {
      adminGroup.links[0].links?.push({
        name: t('translation:NavAdmin.OrgUnits'),
        url: '',
        link: '/admin/orgunits',
        key: 'admin-orgunits',
        icon: 'Org',
        iconProps: { iconName: 'Org', className: 'redlab-usetiful-nav-main-orgunits' },
      });
    }

    return adminGroup;
  };

  //
  // The partner admin group is availale for every tenant that is registered with a Partner subscription
  //
  const getPartnerAdminGroup = () => {
    const items: INavLinkGroup = {
      links: [
        {
          name: props.collapsed && !props.expandedOnHover ? '' : t('translation:NavPartnerAdmin.Title'),
          url: '',
          isExpanded: isExpandedPartnerAdmin,
          data: 4,
          links: [
            {
              name: t('translation:NavPartnerAdmin.Customers'),
              url: '',
              link: '/partner/customers',
              key: 'partneradmin-customers',
              icon: 'PlayerSettings',
              iconProps: { iconName: 'PlayerSettings', className: 'redlab-usetiful-nav-main-partner' },
            },
          ],
        },
      ],
    };

    return items;
  };

  //
  // The tenant ISO admin group is availale for every user with the tenant ISO admin role
  //
  const getTenantAdminGroup = () => {
    const items: INavLinkGroup = {
      links: [
        {
          name: props.collapsed && !props.expandedOnHover ? '' : t('translation:NavTenantAdmin.Title'),
          url: '',
          isExpanded: isExpandedTenantAdmin,
          data: 3,
          links: [
            {
              name: t('translation:NavTenantAdmin.Controls'),
              url: '',
              link: '/tenant/controls',
              key: 'tenantadmin-controls',
              icon: 'CreateMailRule',
              iconProps: { iconName: 'CreateMailRule', className: 'redlab-usetiful-nav-main-tenantadmin' },
            },
          ],
        },
      ],
    };

    if (hasUserFeature(appContext, FeatureTypes.TenantManagement)) {
      items.links[0].links?.push({
        name: t('translation:NavTenantAdmin.Manage'),
        url: '',
        link: '/tenant/manage',
        key: 'tenantadmin-manage',
        icon: 'FabricUserFolder',
      });

      items.links[0].links?.push({
        name: t('translation:NavTenantAdmin.Logs'),
        url: '',
        link: '/tenant/logs',
        key: 'tenantadmin-logs',
        icon: 'SearchData',
      });
    }

    return items;
  };

  const getNavLinks = (): INavLinkGroup[] => {
    const navLinks: INavLinkGroup[] = [];
    const mainGroup: INavLinkGroup = { links: [] };

    //
    // Add the groups that are 'on' to the main group
    //
    if (
      appContext.isAuthenticated &&
      (appContext.globalDataCache.settings.get(ModuleOrg) as boolean) !== false &&
      hasUserFeature(appContext, FeatureTypes.Organization)
    ) {
      mainGroup.links.push(orgGroup);
    }

    if (
      appContext.isAuthenticated &&
      (appContext.globalDataCache.settings.get(ModuleThemes) as boolean) !== false &&
      hasUserFeature(appContext, FeatureTypes.Requirements)
    ) {
      mainGroup.links.push(themesGroup);
    }

    if (
      appContext.isAuthenticated &&
      (appContext.globalDataCache.settings.get(ModuleAssets) as boolean) !== false &&
      hasUserFeature(appContext, FeatureTypes.Assets)
    ) {
      mainGroup.links.push(assetGroup);
    }

    if (
      appContext.isAuthenticated &&
      (appContext.globalDataCache.settings.get(ModuleRisks) as boolean) !== false &&
      hasUserFeature(appContext, FeatureTypes.Risks)
    ) {
      mainGroup.links.push(riskGroup);
    }

    if (
      appContext.isAuthenticated &&
      (appContext.globalDataCache.settings.get(ModuleControls) as boolean) !== false &&
      hasUserFeature(appContext, FeatureTypes.Controls)
    ) {
      mainGroup.links.push(controlGroup);
    }

    if (appContext.isAuthenticated && hasUserFeature(appContext, FeatureTypes.AnnualPlan)) {
      mainGroup.links.push(annualPlanGroup);
    }

    if (appContext.isAuthenticated && hasUserFeature(appContext, FeatureTypes.Tasks)) {
      mainGroup.links.push(tasksGroup);
    }

    if (appContext.isAuthenticated && hasUserFeature(appContext, FeatureTypes.Library)) {
      mainGroup.links.push(libraryGroup);
    }

    if (
      appContext.isAuthenticated &&
      (appContext.globalDataCache.settings.get(ModuleStandards) as boolean) !== false &&
      hasUserFeature(appContext, FeatureTypes.Standards)
    ) {
      mainGroup.links.push(standardsGroup);
    }

    //always show Start
    navLinks.push(startGroup);

    //when there is an active license and user is allowed to see the dashboard, show the main group
    if (
      appContext.user.login.tenantLicensed &&
      appContext.user.login.userLicensed &&
      hasUserFeature(appContext, FeatureTypes.Dashboard)
    ) {
      navLinks.push(mainGroup);
    }

    //for the admin groups, the user should have a license and appropriate roles
    //show these groups in 1 extra condition so that an admin user can subscribe again when subscription/trial has ended:
    //- tenant is registered
    //- tenantLicensed = false
    //- userLicensed = false
    if (
      appContext.user.login &&
      (appContext.user.login.userLicensed ||
        (appContext.user.login.tenantId &&
          appContext.user.login.userLicensed === false &&
          appContext.user.login.tenantLicensed === false))
    ) {
      if (
        hasUserFeature(appContext, FeatureTypes.Admin) ||
        hasUserFeature(appContext, FeatureTypes.AdminOrgUnits) ||
        hasUserFeature(appContext, FeatureTypes.AdminAuth) ||
        hasUserFeature(appContext, FeatureTypes.AdminSubscription)
      ) {
        navLinks.push(getAdminGroup());
      }
      if (hasUserFeature(appContext, FeatureTypes.PartnerAdmin)) {
        navLinks.push(getPartnerAdminGroup());
      }
      if (hasUserFeature(appContext, FeatureTypes.TenantAdmin)) {
        navLinks.push(getTenantAdminGroup());
      }
    }

    return navLinks;
  };

  //
  // Main render
  //
  return (
    <Nav
      selectedKey={selectedNavKey}
      styles={navStyles}
      groups={getNavLinks()}
      onLinkClick={(ev, item?: INavLink) => {
        if (item && item.link) {
          setSelectedNavKey(item.key);
          const url = item.link;
          history.push(url);
          if (appContext.isMobileView) {
            appContext.toggleSideBarPanel();
          }
        }
      }}
      onLinkExpandClick={(ev, item) => {
        if (item?.data) {
          switch (item.data) {
            case 2:
              setIsExpandedAdmin(!item.isExpanded || false);
              break;
            case 3:
              setIsExpandedTenantAdmin(!item.isExpanded || false);
              break;
            case 4:
              setIsExpandedPartnerAdmin(!item.isExpanded || false);
              break;
          }
        }
      }}
    />
  );
};

export default NavMain;
