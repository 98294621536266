import { newGuidNil } from 'utils/guid';
import { SubscriptionTypes } from 'utils/subscription';
import { StoreMgmtStates } from './../adminTenant';
import SettingDTO from './settingDTO';

export default class TenantDTO {
  id: string;

  parentId?: string;

  topLevelParentTenantId: string;

  azureTenantId?: string;

  name: string;

  created?: Date;

  storeMgmtState: number;

  onboardingState?: TenantOnboardingDTO;

  subscriptionType: number;

  settings?: SettingDTO[];

  timeZoneCode?: string;

  currentUserLicensed?: boolean;

  logo?: Blob;

  constructor() {
    this.id = newGuidNil();
    this.topLevelParentTenantId = newGuidNil(); //default same as id
    this.name = 'unknown';
    this.storeMgmtState = StoreMgmtStates.Customer;
    this.subscriptionType = SubscriptionTypes.Basic;
  }
}

export class TenantOnboardingDTO {
  packageIds: string[];

  leadSource?: string;

  timeZoneOffset?: number;

  onboardingSteps: TenantOnboardingStepsDTO;

  constructor() {
    this.packageIds = [];
    this.onboardingSteps = new TenantOnboardingStepsDTO();
  }
}

export class TenantOnboardingStepsDTO {
  currentStep: number | undefined;

  certifiedStandards: number[] | undefined;

  certifiedOtherStandards: string | undefined;

  newStandards: number[] | undefined;

  newOtherStandards: string | undefined;

  wantsToCertify: boolean | undefined;

  currentSystem: number | undefined;

  currentOtherSystem: string | undefined;

  currentSystemImprovement: string | undefined;

  startDate: string | undefined;

  endDate: string | undefined;
  
  dashboardName: string | undefined;

  dashboardWidgetTaskStatusName: string | undefined;

  dashboardWidgetTaskTagName: string | undefined;

  languageIsSet: boolean | undefined;

  optInAI: boolean = false;

  dashboardId: number | undefined;

  previewPackageInstalled: boolean | undefined;

  projectMilestonesCreated: boolean | undefined;
}

export class ImportOrgUnitDTO {
  sourceId: string;

  targetId: string;

  method: string;

  constructor() {
    this.sourceId = '';
    this.targetId = '';
    this.method = '';
  }
}
