import { Coachmark, DirectionalHint, Target, TeachingBubbleContent } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { useContext, useEffect, useState } from 'react';
import { apiRequest } from 'services/Auth/authConfig';
import { CoachMarkDefinition, CoachTypes, getCoachMarkTimeline } from './CoachMarkDefinitions';
import { useTranslation } from 'react-i18next';
import { apiCreateActivity, apiGetActivitiesForUserTypeEntity } from 'services/Api/activityService';
import { apiDismissActivity } from 'services/Api/dashboardService';
import Activity, { ActivityType } from 'models/activity';
import { EntityTypes } from 'models/entity';

interface ICoachProps {
  target: Target;
  type: CoachTypes;
  direction?: DirectionalHint;
}

const Coach = (props: ICoachProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation', 'coachmarks']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [activity, setActivity] = useState<Activity | undefined>(undefined);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      setIsLoading(true);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const activities = await apiGetActivitiesForUserTypeEntity(
        ActivityType.UserNotification,
        EntityTypes.CoachMark,
        props.type,
        accessToken,
      );
      const activity = activities[0];
      setActivity(activity);
      const shouldShow = activity?.dismissed === undefined;
      setShow(shouldShow);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const saveData = async (dismiss: boolean) => {
    try {
      setShow(false);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      if (activity) {
        if (dismiss) {
          await apiDismissActivity(activity.activityId, accessToken);
        }
      } else {
        const newActivity = new Activity();
        newActivity.typeOfActivity = ActivityType.UserNotification;
        newActivity.entity.typeOfEntity = EntityTypes.CoachMark;
        newActivity.entity.entityId = props.type;
        newActivity.dismissed = dismiss ? new Date() : undefined;
        newActivity.userId = appContext.user?.id;
        const createdActivity = await apiCreateActivity(newActivity, true, true, true, accessToken);
        if (createdActivity) {
          setActivity(createdActivity);
        } else {
          setActivity(newActivity); //there is an activity in the back-end, we could retrieve it but this will do: we don't need the Id
        }
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  //
  // Main render
  //
  if (isLoading || !show) {
    return null;
  }

  let content: CoachMarkDefinition | undefined;

  switch (props.type) {
    case CoachTypes.Timeline:
      content = getCoachMarkTimeline(t);
      break;
    default:
      return null;
  }

  return (
    <Coachmark
      target={props.target}
      delayBeforeMouseOpen={1000}
      positioningContainerProps={{
        directionalHint: props.direction,
      }}
    >
      <TeachingBubbleContent
        headline={content.title}
        primaryButtonProps={{
          text: t('coachmarks:Buttons.GotIt'),
          onClick: () => {
            saveData(true);
          },
        }}
        secondaryButtonProps={{
          text: t('coachmarks:Buttons.RemindMeLater'),
          onClick: () => {
            saveData(false);
          },
        }}
      >
          {content.jsx}
      </TeachingBubbleContent>
    </Coachmark>
  );
};

export default Coach;
