import { useContext, useState } from 'react';
import {
  ActionButton,
  FontIcon,
  getTheme,
  Image,
  ITheme,
  Link,
  PrimaryButton,
  SharedColors,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import AppContext, { IAppContext } from 'App/AppContext';
import {
  globalBoxBorder,
  globalBoxBorderRadius,
  globalBoxShadow,
  globalStackTokensGapSmall,
  globalTextStylesBold,
} from 'globalStyles';
import { useHistory } from 'react-router-dom';
import { TenantOnboarding, TenantOnboardingSteps } from 'models/tenant';
import {
  globalKB_Demo,
  globalKB_howToSetupLibrary,
  globalKB_howToWorkWithTasks,
  globalKB_ISOBasicInfo,
  globalKB_Training,
  globalPartnerProgramUrl,
} from 'globalConstants';
import { navigateToExternalUrl } from 'utils/url';
import { downloadProjectDocument } from './TrialWelcomeTimeline';
import Config from 'services/Config/configService';
import { getLocalStorageData, LocalStorageKeys, setLocalStorageData } from 'utils/localstorage';
import { hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';
import { TagSystemTypes } from 'models/tag';
import { AIEnabled } from 'models/setting';

interface INextStepActionsProps {
  onboardingSteps: TenantOnboardingSteps;
}

const maxClosedItemsPerWidget = 2;

const canShowItem = (isOpen: boolean, nr: number): boolean => {
  return isOpen || nr <= maxClosedItemsPerWidget;
};

const navigateToKB = (kbLink: string) => {
  navigateToExternalUrl(kbLink, '', '', true);
};

const getWidgetStyle = (isOpen: boolean, appContext: IAppContext, theme: ITheme) => {
  return {
    root: {
      border: globalBoxBorder,
      borderRadius: globalBoxBorderRadius,
      boxShadow: globalBoxShadow,
      paddingTop: 20,
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 0,
      height: isOpen ? 270 : 180,
      width: 350,
      background: appContext.useDarkMode ? theme.palette.neutralDark : theme.palette.white,
      overflowY: 'hidden',
      transition: 'height 0.5s',
    },
  };
};

//
// RenderNextActions
//
interface IRenderNextActionsProps extends INextStepActionsProps {
  setShowProject: (value: boolean) => void;
}

export const RenderNextActions = (props: IRenderNextActionsProps) => {
  const { t } = useTranslation(['translation', 'dashboard', 'adminCompany']);
  const appContext = useContext(AppContext);
  const theme = getTheme();
  const history = useHistory();

  const loadActionsOpen = (): boolean => {
    const savedActionsOpen = getLocalStorageData(appContext, LocalStorageKeys.NextStepsActionsOpen);

    return savedActionsOpen === 'true';
  };

  const [actionsOpen, setActionsOpen] = useState<boolean>(loadActionsOpen());

  const saveActionsOpen = (value: boolean) => {
    setActionsOpen(value);
    setLocalStorageData(appContext, LocalStorageKeys.NextStepsActionsOpen, value === true ? 'true' : 'false');
  };

  const navigateToSubscription = () => {
    const url = '/admin/subscription';
    history.push(url);
  };

  const openUserAdmin = () => {
    const url = '/admin/users/licensing#add';
    history.push(url);
  };

  const openGeneralSettings = () => {
    const url = '/admin/settings/general';
    history.push(url);
  };

  const openIntegrationSettings = () => {
    const url = '/admin/settings/integration';
    history.push(url);
  };

  const openAutomationSettings = () => {
    const url = '/admin/settings/automation';
    history.push(url);
  };

  const getAction1 = (nr: number) => {
    //When props.onboardingSteps.projectMilestonesCreated is undefined, the customer did not do the onboarding yet
    //In this case, we do not show the partner program link, only when the user chose do install no standards (which results in projectMilestonesCreated == false)
    const showPartnerProgram = props.onboardingSteps.projectMilestonesCreated === false;

    if (!showPartnerProgram && appContext.user.isAdmin()) {
      return (
        <Stack.Item>
          <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.NextActions.Item1aTooltip')}>
            <Text>
              <Link
                onClick={(ev) => {
                  openUserAdmin();
                  ev.stopPropagation();
                }}
              >
                {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.NextActions.Item1a')}`}
              </Link>
            </Text>
          </TooltipHost>
        </Stack.Item>
      );
    }

    if (showPartnerProgram) {
      return (
        <Stack.Item>
          <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.NextActions.Item1bTooltip')}>
            <Text>
              <Link
                onClick={(ev) => {
                  navigateToKB(globalPartnerProgramUrl);
                  ev.stopPropagation();
                }}
              >
                {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.NextActions.Item1b')}`}
              </Link>
            </Text>
          </TooltipHost>
        </Stack.Item>
      );
    }

    return null;
  };

  const getAction2 = (nr: number) => {
    const settingAIEnabled = (appContext.globalDataCache.settings.get(AIEnabled) as boolean) === true;

    if (props.onboardingSteps.projectMilestonesCreated && props.onboardingSteps.projectDocument && settingAIEnabled) {
      return (
        <Stack.Item>
          <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.NextActions.Item2Tooltip')}>
            <Text>
              <Link
                onClick={(ev) => {
                  if (props.onboardingSteps.projectDocument) {
                    downloadProjectDocument(props.onboardingSteps.projectDocument, t);
                  }
                  ev.stopPropagation();
                }}
              >
                {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.NextActions.Item2')}`}
              </Link>
            </Text>
          </TooltipHost>
        </Stack.Item>
      );
    }

    if (props.onboardingSteps.projectMilestonesCreated && !props.onboardingSteps.projectDocument && settingAIEnabled) {
      return (
        <Stack.Item>
          <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.NextActions.Item2aTooltip')}>
            <Text>
              <Link
                onClick={(ev) => {
                  props.setShowProject(true);
                  ev.stopPropagation();
                }}
              >
                {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.NextActions.Item2a')}`}
              </Link>
            </Text>
          </TooltipHost>
        </Stack.Item>
      );
    }

    if (!settingAIEnabled) {
      return (
        <Stack.Item>
          <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.NextActions.Item2bTooltip')}>
            <Text>
              <Link
                onClick={(ev) => {
                  openAutomationSettings();
                  ev.stopPropagation();
                }}
              >
                {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.NextActions.Item2b')}`}
              </Link>
            </Text>
          </TooltipHost>
        </Stack.Item>
      );
    }

    return null;
  };

  const getAction3 = (nr: number) => {
    return (
      <Stack.Item>
        <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.NextActions.Item3Tooltip')}>
          <Text>
            <Link
              onClick={(ev) => {
                openIntegrationSettings();
                ev.stopPropagation();
              }}
            >
              {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.NextActions.Item3')}`}
            </Link>
          </Text>
        </TooltipHost>
      </Stack.Item>
    );
  };

  const getAction4 = (nr: number) => {
    return (
      <Stack.Item>
        <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.NextActions.Item4Tooltip')}>
          <Text>
            <Link
              onClick={(ev) => {
                openGeneralSettings();
                ev.stopPropagation();
              }}
            >
              {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.NextActions.Item4')}`}
            </Link>
          </Text>
        </TooltipHost>
      </Stack.Item>
    );
  };

  const getAction5 = (nr: number) => {
    if (appContext.user.isAdmin()) {
      return (
        <Stack.Item>
          <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.NextActions.Item5Tooltip')}>
            <Text>
              <Link
                onClick={(ev) => {
                  navigateToSubscription();
                  ev.stopPropagation();
                }}
              >
                {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.NextActions.Item5')}`}
              </Link>
            </Text>
          </TooltipHost>
        </Stack.Item>
      );
    }

    return null;
  };

  const getActions = () => {
    const actions: JSX.Element[] = [];
    let nr = 1;

    for (let idx = 1; idx <= 5; idx++) {
      let action = null;

      switch (idx) {
        case 1:
          action = getAction1(nr);
          break;
        case 2:
          action = getAction2(nr);
          break;
        case 3:
          action = getAction3(nr);
          break;
        case 4:
          action = getAction4(nr);
          break;
        case 5:
          action = getAction5(nr);
          break;
      }

      if (action) {
        const canShow = canShowItem(actionsOpen, nr);
        if (canShow) {
          actions.push(action);
          nr++;
        } else {
          hasMoreItems = true;
        }
      }
    }

    return actions;
  };

  //
  // Main render
  //

  //only show next actions for managers/consultants/admins
  if (!hasUserFeatureGenericManager(appContext)) return null;

  let hasMoreItems = false;

  return (
    <Stack tokens={globalStackTokensGapSmall} styles={getWidgetStyle(actionsOpen, appContext, theme)}>
      <Stack.Item grow>
        <Stack tokens={globalStackTokensGapSmall}>
          <Stack
            horizontal
            tokens={globalStackTokensGapSmall}
            horizontalAlign="center"
            verticalAlign="center"
            styles={{ root: { paddingBottom: 10 } }}
          >
            <Image src={`${Config.getImageURL()}/onboarding-next-steps.png`} width={32} height={32} />
            <Text variant="large" styles={globalTextStylesBold}>
              {t('dashboard:TrialWelcome.NextSteps.NextActions.Title')}
            </Text>
          </Stack>
          {getActions()}
        </Stack>
      </Stack.Item>
      {(actionsOpen || hasMoreItems) && (
        <Stack.Item>
          <Stack horizontal horizontalAlign="center">
            <ActionButton
              onClick={() => saveActionsOpen(!actionsOpen)}
              iconProps={{ iconName: actionsOpen ? 'ChevronUp' : 'ChevronDown' }}
              text={
                actionsOpen
                  ? t('dashboard:TrialWelcome.NextSteps.NextActions.Less')
                  : t('dashboard:TrialWelcome.NextSteps.NextActions.More')
              }
            />
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
};

//
// RenderExplore
//
export const RenderExplore = (props: INextStepActionsProps) => {
  const { t } = useTranslation(['translation', 'dashboard', 'adminCompany']);
  const appContext = useContext(AppContext);
  const theme = getTheme();
  const history = useHistory();

  const loadExploreOpen = (): boolean => {
    const savedExploreOpen = getLocalStorageData(appContext, LocalStorageKeys.NextStepsExploreOpen);

    return savedExploreOpen === 'true';
  };

  const [exploreOpen, setExploreOpen] = useState<boolean>(loadExploreOpen());

  const saveExploreOpen = (value: boolean) => {
    setExploreOpen(value);
    setLocalStorageData(appContext, LocalStorageKeys.NextStepsExploreOpen, value === true ? 'true' : 'false');
  };

  const navigateToProject = () => {
    const groupNames = appContext.globalDataCache.tags.getGroupNamesForSystemType(TagSystemTypes.Project);
    const url = `/tasks/alltasks?view=timeline&tagGroups=${groupNames.join(',')}`;
    history.push(url);
  };

  const navigateToStore = () => {
    const url = '/admin/store/content';
    history.push(url);
  };

  const navigateToLibrary = () => {
    const url = '/library';
    history.push(url);
  };

  const navigateToDashboard = () => {
    const url = getDashboardLink(props.onboardingSteps.dashboardId);
    history.push(url);
  };

  const getDashboardLink = (id: number | undefined): string => {
    if (!id) return '/dashboard/org';

    return `/dashboard/org#${id}`;
  };

  const getAction1 = (nr: number) => {
    if (hasUserFeatureGenericManager(appContext) && props.onboardingSteps.projectMilestonesCreated === true) {
      return (
        <Stack.Item>
          <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.Explore.Item1Tooltip')}>
            <Text>
              <Link
                onClick={(ev) => {
                  navigateToProject();
                  ev.stopPropagation();
                }}
              >
                {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.Explore.Item1')}`}
              </Link>
            </Text>
          </TooltipHost>
        </Stack.Item>
      );
    }

    return null;
  };

  const getAction2 = (nr: number) => {
    if (hasUserFeatureGenericManager(appContext)) {
      return (
        <Stack.Item>
          <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.Explore.Item2Tooltip')}>
            <Text>
              <Link
                onClick={(ev) => {
                  ev.stopPropagation();
                }}
                className="redlab-usetiful-start-tour"
              >
                {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.Explore.Item2')}`}
              </Link>
            </Text>
          </TooltipHost>
        </Stack.Item>
      );
    }

    return null;
  };

  const getAction3 = (nr: number) => {
    return (
      <Stack.Item>
        <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.Explore.Item3Tooltip')}>
          <Text>
            <Link
              onClick={(ev) => {
                navigateToLibrary();
                ev.stopPropagation();
              }}
            >
              {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.Explore.Item3')}`}
            </Link>
          </Text>
        </TooltipHost>
      </Stack.Item>
    );
  };

  const getAction4 = (nr: number) => {
    return (
      <Stack.Item>
        <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.Explore.Item4Tooltip')}>
          <Text>
            <Link
              onClick={(ev) => {
                navigateToDashboard();
                ev.stopPropagation();
              }}
            >
              {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.Explore.Item4')}`}
            </Link>
          </Text>
        </TooltipHost>
      </Stack.Item>
    );
  };

  const getAction5 = (nr: number) => {
    if (hasUserFeatureGenericManager(appContext)) {
      return (
        <Stack.Item>
          <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.Explore.Item5Tooltip')}>
            <Text>
              <Link
                onClick={(ev) => {
                  navigateToStore();
                  ev.stopPropagation();
                }}
              >
                {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.Explore.Item5')}`}
              </Link>
            </Text>
          </TooltipHost>
        </Stack.Item>
      );
    }

    return null;
  };

  const getActions = () => {
    const actions: JSX.Element[] = [];
    let nr = 1;

    for (let idx = 1; idx <= 5; idx++) {
      let action = null;

      switch (idx) {
        case 1:
          action = getAction1(nr);
          break;
        case 2:
          action = getAction2(nr);
          break;
        case 3:
          action = getAction3(nr);
          break;
        case 4:
          action = getAction4(nr);
          break;
        case 5:
          action = getAction5(nr);
          break;
      }

      if (action) {
        const canShow = canShowItem(exploreOpen, nr);
        if (canShow) {
          actions.push(action);
          nr++;
        } else {
          hasMoreItems = true;
        }
      }
    }

    return actions;
  };

  //
  // Main render
  //
  let hasMoreItems = false;

  return (
    <Stack tokens={globalStackTokensGapSmall} styles={getWidgetStyle(exploreOpen, appContext, theme)}>
      <Stack.Item grow>
        <Stack tokens={globalStackTokensGapSmall}>
          <Stack
            horizontal
            tokens={globalStackTokensGapSmall}
            horizontalAlign="center"
            verticalAlign="center"
            styles={{ root: { paddingBottom: 10 } }}
          >
            <Image src={`${Config.getImageURL()}/onboarding-explore.png`} width={32} height={32} />
            <Text variant="large" styles={globalTextStylesBold}>
              {t('dashboard:TrialWelcome.NextSteps.Explore.Title')}
            </Text>
          </Stack>
          {getActions()}
        </Stack>
      </Stack.Item>
      {(exploreOpen || hasMoreItems) && (
        <Stack.Item>
          <Stack horizontal horizontalAlign="center">
            <ActionButton
              onClick={() => saveExploreOpen(!exploreOpen)}
              iconProps={{ iconName: exploreOpen ? 'ChevronUp' : 'ChevronDown' }}
              text={
                exploreOpen
                  ? t('dashboard:TrialWelcome.NextSteps.Explore.Less')
                  : t('dashboard:TrialWelcome.NextSteps.Explore.More')
              }
            />
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
};

//
// RenderLearn
//
export const RenderLearn = (props: INextStepActionsProps) => {
  const { t } = useTranslation(['translation', 'dashboard', 'adminCompany']);
  const appContext = useContext(AppContext);
  const theme = getTheme();

  const loadLearnOpen = (): boolean => {
    const savedLearnOpen = getLocalStorageData(appContext, LocalStorageKeys.NextStepsLearnOpen);

    return savedLearnOpen === 'true';
  };

  const [learnOpen, setLearnOpen] = useState<boolean>(loadLearnOpen());

  const saveLearnOpen = (value: boolean) => {
    setLearnOpen(value);
    setLocalStorageData(appContext, LocalStorageKeys.NextStepsLearnOpen, value === true ? 'true' : 'false');
  };

  const getAction1 = (nr: number) => {
    if (hasUserFeatureGenericManager(appContext) && appContext.user.login.isInTrial()) {
      return (
        <Stack.Item>
          <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.Learn.Item1Tooltip')}>
            <Text>
              <Link
                onClick={(ev) => {
                  navigateToExternalUrl(globalKB_Demo, '', '', true);
                  ev.stopPropagation();
                }}
              >
                {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.Learn.Item1')}`}
              </Link>
            </Text>
          </TooltipHost>
        </Stack.Item>
      );
    }

    return null;
  };

  const getAction2 = (nr: number) => {
    // if (props.onboardingSteps.previewPackageInstalled) {
    //   return (
    //     <Stack.Item>
    //       <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.Learn.Item2bTooltip')}>
    //         <Text>
    //           <Link
    //             onClick={(ev) => {
    //               ev.stopPropagation();
    //             }}
    //             className="redlab-usetiful-start-tour-instant"
    //           >
    //             {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.Learn.Item2b')}`}
    //           </Link>
    //         </Text>
    //       </TooltipHost>
    //     </Stack.Item>
    //   );
    // }

    if (hasUserFeatureGenericManager(appContext)) {
      return (
        <Stack.Item>
          <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.Learn.Item2Tooltip')}>
            <Text>
              <Link
                onClick={(ev) => {
                  navigateToKB(globalKB_ISOBasicInfo);
                  ev.stopPropagation();
                }}
              >
                {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.Learn.Item2')}`}
              </Link>
            </Text>
          </TooltipHost>
        </Stack.Item>
      );
    }

    return null;
  };

  const getAction3 = (nr: number) => {
    return (
      <Stack.Item>
        <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.Learn.Item3Tooltip')}>
          <Text>
            <Link
              onClick={(ev) => {
                navigateToKB(globalKB_Training);
                ev.stopPropagation();
              }}
            >
              {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.Learn.Item3')}`}
            </Link>
          </Text>
        </TooltipHost>
      </Stack.Item>
    );
  };

  const getAction4 = (nr: number) => {
    return (
      <Stack.Item>
        <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.Learn.Item4Tooltip')}>
          <Text>
            <Link
              onClick={(ev) => {
                navigateToKB(globalKB_howToWorkWithTasks);
                ev.stopPropagation();
              }}
            >
              {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.Learn.Item4')}`}
            </Link>
          </Text>
        </TooltipHost>
      </Stack.Item>
    );
  };

  const getAction5 = (nr: number) => {
    if (hasUserFeatureGenericManager(appContext)) {
      return (
        <Stack.Item>
          <TooltipHost content={t('dashboard:TrialWelcome.NextSteps.Learn.Item5Tooltip')}>
            <Text>
              <Link
                onClick={(ev) => {
                  navigateToKB(globalKB_howToSetupLibrary);
                  ev.stopPropagation();
                }}
              >
                {`${nr}. ${t('dashboard:TrialWelcome.NextSteps.Learn.Item5')}`}
              </Link>
            </Text>
          </TooltipHost>
        </Stack.Item>
      );
    }

    return null;
  };

  const getActions = () => {
    const actions: JSX.Element[] = [];
    let nr = 1;

    for (let idx = 1; idx <= 5; idx++) {
      let action = null;

      switch (idx) {
        case 1:
          action = getAction1(nr);
          break;
        case 2:
          action = getAction2(nr);
          break;
        case 3:
          action = getAction3(nr);
          break;
        case 4:
          action = getAction4(nr);
          break;
        case 5:
          action = getAction5(nr);
          break;
      }

      if (action) {
        const canShow = canShowItem(learnOpen, nr);
        if (canShow) {
          actions.push(action);
          nr++;
        } else {
          hasMoreItems = true;
        }
      }
    }

    return actions;
  };

  //
  // Main render
  //
  let hasMoreItems = false;

  return (
    <Stack tokens={globalStackTokensGapSmall} styles={getWidgetStyle(learnOpen, appContext, theme)}>
      <Stack.Item grow>
        <Stack tokens={globalStackTokensGapSmall}>
          <Stack
            horizontal
            tokens={globalStackTokensGapSmall}
            horizontalAlign="center"
            verticalAlign="center"
            styles={{ root: { paddingBottom: 10 } }}
          >
            <Image src={`${Config.getImageURL()}/onboarding-gain-knowledge.png`} width={32} height={32} />
            <Text variant="large" styles={globalTextStylesBold}>
              {t('dashboard:TrialWelcome.NextSteps.Learn.Title')}
            </Text>
          </Stack>
          {getActions()}
        </Stack>
      </Stack.Item>
      {(learnOpen || hasMoreItems) && (
        <Stack.Item>
          <Stack horizontal horizontalAlign="center">
            <ActionButton
              onClick={() => saveLearnOpen(!learnOpen)}
              iconProps={{ iconName: learnOpen ? 'ChevronUp' : 'ChevronDown' }}
              text={
                learnOpen
                  ? t('dashboard:TrialWelcome.NextSteps.Learn.Less')
                  : t('dashboard:TrialWelcome.NextSteps.Learn.More')
              }
            />
          </Stack>
        </Stack.Item>
      )}
    </Stack>
  );
};

//
// RenderDone
//
interface IRenderDoneProps {
  onboardingState: TenantOnboarding;
  updateOnboardingState: (onboardingState: TenantOnboarding) => Promise<void>;
}

export const RenderDone = (props: IRenderDoneProps) => {
  const { t } = useTranslation(['translation', 'dashboard', 'adminCompany']);
  const appContext = useContext(AppContext);
  const history = useHistory();

  const startOnboardingAgain = async () => {
    const newOnboardingState = props.onboardingState.clone();
    newOnboardingState.onboardingSteps.currentStep = 0;
    await props.updateOnboardingState(newOnboardingState);
    history.push('/reload');
    history.goBack();
  };

  if (appContext.isMobileView) return null;

  const getWidgetStyleDone = () => {
    return {
      root: {
        padding: 10,
      },
    };
  };

  //
  // Main render
  //
  if (
    props.onboardingState.onboardingSteps.currentStep === undefined &&
    appContext.user.login.isInTrial() &&
    hasUserFeatureGenericManager(appContext)
  ) {
    //let existing trials allow to start the onboarding
    //this code can be removed after 1 month
    return (
      <Stack>
        <Stack.Item>
          <PrimaryButton
            iconProps={{ iconName: 'Play' }}
            onClick={() => startOnboardingAgain()}
            text={t('dashboard:TrialWelcome.NextSteps.Summary.StartExistingTrialsLink')}
          />
        </Stack.Item>
      </Stack>
    );
  }

  if (
    !props.onboardingState.onboardingSteps.languageIsSet &&
    (props.onboardingState.onboardingSteps.certifiedStandards?.length ?? 0) === 0 &&
    (props.onboardingState.onboardingSteps.newStandards?.length ?? 0) === 0 &&
    !props.onboardingState.onboardingSteps.previewPackageInstalled &&
    !props.onboardingState.onboardingSteps.projectMilestonesCreated &&
    !props.onboardingState.onboardingSteps.projectDocument
  ) {
    return null;
  }

  return (
    <Stack>
      <Stack.Item>
        <Text>{t('dashboard:TrialWelcome.NextSteps.Summary.Title')}</Text>&nbsp;
        {appContext.user.login.isInTrial() && hasUserFeatureGenericManager(appContext) && (
          <Text>
            <Link onClick={() => startOnboardingAgain()}>
              {t('dashboard:TrialWelcome.NextSteps.Summary.StartAgainLink')}
            </Link>
          </Text>
        )}
      </Stack.Item>
      <Stack.Item styles={getWidgetStyleDone()}>
        <Stack tokens={globalStackTokensGapSmall}>
          {props.onboardingState.onboardingSteps.languageIsSet && (
            <Stack horizontal tokens={globalStackTokensGapSmall}>
              <FontIcon iconName="SkypeCircleCheck" style={{ fontSize: '14px', color: SharedColors.green20 }} />
              <Text variant="small">{t('dashboard:TrialWelcome.NextSteps.Done.Item1')}</Text>
            </Stack>
          )}
          {((props.onboardingState.onboardingSteps.certifiedStandards?.length ?? 0) > 0 ||
            (props.onboardingState.onboardingSteps.newStandards?.length ?? 0) > 0) && (
            <Stack horizontal tokens={globalStackTokensGapSmall}>
              <FontIcon iconName="SkypeCircleCheck" style={{ fontSize: '14px', color: SharedColors.green20 }} />
              <Text variant="small">{t('dashboard:TrialWelcome.NextSteps.Done.Item2')}</Text>
            </Stack>
          )}
          {props.onboardingState.onboardingSteps.previewPackageInstalled && (
            <Stack horizontal tokens={globalStackTokensGapSmall}>
              <FontIcon iconName="SkypeCircleCheck" style={{ fontSize: '14px', color: SharedColors.green20 }} />
              <Text variant="small">{t('dashboard:TrialWelcome.NextSteps.Done.Item3')}</Text>
            </Stack>
          )}
          {props.onboardingState.onboardingSteps.projectMilestonesCreated && (
            <Stack horizontal tokens={globalStackTokensGapSmall}>
              <FontIcon iconName="SkypeCircleCheck" style={{ fontSize: '14px', color: SharedColors.green20 }} />
              <Text variant="small">{t('dashboard:TrialWelcome.NextSteps.Done.Item4')}</Text>
            </Stack>
          )}
          {props.onboardingState.onboardingSteps.projectDocument && (
            <Stack horizontal tokens={globalStackTokensGapSmall}>
              <FontIcon iconName="SkypeCircleCheck" style={{ fontSize: '14px', color: SharedColors.green20 }} />
              <Text variant="small">{t('dashboard:TrialWelcome.NextSteps.Done.Item5')}</Text>
            </Stack>
          )}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};
