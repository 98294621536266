import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import RichTextEditorMCE, { IRichTextEditorMCE } from './RichTextEditorMCE';
import RichTextReadOnly from './RichTextReadOnly';
import AppContext from 'App/AppContext';
import { getEntityUrl } from 'utils/url';
import Entity, { EntityTypes } from 'models/entity';
import { useHistory } from 'react-router-dom';
import { ControlTypes } from 'models/control';
import { apiGetControlForISOControl } from 'services/Api/isoControlService';
import { apiRequest } from 'services/Auth/authConfig';
import { useTranslation } from 'react-i18next';
import { clearHtmlValue } from 'utils/html';
import { TMCERequest } from './RichTextEditorMCETypes';

export interface IRichTextEditor {
  focus: () => void;
}

export enum IRichTextEditorSpecialLinkType {
  None = 0,
  ISOControl = 1,
}

export interface IRichTextEditorProps {
  className?: string;
  html?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  placeholderReadonly?: string;
  height?: string | number;
  maxHeight?: number | undefined;
  maxLength?: number | undefined;
  readOnly?: boolean;
  onBadgeClick?: (badgeText: string) => void;
  onSpecialLinkClick?: (type: IRichTextEditorSpecialLinkType, text: string, href: string, success: boolean) => void;
  onStartEdit?: () => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyUp?: (event: Partial<React.KeyboardEvent>) => void;
  onKeyDown?: (event: Partial<React.KeyboardEvent>) => void;
  onAccept?: (value: string | undefined, accept: boolean) => void;
  onAIRequest?: (request: TMCERequest, connectionId: string | null) => Promise<void>;
  unFocusKey?: string;
  directEdit?: boolean;
  hideBorder?: boolean;
  hideEditIcon?: boolean;
  stripParagraph?: boolean;
  disallowFullscreen?: boolean;
  compactToolbar?: boolean;
  hideRemainingChars?: boolean;
}

const RichTextEditor = forwardRef(function RichTextEditor(props: IRichTextEditorProps, ref) {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);
  const history = useHistory();
  const [focus, setFocus] = useState<boolean>(false);
  const editorRef = useRef<IRichTextEditorMCE | null>(null);

  useEffect(() => {
    if (props.unFocusKey) {
      setFocus(false);
    }
  }, [props.unFocusKey]);

  useEffect(() => {
    if (props.directEdit === false) {
      setFocus(false);
    } else if (props.directEdit === true) {
      setFocus(true);
    }
  }, [props.directEdit]);

  useImperativeHandle(
    ref,
    (): IRichTextEditor => {
      return {
        focus() {
          editorRef.current?.focus();
        },
      };
    },
    [],
  );

  const onFocus = () => {
    setFocus(true);
    if (props.onStartEdit) {
      //before starting edit, clean the html
      const newHtml = clearHtmlValue(props.html, props.stripParagraph ?? false, true, true);
      if (newHtml && newHtml !== props.html) {
        props.onChange(newHtml);
      }
      props.onStartEdit();
    }
  };

  const onBlur = () => {
    //When the editor shows the Accept/Cancel buttons, we want to switch to read-only mode on Blur
    if (props.onAccept) {
      setFocus(false);
    }
    if (props.onBlur) {
      props.onBlur();
    }
  };

  const onSpecialLinkClick = async (
    type: IRichTextEditorSpecialLinkType,
    text: string,
    href: string,
  ): Promise<void> => {
    let success: boolean = false;

    try {
      if (type === IRichTextEditorSpecialLinkType.ISOControl) {
        const isoControlIdStr = href.substring('isocontrol://'.length);
        if (isoControlIdStr) {
          const isoControlId = Number.parseInt(isoControlIdStr);
          if (!isNaN(isoControlId)) {
            const accessToken = await appContext.getAccessToken(apiRequest.scopes);
            const controlOrTheme = await apiGetControlForISOControl(
              isoControlId,
              accessToken,
              appContext.globalDataCache,
            );
            if (controlOrTheme) {
              const entity = new Entity(
                controlOrTheme.controlId,
                controlOrTheme.controlType === ControlTypes.Measure ? EntityTypes.Control : EntityTypes.Requirement,
                controlOrTheme.name,
                controlOrTheme.code,
              );
              const url = getEntityUrl(entity);
              if (url) {
                history.push(url);
                success = true;
              }
            }
          }
        }
      }
    } catch {
      success = false;
    }

    if (props.onSpecialLinkClick) props.onSpecialLinkClick(type, text, href, success);
    if (!success) {
      window.alert(t('translation:General.Notifications.ISOLinkNotFound', { id: href?.substring(13) }));
    }
  };

  //
  // Main render
  //
  if (props.directEdit === true || (!props.readOnly && focus)) {
    return <RichTextEditorMCE ref={editorRef} {...props} onSpecialLinkClick={onSpecialLinkClick} onBlur={onBlur} />;
  } else {
    return (
      <RichTextReadOnly
        onClick={props.readOnly ? undefined : () => onFocus()}
        {...props}
        onSpecialLinkClick={onSpecialLinkClick}
        placeholder={props.placeholderReadonly || props.placeholder}
        linkify={true}
      />
    );
  }
});

export default RichTextEditor;
