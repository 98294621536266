import { FontIcon, Stack, TextField, Text } from '@fluentui/react';
import AppContext from 'App/AppContext';
import { canUpdateTaskField, TaskFieldTypes } from 'components/Tasks/TaskAuthHelper';
import {
  globalStackTokensGapExtraSmall,
  globalStackTokensGapMedium,
  globalTextBig,
  globalTextFieldBig,
} from 'globalStyles';
import Task from 'models/tasks/task';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { iconFontSize, readOnlyRowSpace } from './TaskDetailStyles';
import { capitalizeFirstLetter } from 'utils/string';

interface ITaskDetailNameProps {
  orgTask: Task;
  task: Task;
  canUpdate: boolean;
  compactView?: boolean;
  onUpdate: (newTask: Task) => void;
  getErrorMessage: (field: string) => string | undefined;
}

export const TaskDetailName = (props: ITaskDetailNameProps) => {
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['task', 'translation']);
  const isReadonly = !canUpdateTaskField(props.orgTask, props.canUpdate, TaskFieldTypes.Name, appContext);

  const getPlaceholder = (): string => {
    if (props.task.isTask() || props.task.isSubTask()) {
      return t('task:Title.Placeholder');
    } else if (props.task.isEvent()) {
      return t('task:Title.PlaceholderEvent');
    } else if (props.task.isTemplate()) {
      return t('task:Title.PlaceholderTemplate');
    } else {
      //default
      return t('task:Title.Placeholder');
    }
  };

  //
  // Main render
  //
  if (props.compactView && isReadonly) {
    //hide name because in compact mode it is in the title of the modal
    return null;
  }

  return (
    <Stack
      horizontal
      tokens={appContext.isMobileView ? globalStackTokensGapExtraSmall : globalStackTokensGapMedium}
      verticalAlign="start"
      styles={isReadonly ? { root: { paddingBottom: readOnlyRowSpace / 2 } } : undefined}
    >
      <Stack.Item>
        {!appContext.isMobileView && <FontIcon iconName="StatusCircleRing" style={iconFontSize} />}
      </Stack.Item>
      <Stack.Item grow>
        {isReadonly && <Text styles={globalTextBig}>{capitalizeFirstLetter(props.task.name)}</Text>}
        {!isReadonly && (
          <TextField
            className="redlab-usetiful-task-name"
            underlined
            autoFocus={!appContext.isMobileView}
            value={props.task.name ? capitalizeFirstLetter(props.task.name) : ''}
            styles={globalTextFieldBig}
            onGetErrorMessage={(value) => {
              if (value === '') return '';

              return props.getErrorMessage('name');
            }}
            placeholder={getPlaceholder()}
            onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
              const copyTask = props.task.clone();
              copyTask.name = newValue ? newValue.trimStart() : '';
              props.onUpdate(copyTask);
            }}
            maxLength={512}
          />
        )}
      </Stack.Item>
    </Stack>
  );
};
