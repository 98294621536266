import { CopilotDataRiskField } from 'models/copilot/copilotRisk';
import RiskDTO from '../riskDTO';
import { CopilotConversationDTO } from './copilotConversationDTO';

export class CopilotRiskDTO {
  risk: RiskDTO;

  field: CopilotDataRiskField;

  conversation: CopilotConversationDTO 

  constructor() {
    this.risk = new RiskDTO();
    this.field = CopilotDataRiskField.None;
    this.conversation = new CopilotConversationDTO();
  }
}
