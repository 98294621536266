import { CopilotConversation, CopilotConversationItem } from 'models/copilot/copilotConversation';
import { CopilotRisk, CopilotDataRiskField } from 'models/copilot/copilotRisk';
import { CopilotConversationItemDTO, CopilotConversationDTO } from 'models/dto/copilot/copilotConversationDTO';
import { CopilotRiskDTO } from 'models/dto/copilot/copilotRiskDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { fromApiDateTimeOptional, toApiDateTimeOptional } from 'utils/datetime';
import { mapFromRisk, mapToRisk } from './riskMapping';
import Risk from 'models/risk';
import { CopilotOnboardingProject } from 'models/copilot/copilotOnboardingProject';
import { CopilotOnboardingProjectDTO } from 'models/dto/copilot/copilotOnboardingProjectDTO';
import { mapFromTasksArray } from './taskMapping';
import { mapFromDashboard } from './dashboardMapping';
import { mapFromTags } from './tagMapping';

export function mapToCopilotConversation(item: CopilotConversation): CopilotConversationDTO {
  const output = new CopilotConversationDTO();
  output.id = item.id;
  output.request = mapToCopilotConversationItem(item.request);
  output.thread = mapToCopilotConversationItems(item.thread);

  return output;
}

export function mapToCopilotConversationItems(items: CopilotConversationItem[]): CopilotConversationItemDTO[] {
  return items.map((item) => {
    return mapToCopilotConversationItem(item);
  }) as CopilotConversationItemDTO[];
}

export function mapToCopilotConversationItem(
  item: CopilotConversationItem | undefined,
): CopilotConversationItemDTO | undefined {
  if (!item) return undefined;
  const output = new CopilotConversationItemDTO();
  output.id = item.id;
  output.type = item.type;
  output.date = toApiDateTimeOptional(item.date);
  output.userId = item.userId;
  output.question = item.question;
  output.context = item.context;
  output.promptType = item.promptType;
  output.generalPromptType = item.generalPromptType;
  output.response = item.response;

  return output;
}

export function mapFromCopilotConversation(item: CopilotConversationDTO): CopilotConversation {
  const output = new CopilotConversation();
  output.id = item.id;
  output.request = mapFromCopilotConversationItem(item.request);
  output.thread = mapFromCopilotConversationItems(item.thread);

  return output;
}

export function mapFromCopilotConversationItems(items: CopilotConversationItemDTO[]): CopilotConversationItem[] {
  return items.map((item) => {
    return mapFromCopilotConversationItem(item);
  }) as CopilotConversationItem[];
}

export function mapFromCopilotConversationItem(
  item: CopilotConversationItemDTO | undefined,
): CopilotConversationItem | undefined {
  if (!item) return undefined;
  const output = new CopilotConversationItem();
  output.id = item.id;
  output.type = item.type;
  output.date = fromApiDateTimeOptional(item.date);
  output.userId = item.userId;
  output.question = item.question;
  output.context = item.context;
  output.promptType = item.promptType;
  output.generalPromptType = item.generalPromptType;
  output.response = item.response;

  return output;
}

//
// Risk
//
export function mapToCopilotRisk(
  risk: Risk,
  field: CopilotDataRiskField,
  conversation: CopilotConversation,
): CopilotRiskDTO {
  const output = new CopilotRiskDTO();
  output.risk = mapToRisk(risk);
  output.field = field;
  output.conversation = mapToCopilotConversation(conversation);

  return output;
}

export function mapFromCopilotRisk(item: CopilotRiskDTO, cache: GlobalDataCache): CopilotRisk {
  const output = new CopilotRisk();
  output.risk = mapFromRisk(item.risk, cache);
  output.field = item.field;
  output.conversation = mapFromCopilotConversation(item.conversation);

  return output;
}

//
// Onboarding project
//
export function mapFromCopilotOnboardingProject(
  item: CopilotOnboardingProjectDTO,
  cache: GlobalDataCache,
): CopilotOnboardingProject {
  const output = new CopilotOnboardingProject();
  output.tasks = mapFromTasksArray(item.tasks, cache);
  output.tags = mapFromTags(item.tags);
  output.projectDocument = item.projectDocument;
  output.dashboard = item.dashboard ? mapFromDashboard(item.dashboard, cache) : undefined;
  output.previewPackageInstalled = item.previewPackageInstalled;
  output.projectMilestonesCreated = item.projectMilestonesCreated;

  return output;
}
