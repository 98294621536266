import { ICalloutContentStyles } from '@fluentui/react';
import { newGuid } from 'utils/guid';

export const timelineCalloutWidth = 400;
export const timelineDefaultGroupId = newGuid();

export interface ITimelineChartProps {
  isLoading?: boolean;
  height?: string;
  defaultGroupName: string;
  allowEdit?: boolean;
}

export type onSelectProps = {
  items: string[];
  event: MouseEvent;
};

export type onItemHoverProps = {
  item: string;
  event: MouseEvent;
};

export type onRangeChangedProps = {
  start: number;
  end: number;
  byUser: boolean;
  event: Event;
};

export interface ITimelineChart {
  setWindow(start: Date, end: Date): void;
  focus(ids: string[]): void;
  fit(): void;
}

export const timelineCalloutStyleTaskDetails: Partial<ICalloutContentStyles> = {
  root: {
    width: timelineCalloutWidth,
    maxHeight: 500,
    borderRadius: '3px',
    border: '1px solid lightgrey',
    margin: 3,
  },
};

export const timelineCalloutStyleItemDrag: Partial<ICalloutContentStyles> = {
  root: {
    width: timelineCalloutWidth + 100,
    borderRadius: '3px',
    border: '1px solid lightgrey',
    margin: 3,
    padding: 10,
  },
};
