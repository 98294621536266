import {
  AccountInfo,
  AuthenticationResult,
  EndSessionRequest,
  PopupRequest,
  PublicClientApplication,
  RedirectRequest,
  SsoSilentRequest,
} from '@azure/msal-browser';
import { msalConfigCommon, loginRequest, apiRequest, getMsalConfigForTenant } from 'services/Auth/authConfig';
import { Client } from '@microsoft/microsoft-graph-client';
import { graphGetTenantDetails, graphGetUserDetails, graphGetUserPhoto } from 'services/Graph/graphService';
import CurrentUser from 'models/currentuser';
import { apiLoginUser } from 'services/Api/userService';
import logger from 'services/Logging/logService';
import { AuthStateUpdate, SetGlobalDataCache } from 'App/AppContextProvider';
import Config from 'services/Config/configService';
import { HomeAccount } from 'models/userSetting';
import { apiGetTenantLogo } from 'services/Api/tenantService';
import AppError from 'utils/appError';
import { setLocalStorageData, LocalStorageKeys, getLocalStorageData, removeLocalStorageData } from 'utils/localstorage';
import { overflow } from 'utils/string';
import { isAuthInteractionError, isAuthUserError, isPopupWindowError } from './authErrors';
import { globalReturnUrls } from 'globalConstants';
import { FeatureTypes, hasSubscriptionFeature } from './featurePermissions';
import Tenant from 'models/tenant';
import UserLanguage from 'models/userLanguage';
import User from 'models/user';

//
// Silent login
//
const ssoSilent = async (
  publicClientApplication: PublicClientApplication,
  azureTenantId: string | undefined,
  authStateUpdate: AuthStateUpdate,
  setGlobalDataCache: SetGlobalDataCache,
) => {
  try {
    try {
      //handle redirect responses
      //tokens will be placed into MSAL cache
      //this method will throw on errors (e.g. cancel)
      await publicClientApplication.handleRedirectPromise();
    } catch (err) {
      logger.debug('handleRedirectPromise', err);
    }

    logger.debug('Performing SSO');

    //if tenant is provided, try to login to that tenant
    if (azureTenantId) {
      const azureTenant = new Tenant(azureTenantId, '');
      azureTenant.azureTenantId = azureTenantId;
      await switchTenant(publicClientApplication, authStateUpdate, setGlobalDataCache, azureTenant);

      return;
    }

    // If MSAL already has an account, the user
    // is already logged in, else try to do a silent SSO
    authStateUpdate(undefined, undefined, true, undefined);
    const accounts = publicClientApplication.getAllAccounts();

    if (accounts && accounts.length > 0) {
      if (accounts.length === 1) {
        logger.debug('Single account found', accounts[0]);
        // 1 account
        publicClientApplication.setActiveAccount(accounts[0]);
        await setUserProfile(publicClientApplication, authStateUpdate, setGlobalDataCache);
      } else {
        // multiple accounts
        // choose the local account from the storageKey, otherwise login
        logger.debug('Multiple accounts found', accounts);
        const localAccountId = getLocalAccountFromStorage();
        const localAccount = publicClientApplication.getAccountByLocalId(localAccountId);
        logger.debug('Search for local account Id: ' + (localAccountId ?? 'unknown'), localAccount);
        if (localAccount) {
          publicClientApplication.setActiveAccount(localAccount);
          await setUserProfile(publicClientApplication, authStateUpdate, setGlobalDataCache);
        } else {
          await login(publicClientApplication, azureTenantId, authStateUpdate, setGlobalDataCache);
        }
      }
    } else {
      try {
        const ssoRequest: SsoSilentRequest = {
          authority: `https://login.microsoftonline.com/common`,
          scopes: loginRequest.scopes,
          loginHint: getUserNameFromStorage(),
          redirectUri: Config.getAppURL() + '/blank.html',
        };
        logger.debug('No account found, performing SSO silent', ssoRequest);
        const authResult = await publicClientApplication.ssoSilent(ssoRequest);
        if (authResult.account) {
          logger.debug('Account found', authResult.account);
          publicClientApplication.setActiveAccount(authResult.account);
          await setUserProfile(publicClientApplication, authStateUpdate, setGlobalDataCache);
        } else {
          throw new AppError('interaction_required');
        }
      } catch (err) {
        logger.debug('SSO Silent error', err);
        if (isAuthInteractionError(err as Error)) {
          await login(publicClientApplication, azureTenantId, authStateUpdate, setGlobalDataCache);
        } else {
          authStateUpdate(false, CurrentUser.getEmptyUser(), false, isAuthUserError(err as Error) ? undefined : err);
        }
      }
    }
  } catch (err) {
    logger.debug('Error', err);
    authStateUpdate(false, CurrentUser.getEmptyUser(), false, isAuthUserError(err as Error) ? undefined : err);
  }
};

const login = async (
  publicClientApplication: PublicClientApplication,
  azureTenantId: string | undefined,
  authStateUpdate: AuthStateUpdate,
  setGlobalDataCache: SetGlobalDataCache,
) => {
  try {
    authStateUpdate(undefined, undefined, true, undefined);
    let authResult: AuthenticationResult;
    logger.debug('Performing login');

    const popupRequest: PopupRequest = {
      authority: `https://login.microsoftonline.com/${azureTenantId ? azureTenantId : 'common'}`,
      scopes: loginRequest.scopes,
      loginHint: getUserNameFromStorage(),
      prompt: 'select_account',
      redirectUri: Config.getAppURL() + '/blank.html',
    };

    try {
      authResult = await publicClientApplication.loginPopup(popupRequest);

      logger.debug('Login account', authResult.account);
      publicClientApplication.setActiveAccount(authResult.account);
      await setUserProfile(publicClientApplication, authStateUpdate, setGlobalDataCache);
    } catch (err) {
      logger.debug('Login popup error', err);
      if (isPopupWindowError(err)) {
        logger.debug('Popup window error');
        const redirectRequest: RedirectRequest = {
          authority: `https://login.microsoftonline.com/${azureTenantId ? azureTenantId : 'common'}`,
          scopes: loginRequest.scopes,
          loginHint: getUserNameFromStorage(),
          prompt: 'select_account',
          redirectUri: GetValidReturnUrl(),
        };
        logger.debug('Performing redirect login');
        await publicClientApplication.loginRedirect(redirectRequest);

        return;
      }

      throw err;
    }
  } catch (err) {
    logger.debug('Error', err);
    authStateUpdate(false, CurrentUser.getEmptyUser(), false, isAuthUserError(err as Error) ? undefined : err);
  }
};

const logout = async (publicClientApplication: PublicClientApplication, authStateUpdate: AuthStateUpdate) => {
  try {
    try {
      await publicClientApplication.logoutPopup();
      authStateUpdate(false, CurrentUser.getEmptyUser(), false, undefined);
    } catch (err) {
      if (isPopupWindowError(err)) {
        const endSessionRequest: EndSessionRequest = {
          postLogoutRedirectUri: Config.getAppURL(),
        };
        await publicClientApplication.logoutRedirect(endSessionRequest);
      }

      throw err;
    }
  } catch {
    authStateUpdate(false, CurrentUser.getEmptyUser(), false, undefined);
  } finally {
    removeLocalStorageData(undefined, LocalStorageKeys.UserName);
    removeLocalStorageData(undefined, LocalStorageKeys.LocalAccount);
  }
};

//
// Switch tenant
//

const switchTenant = async (
  publicClientApplication: PublicClientApplication,
  authStateUpdate: AuthStateUpdate,
  setGlobalDataCache: SetGlobalDataCache,
  tenant: Tenant,
) => {
  try {
    authStateUpdate(undefined, undefined, true, undefined);
    logger.debug(`Switching tenant: ${tenant.name}`);
    logger.debug(`Tenant Id: ${tenant.id}`);
    logger.debug(`Tenant Azure Id: ${tenant.azureTenantId}`);

    const accounts = publicClientApplication.getAllAccounts();
    const accountsForTenant = accounts.filter((a) => a.tenantId === tenant.azureTenantId);
    let account: AccountInfo | undefined = undefined;

    if (accountsForTenant.length === 1) {
      logger.debug('Found 1 active account');
      account = accounts[0];
    } else if (accountsForTenant.length > 1) {
      logger.debug('Found multiple active accounts');
      const currentUserName = publicClientApplication.getActiveAccount()?.username;
      logger.debug('Current user name: ' + (currentUserName ?? 'unknown'));
      if (currentUserName) {
        account = accountsForTenant.find((a) => a.username === currentUserName);
        if (account) logger.debug('Found active account based on user name');
      }
    }

    logger.debug(`Getting MSAL public app for tenant: ${tenant.azureTenantId} ${tenant.name}`);
    publicClientApplication = new PublicClientApplication(getMsalConfigForTenant(tenant));
    await publicClientApplication.initialize();
    let ssoRequest: SsoSilentRequest;

    if (account) {
      ssoRequest = {
        authority: `https://login.microsoftonline.com/${tenant.azureTenantId}`,
        scopes: loginRequest.scopes,
        account: account,
        redirectUri: Config.getAppURL() + '/blank.html',
      };
    } else {
      ssoRequest = {
        authority: `https://login.microsoftonline.com/${tenant.azureTenantId}`,
        scopes: loginRequest.scopes,
        loginHint: getUserNameFromStorage(),
        domainHint: undefined,
        redirectUri: Config.getAppURL() + '/blank.html',
      };
    }

    try {
      logger.debug('Performing SSO silent', ssoRequest);
      const response = await publicClientApplication.ssoSilent(ssoRequest);
      logger.debug('Setting active account', response.account);
      publicClientApplication.setActiveAccount(response.account);
      await setUserProfile(publicClientApplication, authStateUpdate, setGlobalDataCache);
    } catch (err) {
      logger.debug('Error in SSO silent', err);
      if (isAuthInteractionError(err as Error)) {
        logger.debug('Interaction error, performing login', err);
        login(publicClientApplication, tenant.azureTenantId, authStateUpdate, setGlobalDataCache);
      } else {
        logger.debug('No interaction error, aborting');
        authStateUpdate(false, CurrentUser.getEmptyUser(), false, isAuthUserError(err as Error) ? undefined : err);
      }
    }
  } catch (err) {
    logger.debug('Error', err);
    authStateUpdate(false, CurrentUser.getEmptyUser(), false, isAuthUserError(err as Error) ? undefined : err);
  }
};

//
// admin consent
//
const adminConsent = (
  publicClientApplication: PublicClientApplication,
  authStateUpdate: AuthStateUpdate,
  redirectUrl: string,
) => {
  try {
    // if you want to work with multiple accounts, add your account selection logic below
    const account = publicClientApplication.getActiveAccount();

    if (account) {
      const state = Math.floor(Math.random() * 90000) + 10000; // state parameter for anti token forgery
      const adminConsentUri = `https://login.microsoftonline.com/${account.tenantId}/adminconsent?client_id=${msalConfigCommon.auth.clientId}&state=${state}&redirect_uri=${msalConfigCommon.auth.redirectUri}/${redirectUrl}`;

      window.location.replace(adminConsentUri);
    }
  } catch (err) {
    authStateUpdate(undefined, undefined, undefined, err);
  }
};

const adminConsentScope = (
  clientId: string,
  scopes: string,
  publicClientApplication: PublicClientApplication,
  authStateUpdate: AuthStateUpdate,
  redirectUrl: string,
) => {
  try {
    // if you want to work with multiple accounts, add your account selection logic below
    const account = publicClientApplication.getActiveAccount();

    if (account) {
      const state = Math.floor(Math.random() * 90000) + 10000; // state parameter for anti token forgery
      const adminConsentUri = `https://login.microsoftonline.com/${account.tenantId}/v2.0/adminconsent?client_id=${clientId}&scope=${scopes}&state=${state}&redirect_uri=${msalConfigCommon.auth.redirectUri}/${redirectUrl}`;

      window.location.replace(adminConsentUri);
    }
  } catch (err) {
    authStateUpdate(undefined, undefined, undefined, err);
  }
};

//
// Has scopes
//
const hasScopes = async (
  publicClientApplication: PublicClientApplication,
  scopes: string[],
  azureTenant?: Tenant,
): Promise<boolean> => {
  //
  // Returns true when obtaining an access token silently succeeds, otherwise false
  //
  logger.debug('Requested access token check for scopes: ' + scopes.toString());
  let account = publicClientApplication.getActiveAccount() || undefined;

  try {
    if (!account) throw new Error('login_required'); //no AppError to check if this is an auth error
    const publicClientApplicationForAccount = publicClientApplication;

    if (azureTenant && azureTenant.azureTenantId !== account.tenantId) {
      logger.debug('Requested other tenant:', azureTenant);
      //Check if there is an account already for the requested tenant, if so try to get a token with that account
      const accounts = publicClientApplication.getAllAccounts();
      account = accounts.find((a) => a.tenantId === azureTenant.azureTenantId);

      if (!account) {
        return false;
      }
    }

    // Get the access token silently
    // If the cache contains a non-expired token, this function
    // will just return the cached token. Otherwise, it will
    // make a request to the Azure OAuth endpoint to get a token
    const silentResult = await publicClientApplicationForAccount.acquireTokenSilent({
      scopes: scopes,
      account: account,
      authority: `https://login.microsoftonline.com/${account.tenantId}`,
    });

    if (silentResult.accessToken) {
      if (silentResult.tenantId !== account.tenantId) {
        throw new AppError('The selected account is invalid', 'invalid_account');
      }

      logger.debug('Token successfully aquired');

      return true;
    } else {
      throw new AppError('The authorization token could not be obtained', 'invalid_token');
    }
  } catch (err) {
    //in the case that an AppError was already thrown, this means that we should not process the error but re-throw
    //can be the case when getTokenByInteraction throws
    if (err instanceof AppError) {
      throw err;
    }

    // If a silent request fails, it may be because the user needs
    // to login or grant consent to one or more of the requested scopes
    if (isAuthInteractionError(err as Error)) {
      return false;
    }

    logger.debug('Error no interaction', err);
    const appError = AppError.fromAuthError(err);
    throw appError;
  }
};

//
// Get access token
//
const getAccessToken = async (
  publicClientApplication: PublicClientApplication,
  scopes: string[],
  azureTenant?: Tenant,
  allowInteraction: boolean = true,
): Promise<string> => {
  //
  // 1. returns an access token silently
  // 2. when this fails, try to login with popup and obtain token directly
  // 3. when popups are blocked, try to get the token with a redirect and return an empty string
  // 4. throw on any other condition (like cancelling the login)
  //
  logger.debug('Requested access token for scopes: ' + scopes.toString());
  let account = publicClientApplication.getActiveAccount() || undefined;

  try {
    if (!account) throw new Error('login_required'); //no AppError to check if this is an auth error
    const publicClientApplicationForAccount = publicClientApplication;

    if (azureTenant && azureTenant.azureTenantId !== account.tenantId) {
      logger.debug('Requested other tenant:', azureTenant);
      //Check if there is an account already for the requested tenant, if so try to get a token with that account
      const accounts = publicClientApplication.getAllAccounts();
      account = accounts.find((a) => a.tenantId === azureTenant.azureTenantId);

      if (!account) {
        if (!allowInteraction) {
          throw new AppError('interaction_required', 'interaction_required');
        }
        logger.debug('Account not found: interaction login');
        //when there is no account for the request tenant, login into that tenant to get the account information
        const result = await getTokenByInteraction(publicClientApplication, scopes, azureTenant);
        if (result && result.accessToken) {
          logger.debug('Token successfully aquired');

          return result.accessToken;
        } else {
          return ''; //redirect
        }
      } else {
        logger.debug('Cached account found');
      }
    }

    // Get the access token silently
    // If the cache contains a non-expired token, this function
    // will just return the cached token. Otherwise, it will
    // make a request to the Azure OAuth endpoint to get a token
    const silentResult = await publicClientApplicationForAccount.acquireTokenSilent({
      authority: `https://login.microsoftonline.com/${account.tenantId}`,
      scopes: scopes,
      account: account,
    });

    if (silentResult.accessToken) {
      if (silentResult.tenantId !== account.tenantId) {
        throw new AppError('The selected account is invalid', 'invalid_account');
      }

      logger.debug('Token successfully aquired');

      return silentResult.accessToken;
    } else {
      throw new AppError('The authorization token could not be obtained', 'invalid_token');
    }
  } catch (err) {
    //in the case that an AppError was already thrown, this means that we should not process the error but re-throw
    //can be the case when getTokenByInteraction throws
    if (err instanceof AppError) {
      throw err;
    }

    // If a silent request fails, it may be because the user needs
    // to login or grant consent to one or more of the requested scopes
    if (isAuthInteractionError(err as Error)) {
      logger.debug('Interaction required');

      if (!allowInteraction) {
        throw new AppError('interaction_required', 'interaction_required');
      }

      try {
        const result = await getTokenByInteraction(publicClientApplication, scopes, azureTenant);
        if (result && result.accessToken) {
          logger.debug('Token successfully aquired');

          return result.accessToken;
        } else {
          return ''; //redirect
        }
      } catch (err) {
        logger.debug('Error after interaction', err);
        throw err;
      }
    } else {
      logger.debug('Error no interaction', err);
      const appError = AppError.fromAuthError(err);
      throw appError;
    }
  }
};

const getTokenByInteraction = async (
  publicClientApplication: PublicClientApplication,
  scopes: string[],
  azureTenant?: Tenant,
): Promise<AuthenticationResult | undefined> => {
  //
  //1. try to login with popup
  //2. when this fails due to popup blocker, login with redirect and return undefined to indicate the redirect
  //3. when another error occurs, throw it
  //
  logger.debug('getTokenByInteraction');
  let publicClientApplicationForAccount = publicClientApplication;

  if (azureTenant) {
    logger.debug('Requested specific tenant', azureTenant);
    publicClientApplicationForAccount = new PublicClientApplication(getMsalConfigForTenant(azureTenant));
    await publicClientApplicationForAccount.initialize();
  }

  try {
    //To test redirect, uncomment the following line
    //throw new Error('popup_window_error');

    const popupRequest: PopupRequest = {
      authority: `https://login.microsoftonline.com/${azureTenant ? azureTenant.azureTenantId : 'common'}`,
      scopes: scopes,
      domainHint: undefined,
      loginHint: getUserNameFromStorage(),
      prompt: 'select_account',
      redirectUri: Config.getAppURL() + '/blank.html',
    };
    const authResult = await publicClientApplicationForAccount.acquireTokenPopup(popupRequest);

    return authResult;
  } catch (err) {
    logger.debug('error aquire token popup', err);
    if (isPopupWindowError(err as Error)) {
      const redirectRequest: RedirectRequest = {
        authority: `https://login.microsoftonline.com/${azureTenant ? azureTenant.azureTenantId : 'common'}`,
        scopes: scopes,
        domainHint: undefined,
        loginHint: getUserNameFromStorage(),
        prompt: 'select_account',
        redirectUri: GetValidReturnUrl(),
      };
      await publicClientApplicationForAccount.acquireTokenRedirect(redirectRequest);

      return undefined;
    }

    const appError = AppError.fromAuthError(err);
    throw appError;
  }
};

const GetValidReturnUrl = (): string => {
  try {
    const path = window.location.pathname.toLowerCase();
    if (globalReturnUrls.includes(path)) {
      return window.location.origin + path;
    } else {
      return window.location.origin + '/';
    }
  } catch {
    return window.location.origin + '/';
  }
};

//
// User details
//
const setUserProfile = async (
  publicClientApplication: PublicClientApplication,
  authStateUpdate: AuthStateUpdate,
  setGlobalDataCache: SetGlobalDataCache,
) => {
  try {
    logger.debug('Setting user profile');

    saveLocalAccountToStorage(publicClientApplication);
    saveUserNameToStorage(publicClientApplication);

    //Perform login at API
    logger.debug('Getting access token API');
    const accessTokenApi = await getAccessToken(publicClientApplication, apiRequest.scopes);
    logger.debug('Performing login API');
    const login = await apiLoginUser(accessTokenApi);
    if (!login) throw new AppError(`Back-end returned empty login`);

    logger.debug('API login result', login);

    let currentTenant: Tenant | undefined = undefined;
    let currentUser: User | undefined = undefined;

    if (login.tenantLicensed && login.userLicensed) {
      //When there is a successful login, the tenant and user details are returned
      currentTenant = login.tenants.find((t) => t.id === login.tenantId);
      currentUser = login.globalDataCache.users.items.find((u) => u.id === login.userId);
      if (!currentTenant) throw new AppError('Back-end did not return the current tenant');
      if (!login.userId || !currentUser) throw new AppError('Back-end did not return the current user');
    } else {
      //Otherwise, for example when this is a new onboarding, the back-end is unaware of this tenant or user
      //Get the details from Graph
      logger.debug('Get Graph client');
      const accessTokenGraph = await getAccessToken(publicClientApplication, loginRequest.scopes);
      const graphInterface = getGraphClient(accessTokenGraph);
      if (!login.tenantLicensed) {
        logger.debug('Get tenant & user details from Graph');
        currentTenant = await graphGetTenantDetails(graphInterface);
        if (!currentTenant) throw new AppError('Graph did not return the current tenant');
        currentUser = await graphGetUserDetails(graphInterface, currentTenant);
        if (!login.userId || !currentUser) throw new AppError('Graph did not return the current user');
      } else {
        logger.debug('Get user details from Graph');
        currentTenant = login.tenants.find((t) => t.id === login.tenantId);
        if (!currentTenant) throw new AppError('Back-end did not return the current tenant');
        currentUser = await graphGetUserDetails(graphInterface, currentTenant);
        if (!login.userId || !currentUser) throw new AppError('Graph did not return the current user');
      }
    }

    //create new user object
    const usr = new CurrentUser(
      currentUser.id,
      currentTenant,
      currentUser.name,
      currentUser.email,
      login.userLanguageCode || UserLanguage.getBrowserLanguage(),
    );

    usr.login = login;

    //getting photo
    logger.debug('Getting user photo from Graph');
    const accessTokenGraph = await getAccessToken(publicClientApplication, loginRequest.scopes);
    const graphInterface = getGraphClient(accessTokenGraph);
    usr.photo = await graphGetUserPhoto(graphInterface);

    //when user has logged into an organizational unit, update the tenant name
    if (currentTenant.parentId && currentTenant.topLevelParentTenantId !== currentTenant.id) {
      logger.debug('Logged into organizational unit', currentTenant);
      const topLevelParent = login.tenants?.find((ou) => ou.id === currentTenant?.topLevelParentTenantId);
      logger.debug('Top level tenant', topLevelParent);
      usr.login.isOrgUnit = true;
      if (topLevelParent) {
        usr.tenant.name = overflow(topLevelParent.name, 48) + ' | ' + overflow(currentTenant.name, 48);
      }
    } else {
      usr.tenant.name = overflow(currentTenant.name, 100);
    }

    logger.debug('User tenant name', usr.tenant.name);

    //determine whether user is guest based on home account setting
    const homeAccount = usr.login.globalDataCache.userSettings.get(HomeAccount) as string;
    logger.debug('Home account set: ' + (homeAccount ?? 'unknown'));
    if (homeAccount) {
      usr.login.isGuest = homeAccount !== usr.tenant.azureTenantId;
    }

    //impersonation
    if (usr.id !== usr.login.userId) {
      logger.debug('SUPPORT request');
      // back-end reports another user-id. can be the case when impersonating a user for support
      const supportUser = usr.login.globalDataCache.users.get(usr.login.userId);
      usr.name = 'SUPPORT ' + supportUser.name;
    }

    logger.debug('Init global data cache');
    //set the global data cache we got from the login api call and clear the temp cache on the login object
    setGlobalDataCache(usr.login.globalDataCache);
    usr.login.globalDataCache.clear();

    if (
      usr.login.userLicensed === true &&
      hasSubscriptionFeature(usr.login.subscriptionType, FeatureTypes.CustomLogo)
    ) {
      logger.debug('Get custom logo');
      usr.tenant.appLogo = await apiGetTenantLogo(accessTokenApi, usr.login.isOrgUnit ? login.tenantId : undefined);
    }

    authStateUpdate(true, usr, false, undefined);
  } catch (err) {
    logger.debug('Error', err);
    authStateUpdate(false, CurrentUser.getEmptyUser(), false, err);
  }
};

//
// Graph
//

function getGraphClient(accessToken: string) {
  const client = Client.init({
    // Use the provided access token to authenticate requests
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    authProvider: (done: any) => {
      done(null, accessToken);
    },
  });

  return client;
}

//
// Helpers
//

const saveUserNameToStorage = (publicClientApplication: PublicClientApplication) => {
  try {
    const account = publicClientApplication.getActiveAccount();
    if (account) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const claims: any = account.idTokenClaims;
      const preferredUserName: string | undefined = claims.preferred_username;

      if (preferredUserName) {
        setLocalStorageData(undefined, LocalStorageKeys.UserName, preferredUserName);
      } else {
        throw new AppError('The claim preferred-username is not present in the claim collection');
      }
    }
  } catch (err) {
    logger.debug('Cannot save user name to storage', err);
  }
};

const saveLocalAccountToStorage = (publicClientApplication: PublicClientApplication) => {
  try {
    const account = publicClientApplication.getActiveAccount();
    if (account) {
      setLocalStorageData(undefined, LocalStorageKeys.LocalAccount, account.localAccountId);
    }
  } catch (err) {
    logger.debug('Cannot save local account to storage', err);
  }
};

const getUserNameFromStorage = (): string => {
  return getLocalStorageData(undefined, LocalStorageKeys.UserName) || '';
};

const getLocalAccountFromStorage = (): string => {
  return getLocalStorageData(undefined, LocalStorageKeys.LocalAccount) || '';
};

// Export
const authService = {
  hasScopes: hasScopes,
  getAccessToken: getAccessToken,
  ssoSilent: ssoSilent,
  login: login,
  logout: logout,
  setUserProfile: setUserProfile,
  adminConsent: adminConsent,
  adminConsentScope: adminConsentScope,
  switchTenant: switchTenant,
  getGraphClient: getGraphClient,
};

export default authService;
