import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { globalStackTokensGapMedium } from 'globalStyles';
import { useEffect, useState } from 'react';
import RichTextReadOnly from 'components/Text/RichTextReadOnly';
import Markdown from 'react-markdown';
import { renderToStaticMarkup } from 'react-dom/server';
import ScrollableStackItem from 'components/Utils/ScrollableStackItem';

interface ICopilotDocumentProps {
  document: string | undefined;
  active: boolean;
  newCompletionPart?: string;
}

export const CopilotDocument = (props: ICopilotDocumentProps) => {
  const [completions, setCompletions] = useState<string>('');
  const [completionsHtml, setCompletionsHtml] = useState<string>('');

  useEffect(() => {
    if (props.active && props.newCompletionPart) {
      const newCompletions = completions + props.newCompletionPart;
      updateDocument(newCompletions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.newCompletionPart]);

  useEffect(() => {
    if (props.document) {
      updateDocument(props.document);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.document]);

  const updateDocument = (markdown: string) => {
    setCompletions(markdown);
    const jsx = <Markdown>{markdown}</Markdown>;
    let html = renderToStaticMarkup(jsx);
    html = updateLinksToOpenInNewTab(html);
    setCompletionsHtml(html);
  };

  const updateLinksToOpenInNewTab = (html: string) => {
    return html.replace(/<a href=/g, '<a target="_blank" href=');
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill>
      <ScrollableStackItem>
        <Stack tokens={globalStackTokensGapMedium}>
          <RichTextReadOnly height={'100%'} maxHeight={-1} html={completionsHtml} />
          {props.active && <Spinner size={SpinnerSize.large} />}
        </Stack>
      </ScrollableStackItem>
    </Stack>
  );
};
