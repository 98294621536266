import * as SignalR from '@microsoft/signalr';
import { IAppContext } from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import Config from 'services/Config/configService';
import AppError from 'utils/appError';

const SendConnectionId = 'SendConnectionId';

export interface IHubConnection {
  connectionId: string | null;
  connection: SignalR.HubConnection;
}

export const startHubConnection = async (
  appContext: IAppContext,
  hubUrl: string,
  method: string,
  callback: unknown,
): Promise<IHubConnection> => {
  try {
    const baseUrl = Config.get('Api.BaseURL');
    const url = `${baseUrl}/${hubUrl}`;
    const accessToken = appContext.getAccessToken(apiRequest.scopes);
    const newConnection = new SignalR.HubConnectionBuilder()
      .configureLogging(SignalR.LogLevel.Error)
      .withUrl(url, {
        skipNegotiation: true, // skipNegotiation as we specify WebSockets
        transport: SignalR.HttpTransportType.WebSockets, // force WebSocket transport
        accessTokenFactory: () => accessToken, // add this for authentication
      })
      .withAutomaticReconnect()
      .build();

    let connectionId: string | null = null;
    if (newConnection) {
      newConnection.on(method, callback as never);
      await newConnection.start();
      connectionId = await newConnection.invoke<string>(SendConnectionId);
    }

    return {
      connectionId: connectionId,
      connection: newConnection,
    };
  } catch (err) {
    const appErr = AppError.fromApiError(err);
    throw appErr;
  }
};

export const stopHubConnection = async (connection: SignalR.HubConnection | undefined): Promise<void> => {
  try {
    if (connection) {
      if (connection.state === SignalR.HubConnectionState.Connected) {
        await connection.stop();
      }
    }
  } catch (err) {
    const appErr = AppError.fromApiError(err);
    throw appErr;
  }
};
