import { IAppContext } from 'App/AppContext';
import { i18nBase } from '../services/Localization/i18n';
import dayjs from 'dayjs';
import { WeekIndex } from 'microsoft-graph';
import { AuditYearStart } from 'models/setting';

//
// Date manipulation using Dayjs
//

// Add/substract date and time
export function addDateTimeHours(date1: Date, hours: number): Date {
  if (hours < 0) {
    return dayjs(date1).subtract(Math.abs(hours), 'hour').toDate();
  } else {
    return dayjs(date1).add(hours, 'hour').toDate();
  }
}

export function addDateTimeDays(date1: Date, days: number): Date {
  if (days < 0) {
    return dayjs(date1).subtract(Math.abs(days), 'day').toDate();
  } else {
    return dayjs(date1).add(days, 'day').toDate();
  }
}

export function addDateTimeYears(date1: Date, years: number): Date {
  if (years < 0) {
    return dayjs(date1).subtract(Math.abs(years), 'year').toDate();
  } else {
    return dayjs(date1).add(years, 'year').toDate();
  }
}

export function addDateTimeMinutes(date1: Date, mins: number): Date {
  if (mins < 0) {
    return dayjs(date1).subtract(Math.abs(mins), 'minute').toDate();
  } else {
    return dayjs(date1).add(mins, 'minute').toDate();
  }
}

// Diff functions
export function getDateTimeDiff(date1: Date | undefined, date2: Date | undefined): boolean {
  if (date1 === undefined && date2 === undefined) return false;
  if (date1 === undefined || date2 === undefined) return true;

  return dayjs(date1).diff(date2) !== 0;
}

export function getDateTimeDiffMinute(date1: Date, date2: Date): number {
  return dayjs(date1).diff(date2, 'minute');
}

export function getDateTimeDiffHours(date1: Date, date2: Date): number {
  return dayjs(date1).diff(date2, 'hour');
}

export function getDateTimeDiffDays(date1: Date, date2: Date): number {
  return dayjs(date1).diff(date2, 'day');
}

export function getDateTimeDiffMonths(date1: Date, date2: Date): number {
  return dayjs(date1).diff(date2, 'month');
}

export function getDateDiffDays(date1: Date, date2: Date): number {
  const date1NoTime = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), 0, 0, 0);
  const date2NoTime = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate(), 0, 0, 0);

  return dayjs(date1NoTime).diff(date2NoTime, 'day');
}

// add substract date only
export function addDateDays(date1: Date, days: number): Date {
  if (days < 0) {
    return dayjs(date1).subtract(Math.abs(days), 'day').toDate();
  } else {
    return dayjs(date1).add(days, 'day').toDate();
  }
}

export function addDateMonths(date1: Date, months: number): Date {
  if (months < 0) {
    return dayjs(date1).subtract(Math.abs(months), 'month').toDate();
  } else {
    return dayjs(date1).add(months, 'month').toDate();
  }
}

export function addDateYears(date1: Date, years: number): Date {
  if (years < 0) {
    return dayjs(date1).subtract(Math.abs(years), 'year').toDate();
  } else {
    return dayjs(date1).add(years, 'year').toDate();
  }
}

// This formats everything based on the current language
export function getLocaleTimeString(date?: Date, options?: Intl.DateTimeFormatOptions) {
  return date ? new Date(date).toLocaleTimeString(i18nBase.language, options) : '';
}

export function getLocaleDateString(date?: Date, options?: Intl.DateTimeFormatOptions) {
  return date ? new Date(date).toLocaleDateString(i18nBase.language, options) : '';
}

export function getLocaleDateTimeString(
  date?: Date,
  dateOptions?: Intl.DateTimeFormatOptions,
  timeOptions?: Intl.DateTimeFormatOptions,
) {
  if (!date) {
    return '';
  }
  const newDate = new Date(date);

  return (
    newDate.toLocaleDateString(i18nBase.language, dateOptions) +
    ' ' +
    newDate.toLocaleTimeString(i18nBase.language, timeOptions)
  );
}

//
// Date formatting functions
//
export function toLocaleDateNumeric(date?: Date): string {
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: '2-digit', day: '2-digit' };

  return getLocaleDateString(date, options);
}

export function toLocaleDateShort(date?: Date): string {
  const options: Intl.DateTimeFormatOptions = { dateStyle: 'medium' };

  return getLocaleDateString(date, options);
}

export function toLocaleDateLong(date?: Date): string {
  const options: Intl.DateTimeFormatOptions = { dateStyle: 'full' };

  return getLocaleDateString(date, options);
}

export function toLocaleDateShortNoYear(date?: Date): string {
  const options: Intl.DateTimeFormatOptions = { year: undefined, month: 'short', day: 'numeric' };

  return getLocaleDateString(date, options);
}

//
// Date and time formatting functions
//
export function toLocaleDateTime(date?: Date): string {
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  };

  return getLocaleDateTimeString(date, dateOptions, timeOptions);
}

export function toLocaleDateTimeNoSeconds(date?: Date): string {
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: undefined,
    hour12: false,
  };

  return getLocaleDateTimeString(date, dateOptions, timeOptions);
}

export function toLocaleDateTimeShort(date?: Date): string {
  const dateOptions: Intl.DateTimeFormatOptions = { year: undefined, month: 'short', day: 'numeric' };
  const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: '2-digit' };

  return getLocaleDateTimeString(date, dateOptions, timeOptions);
}

export function toLocaleDateTimeMedium(date?: Date): string {
  const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: '2-digit' };

  return getLocaleDateTimeString(date, dateOptions, timeOptions);
}

export function toLocaleDateTimeLong(date?: Date): string {
  const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: 'numeric' };
  const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: '2-digit' };

  return getLocaleDateTimeString(date, dateOptions, timeOptions);
}

export function toLocaleDateTimeMediumWithDay(date?: Date): string {
  const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' };
  const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: '2-digit' };

  return getLocaleDateTimeString(date, dateOptions, timeOptions);
}

//
// Time formatting functions
//
export function toLocaleTime(date?: Date): string {
  const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };

  return getLocaleTimeString(date, options);
}

export function toLocaleTimeNoSeconds(date?: Date): string {
  const options: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit', second: undefined, hour12: false };

  return getLocaleTimeString(date, options);
}

//
// API date time conversion
//
export function toApiDateTimeOptional(date: Date | undefined): string | undefined {
  // convert the date object into a ISO 8601 date string in the UTC time zone
  if (!date) {
    return undefined;
  }

  return toApiDateTimeRequired(date);
}

export function toApiDateTimeRequired(date: Date): string {
  // convert the date object into a ISO 8601 date string in the UTC time zone
  const dateString = date.toISOString();

  return dateString;
}

export function fromApiDateTimeOptional(dateString: string | undefined): Date | undefined {
  // convert the ISO 8601 date string in the UTC time zone to a date object in the local time zone
  if (!dateString) {
    return undefined;
  }

  return fromApiDateTimeRequired(dateString);
}

export function isDST(date: Date): boolean {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);

  return date.getTimezoneOffset() < Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

export function formatOffsetToHhMm(offsetInMins: number): string {
  const sign = offsetInMins > 0 ? '-' : '+';
  const positiveMins = Math.abs(offsetInMins);

  const hours = Math.floor(positiveMins / 60);
  const mins = Math.floor(positiveMins - (hours * 3600) / 60);

  return sign + hours.toString().padStart(2, '0') + ':' + mins.toString().padStart(2, '0');
}

export function fromApiDateTimeRequired(dateString: string): Date {
  // convert the ISO 8601 date string in the UTC time zone to a date object in the local time zone
  const date = dayjs(dateString).toDate();

  return date;
}

export function fromApiDateOptional(dateString: string | undefined): Date | undefined {
  // convert the ISO 8601 date string in the UTC time zone to a date object without time (set to zero)
  if (!dateString) {
    return undefined;
  }

  return fromApiDateRequired(dateString);
}

export function fromApiDateRequired(dateString: string): Date {
  // convert the ISO 8601 date string in the UTC time zone to a date object without time (set to zero)
  const date = dayjs(dateString).toDate();

  return toDateOnly(date);
}

export function toApiDateOptional(date: Date | undefined): string | undefined {
  // convert the date object into a ISO 8601 date string without time (set to zero)
  if (!date) {
    return undefined;
  }

  return toApiDateRequired(date);
}

export function toApiDateRequired(date: Date): string {
  // convert the date object into a ISO 8601 date string without time (set to zero)
  const timeStr = 'T00:00:00.000Z';
  const dateStr =
    date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
  const result = dateStr + timeStr;

  return result;
}

//
// Constant values
//
export const weekDays0: days[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const weekDays1: days[] = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

//
// Other utils
//
export const isLastOfMonth = (date: Date): boolean => {
  const oneWeekAfter = addDateDays(date, 7);

  return oneWeekAfter.getMonth() !== date.getMonth();
};

export const getWeekIndex = (date: Date): WeekIndex => {
  const day = date.getDate();
  if (day <= 7) return 'first';
  if (day <= 14) return 'second';
  if (day <= 21) return 'third';
  if (day <= 28) return 'fourth';

  return 'last';
};

export const toDateOnly = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
};

export const roundTimeToNextHalfHour = (date: Date): Date => {
  let output = new Date(date);
  if (output.getMinutes() < 30) {
    output = addDateTimeMinutes(output, 30 - output.getMinutes());
  } else if (output.getMinutes() > 30) {
    output = addDateTimeMinutes(output, 60 - output.getMinutes());
  }

  return output;
};

export function isValidDate(date?: Date | string): boolean {
  try {
    if (!date) return false;
    let dateInternal: Date;
    if (typeof date === 'string') {
      dateInternal = new Date(date);
    } else {
      dateInternal = date;
    }
    if (Object.prototype.toString.call(dateInternal) === '[object Date]') {
      if (isNaN(dateInternal.getTime())) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  } catch {
    return false;
  }
}

export function setNewDateKeepTime(newDate: Date, currentDateTime: Date): Date {
  const newDateTime: Date = new Date(newDate);
  //create the new start date object, keep the time that was set
  newDateTime.setHours(currentDateTime.getHours());
  newDateTime.setMinutes(currentDateTime.getMinutes());
  newDateTime.setSeconds(0);
  newDateTime.setMilliseconds(0);

  return newDateTime;
}

export function setNewTimeKeepDate(newDate: Date, hours: number, minutes: number): Date {
  const newDateTime: Date = new Date(newDate);
  //create the new start date object, set the date but keep the time that was set
  newDateTime.setHours(hours);
  newDateTime.setMinutes(minutes);
  newDateTime.setSeconds(0);
  newDateTime.setMilliseconds(0);

  return newDateTime;
}

export const isAm = (): boolean => {
  return dayjs().format('A') === 'AM';
};

export const getDays = (month: number): number[] => {
  const days = Array.from({ length: 31 }, (v, k) => k + 1);
  if ([1, 3, 5, 7, 9, 11].includes(month)) {
    return days;
  } else if ([4, 6, 8, 10, 12].includes(month)) {
    return days.slice(0, days.length - 1);
  } else if (month === 2) {
    return days.slice(0, days.length - 3);
  } else {
    return [];
  }
};

export const getDayNumeric = (day: days): number => {
  if (day === 'Sunday') return 0;
  if (day === 'Monday') return 1;
  if (day === 'Tuesday') return 2;
  if (day === 'Wednesday') return 3;
  if (day === 'Thursday') return 4;
  if (day === 'Friday') return 5;
  if (day === 'Saturday') return 6;

  return 0;
};

export type days = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday';

//
// Audit year functions
//
export const getCurrentAuditYear = (auditStart: string | undefined): number => {
  const currentDate = new Date();
  const auditYearStartDate = getAuditStartForSetting(currentDate.getFullYear(), auditStart);

  if (auditYearStartDate.getMonth() > currentDate.getMonth()) {
    return currentDate.getFullYear() - 1;
  } else if (auditYearStartDate.getMonth() === currentDate.getMonth()) {
    if (auditYearStartDate.getDate() > currentDate.getDate()) {
      return currentDate.getFullYear() - 1;
    }
  }

  return currentDate.getFullYear();
};

export const getAuditStartForSetting = (auditYear: number | undefined, auditStart: string | undefined): Date => {
  const output = new Date();
  if (!auditStart) auditStart = '01-01'; // Default to January 1st if no audit start date is set
  const year = auditYear || getCurrentAuditYear(auditStart);

  const split = auditStart.split('-');
  const month = +split[0];
  const date = +split[1];

  output.setFullYear(year);
  output.setDate(date);
  output.setMonth(month - 1);

  output.setHours(12);
  output.setMinutes(0);
  output.setSeconds(0);
  output.setMilliseconds(0);

  return output;
};

export const getAuditStart = (appContext: IAppContext, auditYear?: number): Date => {
  try {
    const auditDateSetting = appContext.globalDataCache.settings.get(AuditYearStart);

    return getAuditStartForSetting(auditYear, auditDateSetting);
  } catch {
    return new Date();
  }
};
