import { Fragment, Suspense, lazy, useContext } from 'react';
import { Stack, Separator, ScrollablePane, ScrollbarVisibility, ThemeProvider } from '@fluentui/react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import NavBar from 'components/NavBar/NavBar';
import MobileNavBar from 'components/NavBar/MobileNavBar';
import NavRoot from 'components/NavMain/NavRoot';
import { ErrorMessage } from 'components/Notification/ErrorMessage';
import SceneLoader from 'components/Loading/SceneLoader';
import Dashboard from 'scenes/Dashboard/Dashboard';
import AppContext from './AppContext';
import AppLoader from 'components/Loading/AppLoader';
import ContentLoader from 'components/Loading/ContentLoader';
import { darkTheme, lightTheme } from 'globalThemes';
import AppRightSideBarLayerHost from './AppRightSideBarLayerHost';
import AppContentAreaTopLayerHost from './AppContentAreaTopLayerHost';
import ProtectedRoute from 'components/Routing/ProtectedRoute';
import { globalNotFoundURL, globalToastDelay } from 'globalConstants';
import { ToastContainer } from 'react-toastify';
import { FeatureTypes, hasUserFeatureGenericManager } from 'services/Auth/featurePermissions';

//
// Lazy load all scenes (React module splitting)
//
const TaskSchedule = lazy(() => import('scenes/Schedule/TaskSchedule'));
const Processes = lazy(() => import('scenes/Organization/Organization'));
const ProcessDetails = lazy(() => import('scenes/Process/ProcessDetails'));
const RisksOverview = lazy(() => import('scenes/Risks/RisksOverview'));
const RiskDetails = lazy(() => import('scenes/Risk/RiskDetails'));
const Controls = lazy(() => import('scenes/Controls/Controls'));
const ControlDetails = lazy(() => import('scenes/Control/ControlDetails'));
const ObjectiveDetails = lazy(() => import('scenes/Objective/ObjectiveDetails'));
const UpdateTenant = lazy(() => import('scenes/Enroll/UpdateTenant'));
const StartTenant = lazy(() => import('scenes/Enroll/StartTenant'));
const TasksOverview = lazy(() => import('scenes/Tasks/TasksOverview'));
const Themes = lazy(() => import('scenes/Themes/Themes'));
const ThemeDetails = lazy(() => import('scenes/Theme/ThemeDetails'));
const Norms = lazy(() => import('scenes/Norms/Norms'));
const Library = lazy(() => import('scenes/Library/Library'));
const StoreOverview = lazy(() => import('scenes/Store/StoreOverview'));
const AdminSubscription = lazy(() => import('scenes/Admin/Subscription/AdminSubscription'));
const CompanySettings = lazy(() => import('scenes/Admin/Company/CompanySettings'));
const AdminNorms = lazy(() => import('scenes/Admin/Norms/AdminNorms'));
const AdminGroups = lazy(() => import('scenes/Admin/Groups/AdminGroups'));
const AdminAuth = lazy(() => import('scenes/Admin/Auth/AdminAuth'));
const NotAuthorized = lazy(() => import('scenes/NotAuthorized/NotAuthorized'));
const NotFound = lazy(() => import('scenes/NotFound/NotFound'));
const Logout = lazy(() => import('scenes/Logout/Logout'));
const Welcome = lazy(() => import('scenes/Welcome/Welcome'));
const TenantAdminControls = lazy(() => import('scenes/TenantAdmin/Controls/TenantAdminControls'));
const TenantAdminManage = lazy(() => import('scenes/TenantAdmin/Manage/TenantAdminManage'));
const AdminOrgUnits = lazy(() => import('scenes/Admin/OrgUnits/AdminOrgUnits'));
const AdminReports = lazy(() => import('scenes/Admin/Reports/AdminReports'));
const AssetsOverview = lazy(() => import('scenes/Assets/AssetsOverview'));
const AssetDetails = lazy(() => import('scenes/Asset/AssetDetails'));
const PartnerAdminManage = lazy(() => import('scenes/PartnerAdmin/PartnerAdminCustomers'));
const KPIDetails = lazy(() => import('scenes/KPI/KPIDetails'));
const PartnerTenantDashboard = lazy(() => import('scenes/PartnerAdmin/PartnerTenantDashboard'));
const TenantAdminLogs = lazy(() => import('scenes/TenantAdmin/TenantLogs/TenantAdminLogs'));

const AppRouting = () => {
  const appContext = useContext(AppContext);

  const getTopNavbar = () => {
    if (!appContext.isMobileView) {
      return (
        <NavBar
          login={appContext.login}
          logout={appContext.logout}
          switchOrg={appContext.switchOrg}
          switchOrgUnit={appContext.switchOrgUnit}
        ></NavBar>
      );
    } else {
      return (
        <MobileNavBar
          login={appContext.login}
          logout={appContext.logout}
          switchOrg={appContext.switchOrg}
          switchOrgUnit={appContext.switchOrgUnit}
        ></MobileNavBar>
      );
    }
  };

  const getNavRoot = () => {
    if (!appContext.isMobileView) {
      return (
        <Fragment>
          <Stack.Item>
            <NavRoot />
          </Stack.Item>
          <Separator vertical></Separator>
        </Fragment>
      );
    }
  };

  return (
    <ThemeProvider applyTo="body" theme={appContext.useDarkMode ? darkTheme : lightTheme}>
      <Router>
        <Stack verticalFill styles={{ root: { height: '100vh' } }}>
          {/* Main App */}

          <Stack.Item>
            {/* Top bar */}
            {getTopNavbar()}
            <AppLoader />
          </Stack.Item>

          <Stack.Item grow>
            {/* Body */}

            <Stack horizontal styles={{ root: { height: '100%' } }}>
              {/* Left side bar */}
              {getNavRoot()}

              <Stack.Item grow>
                {/* Content area */}
                <Stack styles={{ root: { height: '100%' } }}>
                  <Stack.Item>
                    {/* Notification area */}
                    <AppContentAreaTopLayerHost />
                    <ContentLoader />
                    <ErrorMessage error={appContext.error} setError={appContext.setError} />
                    <ToastContainer position="top-right" autoClose={globalToastDelay} containerId="app" />
                  </Stack.Item>

                  <Stack.Item grow styles={{ root: { position: 'relative' } }}>
                    {/* Scene area */}
                    <Suspense fallback={<SceneLoader />}>
                      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <Switch>
                          {/* 1. All users may navigate to unprotected routes, signed in or not
                                  2. When authentication is in progress, wait until completed by rendering the ProgressIndicator
                                  3. When the user is not authenticated and requests a protected route, navigate to not-authorized
                                  4. When the user is authenticated, navigate to the protected route, the route itself is responsible for checking role and redirect to not-authorized
                                  5. Navigate to not-found when the route is not found
                              */}

                          {/* These routes are not protected  */}
                          <Route path="/logout" render={(props) => <Logout {...props} />} />
                          <Route path={globalNotFoundURL} render={(props) => <NotFound {...props} />} />
                          <Route path="/not-authorized" render={(props) => <NotAuthorized {...props} />} />
                          <Route path="/reload" render={() => <SceneLoader />} />

                          {/* Route all paths (not exact /) to the SceneLoader component when authorization is in progress */}
                          {appContext.isAuthInProgress && <Route path="/" render={() => <SceneLoader />} />}

                          {/* Via this route, a new trial can be started for the currently unknown tenant */}
                          <Route
                            path="/start"
                            exact
                            render={(props) => {
                              if (appContext.isAuthInProgress) {
                                return <SceneLoader />;
                              } else if (
                                appContext.isAuthenticated &&
                                !appContext.user.login.tenantId &&
                                appContext.user.login.tenantLicensed === false
                              ) {
                                return <StartTenant {...props} />; //start trial
                              } else if (appContext.user.login.userLicensed) {
                                return <UpdateTenant {...props} />; //update lead source and/or packages
                              } else if (
                                appContext.user.login.tenantId &&
                                appContext.user.login.tenantLicensed === false &&
                                hasUserFeatureGenericManager(appContext)
                              ) {
                                //redirect to dashboard when a registered tenant has no
                                //license anymore (trial/subscription ended) and the user has roles
                                return <Redirect to="/dashboard"></Redirect>;
                              } else {
                                //1. not authenticated
                                //2. tenant is suspended
                                //3. user has no license
                                return <Welcome authButton={appContext.login} {...props} />;
                              }
                            }}
                          />

                          {/* Route to home and switch between is/not authenticated */}
                          <Route
                            path="/"
                            exact
                            render={(props) =>
                              /* Show dashboard when user has a license or when a registered tenant has no 
                                  license anymore (trial/subscription ended) and the user has roles */
                              appContext.user.login.userLicensed ||
                              (appContext.user.login.tenantId &&
                                appContext.user.login.tenantLicensed === false &&
                                hasUserFeatureGenericManager(appContext)) ? (
                                <Redirect to="/dashboard"></Redirect>
                              ) : (
                                <Welcome authButton={appContext.login} {...props} />
                              )
                            }
                          />

                          {/* After this point all routes are protected and must have an authenticated user */}
                          {!appContext.isAuthenticated && <Redirect to="/"></Redirect>}

                          {/* All authenticated users */}
                          <Route path="/dashboard" render={(props) => <Dashboard {...props} />} />

                          <ProtectedRoute
                            featureType={FeatureTypes.Requirements}
                            path="/themes"
                            render={(props) => <Themes {...props} />}
                          />
                          <ProtectedRoute
                            featureType={FeatureTypes.Requirements}
                            path="/theme/:urlThemeParam"
                            render={(props) => <ThemeDetails {...props} />}
                          />
                          <ProtectedRoute
                            featureType={FeatureTypes.Assets}
                            path="/assets"
                            render={(props) => <AssetsOverview {...props}></AssetsOverview>}
                          />
                          <ProtectedRoute
                            featureType={FeatureTypes.Assets}
                            path="/asset/:urlAssetParam"
                            render={(props) => <AssetDetails {...props} />}
                          />
                          <ProtectedRoute
                            featureType={FeatureTypes.Risks}
                            path="/risks"
                            render={(props) => <RisksOverview {...props}></RisksOverview>}
                          />
                          <ProtectedRoute
                            featureType={FeatureTypes.Risks}
                            path="/risk/:urlRiskParam"
                            render={(props) => <RiskDetails {...props} />}
                          />
                          <ProtectedRoute
                            featureType={FeatureTypes.Controls}
                            path="/controls"
                            render={(props) => <Controls {...props} />}
                          />
                          <ProtectedRoute
                            featureType={FeatureTypes.Controls}
                            path="/control/:urlControlParam"
                            render={(props) => <ControlDetails {...props} />}
                          />
                          <ProtectedRoute
                            featureType={FeatureTypes.Organization}
                            path="/organization"
                            render={(props) => <Processes {...props} />}
                          />
                          <ProtectedRoute
                            featureType={FeatureTypes.Processes}
                            path="/process/:urlProcessParam"
                            render={(props) => <ProcessDetails {...props} />}
                          />

                          <ProtectedRoute
                            featureType={FeatureTypes.Objectives}
                            path="/objective/:urlObjectiveParam"
                            render={(props) => <ObjectiveDetails {...props} />}
                          />
                          <ProtectedRoute
                            featureType={FeatureTypes.KPIsAndForms}
                            path="/kpi/:urlKPIParam"
                            render={(props) => <KPIDetails {...props} />}
                          />
                          <ProtectedRoute
                            featureType={FeatureTypes.Standards}
                            path="/standards"
                            render={(props) => <Norms {...props} />}
                          />
                          <ProtectedRoute
                            featureType={FeatureTypes.Tasks}
                            path="/tasks"
                            render={(props) => <TasksOverview {...props} />}
                          />
                          <ProtectedRoute
                            featureType={FeatureTypes.Library}
                            path="/library"
                            render={(props) => <Library {...props} />}
                          />

                          {/* Manager */}
                          <ProtectedRoute
                            path="/schedule"
                            featureType={FeatureTypes.GenericManager}
                            render={(props) => <TaskSchedule {...props} />}
                          />

                          <ProtectedRoute
                            path="/admin/standards"
                            featureType={FeatureTypes.GenericManager}
                            render={(props) => <AdminNorms {...props} />}
                          />

                          <ProtectedRoute
                            path="/admin/settings"
                            featureType={FeatureTypes.GenericManager}
                            render={(props) => <CompanySettings {...props} />}
                          />

                          <ProtectedRoute
                            path="/admin/store"
                            featureType={FeatureTypes.GenericManager}
                            render={(props) => <StoreOverview {...props} />}
                          />

                          <ProtectedRoute
                            path="/admin/groups"
                            featureType={FeatureTypes.GenericManager}
                            render={(props) => <AdminGroups {...props} />}
                          />

                          <ProtectedRoute
                            path="/admin/reports"
                            featureType={FeatureTypes.GenericManager}
                            render={(props) => <AdminReports {...props} />}
                          />

                          {/* Admin */}

                          <ProtectedRoute
                            path="/admin/users"
                            featureType={FeatureTypes.AdminAuth}
                            render={(props) => <AdminAuth {...props} />}
                          />

                          <ProtectedRoute
                            path="/admin/subscription"
                            featureType={FeatureTypes.AdminSubscription}
                            render={(props) => <AdminSubscription {...props} />}
                          />

                          <ProtectedRoute
                            path="/admin/orgunits"
                            featureType={FeatureTypes.AdminOrgUnits}
                            render={(props) => <AdminOrgUnits {...props} />}
                          />

                          {/* Partner Admin */}
                          <ProtectedRoute
                            path="/partner/customers"
                            featureType={FeatureTypes.PartnerAdmin}
                            render={(props) => <PartnerAdminManage {...props} />}
                          />

                          <ProtectedRoute
                            path="/partner/customer/:urlTenantParam"
                            featureType={FeatureTypes.PartnerAdmin}
                            render={(props) => <PartnerTenantDashboard {...props} />}
                          />

                          {/* Tenant Admin */}
                          <ProtectedRoute
                            path="/tenant/manage"
                            featureType={FeatureTypes.TenantManagement}
                            render={(props) => <TenantAdminManage {...props} />}
                          />

                          <ProtectedRoute
                            path="/tenant/controls"
                            featureType={FeatureTypes.TenantISOAdmin}
                            render={(props) => <TenantAdminControls {...props} />}
                          />

                          <ProtectedRoute
                            path="/tenant/logs"
                            featureType={FeatureTypes.TenantManagement}
                            render={(props) => <TenantAdminLogs {...props} />}
                          />

                          <Redirect to="/not-found"></Redirect>
                        </Switch>
                      </ScrollablePane>
                    </Suspense>
                  </Stack.Item>
                </Stack>
              </Stack.Item>
              <Stack.Item>
                {/* Panel area */}
                <AppRightSideBarLayerHost />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        </Stack>
      </Router>
    </ThemeProvider>
  );
};

export default AppRouting;
