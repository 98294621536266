import { Dropdown, IDropdownOption, Link, SelectableOptionMenuItemType, Stack } from '@fluentui/react';
import AppContext from 'App/AppContext';
import ModifyTextDialog from 'components/Dialogs/ModifyTextDialog';
import { ISONorm } from 'models/norm';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sortOnString } from 'utils/sorting';

interface ITrialWelcomeGoalsFrameworksProps {
  standards: ISONorm[];
  placeholder: string;
  selectedFrameworks: string[];
  excludeFrameworks: string[];
  otherFrameworks: string;
  hasReachedMaxStandards: boolean;
  allowDowngrade: boolean;
  updateOtherFrameworks: (value: string) => void;
  updateFrameworks: (value: string[]) => void;
}

const TrialWelcomeGoalsFrameworks = (props: ITrialWelcomeGoalsFrameworksProps) => {
  const { t } = useTranslation(['translation', 'dashboard', 'adminCompany']);
  const appContext = useContext(AppContext);
  const [showOtherFrameworksDialog, setShowOtherFrameworksDialog] = useState<boolean>(false);

  const getISONormOptions = (standardList: ISONorm[]): IDropdownOption[] => {
    let options: IDropdownOption[] = [];

    const mostUsedFrameworksIds = [13, 2, 66, 1, 11, 36, 18, 31, 42, 30, 32];

    options.push({
      key: 'h1',
      itemType: SelectableOptionMenuItemType.Header,
      text: t('dashboard:TrialWelcome.Goals.MostUsedFrameworks'),
    } as IDropdownOption);

    options.push(
      ...mostUsedFrameworksIds
        .filter((s) => standardList.some((s2) => s2.isoNormId === s))
        .map((m) => {
          const s = standardList.find((s) => s.isoNormId === m);

          return {
            key: `${s?.isoNormId}`,
            text: s?.name,
            title: s?.description,
          } as IDropdownOption;
        }),
    );

    options.push({
      key: 'h2',
      itemType: SelectableOptionMenuItemType.Header,
      text: t('dashboard:TrialWelcome.Goals.OtherFrameworks'),
    } as IDropdownOption);

    options.push(
      ...standardList
        .filter((s) => !mostUsedFrameworksIds.includes(s.isoNormId))
        .map((s) => {
          return {
            key: `${s.isoNormId}`,
            text: s.name,
            title: s.description,
          } as IDropdownOption;
        })
        .sort((a, b) => sortOnString(a.text, b.text)),
    );

    //disable items for which an standard is selected that is an upgrade of that item
    options.forEach((option) => {
      const standard = standardList.find((s) => s.isoNormId.toString() === option.key);
      if (standard && standard.upgradeISONormId && !standard.allowDowngrade) {
        const upgrade = standardList.find((s) => s.isoNormId === standard.upgradeISONormId);
        if (upgrade && props.selectedFrameworks.includes(upgrade.isoNormId.toString())) {
          option.disabled = true;
        }
      }
    });

    //remove items that are upgrades of the excluded frameworks
    if (!props.allowDowngrade) {
      options = options.filter((option) => {
        const standard = standardList.find((s) => s.isoNormId.toString() === option.key);
        if (standard && standard.upgradeISONormId && !standard.allowDowngrade) {
          if (props.excludeFrameworks.includes(standard.upgradeISONormId.toString())) {
            return false;
          }
        }

        return true;
      });
    }

    //remove selected items that don't appear in these options
    let newSelectedFrameworks = props.selectedFrameworks.filter((s) => options.some((o) => o.key === s));

    //remove selected items that are disabled in these options
    newSelectedFrameworks = newSelectedFrameworks.filter((s) => !options.some((o) => o.key === s && o.disabled));

    if (newSelectedFrameworks.length !== props.selectedFrameworks.length) {
      props.updateFrameworks(newSelectedFrameworks);
    }

    //disable all options when max standards are reached
    if (props.hasReachedMaxStandards) {
      options.forEach((option) => {
        if (!props.selectedFrameworks.some((s) => s === option.key)) option.disabled = true;
      });
    }

    return options;
  };

  const excludeFrameworks = (all: ISONorm[], exclude: string[]): ISONorm[] => {
    return all.filter((s) => !exclude.includes(s.isoNormId.toString()));
  };

  //
  // Main render
  //
  return (
    <Stack>
      <Stack.Item>
        <Dropdown
          placeholder={props.placeholder}
          options={getISONormOptions(excludeFrameworks(props.standards, props.excludeFrameworks))}
          multiSelect
          styles={{
            root: {
              maxWidth: appContext.isMobileView ? '80%' : 600,
            },
          }}
          calloutProps={{ calloutMaxHeight: 400 }}
          selectedKeys={props.selectedFrameworks}
          onChange={(ev, option) => {
            const id = option?.key as string;
            if (option && id) {
              if (props.selectedFrameworks.includes(id)) {
                props.updateFrameworks(props.selectedFrameworks.filter((s) => s !== id));
              } else {
                props.updateFrameworks([...props.selectedFrameworks, id]);
              }
            }
          }}
        ></Dropdown>
      </Stack.Item>
      <Stack.Item>
        <Link onClick={() => setShowOtherFrameworksDialog(true)}>{t('dashboard:TrialWelcome.Goals.AskNotInList')}</Link>
      </Stack.Item>
      <ModifyTextDialog
        title={t('dashboard:TrialWelcome.Dialogs.AskNotInList.Title')}
        isOpen={showOtherFrameworksDialog}
        value={props.otherFrameworks}
        placeholder={t('dashboard:TrialWelcome.Dialogs.AskNotInList.Placeholder')}
        onUpdate={props.updateOtherFrameworks}
        onClose={() => {
          setShowOtherFrameworksDialog(false);
        }}
        onSave={(value) => {
          if (value) props.updateOtherFrameworks(value);
          setShowOtherFrameworksDialog(false);
        }}
        multiline
        maxLength={256}
        width={appContext.isMobileView ? 300 : 600}
      />
    </Stack>
  );
};

export default TrialWelcomeGoalsFrameworks;
