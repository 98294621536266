import { TFunction } from 'i18next';
import AppError from 'utils/appError';
import logger from 'services/Logging/logService';
import { CopilotErrorType } from 'models/copilot/copilotConversation';

export const getOutlookErrorMsg = (error: AppError, t: TFunction<string[]>): string | undefined => {
  logger.debug('getOutlookErrorMsg', error);

  if (error.code) {
    switch (error.code) {
      case '400':
        if (
          error.debug &&
          (error.debug.indexOf('ResourceNotFound') >= 0 || error.debug.indexOf('ErrorInvalidUser') >= 0)
        ) {
          return t('translation:Errors.Outlook.ResourceNotFound'); //user not found
        }
        if (error.debug && error.debug.indexOf('ResourceNotFound') >= 0 && error.debug.indexOf('CreateEvent') >= 0) {
          return t('translation:Errors.Outlook.ResourceNotFound'); //event not found
        }
        if (error.debug && error.debug.indexOf('NoPermissionsInAccessToken') >= 0) {
          return t('translation:Errors.Outlook.AuthError'); //app is not authorized for calender access
        }
        if (error.debug && error.debug.indexOf('Current authenticated context is not valid for this request') >= 0) {
          return t('translation:Errors.Outlook.AuthError'); //app is not authorized for calender access
        }
        if (error.debug && error.debug.indexOf('ErrorAccessDenied') >= 0) {
          return t('translation:Errors.Outlook.AuthError'); //app is not authorized for calender access
        }
        if (error.debug && error.debug.indexOf('MissingClaimType') >= 0) {
          return t('translation:Errors.Outlook.AuthError'); //user is invalid for calender access
        }
        if (error.debug && error.debug.indexOf('MailboxNotEnabledForRESTAPI') >= 0) {
          return t('translation:Errors.Outlook.ResourceNotFound'); //The mailbox is either inactive, soft-deleted, or is hosted on-premise.
        }
    }
  }

  return undefined;
};

export const getCopilotErrorCode = (error: AppError): CopilotErrorType | undefined => {
  if (error.debug && error.debug.indexOf('tokens') >= 0) {
    return CopilotErrorType.MaxTokens;
  }

  return undefined;
};

export const getCopilotErrorMsg = (error: AppError, t: TFunction<string[]>): string | undefined => {
  logger.debug('getCopilotErrorMsg', error);

  if (error.code) {
    switch (error.code) {
      case '400':
        switch (getCopilotErrorCode(error)) {
          case CopilotErrorType.MaxTokens:
            return t('translation:Errors.Copilot.MaxTokens');
        }
    }
  }

  return undefined;
};
