// App global
export const globalAppName: string = 'ISOPlanner';
export const globalAppVersion: string = '5.0.1'; //this is the main version: increase on every release
export const globalGraphMaxRecords: number = 50;
export const globalSuggestionsMaxRecords: number = 20;
export const globalMinFilterChars: number = 2;
export const globalFilterDelay: number = 300;
export const globalToastDelay: number = 4000;
export const globalTrialLengthDays: number = 30;
export const globalTaskDefaultDuration: number = 8;
export const globalTaskDefaultDeadline: number = 14;
export const globalCompanyAuditTrailId: number = -1; 
export const globalMaxUploadSize: number = 20 * 1024 * 1024;
export const globalAdminTenantCommentName = 'ISOPlanner Support';
export const globalMaxSharePointListSize = 5000;
export const globalNavBarHeight: number = 48;
export const globalStandardLogoBox: number = 200;
export const globalStandardLogoBoxSize: number = 65536;
export const globalStandardLogoDefFile: string = 'standard_logo_generic.png';

//Knowledge base permalinks
export const globalKB_howToSetupWorkflows: string = 'https://isoplanner.app/help/using-the-isoplanner-connector';
export const globalKB_howToWorkWithApprovals: string = 'https://isoplanner.app/help/how-to-work-with-approvals';
export const globalKB_howToWorkWithKPIEvidence: string = 'https://isoplanner.app/help/how-to-work-with-kpi-evidence';
export const globalKB_howToWorkWithKPIExpressions: string = 'https://isoplanner.app/help/how-to-work-with-kpi-expressions';
export const globalKB_howToWorkWithPowerBI: string = 'https://isoplanner.app/help/how-to-work-with-powerbi';
export const globalKB_howToWorkWithOrgUnits: string = 'https://isoplanner.app/help/how-to-work-with-orgunits';
export const globalKB_howToWorkWithOrgUnitsInherit: string = 'https://isoplanner.app/help/how-to-work-with-orgunits#3-toc-title';
export const globalKB_howToWorkWithTasks: string = 'https://isoplanner.app/help/tasks/';
export const globalKB_howToWorkWithAssets: string = 'https://isoplanner.app/help/assets/';
export const globalKB_howToWorkWithRisks: string = 'https://isoplanner.app/help/risks/';
export const globalKB_howToWorkWithAnnualPlan: string = 'https://isoplanner.app/help/annual-plan/';
export const globalKB_howToSetupLibrary: string = 'https://isoplanner.app/help/library/';
export const globalKB_adminAuth: string = 'https://isoplanner.app/help/authorization';
export const globalKB_adminTeamsAndGroups: string = 'https://isoplanner.app/help/teams-and-groups';
export const globalKB_adminSettingsNotifications: string = 'https://isoplanner.app/help/settings-notifications';
export const globalKB_howToWorkWithGlobalSearchAndFilter: string = 'https://isoplanner.app/help/how-to-work-with-search-and-filter';
export const globalKB_howToWorkWithTheDocumentLifeCycle: string = 'https://isoplanner.app/help/how-to-work-with-document-life-cycle';
export const globalKB_howToWorkWithForms: string = 'https://isoplanner.app/help/how-to-work-with-kpis-and-forms/';
export const globalKB_howToWorkWithFormsChecklist: string = 'https://isoplanner.app/help/how-to-work-with-kpis-and-forms/#6-toc-title';
export const globalKB_ISOBasicInfo: string = 'https://isoplanner.app/help/compliance-basics';
export const globalKB_Demo: string = 'https://isoplanner.app/demo-video-watch/'
export const globalKB_AI: string = 'https://isoplanner.app/help/ai-assistent'
export const globalKB_Training: string = 'https://isoplanner.app/course/isoplanner-training-course/'
export const globalKB_TagTaskCategory: string = 'https://isoplanner.app/help/library/#2-toc-title'
export const globalKB_AdminUsers: string = 'https://isoplanner.app/help/users/'
export const globalKB_AdminSubscription: string = 'https://isoplanner.app/help/start-your-subscription/'

//Subscription limits
export const globalWebhookLimitBusiness: number = 10;

// Languages
// These must also be included in i18nBase.options.supportedLngs
export const supportedUILanguages: Map<string, string> = new Map<string, string>();
supportedUILanguages.set('en', 'English');
supportedUILanguages.set('nl', 'Nederlands');
supportedUILanguages.set('de', 'Deutsch');

// Images
export const globalDefaultPackageLogoSmallLight = 'ISOPlanner 200x50 transparent TM.png';
export const globalDefaultPackageLogoSmallDark = 'ISOPlanner 200x50 transparent diap TM.png';
export const globalDefaultPackageLogoLargeLight = 'ISOPlanner 400x100 transparent TM.png';
export const globalDefaultPackageLogoLargeDark = 'ISOPlanner 400x100 transparent diap TM.png';

// URL's
export const globalWebsiteURL: string = 'https://isoplanner.app';
export const globalSupportURL: string = 'https://isoplanner.app/support';
export const globalPrivacyURL: string = 'https://isoplanner.app/privacy';
export const globalTermsURL: string = 'https://isoplanner.app/terms-of-service';
export const globalISOURL: string = 'https://www.iso.org/standards.html';
export const globalSubscriptionURL: string = 'https://subscription.isoplanner.app/home/subscriptions';
export const globalSupportEmail: string = 'support@isoplanner.app';
export const globalAppPortalUrl: string = 'https://portal.isoplanner.app';
export const globalHelpLink: string = 'https://isoplanner.app/help';
export const globalPartnerProgramUrl: string = 'https://isoplanner.app/partners';

// Internal URL's
export const globalNotFoundURL: string = '/not-found';
export const globalNotAuthorizedURL: string = '/not-authorized';
export const globalReturnUrls: string[] = [
  '/',
  '/start',
  '/admin/settings/integration',
  '/dashboard/welcome',
  '/admin/users',
  '/admin/store/content',
];

// KPI's
export const globalKPIDecimal18c8 = 999999999999999999.99999999;

// Kanban board sort gap (10^12)
export const globalKanbanSortGap: number = 10000000000000;

// Reg-ex
export const globalRegExGetNumericPart: RegExp = /\d/g;
export const globalRegExGetAlphaNumericPart: RegExp = /[^0-9.]/g;
export const globalRegExGetAlphaNumericPartNoDots: RegExp = /\D/g;
export const globalRegExSemVer: RegExp = /^([0-9]+)\.([0-9]+)\.([0-9]+)/;
export const globalRegExExtractUrlFromHtml: RegExp = /"(https?):\/\/[^\s/$.?#].[^\s]*"/gi;
export const globalRegExExtractRelSpUrlFromHtml: RegExp = /"(\/sites\/?)[^\s/$.?#].[^\s]*"/gi;
export const globalRegExValidateEmail: RegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i;
export const globalRegExStripKPIExpression: RegExp = /[[0-9]+]/gmi;
export const globalRegExKPIExpressionFuncSubTaskSum: RegExp = /SUBTASK_SUM[[0-9]+]/gmi;
export const globalRegExKPIExpressionFuncSubTaskMultiply: RegExp = /SUBTASK_MULITPLY[[0-9]+]/gmi;

// File types
// export const allowedMimeTypesForUpload: string[] = [

export interface AcceptType {
  [key: string]: string[];
}

export const allowedMimeTypesForUpload: AcceptType = {
  'application/pdf': [],
  'image/*': [],
  'video/*': [],
  'audio/*': [],
  'application/msword': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template': [],
  'application/vnd.ms-word.document.macroEnabled.12': [],
  'application/vnd.ms-word.template.macroEnabled.12': [],
  'application/vnd.ms-excel': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template': [],
  'application/vnd.ms-excel.sheet.macroEnabled.12': [],
  'application/vnd.ms-excel.template.macroEnabled.12': [],
  'application/vnd.ms-excel.addin.macroEnabled.12': [],
  'application/vnd.ms-excel.sheet.binary.macroEnabled.12': [],
  'application/vnd.ms-powerpoint': [],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [],
  'application/vnd.openxmlformats-officedocument.presentationml.template': [],
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow': [],
  'application/vnd.ms-powerpoint.addin.macroEnabled.12': [],
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12': [],
  'application/vnd.ms-powerpoint.template.macroEnabled.12': [],
  'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': [],
  'text/*': [],
  'application/zip': [],
  'application/x-zip-compressed': [],
};

//ref:https://react-dropzone.js.org/#section-accepting-specific-file-types
export const allowedImageMimeTypesForUpload: AcceptType = {
  'image/png': [],
};

//Screen Resolution
export const globalDesktopSize: number = 1024;
export const globalDesktopHeight: number = 768;
