import { useContext, useEffect, useState } from 'react';
import { MessageBar, MessageBarType, MessageBarButton } from '@fluentui/react';
import logger, { getLogWithDateStamp, globalLog } from 'services/Logging/logService';
import Config from 'services/Config/configService';
import AppError from 'utils/appError';
import { useTranslation } from 'react-i18next';
import { truncate } from 'utils/string';
import { useHistory } from 'react-router-dom';
import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { apiAddProcessLog } from 'services/Api/userService';
import { DBProcesLoggerLogLevel, LogDTO } from 'models/dto/logDTO';

interface IErrorMessageProps {
  error: AppError | undefined;
  setError?: Function;
}

export const ErrorMessage = (props: IErrorMessageProps) => {
  const history = useHistory();
  const appContext = useContext(AppContext);
  const { t } = useTranslation(['translation']);
  const [err, setErr] = useState<AppError | undefined>(undefined);

  useEffect(() => {
    if (props.error) {
      if (!err?.isEqual(props.error)) {
        setErr(props.error);
        logger.error(props.error);
        saveErrorToApi(props.error);
      }
    } else {
      setErr(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.error]);

  //
  // Log all errors that the customer gets to our API
  // Filter out errors that originated from the back-end because they are already logged
  //
  const saveErrorToApi = async (error: AppError | undefined): Promise<boolean> => {
    try {
      if (!error) {
        return false;
      }

      const logDTO = new LogDTO(
        error.fromApi === true ? 'Global client log for server exception' : error.message ?? 'Unknown error',
      );
      logDTO.error = JSON.stringify(props.error);
      logDTO.add(getLogWithDateStamp('Environment info: ' + Config.getEnvInfo()));
      logDTO.add(getLogWithDateStamp(error.stack));
      logDTO.add(getLogWithDateStamp(error.debug));
      logDTO.add('Global log entries');
      globalLog.messages.forEach((m) => logDTO.add(m));

      //filter out some specific errors about the authentication flow
      const filterMsg: string[] = [
        'access_denied',
        'interaction_in_progress',
        'user_cancelled',
        'consent_required',
        'popup_window_error',
      ];

      const shouldNotCreateTicket = filterMsg.some((msg) => {
        if (error.code === msg) return true;
        if (error.message && error.message.toLowerCase().indexOf(msg) >= 0) return true;
        if (error.debug && error.debug.toLowerCase().indexOf(msg) >= 0) return true;

        return false;
      });

      if (isNetworkError(error) || shouldNotCreateTicket || error.fromApi === true || isAuthReloadError(error)) {
        logDTO.level = DBProcesLoggerLogLevel.Info;
      } else {
        logDTO.level = DBProcesLoggerLogLevel.Error;
      }

      //log client error to API
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiAddProcessLog(logDTO, accessToken);
      globalLog.clear();

      return true;
    } catch (err) {
      logger.error(new AppError('Error while uploading log to API. ' + JSON.stringify(err)));
      appContext.showNotification(t('translation:Errors.UploadClientLog.Message'), true);

      return false;
    }
  };

  const reloadPage = () => {
    history.push('/reload');
    history.goBack();
  };

  const isLoginError = (error: AppError | undefined): boolean => {
    if (!error) return false;

    const filterMsg: string[] = ['login_error'];

    const isLoginError = filterMsg.some((msg) => {
      if (error.code === msg) return true;

      return false;
    });

    return isLoginError;
  };

  const isNetworkError = (error: AppError | undefined): boolean => {
    if (!error) return false;

    const filterMsg: string[] = ['post_request_failed', 'network_error', 'err_network', 'no_network_connectivity'];

    const isNetworkError = filterMsg.some((msg) => {
      if (error.code && error.code.toLowerCase() === msg) return true;
      if (error.message && error.message.toLowerCase().indexOf(msg) >= 0) return true;
      if (error.debug && error.debug.toLowerCase().indexOf(msg) >= 0) return true;

      return false;
    });

    return isNetworkError;
  };

  const isAuthReloadError = (error: AppError | undefined): boolean => {
    return (
      (error?.code === '403' &&
        (error?.debug?.startsWith('Path: /auth') ||
          error?.debug?.startsWith('Path: /tenant') ||
          error?.debug?.startsWith('Path: tenant') ||
          error?.debug?.startsWith('Path: iso'))) ??
      false
    );
  };

  //
  // Main render
  //
  if (props.error === undefined) {
    return null;
  }

  let msg = '';
  let action: JSX.Element | undefined;
  let mbType: MessageBarType = MessageBarType.severeWarning;

  //in case of network/connectivity errors, show a popup to refresh the browser tab
  if (isNetworkError(props.error) || isLoginError(props.error)) {
    mbType = MessageBarType.warning;
    msg = t('translation:Errors.NetworkError.Message');
    action = (
      <div>
        <MessageBarButton
          onClick={() => {
            if (props.setError) props.setError(undefined);
            reloadPage();
          }}
        >
          {t('translation:Errors.NetworkError.Action')}
        </MessageBarButton>
      </div>
    );
  } else {
    //Determine message and action
    switch (props.error.code) {
      case '409':
        mbType = MessageBarType.warning;
        msg = t('translation:Errors.DatabaseConflict.Message');
        action = (
          <div>
            <MessageBarButton
              onClick={() => {
                if (props.setError) props.setError(undefined);
                reloadPage();
              }}
            >
              {t('translation:Errors.DatabaseConflict.Action')}
            </MessageBarButton>
          </div>
        );
        break;
      case '401':
      case '403':
        //special case: when the user is in the admin module, navigate away to dashboard
        //when the user is in the admin module and switches to another tenant, the app can still refresh the current scene and produce 403
        //with the new account which may not have admin permissions
        if (isAuthReloadError(props.error)) {
          window.location.href = '/';
          window.location.reload();

          return null;
        }
        msg = t('translation:Errors.AccessDenied.Message');
        break;
      case '400':
      case '404':
      case '500':
        if (props.error.debug && props.error.debug.indexOf('AccessDenied') >= 0) {
          msg = t('translation:Errors.AccessDenied.Message');
        } else {
          msg = t('translation:Errors.ApiError.Message');
        }
        break;
      case '999':
        //unhandled exception
        msg =
          'An unknown fatal error has occurred. We are sorry for the inconvenience! Please reload your browser and contact our Support Desk (support@isoplanner.app) when the problem persists.';
        break;
      default:
        msg = props.error.message;
    }
  }

  return (
    <MessageBar
      messageBarType={mbType}
      onDismiss={() => {
        if (props.setError) props.setError(undefined);
      }}
      isMultiline={true}
      actions={action}
    >
      <b>{msg}</b>
      {!Config.isProd() && !Config.isTest() && <p>{truncate(props.error.debug?.toString(), 800)}</p>}
    </MessageBar>
  );
};
