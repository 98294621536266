import { IDropdownOption } from '@fluentui/react';
import { TFunction } from 'i18next';
import Entity from 'models/entity';

export const TagInvalidChars = [',', ':'];

export enum TagSystemTypes {
  None = 0,
  Project = 1,
  Vulnerability = 2,
  NonConformity = 3,
  Incident = 4,
}

export enum TagSystemProjectTypes {
  None = 0,
  ProjectOnboarding = 1,
  ProjectImplHLS = 2,
  ProjectImplControls = 3,
  ProjectMigration = 4,
  ProjectCertification = 5,
}

export default class Tag {
  tagId: number;

  tagName: string;

  tagValue: string;

  tagColor: string;

  tagInfo?: string;

  tagSystemType?: number;

  tagSystemSubType?: number;

  usedCount: number;

  entity?: Entity;

  constructor() {
    this.tagId = 0;
    this.tagName = '';
    this.tagValue = '';
    this.tagColor = '';
    this.usedCount = 0;
  }

  value(): string {
    return this.tagName + ' : ' + this.tagValue;
  }

  static getEmptyTag(): Tag {
    const emptyTag = new Tag();
    emptyTag.tagName = '?';
    emptyTag.tagValue = '?';

    return emptyTag;
  }

  static GetSystemTypeText(systemType: number | undefined, t: TFunction<string[]>): string {
    switch (systemType) {
      case TagSystemTypes.Project:
        return t('tag:SystemTypes.Project');
      case TagSystemTypes.Vulnerability:
        return t('tag:SystemTypes.Vulnerability');
      case TagSystemTypes.NonConformity:
        return t('tag:SystemTypes.NonConformity');
      case TagSystemTypes.Incident:
        return t('tag:SystemTypes.Incident');
      default:
        return '';
    }
  }

  static GetSystemTypeTextInfo(systemType: number | undefined, t: TFunction<string[]>): string {
    switch (systemType) {
      case TagSystemTypes.Project:
        return t('tag:SystemTypes.ProjectInfo');
      case TagSystemTypes.Vulnerability:
        return t('tag:SystemTypes.VulnerabilityInfo');
      case TagSystemTypes.NonConformity:
        return t('tag:SystemTypes.NonConformityInfo');
      case TagSystemTypes.Incident:
        return t('tag:SystemTypes.IncidentInfo');
      default:
        return '';
    }
  }

  static getSystemTypeOptions = (t: TFunction<string[]>): IDropdownOption[] => {
    const options: IDropdownOption[] = [
      { key: TagSystemTypes.None, text: '' },
      { key: TagSystemTypes.Project, text: t('tag:SystemTypes.Project') },
      // Future implementation
      // { key: TagSystemTypes.NonConformity, text: t('tag:SystemTypes.NonConformity') },
      // { key: TagSystemTypes.Incident, text: t('tag:SystemTypes.Incident') },
      // { key: TagSystemTypes.Vulnerability, text: t('tag:SystemTypes.Vulnerability') },
    ];

    return options;
  };

  clone(): Tag {
    const newTag = new Tag();
    newTag.tagId = this.tagId;
    newTag.tagName = this.tagName;
    newTag.tagValue = this.tagValue;
    newTag.tagColor = this.tagColor;
    newTag.tagInfo = this.tagInfo;
    newTag.usedCount = this.usedCount;
    newTag.tagSystemType = this.tagSystemType;
    newTag.tagSystemSubType = this.tagSystemSubType;

    return newTag;
  }
}
