import { mapFromSettings } from 'mappings/dataMapping';
import LicenseDTO from 'models/dto/licenseDTO';
import TenantDTO, { TenantOnboardingDTO, TenantOnboardingStepsDTO } from 'models/dto/tenantDTO';
import Tenant, { TenantOnboarding, TenantOnboardingSteps } from 'models/tenant';
import { fromApiDateOptional, toApiDateOptional } from 'utils/datetime';

export function mapFromTenant(tenantDTO: TenantDTO): Tenant {
  const tenant = new Tenant(tenantDTO.id, tenantDTO.name);
  tenant.parentId = tenantDTO.parentId;
  tenant.topLevelParentTenantId = tenantDTO.topLevelParentTenantId;
  tenant.azureTenantId = tenantDTO.azureTenantId;
  tenant.created = tenantDTO.created;
  tenant.storeMgmtState = tenantDTO.storeMgmtState;
  tenant.onboardingState = new TenantOnboarding(); //we don't get this at the tenant level, a seperate API is used: apiGetTenantOnboardingState
  tenant.subscriptionType = tenantDTO.subscriptionType;
  tenant.settings = tenantDTO.settings ? mapFromSettings(tenantDTO.settings) : undefined;
  tenant.timeZoneCode = tenantDTO.timeZoneCode;
  tenant.currentUserLicensed = tenantDTO.currentUserLicensed;

  return tenant;
}

export function mapToTenant(tenant: Tenant): TenantDTO {
  const tenantDto = new TenantDTO();
  tenantDto.id = tenant.id;
  tenantDto.parentId = tenant.parentId;
  tenantDto.topLevelParentTenantId = tenant.topLevelParentTenantId;
  tenantDto.name = tenant.name;
  tenantDto.subscriptionType = tenant.subscriptionType;
  tenantDto.azureTenantId = tenant.azureTenantId;
  tenantDto.timeZoneCode = tenant.timeZoneCode;

  return tenantDto;
}

export function mapFromTenants(tenantDTOs: TenantDTO[] | undefined): Tenant[] {
  if (!tenantDTOs) return [];

  return tenantDTOs.map((t) => mapFromTenant(t));
}

//
// Onboarding
//
export function mapFromOnboardingState(
  onboardingStateDto: TenantOnboardingDTO | undefined,
): TenantOnboarding | undefined {
  if (!onboardingStateDto) return undefined;

  const onboardingState = new TenantOnboarding();
  onboardingState.leadSource = onboardingStateDto?.leadSource;
  onboardingState.packageIds = onboardingStateDto.packageIds ? [...onboardingStateDto.packageIds] : [];
  onboardingState.onboardingSteps = mapFromOnboardingSteps(onboardingStateDto.onboardingSteps);

  return onboardingState;
}

export function mapFromOnboardingSteps(onboardingStepsDto: TenantOnboardingStepsDTO): TenantOnboardingSteps {
  const onboardingSteps = new TenantOnboardingSteps();
  if (onboardingStepsDto) {
    onboardingSteps.currentStep = onboardingStepsDto.currentStep;
    onboardingSteps.languageIsSet = onboardingStepsDto.languageIsSet;
    onboardingSteps.certifiedStandards = onboardingStepsDto.certifiedStandards;
    onboardingSteps.certifiedOtherStandards = onboardingStepsDto.certifiedOtherStandards;
    onboardingSteps.newStandards = onboardingStepsDto.newStandards;
    onboardingSteps.newOtherStandards = onboardingStepsDto.newOtherStandards;
    onboardingSteps.currentOtherSystem = onboardingStepsDto.currentOtherSystem;
    onboardingSteps.currentSystem = onboardingStepsDto.currentSystem;
    onboardingSteps.currentSystemImprovement = onboardingStepsDto.currentSystemImprovement;
    onboardingSteps.startDate = fromApiDateOptional(onboardingStepsDto.startDate);
    onboardingSteps.endDate = fromApiDateOptional(onboardingStepsDto.endDate);
    onboardingSteps.wantsToCertify = onboardingStepsDto.wantsToCertify;
    onboardingSteps.dashboardName = onboardingStepsDto.dashboardName;
    onboardingSteps.dashboardWidgetTaskStatusName = onboardingStepsDto.dashboardWidgetTaskStatusName;
    onboardingSteps.dashboardWidgetTaskTagName = onboardingStepsDto.dashboardWidgetTaskTagName;
    onboardingSteps.optInAI = onboardingStepsDto.optInAI;
    onboardingSteps.dashboardId = onboardingStepsDto.dashboardId;
    onboardingSteps.previewPackageInstalled = onboardingStepsDto.previewPackageInstalled;
    onboardingSteps.projectMilestonesCreated = onboardingStepsDto.projectMilestonesCreated;
  }

  return onboardingSteps;
}

export function mapFromTenantLicenses(tenant: Tenant, licenseDTO: LicenseDTO): Tenant {
  const newTenant = new Tenant(tenant.id, tenant.name);
  newTenant.numberOfUsers = licenseDTO.numberOfUsers; //total number of registered users in the system
  newTenant.totalLicenses = licenseDTO.totalLicenses; //userLicenses + mgmtLicenses
  newTenant.usedUserLicenses = licenseDTO.usedUserLicenses; //total licensed assigned to a user
  newTenant.usedMgMtLicenses = licenseDTO.usedMgMtLicenses; //total licensed assigned to a manager
  newTenant.userLicenses = licenseDTO.userLicenses; //number of user licenses in the subscription
  newTenant.mgMtLicenses = licenseDTO.mgMtLicenses; //number of management licenses in the subscription

  return newTenant;
}

export function mapToOnboardingState(onboardingState: TenantOnboarding | undefined): TenantOnboardingDTO | undefined {
  if (!onboardingState) return undefined;

  const onboardingStateDto = new TenantOnboardingDTO();
  onboardingStateDto.leadSource = onboardingState?.leadSource;
  onboardingStateDto.packageIds = [...onboardingState.packageIds];
  onboardingStateDto.timeZoneOffset = onboardingState.timeZoneOffset;
  onboardingStateDto.onboardingSteps = mapToOnboardingSteps(onboardingState.onboardingSteps);

  return onboardingStateDto;
}

export function mapToOnboardingSteps(onboardingSteps: TenantOnboardingSteps): TenantOnboardingStepsDTO {
  const onboardingStepsDto = new TenantOnboardingStepsDTO();

  onboardingStepsDto.currentStep = onboardingSteps.currentStep;
  onboardingStepsDto.languageIsSet = onboardingSteps.languageIsSet;
  onboardingStepsDto.certifiedStandards = onboardingSteps.certifiedStandards;
  onboardingStepsDto.certifiedOtherStandards = onboardingSteps.certifiedOtherStandards;
  onboardingStepsDto.newStandards = onboardingSteps.newStandards;
  onboardingStepsDto.newOtherStandards = onboardingSteps.newOtherStandards;
  onboardingStepsDto.currentOtherSystem = onboardingSteps.currentOtherSystem;
  onboardingStepsDto.currentSystem = onboardingSteps.currentSystem;
  onboardingStepsDto.currentSystemImprovement = onboardingSteps.currentSystemImprovement;
  onboardingStepsDto.startDate = toApiDateOptional(onboardingSteps.startDate);
  onboardingStepsDto.endDate = toApiDateOptional(onboardingSteps.endDate);
  onboardingStepsDto.wantsToCertify = onboardingSteps.wantsToCertify;
  onboardingStepsDto.dashboardName = onboardingSteps.dashboardName;
  onboardingStepsDto.dashboardWidgetTaskStatusName = onboardingSteps.dashboardWidgetTaskStatusName;
  onboardingStepsDto.dashboardWidgetTaskTagName = onboardingSteps.dashboardWidgetTaskTagName;
  onboardingStepsDto.optInAI = onboardingSteps.optInAI;
  onboardingStepsDto.dashboardId = onboardingSteps.dashboardId;
  onboardingStepsDto.previewPackageInstalled = onboardingSteps.previewPackageInstalled;
  onboardingStepsDto.projectMilestonesCreated = onboardingSteps.projectMilestonesCreated;

  return onboardingStepsDto;
}
