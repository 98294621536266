import EntityDTO from 'models/dto/entityDTO';

export default class TagDTO {
  tagId: number;

  tagName: string;

  tagValue: string;

  tagColor: string;

  tagInfo?: string;

  tagSystemType?: number;

  tagSystemSubType?: number;

  usedCount: number;

  entity?: EntityDTO;

  constructor() {
    this.tagId = 0;
    this.tagName = '';
    this.tagValue = '';
    this.tagColor = '';
    this.usedCount = 0;
  }
}

export class TaskTagDTO {
  tagId: number;

  taskId: number;

  constructor() {
    this.tagId = 0;
    this.taskId = 0;
  }
}

export class RiskTagDTO {
  tagId: number;

  riskId: number;

  constructor() {
    this.tagId = 0;
    this.riskId = 0;
  }
}

export class ControlTagDTO {
  tagId: number;

  controlId: number;

  constructor() {
    this.tagId = 0;
    this.controlId = 0;
  }
}

export class ThemeTagDTO {
  tagId: number;

  themeId: number;

  constructor() {
    this.tagId = 0;
    this.themeId = 0;
  }
}
