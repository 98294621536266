import React, { FunctionComponent, useContext, useState } from 'react';
import {
  Stack,
  Spinner,
  SpinnerSize,
  Text,
  Label,
  Checkbox,
  Link,
  Dropdown,
  IDropdownOption,
  SelectableOptionMenuItemType,
} from '@fluentui/react';
import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { globalStackTokensGapMedium, globalStackTokensGapSmall } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import { FluentUIPieChart, IPieChartDataPoint } from 'components/Charts/PieChart';
import { apiGetWidgetDataTaskStatus } from 'services/Api/dashboardService';
import { createColorGradient } from 'utils/color';
import Tag from 'models/tag';
import { IWidgetConfigRendererProps } from '../WidgetConfigRenderer';
import KeyValueTagPicker from 'components/Pickers/KeyValueTagPicker';
import { KeyValueTags } from 'components/Tags/KeyValueTag';
import { rotateArrayLeft } from 'utils/array';
import { useHistory } from 'react-router-dom';
import { IWidgetRendererProps } from '../WidgetRenderer';
import { TaskState } from 'models/tasks/taskHelperClasses';
import { TaskViewType } from 'components/Tasks/View/TasksView';
import PeriodFilter, { IPeriodFilterData } from 'components/Filter/PeriodFilter';
import { getPeriodFilterEndDate, getPeriodFilterStartDate } from 'components/Filter/PeriodFilterHelper';
import { fromApiDateOptional } from 'utils/datetime';
import { getPeriodURLParams } from 'components/Tasks/Filter/FilterTasksHelper';

export class WidgetTaskStatusConfig {
  tagIds: number[];

  showTags: boolean;

  showAllTaskLink: boolean;

  tags: Tag[];

  useAndForTagGroups: boolean;

  taskViewType?: TaskViewType | undefined;

  period?: IPeriodFilterData;

  constructor() {
    this.tagIds = [];
    this.showTags = false;
    this.showAllTaskLink = false;
    this.tags = [];
    this.useAndForTagGroups = false;
    this.taskViewType = undefined;
  }

  load(raw: string | undefined) {
    if (raw) {
      try {
        const newRawConfig = JSON.parse(raw);
        this.tagIds = newRawConfig.tagIds ?? [];
        this.showTags = newRawConfig.showTags ?? false;
        this.showAllTaskLink = newRawConfig.showAllTaskLink ?? false;
        this.useAndForTagGroups = newRawConfig.useAndForTagGroups ?? false;
        this.taskViewType = newRawConfig.taskViewType;
        this.period = newRawConfig.period;
      } catch {
        //ignore
      }
    }
  }

  clone(): WidgetTaskStatusConfig {
    const newConfig = new WidgetTaskStatusConfig();
    newConfig.tagIds = [...this.tagIds];
    newConfig.tags = [...this.tags];
    newConfig.showTags = this.showTags;
    newConfig.showAllTaskLink = this.showAllTaskLink;
    newConfig.useAndForTagGroups = this.useAndForTagGroups;
    newConfig.taskViewType = this.taskViewType;
    newConfig.period = this.period;

    return newConfig;
  }
}

interface IWidgetTaskStatusProps extends IWidgetRendererProps {}

const WidgetTaskStatus: FunctionComponent<IWidgetTaskStatusProps> = (props: IWidgetTaskStatusProps) => {
  const { t } = useTranslation(['translation', 'widgets']);
  const history = useHistory();
  const appContext = React.useContext(AppContext);
  const [data, setData] = React.useState<TaskState[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [taskCount, setRiskCount] = React.useState<number>(0);
  const [config, setConfig] = React.useState<WidgetTaskStatusConfig | undefined>(undefined);

  React.useEffect(() => {
    const loadData = async () => {
      try {
        if (isLoading) return;
        setIsLoading(true);

        await appContext.globalDataCache.tags.getItems();
        const config = loadConfig();
        setConfig(config);

        //calculate start and end dates
        const customStart = fromApiDateOptional(config.period?.customStart);
        const customEnd = fromApiDateOptional(config.period?.customEnd);
        const start = getPeriodFilterStartDate(
          config.period?.option,
          config.period?.auditYear,
          customStart,
          appContext,
        );
        const end = getPeriodFilterEndDate(config.period?.option, config.period?.auditYear, customEnd, appContext);

        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const data = await apiGetWidgetDataTaskStatus(config.tags, config.useAndForTagGroups, start, end, accessToken);
        setData(data.sort((a, b) => a.sortOrder - b.sortOrder));
        setRiskCount(data.reduce((sum, current) => sum + (current.taskCount ?? 0), 0));
      } catch (err) {
        appContext.setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadConfig = (): WidgetTaskStatusConfig => {
    const newConfig = new WidgetTaskStatusConfig();
    newConfig.load(props.widget.widgetConfig);
    newConfig.tags = appContext.globalDataCache.tags.getItemsForId(newConfig.tagIds).filter((t) => t.tagId !== 0);

    return newConfig;
  };

  const getTaskStateColor = (idx: number): string => {
    const startColor = '#9e44e3';
    const endColor = '#44e364';
    let colors = createColorGradient(startColor, endColor, data.length);
    colors = rotateArrayLeft(1, colors); //start at rejected at the most purple color

    return colors[idx];
  };

  const getData = (): IPieChartDataPoint[] => {
    if (data.length === 0) return [];

    const config = loadConfig();
    let tagStr = '';
    if (config.tags.length > 0) {
      tagStr = `&tags=${config.tags.map((t) => t.tagId.toString()).join(',')}`;
      tagStr += `&useAndForTagGroups=${config.useAndForTagGroups}`;
    }

    const viewStr = config.taskViewType !== undefined ? `&view=${config.taskViewType}` : '';

    const graphData: IPieChartDataPoint[] = data.map((s, idx) => {
      return {
        name: s.state,
        value: s.taskCount ?? 0,
        color: getTaskStateColor(idx),
        link: `/tasks/alltasks?status=${s.taskStateId.toString()}${tagStr}${viewStr}`,
      };
    });

    return graphData;
  };

  const navigateToAllTasks = () => {
    if (!config) return;

    let tagStr = '';
    if (config.tags.length > 0) {
      tagStr = `?tags=${config.tags.map((t) => t.tagId.toString()).join(',')}`;
      tagStr += `&useAndForTagGroups=${config.useAndForTagGroups}`;
    } else {
      tagStr = `?clearFilter`;
    }

    let viewStr = '';
    if (config.taskViewType !== undefined) {
      viewStr = tagStr.length > 0 ? '&' : '?';
      viewStr += `view=${config.taskViewType}`;
    }

    let periodStr = '';
    if (config.period) {
      periodStr = viewStr.length > 0 || tagStr.length > 0 ? '&' : '?';
      periodStr += getPeriodURLParams(config.period);
    }

    const url = `/tasks/alltasks${tagStr}${viewStr}${periodStr}`;
    history.push(url);
  };

  const getHeight = (): number => {
    if (config && config.tags.length > 0 && config.showTags && config.showAllTaskLink) {
      return 170;
    }

    if (config && ((config.tags.length > 0 && config.showTags) || config.showAllTaskLink)) {
      return 210;
    }

    return 250;
  };

  //
  // Main render
  //
  if (!data || isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (taskCount === 0) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('widgets:TaskStatus.NoTasks')}</Text>
      </Stack>
    );
  }

  return (
    <Stack verticalFill horizontalAlign="center" tokens={globalStackTokensGapSmall}>
      {config && config.tags.length > 0 && config.showTags && (
        <Stack.Item styles={{ root: { maxHeight: 44, overflowY: 'hidden' } }}>
          <KeyValueTags tags={config.tags} compact tagMaxWidth={200} />
        </Stack.Item>
      )}
      <Stack.Item grow>
        <FluentUIPieChart
          data={getData()}
          height={getHeight()}
          width={280}
          label={taskCount.toString()}
        ></FluentUIPieChart>
      </Stack.Item>
      {config && config.showAllTaskLink && (
        <Link onClick={() => navigateToAllTasks()}>{t('widgets:TaskStatus.AllTaskLink')}</Link>
      )}
    </Stack>
  );
};

export default WidgetTaskStatus;

//
// Config
//

interface IWidgetConfigWidgetTaskStatusProps extends IWidgetConfigRendererProps {}

export const WidgetConfigTaskStatus: FunctionComponent<IWidgetConfigWidgetTaskStatusProps> = (
  props: IWidgetConfigWidgetTaskStatusProps,
) => {
  const { t } = useTranslation(['widgets', 'translation', 'dashboard']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [showTags, setShowTags] = useState<boolean>(false);
  const [showAllTasks, setShowAllTasks] = useState<boolean>(false);
  const [useAndForTagGroups, setUseAndForTagGroups] = useState<boolean>(false);
  const [taskViewType, setTaskViewType] = useState<TaskViewType | undefined>(undefined);
  const [period, setPeriod] = useState<IPeriodFilterData | undefined>(undefined);

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget]);

  const loadConfig = (): WidgetTaskStatusConfig => {
    let newRawConfig = new WidgetTaskStatusConfig();
    if (props.widget.widgetConfig) {
      newRawConfig = JSON.parse(props.widget.widgetConfig);
      newRawConfig.tags = appContext.globalDataCache.tags
        .getItemsForId(newRawConfig.tagIds)
        .filter((t) => t.tagId !== 0);
    }

    return newRawConfig;
  };

  const loadData = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      await appContext.globalDataCache.tags.getItems();
      const config = loadConfig();

      if (config.tags) {
        setSelectedTags(config.tags);
      }

      setShowTags(config.showTags);
      setShowAllTasks(config.showAllTaskLink);
      setUseAndForTagGroups(config.useAndForTagGroups);
      setTaskViewType(config.taskViewType);
      setPeriod(config.period);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getConfig = (
    tags: Tag[],
    showTags: boolean,
    showAllTasks: boolean,
    useAndForTagGroups: boolean,
    taskViewType: TaskViewType | undefined,
    period: IPeriodFilterData | undefined,
  ): WidgetTaskStatusConfig => {
    const config = new WidgetTaskStatusConfig();
    config.tagIds = tags.map((t) => t.tagId);
    config.showTags = showTags;
    config.showAllTaskLink = showAllTasks;
    config.useAndForTagGroups = useAndForTagGroups;
    config.taskViewType = taskViewType;
    config.period = period;

    return config;
  };

  const onAdd = (tag: Tag) => {
    const newTags = [...selectedTags, tag];
    setSelectedTags(newTags);
    const config = getConfig(newTags, showTags, showAllTasks, useAndForTagGroups, taskViewType, period);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const onRemove = (tag: Tag) => {
    const newTags = selectedTags.filter((t) => t.tagId !== tag.tagId);
    setSelectedTags(newTags);
    const config = getConfig(newTags, showTags, showAllTasks, useAndForTagGroups, taskViewType, period);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const onSetShowTags = (checked: boolean) => {
    setShowTags(checked);
    const config = getConfig(selectedTags, checked, showAllTasks, useAndForTagGroups, taskViewType, period);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const onSetUseAndForTagGroups = (checked: boolean) => {
    setUseAndForTagGroups(checked);
    const config = getConfig(selectedTags, showTags, showAllTasks, checked, taskViewType, period);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const onSetShowAllTasks = (checked: boolean) => {
    setShowAllTasks(checked);
    const config = getConfig(selectedTags, showTags, checked, useAndForTagGroups, taskViewType, period);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const onSetTaskViewType = (taskViewType: TaskViewType | undefined) => {
    setTaskViewType(taskViewType);
    const config = getConfig(selectedTags, showTags, showAllTasks, useAndForTagGroups, taskViewType, period);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const onSetPeriod = (period: IPeriodFilterData | undefined) => {
    setPeriod(period);
    const config = getConfig(selectedTags, showTags, showAllTasks, useAndForTagGroups, taskViewType, period);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const getTaskViewTypeOptions = (): IDropdownOption[] => {
    const options: IDropdownOption[] = [
      { key: 'notset', text: t('tasks:TaskView.Commands.View.NotSet') },
      { key: 'div1', text: '-', itemType: SelectableOptionMenuItemType.Divider },
      { key: TaskViewType.List, text: t('tasks:TaskView.Commands.View.List') },
      { key: TaskViewType.Board, text: t('tasks:TaskView.Commands.View.Board') },
      { key: TaskViewType.Schedule, text: t('tasks:TaskView.Commands.View.Schedule') },
      { key: TaskViewType.Timeline, text: t('tasks:TaskView.Commands.View.Timeline') },
    ];

    return options;
  };

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  return (
    <Stack verticalFill tokens={globalStackTokensGapMedium}>
      <Stack.Item>
        <PeriodFilter
          showOption={true}
          showCurrentAuditYear={true}
          storageKey={undefined}
          onUpdatePeriodOption={onSetPeriod}
          period={period}
          showAll={true}
        />
      </Stack.Item>
      <Stack.Item>
        <Label>{t('widgets:TaskStatus.Config.Label')}</Label>
        <KeyValueTagPicker
          itemLimit={5}
          selectedTags={selectedTags}
          onAdd={onAdd}
          onRemove={onRemove}
          isLoading={isLoading}
          allowCreate={false}
        />
      </Stack.Item>
      <Checkbox
        label={t('tasks:Filter.TagGroupAnd')}
        checked={useAndForTagGroups}
        onChange={(ev, checked) => {
          onSetUseAndForTagGroups(checked ?? false);
        }}
      />
      <Stack.Item>
        <Checkbox
          checked={showTags}
          label={t('widgets:TaskStatus.Config.LabelShowTags')}
          onChange={(ev, checked) => {
            onSetShowTags(checked ?? false);
          }}
        />
      </Stack.Item>
      <Stack.Item>
        <Checkbox
          checked={showAllTasks}
          label={t('widgets:TaskStatus.Config.LabelShowAllTasks')}
          onChange={(ev, checked) => {
            onSetShowAllTasks(checked ?? false);
          }}
        />
        {showAllTasks && (
          <Stack styles={{ root: { width: 300, paddingLeft: 30, paddingTop: 10 } }}>
            <Dropdown
              selectedKey={taskViewType ?? 'notset'}
              options={getTaskViewTypeOptions()}
              onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => {
                onSetTaskViewType(option?.key === 'notset' ? undefined : (option?.key as TaskViewType));
              }}
              onRenderTitle={(options) => {
                if (!options || options.length === 0) return null;

                return <Text>{t('tasks:TaskView.Commands.View.Main') + ' : ' + options[0].text}</Text>;
              }}
            />
          </Stack>
        )}
      </Stack.Item>
    </Stack>
  );
};
