import { IAppContext } from 'App/AppContext';
import { IHubConnection, startHubConnection } from './GeneralHub';

export const ReceiveStreamingUpdates = 'ReceiveStreamingUpdates';

export type CopilotHubMethodReceiveStreamingUpdates = (msg: string) => void;

export const startCopilotHubConnection = async (
  appContext: IAppContext,
  method: string,
  callback: CopilotHubMethodReceiveStreamingUpdates,
): Promise<IHubConnection> => {
  return startHubConnection(appContext, 'copilot/hub', method, callback);
};
