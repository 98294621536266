import User from 'models/user';
import { Client } from '@microsoft/microsoft-graph-client';
import { globalGraphMaxRecords } from 'globalConstants';
import AppError from 'utils/appError';
import { GroupMember } from 'models/groupMembers';
import Tenant from 'models/tenant';
import CurrentUser from 'models/currentuser';
import UserLanguage from 'models/userLanguage';

//
// Account
//
export async function graphGetUsers(
  client: Client,
  filterText: string,
  maxRecords: number = globalGraphMaxRecords,
): Promise<User[]> {
  try {
    const userList: User[] = [];

    //remove invalid characters {', &, #}
    filterText = filterText.replaceAll("'", '');
    filterText = filterText.replaceAll('&', '');
    filterText = filterText.replaceAll('#', '');

    const filterName = `startswith(displayName,'${filterText}') or startswith(givenName,'${filterText}') or startswith(surname,'${filterText}')`;

    const users = await client
      .api('/users')
      .filter(
        `${filterName} or startswith(mail,'${filterText}') or startswith(userPrincipalName,'${filterText}')`
      )
      .select('id,mail,userPrincipalName,displayName')
      .top(maxRecords)
      .get();

    for (let i = 0; i < users.value.length; i++) {
      const user = users.value[i];
      const newuser = new User(user.id, user.mail || user.userPrincipalName, user.displayName);
      userList.push(newuser);
    }

    return userList;
  } catch (err) {
    const error = AppError.fromGraphError(err);
    if (error?.code === 'Request_UnsupportedQuery') {
      return [];
    } else {
      throw error;
    }
  }
}

export async function graphGetUserPhoto(client: Client): Promise<Blob | undefined> {
  try {
    const photo = await client.api('/me/photo/$value').get();

    return photo;
  } catch {
    // ignore errors on retrieving details which are optional
  }

  return undefined;
}

export async function graphGetGroupMembershipByGroupId(client: Client, groupId: string): Promise<GroupMember> {
  try {
    const teamMembers = await client.api(`teams/${groupId}/members`).get();
    const members: string[] = [];
    for (let i = 0; i < teamMembers.value.length; i++) {
      const member = teamMembers.value[i];
      members.push(member.userId);
    }
    const groupMembers: GroupMember = new GroupMember();
    groupMembers.groupId = groupId;
    groupMembers.members = members;

    return groupMembers;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function graphGetTenantDetails(client: Client): Promise<Tenant> {
  try {
    const org = await client.api('/organization').select('id,displayName').get();
    const tenant = new Tenant(org.value[0].id, org.value[0].displayName);

    return tenant;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function graphGetUserDetails(client: Client, tenant: Tenant): Promise<CurrentUser> {
  try {
    const me = await client.api('/me').select('id,displayName,mail,userPrincipalName').get();
    const user = new CurrentUser(
      me.id,
      tenant,
      me.displayName,
      me.mail || me.userPrincipalName,
      UserLanguage.getBrowserLanguage(),
    );

    return user;
  } catch (err) {
    throw AppError.fromGraphError(err);
  }
}
