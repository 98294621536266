import { Text, Stack, Separator } from '@fluentui/react';
import {
  globalTextStylesCard,
  globalStackStylesCard,
  globalStackTokensGapCard,
  globalStackItemStylesCard,
  globalStackTokensGapSmall,
  globalFontBoldWeight,
} from 'globalStyles';
import { toLocaleDateShortNoYear } from 'utils/datetime';
import CheckListProgress from 'components/Checklist/ChecklistProgress';
import { ICardTaskProps } from './KanbanCard';

const KanbanCardTaskGeneric = (props: ICardTaskProps) => {
  //
  // Main render
  //
  return (
    <div
      onClick={() => {
        if (props.onClick) props.onClick(props.task);
      }}
    >
      <Stack styles={globalStackStylesCard} tokens={globalStackTokensGapCard}>
        <Stack.Item styles={globalStackItemStylesCard}>
          <Text
            block
            styles={{
              root: { fontWeight: globalFontBoldWeight, paddingTop: 0, paddingBottom: 0, wordBreak: 'break-word' },
            }}
          >
            {props.task.name}
          </Text>
        </Stack.Item>
        {props.task.checkList.items.length > 0 && (
          <Stack.Item>
            <CheckListProgress
              compact
              totalChecks={props.task.checkList.items.length}
              completedChecks={props.task.checkList.getCompletedCheckCount()}
              followUp={props.task.followUp}
            ></CheckListProgress>
          </Stack.Item>
        )}
        {props.task.description && (
          <Stack.Item styles={globalStackItemStylesCard}>
            <Separator styles={{ root: { height: 1 } }}></Separator>
            <Text variant="small">{props.task.description}</Text>
          </Stack.Item>
        )}
        <Stack.Item styles={globalStackItemStylesCard}>
          <Separator styles={{ root: { height: 1 } }}></Separator>
          <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
            <Stack.Item>
              <Text block variant="small" styles={globalTextStylesCard}>
                {toLocaleDateShortNoYear(props.task.startDateTime)}
              </Text>
            </Stack.Item>
          </Stack>
        </Stack.Item>
      </Stack>
    </div>
  );
};

export default KanbanCardTaskGeneric;
