import _ from 'lodash';

export function isObjectEqual<T>(a: T, b: T): boolean {
  return _.isEqual(a, b);
}

export function getUniqueString(name?: string): string {
  return _.uniqueId(name + '__');
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function nullsToUndefined(obj: any): any {
  if (obj === null || obj === undefined) {
    return undefined;
  }

  if (obj.constructor.name === 'Object' || obj.constructor.name === 'Array') {
    for (const key in obj) {
      obj[key] = nullsToUndefined(obj[key]);
    }
  }

  return obj;
}

interface IEnumObject {
  [key: string]: string | number;
}

export function enumParse<T extends IEnumObject>(
  enumObj: T,
  value: string | number
): T[keyof T] | undefined {
  const lowerValue = value.toString().toLowerCase();
  const enumValues = Object.values(enumObj).map(v => v.toString().toLowerCase());

  const index = enumValues.indexOf(lowerValue);
  if (index !== -1) {
    return enumObj[Object.keys(enumObj)[index] as keyof T];
  } else {
    return undefined;
  }
}
