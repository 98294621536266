import http from './httpService';
import AppError from 'utils/appError';
import {
  mapFromCopilotConversation,
  mapFromCopilotOnboardingProject,
  mapFromCopilotRisk,
  mapToCopilotConversation,
  mapToCopilotRisk,
} from 'mappings/copilotMapping';
import Risk from 'models/risk';
import { CopilotRiskDTO } from 'models/dto/copilot/copilotRiskDTO';
import GlobalDataCache from 'models/globalDataCache/globalDataCache';
import { CopilotDataRiskField, CopilotRisk } from 'models/copilot/copilotRisk';
import { CopilotOnboardingProjectDTO } from 'models/dto/copilot/copilotOnboardingProjectDTO';
import { CopilotOnboardingProject } from 'models/copilot/copilotOnboardingProject';
import { TenantOnboardingSteps } from 'models/tenant';
import { mapToOnboardingSteps } from 'mappings/tenantMapping';
import { CopilotConversation } from 'models/copilot/copilotConversation';
import { CopilotConversationDTO } from 'models/dto/copilot/copilotConversationDTO';

export async function apiCopilotCompletion(
  conversation: CopilotConversation,
  connectionId: string | null,
  accessToken: string,
): Promise<CopilotConversation> {
  try {
    const dto = mapToCopilotConversation(conversation);
    const ar = await http.post<CopilotConversationDTO>(
      `copilot/completions?connectionId=${connectionId}`,
      dto,
      http.getRequestConfig(accessToken),
    );

    return mapFromCopilotConversation(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCopilotRiskCompletion(
  risk: Risk,
  field: CopilotDataRiskField,
  conversation: CopilotConversation,
  connectionId: string | null,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<CopilotRisk | undefined> {
  try {
    const dto = mapToCopilotRisk(risk, field, conversation);
    const ar = await http.post<CopilotRiskDTO>(
      `copilot/completions/risk?connectionId=${connectionId}`,
      dto,
      http.getRequestConfig(accessToken),
    );
    if (!ar || !ar.data) return undefined;

    return mapFromCopilotRisk(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCopilotCreateOnboardingProject(
  onboardingSteps: TenantOnboardingSteps,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<CopilotOnboardingProject | undefined> {
  try {
    const dto = mapToOnboardingSteps(onboardingSteps);
    const ar = await http.post<CopilotOnboardingProjectDTO>(
      `copilot/onboarding/project`,
      dto,
      http.getRequestConfig(accessToken),
    );
    if (!ar || !ar.data) return undefined;

    return mapFromCopilotOnboardingProject(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCopilotCreateOnboardingProjectDocument(
  onboardingSteps: TenantOnboardingSteps,
  connectionId: string | null,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<CopilotOnboardingProject | undefined> {
  try {
    const dto = mapToOnboardingSteps(onboardingSteps);
    const ar = await http.post<CopilotOnboardingProjectDTO>(
      `copilot/onboarding/project/document?connectionId=${connectionId}`,
      dto,
      http.getRequestConfig(accessToken),
    );
    if (!ar || !ar.data) return undefined;

    return mapFromCopilotOnboardingProject(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCopilotFinishOnboarding(
  onboardingSteps: TenantOnboardingSteps,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<CopilotOnboardingProject | undefined> {
  try {
    const dto = mapToOnboardingSteps(onboardingSteps);
    const ar = await http.post<CopilotOnboardingProjectDTO>(
      `copilot/onboarding/project/finish`,
      dto,
      http.getRequestConfig(accessToken),
    );

    if (!ar || !ar.data) return undefined;

    return mapFromCopilotOnboardingProject(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiCopilotFinishOnboardingProgress(accessToken: string): Promise<number | undefined> {
  try {
    const ar = await http.get<number | undefined>(
      `copilot/onboarding/project/finish/progress`,
      http.getRequestConfig(accessToken),
    );

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
