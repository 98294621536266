import StepIcon, { IStepIconProps, StepIconModes, stepIconSizeRatios, StepIconSizes } from './StepIcon';
import { useEffect, useState } from 'react';

interface IStepperProps {
  steps: string[];
  activeStep: number;
}

const Stepper = (props: IStepperProps) => {
  const [steps, setSteps] = useState<IStepIconProps[]>([]);

  useEffect(() => {
    calcSteps(props.activeStep, props.steps);
  }, [props.activeStep, props.steps]);

  const calcSteps = (activeStep: number, steps: string[]) => {
    const newSteps: IStepIconProps[] = [];
    steps.forEach((step, index) => {
      let mode: StepIconModes = StepIconModes.NotReached;
      if (index < activeStep) mode = StepIconModes.Success;
      if (index === activeStep) mode = StepIconModes.Active;
      newSteps.push({ mode, index: index + 1, size: StepIconSizes.Medium, label: step });
    });
    setSteps(newSteps);
  };

  //
  // Main render
  //
  if (steps.length === 0) return null;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'row',
        padding: 10,
      }}
    >
      {steps.map((step, index) => {
        return (
          <div
            key={index}
            style={{
              flex: 1,
              position: 'relative',
            }}
          >
            {index !== 0 && <StepConnector stepSize={14 * stepIconSizeRatios[step.size]} />}
            <StepIcon mode={step.mode} index={step.index} size={step.size} label={step.label} />
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;

//
// Connector component
//
interface IStepConnectorProps {
  stepSize: number;
  size?: string;
  color?: string;
}

const StepConnector = (props: IStepConnectorProps) => {
  const stepSize = `${props.stepSize}px`;
  const size = `${props.size ?? 1}px`;
  const disabledColor = '#bdbdbd';
  const padding = '-2px';

  return (
    <div
      style={{
        top: `calc((${stepSize} - ${size}) / 2)`,
        left: `calc(-50% + ${stepSize} - ${padding})`,
        right: `calc(50% + ${stepSize} - ${padding})`,
        position: 'absolute',
      }}
    >
      <span
        style={{
          borderTopStyle: 'solid',
          borderTopWidth: size,
          borderColor: props.color ?? disabledColor,
          display: 'block',
        }}
      />
    </div>
  );
};
