import React, { useContext, useState } from 'react';
import {
  Stack,
  Text,
  PrimaryButton,
  ChoiceGroup,
  IChoiceGroupOption,
  Dropdown,
  IDropdownOption,
  TextField,
  DefaultButton,
  Label,
  ActionButton,
  IContextualMenuProps,
  IContextualMenuItem,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import { globalStackTokensGapMedium, globalStackTokensGapSmall, globalTextStylesBold, newIcon } from 'globalStyles';
import { sortOnString } from 'utils/sorting';
import { TenantOnboarding } from 'models/tenant';

interface ITrialWelcomeAskMgMtSystemProps {
  active: boolean;
  onboardingState: TenantOnboarding;
  onNext: (state: TenantOnboarding) => void;
  onPrev: (state: TenantOnboarding) => void;
}

export enum MgMtSystems {
  None = 0,
  ISMSOnline = 1,
  Vanta = 2,
  Drata = 3,
  Sprinto = 4,
  ISO2HANDLE = 5,
  Base27 = 6,
  Normity = 7,
  FullyInControl = 8,
  Conformio = 9,
  Cyberday = 10,
  SmartManSys = 11,
  Eramba = 12,
  SecureFrame = 13,
  OneTrust = 14,
  Upscaler = 15,
  ControlBee = 16,
  TrustBound = 17,
  Scytale = 18,
  Compleye = 19,
  M365 = 20,
  Google = 21,
  Other = 99,
  NotSet = 100,
}

const TrialWelcomeAskMgMtSystem: React.FunctionComponent<ITrialWelcomeAskMgMtSystemProps> = (
  props: ITrialWelcomeAskMgMtSystemProps,
) => {
  const { t } = useTranslation(['translation', 'dashboard']);
  const appContext = useContext(AppContext);
  const [mgMtSystem, setMgMtSystem] = useState<MgMtSystems | undefined>(
    props.onboardingState.onboardingSteps.currentSystem,
  );
  const [otherSystem, setOtherSystem] = useState<string | undefined>(
    props.onboardingState.onboardingSteps.currentOtherSystem,
  );
  const [improvement, setImprovement] = useState<string | undefined>(
    props.onboardingState.onboardingSteps.currentSystemImprovement,
  );

  const optionsAskMgMtSystem: IChoiceGroupOption[] = [
    { key: 'yes', text: t('translation:General.Words.Yes') },
    { key: 'no', text: t('translation:General.Words.No') },
  ];

  const ansersAreNotValid = (): boolean => {
    if (mgMtSystem === undefined) {
      return true;
    }

    if (mgMtSystem !== MgMtSystems.None && !improvement) {
      return true;
    }

    return false;
  };

  const getMgMtSystemName = (system: MgMtSystems): string => {
    switch (system) {
      case MgMtSystems.ISMSOnline:
        return 'ISMS.online';
      case MgMtSystems.Vanta:
        return 'Vanta';
      case MgMtSystems.Drata:
        return 'Drata';
      case MgMtSystems.Sprinto:
        return 'Sprinto';
      case MgMtSystems.ISO2HANDLE:
        return 'ISO2HANDLE';
      case MgMtSystems.Base27:
        return 'Base27';
      case MgMtSystems.Normity:
        return 'Normity';
      case MgMtSystems.FullyInControl:
        return 'Fully In Control';
      case MgMtSystems.Conformio:
        return 'Conformio';
      case MgMtSystems.Cyberday:
        return 'Cyberday';
      case MgMtSystems.SmartManSys:
        return 'SmartManSys';
      case MgMtSystems.Eramba:
        return 'Eramba';
      case MgMtSystems.SecureFrame:
        return 'SecureFrame';
      case MgMtSystems.OneTrust:
        return 'OneTrust';
      case MgMtSystems.Upscaler:
        return 'Upscaler';
      case MgMtSystems.ControlBee:
        return 'ControlBee';
      case MgMtSystems.TrustBound:
        return 'TrustBound';
      case MgMtSystems.Scytale:
        return 'Scytale';
      case MgMtSystems.Compleye:
        return 'Compleye';
      case MgMtSystems.M365:
        return 'Microsoft 365 (e.g. Excel, Word, SharePoint)';
      case MgMtSystems.Google:
        return 'Google (e.g. Sheets, Docs, Drive)';
      case MgMtSystems.Other:
        return t('dashboard:TrialWelcome.Goals.OtherMgMtSystem');
      default:
        return '';
    }
  };

  const getMgMtSystemOptions = (): IDropdownOption[] => {
    const options: IDropdownOption[] = [];

    options.push(
      ...Object.values(MgMtSystems)
        .filter((s) => typeof s === 'number' && s > MgMtSystems.None && s < MgMtSystems.Other)
        .map((s) => {
          return {
            key: s,
            text: getMgMtSystemName(s as MgMtSystems),
          };
        })
        .sort((a, b) => sortOnString(a.text, b.text)),
    );

    options.push({
      key: MgMtSystems.Other,
      text: getMgMtSystemName(MgMtSystems.Other),
    });

    return options;
  };

  const applyNewState = (state: TenantOnboarding): TenantOnboarding => {
    state.onboardingSteps.currentSystem = mgMtSystem;
    state.onboardingSteps.currentOtherSystem = mgMtSystem === MgMtSystems.Other ? otherSystem : undefined;
    state.onboardingSteps.currentSystemImprovement =
      mgMtSystem !== MgMtSystems.None && mgMtSystem !== MgMtSystems.NotSet ? improvement : undefined;

    return state;
  };

  const getImprovementOptions = (): IContextualMenuProps => {
    return {
      items: getImprovementOptionsItems(),
      onItemClick: (event, item) => {
        if (item?.text) {
          addImprovement(item.text);
        }
      },
    };
  };

  const getImprovementOptionsItems = (): IContextualMenuItem[] => {
    return [
      { key: '1', text: t('dashboard:TrialWelcome.Goals.AskMgMtSystemImprovement1') },
      { key: '2', text: t('dashboard:TrialWelcome.Goals.AskMgMtSystemImprovement2') },
      { key: '3', text: t('dashboard:TrialWelcome.Goals.AskMgMtSystemImprovement3') },
      { key: '4', text: t('dashboard:TrialWelcome.Goals.AskMgMtSystemImprovement4') },
      { key: '5', text: t('dashboard:TrialWelcome.Goals.AskMgMtSystemImprovement5') },
      { key: '6', text: t('dashboard:TrialWelcome.Goals.AskMgMtSystemImprovement6') },
    ];
  };

  const addImprovement = (text: string) => {
    if (!improvement?.includes(text)) {
      setImprovement((improvement ? improvement + '\r\n' : '') + text);
    }
  };

  //
  // Main render
  //
  if (!props.active) return null;

  return (
    <Stack verticalFill horizontalAlign="center" styles={{ root: { paddingRight: 20, paddingBottom: 20 } }}>
      <Stack verticalFill tokens={globalStackTokensGapSmall}>
        <Text variant="large" styles={globalTextStylesBold}>
          {t('dashboard:TrialWelcome.Goals.AskMgMtSystem')}
        </Text>
        <ChoiceGroup
          selectedKey={mgMtSystem === MgMtSystems.None ? 'no' : mgMtSystem === undefined ? undefined : 'yes'}
          options={optionsAskMgMtSystem}
          onChange={(ev, option) => {
            if (option) {
              if (option.key === 'yes') {
                setMgMtSystem(MgMtSystems.NotSet);
              } else {
                setMgMtSystem(MgMtSystems.None);
              }
            }
          }}
        />
        {mgMtSystem !== undefined && mgMtSystem !== MgMtSystems.None && (
          <Stack tokens={globalStackTokensGapMedium}>
            <Stack.Item>
              <Dropdown
                placeholder={t('dashboard:TrialWelcome.Goals.AskMgMtSystemPlaceholder')}
                options={getMgMtSystemOptions()}
                styles={{
                  root: {
                    maxWidth: appContext.isMobileView ? '80%' : 600,
                  },
                }}
                calloutProps={{ calloutMaxHeight: 400 }}
                selectedKey={mgMtSystem}
                onChange={(ev, option) => {
                  setMgMtSystem(option?.key as MgMtSystems);
                }}
              ></Dropdown>
            </Stack.Item>
            {mgMtSystem === MgMtSystems.Other && (
              <Stack.Item>
                <TextField
                  label={t('dashboard:TrialWelcome.Goals.AskMgMtSystemOther')}
                  value={otherSystem}
                  placeholder={t('dashboard:TrialWelcome.Goals.AskMgMtSystemOtherPlaceholder')}
                  multiline
                  resizable={true}
                  style={{ resize: 'vertical', minHeight: 50, maxHeight: 200, overflowY: 'scroll' }}
                  rows={3}
                  onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                    setOtherSystem(newValue);
                  }}
                />
              </Stack.Item>
            )}
            <Stack.Item>
              <Stack horizontal verticalAlign="center">
                <Label>{t('dashboard:TrialWelcome.Goals.AskMgMtSystemOwnWords')}</Label>
                <ActionButton menuProps={getImprovementOptions()} iconProps={newIcon} styles={{ root: { height: 29 } }}>
                  {t('dashboard:TrialWelcome.Goals.AskMgMtSystemOwnWordsSuggest')}
                </ActionButton>
              </Stack>
              <TextField
                value={improvement}
                required
                placeholder={t('dashboard:TrialWelcome.Goals.AskMgMtSystemOwnWordsPlaceholder')}
                multiline
                resizable={true}
                style={{ resize: 'vertical', minHeight: 50, maxHeight: 200, overflowY: 'scroll' }}
                rows={5}
                onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                  setImprovement(newValue);
                }}
              />
            </Stack.Item>
          </Stack>
        )}
        <Stack horizontal tokens={globalStackTokensGapMedium} styles={{ root: { paddingTop: 30 } }}>
          <Stack.Item>
            <DefaultButton
              text={t('dashboard:TrialWelcome.Goals.ButtonPrev')}
              onClick={() => props.onPrev(applyNewState(props.onboardingState))}
            />
          </Stack.Item>
          <Stack.Item>
            <PrimaryButton
              disabled={ansersAreNotValid()}
              text={t('dashboard:TrialWelcome.Goals.ButtonNext')}
              onClick={() => props.onNext(applyNewState(props.onboardingState))}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TrialWelcomeAskMgMtSystem;
