import React, { Fragment, FunctionComponent } from 'react';
import {
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Spinner,
  SpinnerSize,
  Persona,
  PersonaSize,
  Text,
  IPersonaStyles,
  IconButton,
  List,
} from '@fluentui/react';

import AppContext from 'App/AppContext';
import Task from 'models/tasks/task';
import { toLocaleDateTimeMedium } from 'utils/datetime';
import SingleTask from 'components/Tasks/SingleTask';
import { toNameInitial } from 'utils/string';
import { useTranslation } from 'react-i18next';
import { sortOnDate } from 'utils/sorting';
import { SkypeCircleCheckIcon } from 'components/Checklist/CheckListStyles';
import { IWidgetRendererProps } from '../WidgetRenderer';
import DateDividerListItem, {
  DataGroup,
  DateComparer,
  DateDivider,
  insertDividersAndGroupsInList,
} from 'components/Utils/ListItemDateDividers';

interface IWidgetMyOverdueTasksProps extends IWidgetRendererProps {}

const WidgetMyOverdueTasks: FunctionComponent<IWidgetMyOverdueTasksProps> = (props: IWidgetMyOverdueTasksProps) => {
  const { t } = useTranslation(['translation', 'widgets']);
  const appContext = React.useContext(AppContext);
  const [tasks, setTasks] = React.useState<Task[]>([]);
  const [tasksDisplay, setTasksDisplay] = React.useState<(Task | DateDivider | DataGroup<Task>)[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedTask, setSelectedTask] = React.useState<Task | undefined>(undefined);

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget, props.sharedData]);

  React.useEffect(() => {
    const getCompareDate: DateComparer<Task> = (a) => {
      return a.getDeadline(appContext.globalDataCache);
    };

    if (tasks && tasks?.length > 0) {
      const newData = insertDividersAndGroupsInList(tasks, getCompareDate, undefined, undefined);
      setTasksDisplay(newData);
    }
  }, [appContext.globalDataCache, tasks]);

  const loadData = async (refresh?: boolean) => {
    try {
      if (props.onRefreshSharedData && props.widget.dashboard && refresh) {
        await props.onRefreshSharedData();

        return;
      }

      if (isLoading) return;
      setIsLoading(true);

      const taskCol = props.sharedData?.myTasks ?? [];

      const filteredTasks = taskCol
        .filter(
          (t) => t.completed === undefined && t.getMinutesOverDeadline(new Date(), appContext.globalDataCache) > 0,
        )
        .sort((a, b) =>
          sortOnDate(a.getDeadline(appContext.globalDataCache), b.getDeadline(appContext.globalDataCache)),
        );

      setTasks(filteredTasks);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const personaStyle: Partial<IPersonaStyles> = {
    root: {
      marginBottom: 5,
      selectors: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  };

  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  const saveTask = async (task: Task, isNew: boolean, isCancelled: boolean) => {
    if (!isCancelled) {
      await loadData(true);
    }
    setSelectedTask(undefined);
  };

  const removeTask = async (task: Task) => {
    setSelectedTask(undefined);
    await loadData(true);
  };

  const updateTask = async (task: Task) => {};

  return (
    <Stack verticalFill styles={{ root: { paddingTop: 5 } }}>
      <Stack.Item grow styles={{ root: { position: 'relative' } }}>
        <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
          <List
            items={tasksDisplay}
            onRenderCell={(task) => {
              if (!task) return null;

              if (task instanceof DateDivider) {
                return <DateDividerListItem {...task} />;
              }

              if (task instanceof DataGroup) {
                return null;
              }

              return (
                <Persona
                  size={PersonaSize.size40}
                  text={task.name}
                  imageInitials={toNameInitial(appContext.globalDataCache.users.get(task.userId).name)}
                  secondaryText={toLocaleDateTimeMedium(task.getDeadline(appContext.globalDataCache))}
                  onClick={() => {
                    if (!task) return;

                    setSelectedTask(task);
                  }}
                  styles={personaStyle}
                />
              );
            }}
          />
        </ScrollablePane>
      </Stack.Item>
      {tasks?.length === 0 && (
        <Fragment>
          <Stack verticalFill horizontalAlign="center" verticalAlign="center">
            <Stack.Item>
              <IconButton iconProps={SkypeCircleCheckIcon} />
            </Stack.Item>
          </Stack>
          <Stack.Item>
            <Text>{t('widgets:MyOverdueTasks.NoItems')}</Text>
          </Stack.Item>
        </Fragment>
      )}
      {selectedTask && (
        <SingleTask
          task={selectedTask}
          isOpen={true}
          close={() => {
            setSelectedTask(undefined);
          }}
          onSave={saveTask}
          onRemove={removeTask}
          onUpdate={updateTask}
        />
      )}
    </Stack>
  );
};

export default WidgetMyOverdueTasks;
