import { forwardRef, useContext, useImperativeHandle, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import AppContext from 'App/AppContext';
import { Stack, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { IRichTextEditorSpecialLinkType } from './RichTextEditor';
import { Editor as TinyMCEEditor } from 'tinymce';
import AppError from 'utils/appError';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';
import { AIEnabled } from 'models/setting';
import { TMCERequest, TMCERespondWith, TMCEStreamMessageCallback } from './RichTextEditorMCETypes';
import './RichTextEditor.css';
import { ReceiveStreamingUpdates, startCopilotHubConnection } from 'services/SignalR/CopilotHub';
import { IHubConnection, stopHubConnection } from 'services/SignalR/GeneralHub';

export interface IRichTextEditorMCE {
  focus: () => void;
}

export interface IRichTextEditorMCEProps {
  className?: string;
  html?: string;
  placeholder?: string;
  height?: string | number;
  maxHeight?: number | undefined;
  maxLength?: number | undefined;
  readOnly?: boolean;
  disallowFullscreen?: boolean;
  compactToolbar?: boolean;
  hideRemainingChars?: boolean;
  onChange: (value: string) => void;
  onBadgeClick?: (badgeText: string) => void;
  onSpecialLinkClick?: (type: IRichTextEditorSpecialLinkType, text: string, href: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onKeyUp?: (event: Partial<React.KeyboardEvent>) => void;
  onKeyDown?: (event: Partial<React.KeyboardEvent>) => void;
  onAccept?: (value: string | undefined, accept: boolean) => void;
  onAIRequest?: (request: TMCERequest, connectionId: string | null) => Promise<void>;
}

const RichTextEditorMCE = forwardRef(function RichTextEditorMCE(props: IRichTextEditorMCEProps, ref) {
  const { t } = useTranslation(['translation', 'copilot']);
  const appContext = useContext(AppContext);
  const [currentLength, setCurrentLength] = useState<number>(props.html?.length ?? 0);
  const maxLength = props.maxLength ?? 4000;
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const [hasAIFeature] = useState<boolean>(
    (props.onAIRequest ?? false) &&
      hasUserFeature(appContext, FeatureTypes.AIAgent) &&
      (appContext.globalDataCache.settings.get(AIEnabled) as boolean) === true,
  );

  useImperativeHandle(
    ref,
    (): IRichTextEditorMCE => {
      return {
        focus() {
          editorRef.current?.focus();
        },
      };
    },
    [],
  );

  //
  // AI integration
  //
  const getAIRequest = () => {
    if (hasAIFeature) {
      return ai_request;
    } else {
      return undefined;
    }
  };

  const getAIShortcuts = () => {
    if (hasAIFeature) {
      return [
        {
          title: t('copilot:RichTextEditor.Prompts.SummarizeContent'),
          prompt: 'SummarizeContent',
          selection: undefined,
        },
        {
          title: t('copilot:RichTextEditor.Prompts.ImproveWriting'),
          prompt: 'ImproveWriting',
          selection: undefined,
        },
        {
          title: t('copilot:RichTextEditor.Prompts.SimplifyLanguage'),
          prompt: 'SimplifyLanguage',
          selection: undefined,
        },
        {
          title: t('copilot:RichTextEditor.Prompts.ExpandUpon'),
          prompt: 'ExpandUpon',
          selection: undefined,
        },
        { title: t('copilot:RichTextEditor.Prompts.FillInField'), prompt: 'FillInField', selection: false },
      ];
    } else {
      return undefined;
    }
  };

  const ai_request = (request: TMCERequest, respondWith: TMCERespondWith) => {
    respondWith.stream(async (signal: AbortSignal, streamMessage: TMCEStreamMessageCallback): Promise<void> => {
      if (props.onAIRequest) {
        let hubConnection: IHubConnection | undefined = undefined;
        try {
          signal.onabort = async () => {
            await stopHubConnection(hubConnection?.connection);
          };
          if (!request.context) request.context = editorRef.current?.getContent();
          hubConnection = await startCopilotHubConnection(appContext, ReceiveStreamingUpdates, streamMessage);
          await props.onAIRequest(request, hubConnection.connectionId);
        } catch (err) {
          const appErr = AppError.fromApiError(err);
          appContext.showNotification(appErr.message, true);
        } finally {
          await stopHubConnection(hubConnection?.connection);
        }
      }
    });
  };

  //
  // Plugin & Toolbar setup
  //
  const getPlugins = (): string[] => {
    let plugins = [
      'autoresize',
      'emoticons',
      'advlist',
      'autolink',
      'autoresize',
      'lists',
      'link',
      'anchor',
      'searchreplace',
      'visualblocks',
      'code',
      'table',
      'quickbars',
      'tinymcespellchecker',
    ];

    if (hasAIFeature) {
      plugins = ['ai', ...plugins];
    }

    if (!props.disallowFullscreen) {
      plugins = ['fullscreen', ...plugins];
    }

    return plugins;
  };

  const getToolbar = (): string => {
    let toolbar: string = '';

    if (props.compactToolbar) {
      if (hasAIFeature) {
        toolbar =
          'undo | styles bold underline | aidialog aishortcuts | backcolor removeformat | bullist numlist | link unlink';
      } else {
        toolbar = 'undo | styles bold underline | backcolor removeformat | bullist numlist | link unlink';
      }
    } else {
      if (hasAIFeature) {
        toolbar =
          'undo redo | styles fontsize lineheight | aidialog aishortcuts | bold italic underline strikethrough | forecolor backcolor removeformat | table bullist numlist emoticons | link unlink | outdent indent | alignleft aligncenter alignright alignjustify | spellchecker print code | help';
      } else {
        toolbar =
          'undo redo | styles fontsize lineheight | bold italic underline strikethrough | forecolor backcolor removeformat | table bullist numlist emoticons | link unlink | outdent indent | alignleft aligncenter alignright alignjustify | spellchecker print code | help';
      }
    }
    if (!props.disallowFullscreen) {
      toolbar = 'fullscreen | ' + toolbar;
    }
    if (props.onAccept) {
      toolbar = 'customSaveButton customCancelButton | ' + toolbar;
    }

    return toolbar;
  };

  const getLangCode = (code: string): string | undefined => {
    //https://www.tiny.cloud/docs/tinymce/latest/introduction-to-tiny-spellchecker/
    const supportedLangs = ['af', 'en', 'da', 'de', 'es', 'fi', 'fr', 'hu', 'it', 'nb', 'nl', 'pl', 'pt', 'sv'];

    //the user can change the language on the toolbar and this is remembered in local storage by TinyMCE
    if (supportedLangs.includes(code.toLowerCase())) {
      return code;
    } else {
      return 'en';
    }
  };

  //
  // Main render
  //
  return (
    <Stack className={props.className}>
      <Editor
        apiKey="ly09v4a3kahz4m9653scj9iqospefqevznn1shd60x0ze6mg"
        //
        // Set content
        //
        value={props.html}
        //
        // Event handlers
        //
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        onKeyUp={(evt) => {
          if (evt.ctrlKey === false && evt.shiftKey === false && evt.altKey === false && evt.key === 'Enter') {
            // Remove format on Enter to get out of a badge
            editorRef.current?.editorCommands.execCommand('RemoveFormat');
          }
          if (props.onKeyUp) {
            const ev: Partial<React.KeyboardEvent> = {
              key: evt.key,
              ctrlKey: evt.ctrlKey,
              shiftKey: evt.shiftKey,
              altKey: evt.altKey,
              metaKey: evt.metaKey,
              preventDefault: evt.preventDefault,
              stopPropagation: evt.stopPropagation,
            };
            props.onKeyUp(ev);
          }
        }}
        onKeyDown={(evt) => {
          if (evt.ctrlKey === true && evt.key === 'Enter') {
            //ignore ctrl+enter: this key combination is used to submit forms
            evt.preventDefault();
          }
          if (props.onKeyDown) {
            const ev: Partial<React.KeyboardEvent> = {
              key: evt.key,
              ctrlKey: evt.ctrlKey,
              shiftKey: evt.shiftKey,
              altKey: evt.altKey,
              metaKey: evt.metaKey,
              preventDefault: evt.preventDefault,
              stopPropagation: evt.stopPropagation,
            };
            props.onKeyDown(ev);
          }
        }}
        onBlur={(ev) => {
          // https://github.com/tinymce/tinymce/issues/5330
          ev.stopImmediatePropagation();
          if (props.onBlur) {
            props.onBlur();
          }
        }}
        onFocus={props.onFocus}
        onBeforeAddUndo={(evt, editor) => {
          const length = editor.getContent({ format: 'text' }).length;
          if (length > maxLength) {
            evt.preventDefault();
          }
        }}
        onEditorChange={(value: string) => {
          if (value.length <= maxLength) {
            props.onChange(value);
          }
          setCurrentLength(value.length);
        }}
        disabled={props.readOnly}
        //
        // Main init
        //
        init={{
          auto_focus: true,
          placeholder: props.placeholder,
          branding: false,
          toolbar_location: 'bottom',
          skin: appContext.useDarkMode ? 'oxide-dark' : 'oxide',
          content_css: appContext.useDarkMode ? 'dark' : 'default',
          content_style: 'body { line-height: 1.1; }',
          language: appContext.user.language.code,
          spellchecker_language: getLangCode(appContext.user.language.code),
          menubar: false,
          statusbar: false,
          toolbar_mode: 'sliding',
          plugins: getPlugins(),
          paste_data_images: true,
          link_default_target: '_blank',
          toolbar: getToolbar(),
          fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt',
          height: props.height,
          max_height: props.maxHeight === -1 ? undefined : props.maxHeight,
          style_formats_autohide: true,
          //
          // Setup custom toolbar buttons
          //
          setup: (editor) => {
            if (props.onAccept) {
              editor.ui.registry.addButton('customSaveButton', {
                text: appContext.isMobileView ? '' : t('translation:General.Button.Update'),
                icon: 'checkmark',
                tooltip: t('translation:General.Button.Update'),
                onAction: (_) => {
                  if (props.onAccept) props.onAccept(editor.getContent(), true);
                },
              });
              editor.ui.registry.addButton('customCancelButton', {
                text: appContext.isMobileView ? '' : t('translation:General.Button.Discard'),
                icon: 'cancel',
                tooltip: t('translation:General.Button.Discard'),
                onAction: (_) => {
                  if (props.onAccept) props.onAccept(editor.getContent(), false);
                },
              });
            }
          },
          //
          // Custom formats
          //
          style_formats: [
            { title: 'Paragraph', format: 'p' },
            {
              title: 'Badge',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '1px solid #2276d2',
                'border-radius': '5px',
                padding: '2px 5px',
                margin: '0 2px',
                color: '#2276d2',
                cursor: 'pointer',
              },
            },
            {
              title: 'Info',
              inline: 'span',
              styles: {
                display: 'inline-block',
                background: '#cce5ff',
                'border-radius': '5px',
                padding: '5px 5px 5px 5px',
                margin: '0 2px',
              },
            },
            {
              title: 'Note',
              inline: 'span',
              styles: {
                display: 'inline-block',
                background: '#ffffcc',
                'border-radius': '5px',
                padding: '5px 5px 5px 5px',
                margin: '0 2px',
              },
            },
            {
              title: 'Success',
              inline: 'span',
              styles: {
                display: 'inline-block',
                background: '#ccffe6',
                'border-radius': '5px',
                padding: '5px 5px 5px 5px',
                margin: '0 2px',
              },
            },
            {
              title: 'Warning',
              inline: 'span',
              styles: {
                display: 'inline-block',
                background: '#ffb3b3',
                'border-radius': '5px',
                padding: '5px 5px 5px 5px',
                margin: '0 2px',
              },
            },
            { title: 'Header 1', format: 'h1' },
            { title: 'Header 2', format: 'h2' },
            { title: 'Header 3', format: 'h3' },
            { title: 'Header 4', format: 'h4' },
            { title: 'Header 5', format: 'h5' },
            { title: 'Blockquote', format: 'blockquote' },
            { title: 'Code', format: 'code' },
          ],
          ai_request: getAIRequest(),
          ai_shortcuts: getAIShortcuts(),
          quickbars_selection_toolbar: 'bold italic underline removeformat | link',
          quickbars_insert_toolbar: false,
          quickbars_image_toolbar: false,
        }}
      />
      {!props.hideRemainingChars && (
        <Text variant="small">
          {t('translation:General.Text.RichTextEditorMaxLength', { len: maxLength - currentLength })}
        </Text>
      )}
    </Stack>
  );
});

export default RichTextEditorMCE;
