import { useContext } from 'react';
import { FontIcon, Text, Persona, PersonaSize, Stack, Separator } from '@fluentui/react';

import {
  globalTextStylesCard,
  globalStackStylesCard,
  globalStackTokensGapCard,
  globalStackItemStylesCard,
  globalStackTokensGapSmall,
  globalTextStylesCardItalic,
  globalTextStylesCardRed,
  globalFontBoldWeight,
} from 'globalStyles';
import { toLocaleDateShortNoYear } from 'utils/datetime';
import CheckListProgress from 'components/Checklist/ChecklistProgress';
import { ICardTaskProps } from './KanbanCard';
import { KeyValueTags } from 'components/Tags/KeyValueTag';
import AppContext from 'App/AppContext';
import { RecurringSummary, RecurringPatternSummaryStyle } from 'components/RecurringPattern/RecurringSummary';

const KanbanCardTaskGenericWithState = (props: ICardTaskProps) => {
  const appContext = useContext(AppContext);

  return (
    <div
      onClick={() => {
        if (props.onClick) props.onClick(props.task);
      }}
    >
      <Stack styles={globalStackStylesCard} tokens={globalStackTokensGapCard}>
        <Stack.Item styles={globalStackItemStylesCard}>
          <Text
            block
            styles={{
              root: { fontWeight: globalFontBoldWeight, paddingTop: 0, paddingBottom: 0, wordBreak: 'break-word' },
            }}
          >
            {props.task.name}
          </Text>
        </Stack.Item>
        <Stack.Item styles={globalStackItemStylesCard}>
          <Text block variant="small" styles={globalTextStylesCardItalic}>
            {
              props.task.taskStates?.find((t) => {
                return t.taskStateId === props.task.taskStateId;
              })?.state
            }
          </Text>
        </Stack.Item>
        <Stack.Item>
          <CheckListProgress
            compact
            totalChecks={props.task.checkList.items.length}
            completedChecks={props.task.checkList.getCompletedCheckCount()}
            followUp={props.task.followUp}
          ></CheckListProgress>
        </Stack.Item>
        {props.task.tagIds && props.task.tagIds.length > 0 && (
          <Stack.Item styles={globalStackItemStylesCard}>
            <Separator styles={{ root: { height: 1 } }}></Separator>
            <KeyValueTags
              compact
              tagWidth={150}
              tags={appContext.globalDataCache.tags.getItemsForId(props.task.tagIds)}
            />
          </Stack.Item>
        )}
        <Stack.Item styles={globalStackItemStylesCard}>
          <Separator styles={{ root: { height: 1 } }}></Separator>
          <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
            <Stack.Item>
              <Text block variant="small" styles={globalTextStylesCard}>
                {toLocaleDateShortNoYear(props.task.startDateTime)}
              </Text>
            </Stack.Item>
            <Text>-</Text>
            <Stack.Item>
              <Text
                block
                variant="small"
                styles={
                  props.task.getMinutesOverDeadline(props.task.completed || new Date(), appContext.globalDataCache) > 0
                    ? globalTextStylesCardRed
                    : globalTextStylesCard
                }
              >
                {toLocaleDateShortNoYear(props.task.getDeadline(appContext.globalDataCache))}
              </Text>
            </Stack.Item>
            {props.task.recurringPattern.isActive && (
              <Stack.Item>
                <RecurringSummary
                  pattern={props.task.recurringPattern}
                  summaryStyle={RecurringPatternSummaryStyle.icon}
                />
              </Stack.Item>
            )}
          </Stack>
        </Stack.Item>
        <Separator styles={{ root: { height: 1 } }}></Separator>
        <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
          <Persona
            text={props.task.user ? props.task.user.name : undefined}
            size={PersonaSize.size24}
            hidePersonaDetails={false}
          />
          {props.task.isScheduled() && (
            <FontIcon iconName="Calendar" style={appContext.useDarkMode ? { color: 'lightblue' } : { color: 'blue' }} />
          )}
        </Stack>
      </Stack>
    </div>
  );
};

export default KanbanCardTaskGenericWithState;
