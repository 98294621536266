import React, { useState } from 'react';
import { Stack, Text, DefaultButton, PrimaryButton, ChoiceGroup, IChoiceGroupOption } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapMedium, globalTextStylesBold } from 'globalStyles';
import LocalizedDatePicker from 'components/Pickers/LocalizedDatePicker';
import { addDateDays, addDateMonths } from 'utils/datetime';
import { TenantOnboarding } from 'models/tenant';

interface ITrialWelcomeAskTimelineProps {
  active: boolean;
  onboardingState: TenantOnboarding;
  onNext: (state: TenantOnboarding) => void;
  onPrev: (state: TenantOnboarding) => void;
}

const TrialWelcomeAskTimeline: React.FunctionComponent<ITrialWelcomeAskTimelineProps> = (
  props: ITrialWelcomeAskTimelineProps,
) => {
  const { t } = useTranslation(['translation', 'dashboard']);
  const [start, setStart] = useState<Date | undefined>(
    props.onboardingState.onboardingSteps.startDate ?? addDateDays(new Date(), 1),
  );
  const [end, setEnd] = useState<Date | undefined>(
    props.onboardingState.onboardingSteps.endDate ?? addDateMonths(new Date(), 3),
  );
  const [optInAI, setOptInAI] = useState<boolean>(props.onboardingState.onboardingSteps.optInAI);

  const ansersAreNotValid = (): boolean => {
    return false;
  };

  const applyNewState = (state: TenantOnboarding): TenantOnboarding => {
    state.onboardingSteps.startDate = start;
    state.onboardingSteps.endDate = end;
    state.onboardingSteps.optInAI = optInAI;

    return state;
  };

  const optionsAskOptInAI: IChoiceGroupOption[] = [
    { key: 'yes', text: t('translation:General.Words.Yes') },
    { key: 'no', text: t('translation:General.Words.No') },
  ];

  //
  // Main render
  //
  if (!props.active) return null;

  return (
    <Stack verticalFill horizontalAlign="center" styles={{ root: { paddingRight: 20, paddingBottom: 20 } }}>
      <Stack tokens={globalStackTokensGapMedium}>
        <Stack.Item>
          <Text variant="xLarge" styles={globalTextStylesBold}>
            {t('dashboard:TrialWelcome.Goals.AskTimelineSection')}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Text variant="large" styles={globalTextStylesBold}>
            {t('dashboard:TrialWelcome.Goals.AskTimeline')}
          </Text>
        </Stack.Item>
        <Stack
          horizontal
          wrap
          tokens={globalStackTokensGapMedium}
          verticalAlign="center"
          styles={{ root: { paddingRight: 10 } }}
        >
          <Stack.Item>
            <LocalizedDatePicker
              label={t('dashboard:TrialWelcome.Goals.AskTimelineStart')}
              showMonthPickerAsOverlay={false}
              showWeekNumbers={true}
              value={start}
              minDate={new Date("2025-01-01")} // Minimum date is this because the default project starts 2024-12-01 
              maxDate={end}
              onDateChange={(date: Date | undefined) => setStart(date)}
              styles={{ root: { minWidth: 150, maxWidth: 300 } }}
              format="short"
            />
          </Stack.Item>
          <Stack.Item>
            <LocalizedDatePicker
              label={t('dashboard:TrialWelcome.Goals.AskTimelineEnd')}
              showMonthPickerAsOverlay={false}
              showWeekNumbers={true}
              value={end}
              minDate={start}
              onDateChange={(date: Date | undefined) => setEnd(date)}
              styles={{ root: { minWidth: 150, maxWidth: 300 } }}
              format="short"
            />
          </Stack.Item>
        </Stack>
        <Stack.Item styles={{ root: { paddingTop: 30 } }}>
          <Text variant="large" styles={globalTextStylesBold}>
            {t('dashboard:TrialWelcome.Goals.AskOptInAI')}
          </Text>
          <ul>
            <li>{t('dashboard:TrialWelcome.Goals.AskOptInAIInfo1')}</li>
            <li>{t('dashboard:TrialWelcome.Goals.AskOptInAIInfo2')}</li>
          </ul>
          <ChoiceGroup
            selectedKey={optInAI ? 'yes' : 'no'}
            options={optionsAskOptInAI}
            onChange={(ev, option) => {
              if (option) {
                if (option.key === 'yes') {
                  setOptInAI(true);
                } else {
                  setOptInAI(false);
                }
              }
            }}
          />
        </Stack.Item>
        <Stack horizontal tokens={globalStackTokensGapMedium} styles={{ root: { paddingTop: 30 } }}>
          <Stack.Item>
            <DefaultButton
              text={t('dashboard:TrialWelcome.Goals.ButtonPrev')}
              onClick={() => props.onPrev(applyNewState(props.onboardingState))}
            />
          </Stack.Item>
          <Stack.Item>
            <PrimaryButton
              disabled={ansersAreNotValid()}
              text={t('dashboard:TrialWelcome.Goals.ButtonNext')}
              onClick={() => props.onNext(applyNewState(props.onboardingState))}
            />
          </Stack.Item>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TrialWelcomeAskTimeline;
