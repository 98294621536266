import {
  Callout,
  getTheme,
  Persona,
  PersonaSize,
  Rectangle,
  Spinner,
  SpinnerSize,
  Stack,
  Target,
  Text,
  Image,
  IImageProps,
  ImageFit,
} from '@fluentui/react';
import AppContext from 'App/AppContext';
import Task, { TaskTypes } from 'models/tasks/task';
import moment from 'moment';
import { forwardRef, Fragment, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Timeline from 'react18-vis-timeline';
import { IdType, TimelineEventPropertiesResult, TimelineGroup, TimelineItem, TimelineOptions } from 'vis-timeline';
import { sortOnDate, sortOnString } from 'utils/sorting';
import { newGuid } from 'utils/guid';
import 'moment/locale/nl';
import 'moment/locale/de';
import KanbanCardTaskTimeline from 'components/Kanban/Cards/KanbanCardTaskTimeline';
import Tag, { TagSystemTypes } from 'models/tag';
import User from 'models/user';
import { KeyValueTag } from 'components/Tags/KeyValueTag';
import { createRoot } from 'react-dom/client';
import KanbanCardEventTimeline from 'components/Kanban/Cards/KanbanCardEventTimeline';
import {
  timelineDefaultGroupId,
  ITimelineChart,
  ITimelineChartProps,
  onItemHoverProps,
  onSelectProps,
  timelineCalloutStyleTaskDetails,
  timelineCalloutStyleItemDrag,
  timelineCalloutWidth,
  onRangeChangedProps,
} from './TimelineChart';
import { colorMixer } from 'utils/color';
import { usePrevious } from 'globalHooks';
import { canUpdateTaskField, hasTaskUpdatePermission, TaskFieldTypes } from 'components/Tasks/TaskAuthHelper';
import { addDateYears, toLocaleDateTimeMediumWithDay } from 'utils/datetime';
import { globalFontBoldWeight, globalTextStylesBold } from 'globalStyles';
import { overflow } from 'utils/string';
import './TimelineChartStyles.css';
import { useTranslation } from 'react-i18next';
import WorkingHoursPattern from 'models/WorkingHoursPattern';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import logger from 'services/Logging/logService';
import AppError from 'utils/appError';
import Config from 'services/Config/configService';

const imagePropsDefaultGroup: IImageProps = {
  src: `${Config.getImageURL()}/tasks.png`,
  imageFit: ImageFit.centerContain,
  shouldFadeIn: false,
  width: 24,
  height: 24,
};

export enum TimelineTaskGroupMode {
  None = 0,
  User = 1, //Not tested
  Tag = 2,
}

interface ITimelineChartTaskProps extends ITimelineChartProps {
  tasks?: Task[];
  tags?: Tag[];
  groupMode: TimelineTaskGroupMode;
  allowEdit?: boolean;
  maxStackCount?: number;
  onClickTask?: (task: Task) => void;
  onAddTask?: (start: Date, tagId: number | undefined) => void;
  onUpdateTasks?: (tasks: Task[]) => void;
  onInitialDrawComplete?: () => void;
}

interface ITimelineTaskItem extends TimelineItem {
  data: Task | undefined;
}

interface ITimelineTaskGroup extends TimelineGroup {
  data: User | Tag[] | undefined;
}

const taskMaxChars = 40;
const defMaxStackCount = 5;

const TimelineChartTask = forwardRef(function (props: ITimelineChartTaskProps, ref) {
  const { t } = useTranslation(['translation', 'tasks']);
  const appContext = useContext(AppContext);
  const timelineRef = useRef<Timeline | null>(null);
  const [taskData, setTaskData] = useState<ITimelineTaskItem[]>([]);
  const [groups, setGroups] = useState<ITimelineTaskGroup[]>([]);
  const [selectedTasks, setSelectedTasks] = useState<string[]>([]);
  const [tasksToMove, setTasksToMove] = useState<Task[]>([]);
  const prevSelectedTasks = usePrevious(selectedTasks);
  const [showTaskDetails, setShowTaskDetails] = useState<Task | undefined>(undefined);
  const [itemEvent, setItemEvent] = useState<MouseEvent | undefined>(undefined);
  const [mouseClickEvent, setMouseClickEvent] = useState<TimelineEventPropertiesResult | undefined>(undefined);
  const [mouseEvent, setMouseEvent] = useState<TimelineEventPropertiesResult | undefined>(undefined);
  const [groupElementMap, setGroupElementMap] = useState<{ [id: IdType]: HTMLElement }>({});
  const [dragItemFirst, setDragItemFirst] = useState<ITimelineTaskItem | undefined>(undefined);
  const [dragItemLast, setDragItemLast] = useState<ITimelineTaskItem | undefined>(undefined);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [workingHours] = useState<WorkingHoursPattern>(appContext.globalDataCache.settings.getStaticWorkingHours());
  const [showTaskDetailsCallout, setShowTaskDetailsCallout] = useState<Task | undefined>(undefined);
  const [showTaskDetailsCalloutTimer, setShowTaskDetailsCalloutTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  const [showConfirmMoveDialog, setShowConfirmMoveDialog] = useState<boolean>(false);
  const theme = getTheme();

  useImperativeHandle(
    ref,
    (): ITimelineChart => {
      return {
        setWindow(start: Date, end: Date) {
          if (timelineRef?.current?.timeline) {
            timelineRef.current.timeline.setWindow(start, end);
          }
        },
        focus(ids: string[]) {
          if (timelineRef?.current?.timeline) {
            timelineRef.current.timeline.focus(ids);
          }
        },
        fit() {
          if (timelineRef?.current?.timeline) {
            timelineRef.current.timeline.fit();
          }
        },
      };
    },
    [],
  );

  //
  // Effects
  //
  useEffect(() => {
    //localize vis-timeline
    moment.locale(appContext.user.login.userLanguageCode, {
      workinghours: workingHours.getConfig(),
    });

    return () => {
      if (showTaskDetailsCalloutTimer) {
        clearTimeout(showTaskDetailsCalloutTimer);
      }
      setGroupElementMap({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showTaskDetailsCalloutTimer) {
      clearTimeout(showTaskDetailsCalloutTimer);
    }

    if (showTaskDetailsCallout) {
      setShowTaskDetailsCalloutTimer(
        setTimeout(() => {
          setShowTaskDetails(showTaskDetailsCallout);
        }, 300),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTaskDetailsCallout]);

  useEffect(() => {
    calcDataTasks(props.tasks, props.tags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tasks, props.tags]);

  useEffect(() => {
    onClick(mouseClickEvent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mouseClickEvent]);

  useEffect(() => {
    if (timelineRef?.current?.timeline) {
      if (taskData?.length > 0) {
        const ref = timelineRef.current.timeline;
        ref.setGroups(groups);
        ref.setItems(taskData);

        ref.off('click', onClickCatch);
        ref.off('doubleClick', onDblClick);
        ref.off('select', onSelect);
        ref.off('itemover', onItemHoverIn);
        ref.off('itemout', onItemHoverOut);
        ref.off('mouseOver', onMouseOver);
        ref.off('rangechange', onRangeChange);

        ref.on('click', onClickCatch);
        ref.on('doubleClick', onDblClick);
        ref.on('select', onSelect);
        ref.on('itemover', onItemHoverIn);
        ref.on('itemout', onItemHoverOut);
        ref.on('mouseOver', onMouseOver);
        ref.on('rangechange', onRangeChange);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskData, groups, timelineRef.current]);

  useEffect(() => {
    if (taskData?.length > 0) {
      const updatedTaskData = [...taskData];

      //de-select
      if (prevSelectedTasks) {
        for (let idx = 0; idx < prevSelectedTasks.length; idx++) {
          const id = prevSelectedTasks[idx];
          const updatedTask = updatedTaskData.find((x) => x.id === id);
          if (updatedTask) {
            updatedTask.style = getTaskStyle(updatedTask.data as Task, false);
          }
        }
      }

      //select
      for (let idx = 0; idx < selectedTasks.length; idx++) {
        const id = selectedTasks[idx];
        const updatedTask = updatedTaskData.find((x) => x.id === id);
        if (updatedTask) {
          updatedTask.style = getTaskStyle(updatedTask.data as Task, true);
        }
      }

      //update the timeline items and selection
      timelineRef?.current?.timeline?.setItems(updatedTaskData);
      timelineRef?.current?.timeline?.setSelection(selectedTasks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTasks]);

  //
  // Event handlers
  //
  const onClickCatch = (properties: TimelineEventPropertiesResult) => {
    setMouseClickEvent(properties);
  };

  const onClick = (properties: TimelineEventPropertiesResult | undefined) => {
    try {
      //
      // This event uses state so we use onClickCatch to process this event oustide of the state
      // stored with the registration of the event in the timeline
      //
      if (!properties) return;

      // detect the ctrl or shift key
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const event1 = (properties.event as any).srcEvent;
      const event2 = properties.event as MouseEvent;
      const ctrlOrShift = event1?.ctrlKey || event1?.shiftKey || event2?.ctrlKey || event2?.shiftKey;

      if (properties.group && properties.what === 'group-label') {
        //when clicking a group, select all tasks in that group
        const tasksInGroup = taskData.filter((x) => x.group === properties.group);
        const tasksInGroupIds = tasksInGroup.map((x) => x.id as string);
        let newSelection: string[] = [];
        if (ctrlOrShift) {
          newSelection = [...selectedTasks, ...tasksInGroupIds];
        } else {
          newSelection = tasksInGroupIds;
        }
        setSelectedTasks(newSelection);
        if (newSelection.length > 1) {
          timelineRef?.current?.timeline?.focus(newSelection);
        } else if (newSelection.length === 1) {
          const task = taskData.find((x) => x.id === newSelection[0])?.data;
          if (task) timelineRef?.current?.timeline?.moveTo(task.startDateTime);
        }
      } else if (
        properties.item &&
        !ctrlOrShift &&
        props.onClickTask &&
        selectedTasks.includes(properties.item as string)
      ) {
        //open a selected task click, except when click is to de-select an item using ctrl or shift
        const task = getTaskForId(properties.item as string);
        if (task) {
          props.onClickTask(task);
        }
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  const getTaskForId = (id: string): Task | undefined => {
    return taskData.find((x) => x.id === id)?.data;
  };

  //open task on double click
  //this works only when item cannot be selected (due to no authorization)
  //for opening a task that can be selected, see the click event (first click select, second open)
  const onDblClick = (properties: TimelineEventPropertiesResult) => {
    if (properties.item && props.onClickTask) {
      const task = getTaskForId(properties.item as string);
      if (task) {
        props.onClickTask(task);
      }
    }
  };

  //update the tasks selection
  const onSelect = (properties: onSelectProps) => {
    //this prevents the callout from showing shortly in the upper-left corner due to setting the new selection
    hideTaskDetailsCallout();
    //update the selection
    if (properties.items.length > 0) {
      setSelectedTasks(properties.items);
    } else {
      setSelectedTasks([]);
    }
  };

  //show & hide callout with task info when task is clicked
  const onItemHoverIn = (properties: onItemHoverProps) => {
    //register the event for the callout (details and drag-update) for positioning
    if (properties.event) {
      setItemEvent(properties.event);
    }

    //don't show call out when dragging
    const mouseEvent = properties.event as MouseEvent;
    if (properties.item && !mouseEvent.buttons) {
      //find the task
      const task = getTaskForId(properties.item);
      if (task) {
        //and show the callout after a short delay
        setShowTaskDetailsCallout(task);

        return;
      }
    }

    hideTaskDetailsCallout();
  };

  const onItemHoverOut = (properties: onItemHoverProps) => {
    hideTaskDetailsCallout();
  };

  const onMouseOver = (properties: TimelineEventPropertiesResult) => {
    setMouseEvent(properties);
  };

  const onRangeChange = (properties: onRangeChangedProps) => {
    if (properties.byUser) {
      hideTaskDetailsCallout();
    }
  };

  //
  // Data calculation
  //

  // Calculate the data for the tasks
  const calcDataTasks = (tasks: Task[] | undefined, tagCache: Tag[] | undefined) => {
    try {
      if (!tasks || tasks.length === 0) {
        setTaskData([]);
        setGroups([]);
      } else {
        //now calculate the groups
        const groups = calcGroups(tasks, props.tags);

        //sort the tasks on start date
        const tasksOrderedByStart = tasks.sort((a, b) => sortOnDate(a.startDateTime, b.startDateTime));
        const data: ITimelineTaskItem[] = [];

        //clone each task (because we will can change them) and create a timeline item
        for (let i = 0; i < tasksOrderedByStart.length; i++) {
          const task = tasksOrderedByStart[i].clone();
          const canUpdate = props.allowEdit === true && hasTaskUpdatePermission(task, appContext);
          const hasAuthMove =
            canUpdateTaskField(task, canUpdate, TaskFieldTypes.Start, appContext) &&
            canUpdateTaskField(task, canUpdate, TaskFieldTypes.Deadline, appContext);
          task.id ??= newGuid();
          task.startDateTime.setSeconds(0, 0);
          const newItem: ITimelineTaskItem = {
            id: task.id,
            start: task.startDateTime,
            content: overflow(task.name, taskMaxChars),
            style: getTaskStyle(task, false),
            group: getGroupForTask(task, groups),
            selectable: canUpdate,
            editable: {
              updateGroup: canUpdate,
              updateTime: hasAuthMove,
              remove: false,
            },
            data: task,
          };

          if (!(task.taskType === TaskTypes.Event && task.taskMasterId === 0)) {
            //show events as milestones, all other tasks - including subtasks of events - as bars
            newItem.end = task.getDeadline(appContext.globalDataCache);
          }

          data.push(newItem);
        }

        //Remove default group if not used
        const isDefGroupUsed = data.some((d) => d.group === timelineDefaultGroupId);
        if (!isDefGroupUsed) {
          groups.pop();
        }

        setGroups(groups);
        setTaskData(data);
      }
    } catch (err) {
      appContext.setError(err);
    }
  };

  const calcGroups = (tasks: Task[], tagCache: Tag[] | undefined): ITimelineTaskGroup[] => {
    try {
      let groups: ITimelineTaskGroup[] = [];

      switch (props.groupMode) {
        case TimelineTaskGroupMode.User:
          const usersIds = tasks.filter((t) => t.isAssigned()).map((t) => t.userId as string);
          const uniqueUsers = [...new Set(usersIds)];
          const users = appContext.globalDataCache.users
            .getItemsForId(uniqueUsers)
            .sort((a, b) => sortOnString(a.name, b.name));

          groups = users.map((user) => {
            return {
              id: user.id,
              content: user.name,
              data: user,
            };
          });
          break;
        case TimelineTaskGroupMode.Tag:
          //Create groups for all unique combinations of tags
          //calculate the group key for each task and save them in a hash table
          const tagGroups: { [key: string]: Tag[] } = {};
          tasks.forEach((task) => {
            const taskTags = getGroupTagsForTask(task, tagCache);
            task.timelineTagKeys = createGroupKey(taskTags);
            tagGroups[task.timelineTagKeys] = taskTags ?? [];
          });

          const allTagGroupKeys = Object.keys(tagGroups).filter((id) => id !== timelineDefaultGroupId);

          //sort the groups
          const sortedUniqueTagGroupKeys = allTagGroupKeys.sort((a, b) => {
            const partsA = a.split('-');
            const partsB = b.split('-');
            const tagA =
              partsA.length === 1 ? tagCache?.find((t) => t.tagId === Number.parseInt(partsA[0])) : undefined;
            const tagB =
              partsB.length === 1 ? tagCache?.find((t) => t.tagId === Number.parseInt(partsB[0])) : undefined;

            //project tags on top
            if (tagA?.tagSystemType === TagSystemTypes.Project && tagB?.tagSystemType === TagSystemTypes.Project) {
              return sortOnString(tagA.value(), tagB.value());
            }

            if (tagA?.tagSystemType === TagSystemTypes.Project) return -1;
            if (tagB?.tagSystemType === TagSystemTypes.Project) return 1;

            return sortOnString(a, b);
          });

          groups = sortedUniqueTagGroupKeys.map((key) => {
            return {
              id: key,
              content: key,
              data: tagGroups[key],
            };
          });

          break;
        default:
          break;
      }

      groups.push({
        id: timelineDefaultGroupId,
        content: props.defaultGroupName,
        data: undefined,
      });

      return groups;
    } catch (err) {
      appContext.setError(err);

      return [];
    }
  };

  const createGroupKey = (tags: Tag[] | undefined): string => {
    if (!tags || tags.length === 0) return timelineDefaultGroupId;
    let key = '';

    for (let idx = 0; idx < tags.length; idx++) {
      key += tags[idx].tagId.toString();
      if (idx < tags.length - 1) {
        key += '-';
      }
    }

    return key;
  };

  const getGroupTagsForTask = (task: Task, tagCache: Tag[] | undefined): Tag[] | undefined => {
    //1. return the first tag of task category Project (sorted on tag value)
    //2. if no project tag is present, return all tags sorted on tag value
    if (!task.tagIds || task.tagIds.length === 0) return undefined;
    const taskTags = tagCache?.filter((t) => task.tagIds?.includes(t.tagId)) ?? [];
    const projectTags = taskTags.filter((t) => t.tagSystemType === TagSystemTypes.Project);
    if (projectTags.length > 0) {
      return [projectTags.sort((a, b) => sortOnString(a.value(), b.value()))[0]];
    } else {
      return taskTags.sort((a, b) => sortOnString(a.value(), b.value()));
    }
  };

  const getGroupForTask = (task: Task, groups: ITimelineTaskGroup[]): IdType | undefined => {
    switch (props.groupMode) {
      case TimelineTaskGroupMode.User:
        return (
          groups.filter((g) => g.data !== undefined).find((g) => (g.data as User).id === task.userId)?.id ??
          timelineDefaultGroupId
        );
      case TimelineTaskGroupMode.Tag:
        if (!task.timelineTagKeys) return timelineDefaultGroupId;

        return task.timelineTagKeys;
      default:
        return timelineDefaultGroupId;
    }
  };

  //
  // Helper functions
  //
  const getTaskStyle = (task: Task, selected: boolean): string => {
    return `color:${
      appContext.useDarkMode ? theme.palette.white : theme.palette.black
    };background-color:${getTaskBackgroundColor(task, selected)};`;
  };

  const getTaskBackgroundColor = (task: Task, selected: boolean): string => {
    let backgroundColor = task.getBackgroundColor(appContext.useDarkMode, appContext.globalDataCache);

    if (selected) {
      backgroundColor = colorMixer(backgroundColor, '#FDDA0D', 0.5);
    }

    return backgroundColor;
  };

  const hideTaskDetailsCallout = () => {
    clearTimeout(showTaskDetailsCalloutTimer);
    setShowTaskDetails(undefined);
    setShowTaskDetailsCallout(undefined);
  };

  const hideItemDragCallout = () => {
    setDragItemFirst(undefined);
    setDragItemLast(undefined);
  };

  //
  // Render helpers
  //
  const renderItemUpdateTimeTooltip = (item: ITimelineTaskItem): string | null => {
    if (!item) return null;
    if (selectedTasks.length === 0) return null;
    if (item.id === selectedTasks[0]) {
      setDragItemFirst(item);
    }
    if (item.id === selectedTasks[selectedTasks.length - 1]) {
      setDragItemLast(item);
    }

    return null;
  };

  const renderGroupTemplate = (group: ITimelineTaskGroup, element: HTMLElement): string | HTMLElement => {
    if (!group) return '';

    const mapId = group.id;
    if (groupElementMap[mapId]) {
      return groupElementMap[mapId];
    }

    let elm: JSX.Element | null = null;
    switch (props.groupMode) {
      case TimelineTaskGroupMode.User:
        if (group.data) {
          elm = getGroupTemplateUser(group.data as User);
        }
        break;
      case TimelineTaskGroupMode.Tag:
        if (Array.isArray(group.data) && group.data.length > 0) {
          elm = getGroupTemplateTag(group.data as Tag[]);
        }
        break;
      default:
        break;
    }

    if (elm === null) {
      if (group.content instanceof HTMLElement) {
        return group.content;
      } else {
        elm = (
          <Stack horizontal>
            <Stack.Item>
              <Image {...imagePropsDefaultGroup}></Image>
            </Stack.Item>
            <Stack.Item>
              <Text styles={{ root: { fontWeight: globalFontBoldWeight, paddingLeft: 10 } }}>{group.content}</Text>
            </Stack.Item>
          </Stack>
        );
      }
    }

    // Create a container for the react element (prevents DOM node errors)
    const container = document.createElement('div');
    element.appendChild(container);
    createRoot(container).render(elm);

    // Store the rendered element container to reference later
    groupElementMap[mapId] = container;

    // Return the new container
    return container;
  };

  const getGroupTemplateUser = (user: User): JSX.Element => {
    return <Persona key={user.id} text={user.name} size={PersonaSize.size24} />;
  };

  const getGroupTemplateTag = (tags: Tag[]): JSX.Element => {
    return (
      <Stack>
        {tags.map((t) => (
          <KeyValueTag key={t.tagId} tag={t} />
        ))}
      </Stack>
    );
  };

  const getItemHoverCard = (task: Task): JSX.Element => {
    switch (task.taskType) {
      case TaskTypes.Event:
        return <KanbanCardEventTimeline key={task.taskId.toString()} task={task} />;
      default:
        return <KanbanCardTaskTimeline key={task.taskId.toString()} task={task} />;
    }
  };

  const getItemDragHoverCard = (first: ITimelineTaskItem, last: ITimelineTaskItem): JSX.Element | null => {
    const task = first.data;
    if (!task) return null;

    if (last.id !== first.id) {
      return (
        <Stack>
          <Stack.Item>
            <Text styles={globalTextStylesBold}>{toLocaleDateTimeMediumWithDay(first.start as Date)}</Text>
            <Text>{' : '}</Text>
            <Text>{overflow(first.content as string, taskMaxChars)}</Text>
          </Stack.Item>
          <Fragment>
            <Stack.Item>
              <Text styles={globalTextStylesBold}>{toLocaleDateTimeMediumWithDay(last.start as Date)}</Text>
              <Text>{' : '}</Text>
              <Text>{overflow(last.content as string, taskMaxChars)}</Text>
            </Stack.Item>
          </Fragment>
        </Stack>
      );
    } else {
      return (
        <Stack>
          <Stack.Item>
            <Text>{t('task:Start.Label')}</Text>
            <Text>{' : '}</Text>
            <Text styles={globalTextStylesBold}>{toLocaleDateTimeMediumWithDay(first.start as Date)}</Text>
          </Stack.Item>
          {(!task.isEvent() || task.isInstance()) && (
            <Stack.Item>
              <Text>{t('task:Deadline.Label')}</Text>
              <Text>{' : '}</Text>
              <Text styles={globalTextStylesBold}>{toLocaleDateTimeMediumWithDay(first.end as Date)}</Text>
            </Stack.Item>
          )}
        </Stack>
      );
    }
  };

  const snapToGrid = (date: Date, scale: string, step: number): Date | number => {
    try {
      let newDate: number;

      if (scale === 'hour') {
        const hour = 60 * 60 * 1000;
        const currentDate = date.getTime();
        newDate = Math.round(currentDate / hour) * hour;
      } else {
        const day = 60 * 60 * 1000 * 24;
        const currentDate = date.getTime();
        newDate = Math.round(currentDate / day) * day;
      }

      const newDateObj = new Date(newDate);
      const newDateWorking = workingHours.getNextWorkingTime(newDateObj);

      return newDateWorking;
    } catch (err) {
      const appErr = AppError.fromApiError(err);
      logger.debug(appErr?.message, err);

      return date;
    }
  };

  const getItemDragTarget = (): Rectangle | undefined => {
    const rect = document.getElementById('timeline')?.getBoundingClientRect();
    if (!rect) return undefined;
    const center = rect.left + (rect.right - rect.left + timelineCalloutWidth) / 2;

    return {
      bottom: 0,
      left: center,
      right: center,
      top: rect.top - 100,
      width: 0,
      height: 0,
      equals: (rect: Rectangle) => false,
    };
  };

  const onMoveEnd = (item: ITimelineTaskItem) => {
    //update the tasks
    const task = item.data as Task;

    //tag
    const group = groups.find((g) => g.id === item.group);
    if (group?.data) {
      task.tagIds = (group.data as Tag[]).map((t) => t.tagId);
    }

    //start date
    task.setNewStartDate(item.start as Date);

    //deadline
    if (!task.isEvent() || task.isInstance()) {
      task.deadline = item.end as Date;
    }

    //add to tasks to move list
    const newTasksToMove = [...tasksToMove, task];
    setTasksToMove(newTasksToMove);

    //this method is called for every selected item, so check if all items are moved
    if (newTasksToMove.length === selectedTasks.length) {
      setIsDragging(false);
      setShowConfirmMoveDialog(true);
    }
  };

  const moveTasks = () => {
    if (props.onUpdateTasks) {
      props.onUpdateTasks([...tasksToMove]);
    }
    setTasksToMove([]);
    setShowConfirmMoveDialog(false);
  };

  const canCluster = (firstItem: TimelineItem, secondItem: TimelineItem): boolean => {
    //cluster only when start date is not the same, otherwise the user cannot see the items ever
    //because they remain the a cluster if the number of items on exactly the same date+time is more than maxItems.
    if (firstItem.start.valueOf() === secondItem.start.valueOf()) {
      return false;
    }

    //do not cluster selected items. This is Important for dragging, otherwise dragged items can 'disappear' in a cluster.
    if (
      isDragging &&
      (selectedTasks.includes(firstItem.id as string) || selectedTasks.includes(secondItem.id as string))
    ) {
      return false;
    }

    return true;
  };

  const onInitialDrawComplete = () => {
    if (props.onInitialDrawComplete) {
      props.onInitialDrawComplete();
    }
  };

  //calculate options every render for height adjustment
  const options: TimelineOptions = {
    width: '100%',
    height: props.height ?? `300px`,
    verticalScroll: true,
    zoomKey: 'ctrlKey',
    selectable: true,
    locale: appContext.user.login.userLanguageCode,
    min: new Date('2021-01-01'),
    max: addDateYears(new Date(), 10),
    zoomMax: 1000 * 60 * 60 * 24 * 365 * 10, //10 years
    zoomMin: 1000 * 60 * 60 * 24, //1 day
    showWeekScale: true,
    editable: {
      add: props.allowEdit ? true : false,
      updateTime: props.allowEdit ? true : false,
      updateGroup: props.allowEdit ? true : false,
      remove: false,
      overrideItems: props.allowEdit ? true : false,
    },
    multiselect: true,
    multiselectPerGroup: true,
    onInitialDrawComplete: onInitialDrawComplete,
    cluster: {
      maxItems: props.maxStackCount ?? defMaxStackCount,
      titleTemplate: t('tasks:Timeline.ClusterItemTooltip'),
      showStipes: true,
      clusterCriteria: canCluster,
    },
    orientation: 'top',
    tooltipOnItemUpdateTime: {
      template: renderItemUpdateTimeTooltip,
    },
    template: (itemData: ITimelineTaskItem, element: HTMLElement, data) => {
      if (data.isCluster) {
        return t('tasks:Timeline.ClusterItem', { count: data.items.length });
      } else {
        return data.content;
      }
    },
    snap: snapToGrid,
    groupTemplate: renderGroupTemplate,
    onMove: (item, callback) => {
      if (item) {
        if (!item.end || item.start < item.end) {
          callback(item);
          onMoveEnd(item as ITimelineTaskItem);
        } else {
          callback(null);
        }
      }
    },
    onMoving: (item, callback) => {
      if (item) {
        callback(item);
        if (!isDragging) {
          setIsDragging(true);
          hideTaskDetailsCallout();
        }
      }
    },
    onAdd: (item, callback) => {
      if (props.onAddTask) {
        const group = groups.find((g) => g.id === item.group);
        const tag = group?.data as Tag | undefined;
        props.onAddTask(item.start as Date, tag?.tagId);
      }
      callback(null);
    },
  };

  //
  // Main render
  //
  if (props.isLoading || !options) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (taskData.length === 0) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('translation:General.Notifications.NoItemsFilter')}</Text>
      </Stack>
    );
  }

  return (
    <Stack id="timeline" verticalFill>
      <Timeline ref={timelineRef} options={options} />
      {showTaskDetails && itemEvent?.target && (
        <Callout
          hidden={false}
          styles={timelineCalloutStyleTaskDetails}
          gapSpace={-5}
          target={itemEvent.target as Target}
          beakWidth={10}
          onDismiss={() => hideTaskDetailsCallout()}
          dismissOnTargetClick={true}
        >
          {getItemHoverCard(showTaskDetails)}
        </Callout>
      )}
      {dragItemFirst && dragItemLast && mouseEvent && isDragging && (
        <Callout
          hidden={false}
          styles={timelineCalloutStyleItemDrag}
          gapSpace={0}
          target={getItemDragTarget()}
          beakWidth={0}
          onDismiss={() => hideItemDragCallout()}
          dismissOnTargetClick={true}
        >
          {getItemDragHoverCard(dragItemFirst, dragItemLast)}
        </Callout>
      )}
      <DialogYesNo
        hidden={!showConfirmMoveDialog}
        title={selectedTasks.length > 1 ? t('tasks:Timeline.ConfirmMoveTitle') : t('tasks:Timeline.ConfirmMoveTitle1')}
        subText={
          selectedTasks.length > 1
            ? t('tasks:Timeline.ConfirmMoveSubText', { count: selectedTasks.length })
            : t('tasks:Timeline.ConfirmMoveSubText1')
        }
        onYes={moveTasks}
        onNo={() => {
          setShowConfirmMoveDialog(false);
          setTasksToMove([]);
          calcDataTasks(props.tasks, props.tags);
        }}
      />
    </Stack>
  );
});

export default TimelineChartTask;
