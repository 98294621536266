import { Fragment, useState, useContext } from 'react';
import { Panel, IconButton, Link, Text, PanelType, DefaultButton, Separator , Stack } from '@fluentui/react';

import { navBarItemStyles } from './NavBarStyles';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import Config from 'services/Config/configService';
import {
  globalLayerRightSideBarProps,
  globalStackStylesHeight100,
  globalStackTokensGapLarge,
  globalStackTokensGapSmall,
  helpIcon,
} from 'globalStyles';
import {
  globalAppName,
  globalHelpLink,
  globalPrivacyURL,
  globalSupportEmail,
  globalSupportURL,
  globalTermsURL,
} from 'globalConstants';
import { navigateToExternalUrl } from 'utils/url';
import AppContext from 'App/AppContext';
import {
  freshdeskWidgetClose,
  freshdeskWidgetOpen,
  freshdeskWidgetSetContactInfo,
  freshdeskWidgetSetTranslations,
  getSubscriptionTypeText,
} from 'globalFunctions';
import { useHistory } from 'react-router-dom';
import logger, { globalLog } from 'services/Logging/logService';
import GlobalLogModal from './GlobalLogModal';

interface IHelpNavItemProps {
  togglePanel: () => void;
  isOpen: boolean;
}

const HelpNavItem = (props: IHelpNavItemProps) => {
  const { t } = useTranslation(['translation', 'adminSubscription']);
  const [showGlobalLog, setShowGlobalLog] = useState<boolean>(false);
  const [globalLogUploaded, setGlobalLogUploaded] = useState<boolean>(false);
  const appContext = useContext(AppContext);
  const history = useHistory();

  const openTicket = () => {
    if (props.isOpen) {
      freshdeskWidgetOpen();
    } else {
      freshdeskWidgetClose();
    }

    freshdeskWidgetSetContactInfo(appContext.user.name, appContext.user.email);
    freshdeskWidgetSetTranslations();
  };

  const openTrialWelcome = () => {
    const url = '/dashboard/welcome';
    history.push(url);
    props.togglePanel();
  };

  const onRenderFooter = () => {
    return (
      <Stack>
        <Stack.Item>
          <Link onClick={() => navigateToExternalUrl(globalPrivacyURL, '', '', true)}>
            {t('translation:Help.PrivacyLink')}
          </Link>
        </Stack.Item>
        <Stack.Item>
          <Link onClick={() => navigateToExternalUrl(globalTermsURL, '', '', true)}>
            {t('translation:Help.TermsOfServiceLink')}
          </Link>
        </Stack.Item>
        <Stack.Item>
          <Link onClick={() => navigateToExternalUrl(globalSupportURL, '', '', true)}>
            {t('translation:Help.Support')}
          </Link>
        </Stack.Item>
      </Stack>
    );
  };

  const getSubscriptionText = (): string => {
    if (!appContext.user.login.tenantLicensed) {
      return t('translation:General.Words.None');
    }
    if (appContext.user.login.isInTrial()) {
      return t('translation:General.Words.Trial');
    } else {
      return getSubscriptionTypeText(appContext.user.login.subscriptionType, t as TFunction<string[]>);
    }
  };

  const uploadGlobalLog = async () => {
    try {
      await logger.upload(appContext);
      appContext.showNotification(t('translation:Help.LogUploaded'));
      setGlobalLogUploaded(true);
    } catch (err) {
      appContext.setError(err);
    }
  };

  //
  // Main render
  //
  return (
    <Fragment>
      <Stack.Item>
        <IconButton styles={navBarItemStyles} iconProps={helpIcon} onClick={() => props.togglePanel()}></IconButton>
      </Stack.Item>
      <Panel
        isLightDismiss
        headerText={t('translation:Help.Title')}
        isBlocking={false}
        isOpen={props.isOpen}
        layerProps={globalLayerRightSideBarProps}
        onDismiss={() => props.togglePanel()}
        isFooterAtBottom={true}
        onRenderFooterContent={onRenderFooter}
        type={!appContext.isMobileView ? PanelType.custom : PanelType.smallFixedFar}
        customWidth={'800px'}
      >
        <Stack horizontal tokens={globalStackTokensGapSmall} styles={globalStackStylesHeight100}>
          <Stack.Item>
            <Stack
              verticalFill
              styles={{ root: { paddingTop: 30, paddingBottom: 10, maxWidth: 300 } }}
              tokens={globalStackTokensGapLarge}
            >
              <Stack.Item>
                <Text block>
                  {t('translation:App.Title', { appName: globalAppName }) + ' ' + Config.getVersion()}
                </Text>
                <Text block>
                  {t('translation:App.SubscriptionType', { type: getSubscriptionText() })}
                </Text>
              </Stack.Item>
              <Stack.Item>
                <Text>{t('translation:Help.CreateTicket')}</Text>&nbsp;
              </Stack.Item>
              <Stack.Item>
                <Stack>
                  <Text>{t('translation:Help.HandyLinks')}</Text>
                  <li>
                    <Link onClick={() => navigateToExternalUrl(globalHelpLink, '', '', true)}>
                      {t('translation:Help.HelpLink')}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={() => openTicket()}>{t('translation:Help.TicketLink')}</Link>
                  </li>
                  <li>
                    <Link href={'mailto:' + globalSupportEmail}>{t('translation:Help.MailLink')}</Link>
                  </li>
                  {appContext.user.login.userLicensed && (
                    <li>
                      <Link onClick={() => openTrialWelcome()}>{t('translation:Help.TrialWelcomeLink')}</Link>
                    </li>
                  )}
                </Stack>
              </Stack.Item>
              {appContext.user.login.userLicensed && (
                <Stack.Item>
                  <Text block>{t('translation:Help.InfoLog')}</Text>
                  <Link styles={{ root: { paddingTop: 10 } }} onClick={() => setShowGlobalLog(true)}>
                    {t('translation:Help.ShowLog')}
                  </Link>
                  <DefaultButton
                    disabled={globalLog.messages.length === 0 || globalLogUploaded}
                    onClick={() => uploadGlobalLog()}
                  >
                    {t('translation:Help.UploadLog')}
                  </DefaultButton>
                </Stack.Item>
              )}
            </Stack>
          </Stack.Item>
          {!appContext.isMobileView && <Separator vertical />}
          {!appContext.isMobileView && (
            <Stack.Item styles={{ root: { width: 500, height: '100%' } }}>
              <iframe
                title="kb-chatbot"
                src="https://www.chatbase.co/chatbot-iframe/9DZICE0WfrwQ5RVkumtt4"
                width="100%"
                height="100%"
                allowFullScreen={true}
                referrerPolicy="no-referrer"
                style={{ border: 'none' }}
              ></iframe>
            </Stack.Item>
          )}
        </Stack>
        <GlobalLogModal isOpen={showGlobalLog} onClose={() => setShowGlobalLog(false)} />
      </Panel>
    </Fragment>
  );
};

export default HelpNavItem;
