import { globalRegExGetAlphaNumericPart, globalRegExGetNumericPart, globalRegExValidateEmail } from 'globalConstants';
import LocaleCurrency from 'locale-currency';

export function startsWithNumber(str: string) {
  return /^\d/.test(str);
}

export function isValidEmail(str: string): boolean {
  const result = globalRegExValidateEmail.test(str);

  return result;
}

export function extractNumber(str: string): number {
  let ca1 = str.replace(globalRegExGetAlphaNumericPart, '');
  if (ca1.split('.').length > 2) {
    ca1 = ca1.replaceAll('.', '');
  }
  if (ca1.startsWith('.')) ca1 = ca1.substring(1);
  if (ca1.endsWith('.')) ca1 = ca1.slice(0, -1);

  return Number.parseFloat(ca1);
}

export function extractAlpha(str: string): string {
  return str.replace(globalRegExGetNumericPart, '');
}

export function truncate(str: string | undefined, maxlen: number): string {
  if (str) {
    return str.length > maxlen ? str.substring(0, maxlen) : str;
  } else {
    return '';
  }
}

export function overflow(str: string | undefined, len: number): string {
  if (str && len > 4) {
    return str.length > len ? str.substring(0, len - 3) + '...' : str;
  } else {
    return str ? str : '';
  }
}

export function overflowStart(str: string | undefined, len: number): string {
  if (str && len > 4) {
    return str.length > len ? '...' + str.substring(str.length - len - 3) : str;
  } else {
    return str ? str : '';
  }
}

export function isEmpty(str: string | undefined | null): boolean {
  if (!str) return true;
  if (str.trim() === '') return true;

  return false;
}

export function toBool(str: string | undefined): boolean {
  if (!str) {
    return false;
  }

  return str.toLowerCase() === 'true' ? true : false;
}

export const toNameInitial = (name: string): string => {
  let initials: string = '';
  if (name) {
    const nameParts = name.split(' ');
    nameParts.forEach((item: string) => {
      if (item.length > 0) {
        initials += item[0].toUpperCase();
      }
    });
  }

  return initials;
};

const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

export const stripFileExtension = (file: string): string => {
  return file.replace(/\.[^/.]+$/, '');
};

export const bytesToSize = (bytes: number): string => {
  if (bytes === 0) return '0 Byte';
  const i = Math.round(Math.floor(Math.log(bytes) / Math.log(1024)));
  if (i === 0) return bytes + ' ' + sizes[i];

  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
};

export const matchWildcard = (str: string, rule: string) => {
  const escapeRegex = (str: string) => str.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');

  return new RegExp('^' + rule.split('*').map(escapeRegex).join('.*') + '$').test(str);
};

export const toCurrency = (content: number): string => {
  const locale = 'NL';
  const currency = LocaleCurrency.getCurrency(locale);
  const formatter = new Intl.NumberFormat(locale, { style: 'currency', currency: currency });

  return formatter.format(content);
};

export const toBlob = (b64Data: string | undefined, contentType: string = ''): Blob | undefined => {
  if (!b64Data) return undefined;

  const sliceSize = 512;
  const byteCharacters = atob(b64Data);
  const byteArrays: Uint8Array[] = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });

  return blob;
};

export const getRandomId = (): string => {
  return Math.random().toString(36).substring(7);
};

export const capitalizeFirstLetter = (text: string | undefined): string | undefined => {
  if (!text) return undefined;

  return text.slice(0, 1).toUpperCase() + text.slice(1);
};

export const escapeRegExp = (text: string): string => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const highlightText = (
  source: string | undefined,
  highlight: string | undefined,
  maxLen: number,
): string | undefined => {
  if (!source || !highlight) return source; // If no highlight string is provided, return the source as is.

  //replace virtual tags that SharePoint might insert in the search results
  source = source.replaceAll('<c0>', ''); //means start highlight
  source = source.replaceAll('</c0>', ''); //means end highlight
  source = source.replaceAll('<ddd/>', '...'); //means ...

  const spanStyle =
    'style="border-radius: 3px; padding-left: 2px; padding-right: 2px; color: black; background-color: yellow;"';
  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi'); // Create a case-insensitive regex to find the highlight string.
  const highlighted = source.replace(regex, `<span ${spanStyle}>$1</span>`); // Replace with span tag.

  // Find the position of the first highlighted part
  const firstHighlightIndex = highlighted.indexOf('<span');
  if (firstHighlightIndex === -1) {
    let truncatedNoHighlight = highlighted.substring(0, maxLen);
    if (truncatedNoHighlight.length > maxLen && !truncatedNoHighlight.endsWith('...')) {
      truncatedNoHighlight += '...';
    }

    //when nothing is highlighted, return now with truncated source for performance
    return `<div style="font-size: 12px;">${truncatedNoHighlight}</div>`;
  }

  // Ensure at least 5 words before the first highlighted part
  const wordsBeforeHighlight = source.substring(0, firstHighlightIndex).split(' ').slice(-5).join(' ');
  const startIndex = source.indexOf(wordsBeforeHighlight);

  let truncated = highlighted.substring(startIndex, firstHighlightIndex + spanStyle.length + maxLen);

  // Ensure we do not cut off in the middle of a <span> tag
  const openSpanIndex = truncated.lastIndexOf('<span ');
  const closeSpanIndex = truncated.lastIndexOf('</span>');

  if (openSpanIndex > closeSpanIndex) {
    // If there's an unclosed <span> tag, extend the truncation to include the closing tag
    const endSpanIndex = highlighted.indexOf('</span>', openSpanIndex);
    if (endSpanIndex !== -1) {
      truncated = highlighted.substring(startIndex, endSpanIndex + 7); // 7 is the length of '</span>'
    } else {
      //error in highlighting: cannot find closing span tag
      return source;
    }
  }

  //Ensure we do not cut off in the middle of a starting <span> tag
  if (truncated.endsWith('<span')) {
    truncated = truncated.substring(0, -5);
  } else if (truncated.endsWith('<spa')) {
    truncated = truncated.substring(0, -4);
  } else if (truncated.endsWith('<sp')) {
    truncated = truncated.substring(0, -3);
  } else if (truncated.endsWith('<s')) {
    truncated = truncated.substring(0, -2);
  } else if (truncated.endsWith('<')) {
    truncated = truncated.substring(0, -1);
  }

  if (truncated.length > maxLen && !truncated.endsWith('...')) {
    truncated += '...';
  }

  return `<div style="font-size: 12px;">${truncated}</div>`; // Wrap the truncated output in a div with font size 12.
};
