import React, { useContext, useEffect, useRef, useState } from 'react';
import { Stack, Spinner, SpinnerSize, Separator, Text } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import { globalStackStylesHeight100, globalStackTokensGapMedium, globalStackTokensGapSmall } from 'globalStyles';
import { apiRequest } from 'services/Auth/authConfig';
import { addDateDays } from 'utils/datetime';
import { TenantOnboarding } from 'models/tenant';
import Task from 'models/tasks/task';
import {
  apiCopilotCreateOnboardingProject,
  apiCopilotCreateOnboardingProjectDocument,
} from 'services/Api/copilotService';
import { unified } from 'unified';
import markdown from 'remark-parse';
import docx from 'remark-docx';
import { saveAs } from 'file-saver';
import { paragraphStyles } from 'components/Documents/documentStyles';
import { CopilotDocument } from 'components/Copilot/CopilotDocument';
import TimelineChartTask, { TimelineTaskGroupMode } from 'components/Charts/Timeline/TimelineChartTask';
import { newGuid } from 'utils/guid';
import { ITimelineChart } from 'components/Charts/Timeline/TimelineChart';
import Tag from 'models/tag';
import ScrollableStackItem from 'components/Utils/ScrollableStackItem';
import GenericModal from 'components/Dialogs/GenericModal';
import { Info } from 'components/Notification/Info';
import { CopilotButton } from 'components/Copilot/CopilotButtons';
import { TFunction } from 'i18next';
import { ReceiveStreamingUpdates, startCopilotHubConnection } from 'services/SignalR/CopilotHub';
import { stopHubConnection } from 'services/SignalR/GeneralHub';

interface ITrialWelcomeTimelineProps {
  isOpen: boolean;
  onClose: () => void;
  onboardingState: TenantOnboarding;
  onUpdateProjectDocument: (projectDocument: string | undefined) => void;
}

export const downloadProjectDocument = async (projectDocument: string, t: TFunction<string[]>) => {
  if (!projectDocument) return;

  const processor = unified()
    .use(markdown)
    .use(docx, { output: 'blob', styles: { paragraphStyles: paragraphStyles } });
  const text = projectDocument;

  const doc = await processor.process(text);
  const blob = (await doc.result) as Blob;

  saveAs(blob, t('dashboard:TrialWelcome.Dialogs.Timeline.Filename'));
};

const TrialWelcomeTimeline: React.FunctionComponent<ITrialWelcomeTimelineProps> = (
  props: ITrialWelcomeTimelineProps,
) => {
  const { t } = useTranslation(['translation', 'dashboard']);
  const appContext = useContext(AppContext);
  const timelineRef = useRef<ITimelineChart | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDocumentCreating, setIsDocumentCreating] = useState<boolean>(false);
  const [projectTasks, setProjectTasks] = useState<Task[]>([]);
  const [projectTags, setProjectTags] = useState<Tag[]>([]);
  const [projectDocument, setProjectDocument] = useState<string | undefined>(undefined);
  const [newCompletionPart, setNewCompletionPart] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (props.isOpen) {
      setProjectDocument(undefined);
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const loadData = async () => {
    if (isLoading || !props.isOpen) return;
    try {
      setIsLoading(true);
      setIsDocumentCreating(false);
      setProjectDocument(undefined);
      setProjectTasks([]);

      const newOnboardingSteps = props.onboardingState.onboardingSteps.clone();
      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const result = await apiCopilotCreateOnboardingProject(
        newOnboardingSteps,
        accessToken,
        appContext.globalDataCache,
      );
      if (result?.tasks) {
        result.tasks.forEach((task) => {
          task.id = newGuid();
        });
        setProjectTasks(result.tasks);
      } else {
        setProjectTasks([]);
      }
      if (result?.tags) {
        setProjectTags(result.tags);
      } else {
        setProjectTags([]);
      }

      onCreateProjectDocument();
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onInitialDrawComplete = () => {
    //show the first 14 days of the project in the timeline component
    const start = props.onboardingState.onboardingSteps.startDate ?? new Date();
    timelineRef.current?.setWindow(addDateDays(start, -4), addDateDays(start, 24));
  };

  const onCreateProjectDocument = async () => {
    if (isLoading || !props.isOpen) return;
    try {
      setIsDocumentCreating(true);
      const hubConnection = await startCopilotHubConnection(appContext, ReceiveStreamingUpdates, setNewCompletionPart);
      const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
      const result = await apiCopilotCreateOnboardingProjectDocument(
        props.onboardingState.onboardingSteps,
        hubConnection.connectionId,
        accessToken,
        appContext.globalDataCache,
      );
      await stopHubConnection(hubConnection?.connection);
      const doc = result?.projectDocument;
      setProjectDocument(doc);
      props.onUpdateProjectDocument(doc);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsDocumentCreating(false);
    }
  };

  const showProjectDocument = (): boolean => {
    return isDocumentCreating || projectDocument !== undefined;
  };

  //
  // Main render
  //
  if (!props.isOpen) return null;

  return (
    <GenericModal
      title={t('dashboard:TrialWelcome.Dialogs.Timeline.Title')}
      isOpen={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
      width={'93vw'}
      maxWidth={'93vw'}
      minHeight={650}
      height={'95vh'}
      maxHeight={'95vh'}
      transition={'all 0.6s ease'}
      isBlocking={true}
      hideFooter={true}
    >
      <Stack verticalFill>
        <Separator />
        <ScrollableStackItem>
          <Stack horizontal tokens={globalStackTokensGapMedium} styles={globalStackStylesHeight100}>
            <Stack.Item
              styles={{
                root: { width: '50%', transition: 'all 0.6s ease' },
              }}
            >
              <Stack verticalFill>
                <Stack.Item>
                  <Stack horizontal tokens={globalStackTokensGapMedium}>
                    <Stack.Item>
                      <Text>{t('dashboard:TrialWelcome.Dialogs.Timeline.Description')}</Text>
                    </Stack.Item>
                    <Stack.Item>
                      <Info message={t('dashboard:TrialWelcome.Dialogs.Timeline.TimelineInteractionInfo')}></Info>
                    </Stack.Item>
                  </Stack>
                </Stack.Item>
                {isLoading && (
                  <Stack verticalFill horizontalAlign="center" verticalAlign="center">
                    <Spinner size={SpinnerSize.large} />
                  </Stack>
                )}
                {!isLoading && (
                  <Stack.Item id="timeline-container-goals" grow styles={{ root: { paddingTop: 27 } }}>
                    <TimelineChartTask
                      ref={timelineRef}
                      tasks={projectTasks}
                      tags={projectTags}
                      defaultGroupName={t('dashboard:TrialWelcome.Goals.TimelineDefaultGroup')}
                      groupMode={TimelineTaskGroupMode.Tag}
                      height={`${
                        (document.getElementById('timeline-container-goals')?.clientHeight ?? 300) -
                        (showProjectDocument() ? 35 : 0)
                      }px`}
                      onInitialDrawComplete={onInitialDrawComplete}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
            <Stack.Item
              styles={{
                root: { width: '50%', transition: 'all 0.6s ease' },
              }}
            >
              <Stack verticalFill tokens={globalStackTokensGapSmall}>
                <Stack.Item>
                  <CopilotButton
                    text={
                      isDocumentCreating || isLoading
                        ? t('dashboard:TrialWelcome.Dialogs.Timeline.CreateProjectPlan')
                        : t('dashboard:TrialWelcome.Dialogs.Timeline.DownloadProjectPlan')
                    }
                    disabled={isDocumentCreating}
                    showSpinner={isDocumentCreating}
                    onClick={() => {
                      if (projectDocument) {
                        downloadProjectDocument(projectDocument, t);
                      }
                    }}
                    tooltip=""
                  />
                </Stack.Item>
                <Stack.Item grow>
                  <CopilotDocument
                    document={projectDocument}
                    active={isDocumentCreating}
                    newCompletionPart={newCompletionPart}
                  />
                </Stack.Item>
              </Stack>
            </Stack.Item>
          </Stack>
        </ScrollableStackItem>
      </Stack>
    </GenericModal>
  );
};

export default TrialWelcomeTimeline;
