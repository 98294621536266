import { ActionButton, DefaultButton, Stack, TooltipHost, Image, Spinner, SpinnerSize, Text } from '@fluentui/react';
import {
  globalStackTokensGapExtraSmall,
  globalStackTokensGapSmall,
  globalTextStylesBoldBlack,
  globalTextStylesBoldWhite,
  imagePropsCopilotIcon,
  imagePropsCopilotImageButton,
} from 'globalStyles';

interface ICopilotButtonProps {
  onClick: () => void;
  disabled?: boolean;
  text?: string;
  tooltip?: string;
  showSpinner?: boolean;
  isPrimary?: boolean;
}

export const CopilotButton = (props: ICopilotButtonProps) => {
  //
  // Main render
  //
  return (
    <TooltipHost content={props.tooltip}>
      <DefaultButton
        primary={props.isPrimary}
        disabled={props.disabled || props.showSpinner}
        onClick={() => props.onClick()}
      >
        <Stack horizontal tokens={globalStackTokensGapSmall} verticalAlign="center">
          <Stack.Item>
            <Image {...imagePropsCopilotImageButton} />
          </Stack.Item>
          <Stack.Item>
            <Text styles={props.isPrimary ? globalTextStylesBoldWhite : globalTextStylesBoldBlack}>{props.text}</Text>
          </Stack.Item>
          {props.showSpinner && (
            <Stack.Item>
              <Spinner size={SpinnerSize.small} />
            </Stack.Item>
          )}
        </Stack>
      </DefaultButton>
    </TooltipHost>
  );
};

export const CopilotActionButton = (props: ICopilotButtonProps) => {
  //
  // Main render
  //
  return (
    <TooltipHost content={props.tooltip}>
      <Stack horizontal tokens={globalStackTokensGapExtraSmall} verticalAlign="center">
        <Stack.Item>
          <ActionButton
            styles={{ icon: { height: 20 } }}
            text={props.text}
            iconProps={{ imageProps: imagePropsCopilotIcon }}
            onClick={() => props.onClick()}
            disabled={props.disabled || props.showSpinner}
          />
        </Stack.Item>
        {props.showSpinner && (
          <Stack.Item>
            <Spinner size={SpinnerSize.small} />
          </Stack.Item>
        )}
      </Stack>
    </TooltipHost>
  );
};
