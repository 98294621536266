import { useContext, useEffect, useState } from 'react';
import {
  Stack,
  Text,
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  Label,
  SelectionMode,
  DetailsListLayoutMode,
  IColumn,
  IconButton,
  StackItem,
  FontIcon,
  ShimmeredDetailsList,
  ScrollToMode,
} from '@fluentui/react';
import Task, { TaskTypes } from 'models/tasks/task';
import {
  deleteIcon,
  globalTextStylesMarginTopLabelPanel,
  globalTextStylesBold,
  globalStackTokensGapSmall,
  openExtIcon,
  switchIcon,
} from 'globalStyles';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import AppContext from 'App/AppContext';
import Entity, { EntityTypes } from 'models/entity';
import Control from 'models/control';
import { apiGetControls } from 'services/Api/controlService';
import { apiRequest } from 'services/Auth/authConfig';
import Theme from 'models/theme';
import EntityPicker from 'components/Pickers/EntityPicker';
import { apiGetThemes } from 'services/Api/themeService';
import { getEntity, getEntityName, onRenderDetailsHeaderNoPaddingTopGlobal } from 'globalFunctions';
import { getEntityUrl, navigateToExternalUrl } from 'utils/url';
import Objective from 'models/objective/objective';
import { apiGetObjectives } from 'services/Api/objectiveService';
import { apiGetProcesses } from 'services/Api/processService';
import Process from 'models/process/process';
import { apiGetSingleTask, apiGetTaskContext, apiGetTasks } from 'services/Api/taskService';
import { useHistory } from 'react-router-dom';
import { hasUserDataPermission } from 'services/Auth/featurePermissions';
import { TaskRelationship, TaskTask } from 'models/tasks/taskTask';
import Risk from 'models/risk';
import { apiGetRisks } from 'services/Api/riskService';
import Asset from 'models/asset/asset';
import { apiGetAssets } from 'services/Api/assetService';
import { sortOnEntity, sortOnString } from 'utils/sorting';
import { AuthSchemaLineOperation } from 'models/auth/authSchemaLine';
import { canUpdateTaskField, TaskFieldTypes } from '../TaskAuthHelper';
import Risks from 'models/risks';
import Tasks from 'models/tasks/tasks';
import SingleTask from '../SingleTask';
import { toast } from 'react-toastify';
import { areDifferent } from 'utils/array';
import { TaskRelationLinkWithHoverCard } from './TaskRelationLinkWithHoverCard';
import { toLocaleDateTimeShort } from 'utils/datetime';

interface ITaskContextProps {
  orgTask: Task;
  task: Task;
  updateTask?: (task: Task) => void;
  updateTaskForForm?: (task: Task, validateForm: boolean) => void;
  onCancel?: () => boolean;
  allowEdit: boolean;
  hideHeaders?: boolean;
  navigateInPlace?: boolean;
  windowLevel?: number;
  navigateExternal?: boolean;
}

export const TaskContext = (props: ITaskContextProps) => {
  const history = useHistory();
  const { t } = useTranslation(['task', 'translation', 'asset', 'risk', 'control', 'theme', 'objective', 'process']);
  const appContext = useContext(AppContext);
  const [entities, setEntities] = useState<Entity[]>([]);
  const [allEntities, setAllEntities] = useState<Entity[]>([]);
  const [isLoadingTaskRelations, setIsLoadingTaskRelations] = useState<boolean>(false);
  const [taskRelations, setTaskRelations] = useState<TaskTask[]>([]);
  const [isLoadingPicker, setIsLoadingPicker] = useState<boolean>(false);
  const [showRelatedTask, setShowRelatedTask] = useState<Task | undefined>(undefined);
  const [isBusyAddingRemoving, setIsBusyAddingRemoving] = useState<number>(0);
  const [sortedTaskContext, setsortedTaskContext] = useState<(Entity | TaskTask)[]>([]);
  const [newlyAddedItem, setNewlyAddedItem] = useState<Entity | TaskTask | undefined>(undefined);
  const [currentTaskId, setCurrentTaskId] = useState<number>(0);
  const [canEdit] = useState<boolean>(
    props.allowEdit && hasUserDataPermission(appContext, [props.task.authSchemaId], AuthSchemaLineOperation.Update),
  );
  const [canUpdate] = useState<boolean>(
    props.allowEdit && canUpdateTaskField(props.task, canEdit, TaskFieldTypes.Context, appContext),
  );
  const [canAddTasks] = useState<boolean>(
    props.task.taskType !== TaskTypes.Template &&
      props.task.taskType !== TaskTypes.EventTemplate &&
      !(props.windowLevel && props.windowLevel >= 1),
  );

  useEffect(() => {
    const sortedTaskRelations = [...taskRelations].sort((a, b) => sortOnString(a.task()?.name, b.task()?.name));
    const sortedEntities = [...entities].sort((a, b) => sortOnEntity(a, b)).filter((e) => !e.isDeleted);
    const newSortedTaskContext = [...sortedTaskRelations, ...sortedEntities];

    setsortedTaskContext(newSortedTaskContext);
  }, [taskRelations, entities]);

  useEffect(() => {
    if (props.task.taskId !== currentTaskId) {
      setCurrentTaskId(props.task.taskId);

      const newEntities: Entity[] = props.task.RelatedEntities ? props.task.RelatedEntities.map((e) => e.clone()) : [];

      if (props.task.taskId !== -1) {
        loadData(newEntities);
        // This is an existing task, so the data has already been loaded.
      } else {
        //this is a new task
        loadDataFromTemplate(newEntities);
        const newRelations: TaskTask[] = props.task.relations ? props.task.relations.map((r) => r.clone()) : [];
        setTaskRelations(newRelations);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.task]);

  useEffect(() => {
    // If the task was just updated, and the relations no longer match the props, update the state.
    if (
      props.task.relations &&
      props.task.relations.length > 0 &&
      props.task.relations.length !== taskRelations.length
    ) {
      const newRelations: TaskTask[] = props.task.relations.map((r) => r.clone());

      if (newRelations.length === taskRelations.length + 1) {
        const newestRelations = newRelations.filter(
          (nr) => !taskRelations.some((r) => r.taskIdFrom === nr.taskIdFrom && r.taskIdTo === nr.taskIdTo),
        );
        if (newestRelations.length === 1) {
          setNewlyAddedItem(newestRelations[0]);
        }
      }

      setTaskRelations(newRelations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.task.relations]);

  const loadDataFromTemplate = async (existingEntities: Entity[]) => {
    try {
      const newEntities: Entity[] = [...existingEntities];

      if (props.task.templateId) {
        //Load context from the template
        setIsLoadingTaskRelations(true);
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        const context = await apiGetTaskContext(props.task.templateId, accessToken, appContext.globalDataCache);

        //create entities from the context
        const newRelatedEntities: Entity[] = [];
        newRelatedEntities.push(...context.assets.map((a) => getEntity(a)));
        newRelatedEntities.push(...context.risks.map((a) => getEntity(a)));
        newRelatedEntities.push(...context.controls.map((a) => getEntity(a)));
        newRelatedEntities.push(...context.themes.map((a) => getEntity(a)));
        newRelatedEntities.push(...context.processes.map((a) => getEntity(a)));
        newRelatedEntities.push(...context.objectives.map((a) => getEntity(a)));

        //add new entities to the task. There could already be entities in the task from:
        // - creation of a new task from a specific context e.g. Asset
        // - user added context
        for (const newEntity of newRelatedEntities) {
          if (
            !existingEntities.some(
              (e) => e.typeOfEntity === newEntity.typeOfEntity && e.entityId === newEntity.entityId,
            )
          ) {
            newEntities.push(newEntity);
          }
        }
      }

      //make sure that all related entities are set into the arrays with Ids and updated on the task
      newEntities.sort((a, b) => sortOnEntity(a, b));
      setEntities(newEntities);
      addToTask(newEntities);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoadingTaskRelations(false);
    }
  };

  const addToExistingEntities = (
    existingEntities: Entity[],
    entitiesToAdd: Control[] | Theme[] | Risk[] | Process[] | Objective[] | Asset[],
  ) => {
    entitiesToAdd.forEach((entity) => {
      const newEntity = getEntity(entity);
      const matchingEntity = existingEntities.some(
        (e) => e.typeOfEntity === newEntity.typeOfEntity && e.entityId === newEntity.entityId,
      );
      if (!matchingEntity) existingEntities.push(newEntity);
    });
  };

  const loadData = async (existingEntities: Entity[]) => {
    if (isLoadingTaskRelations || !props.task) return;

    try {
      setIsLoadingTaskRelations(true);

      //get context from the back-end
      //add them to the existing context in the task (where they do not exist yet)
      //push everything back to the task
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const result = await apiGetTaskContext(props.task.taskId, accessToken, appContext.globalDataCache);

      addToExistingEntities(existingEntities, result.controls);
      addToExistingEntities(existingEntities, result.themes);
      addToExistingEntities(existingEntities, result.risks);
      addToExistingEntities(existingEntities, result.assets);
      addToExistingEntities(existingEntities, result.processes);
      addToExistingEntities(existingEntities, result.objectives);

      existingEntities.sort((a, b) => sortOnEntity(a, b));
      addToTask(existingEntities);

      setEntities(existingEntities);
      setTaskRelations(result.relations);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoadingTaskRelations(false);
    }
  };

  const pushFulfilledData = (
    localeFilename: string,
    _allEntities: Entity[],
    _dataToPush: PromiseSettledResult<
      Control[] | Process[] | Risks | Theme[] | Process[] | Objective[] | Asset[] | Tasks
    >,
  ): string => {
    let errorMessage = '';
    if (_dataToPush.status === 'fulfilled') {
      _dataToPush.value.forEach((_entity: Control | Process | Risk | Theme | Process | Objective | Asset | Task) => {
        _allEntities.push(getEntity(_entity));
      });
    } else {
      errorMessage = `${t(localeFilename + ':Title')} : ${_dataToPush.reason}`;
    }

    return errorMessage;
  };

  const loadDataForPicker = async () => {
    if (isLoadingPicker || !canUpdate) return;

    try {
      setIsLoadingPicker(true);
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const _allControlsPromise = apiGetControls(accessToken, appContext.globalDataCache);
      const _allRisksPromise = apiGetRisks(accessToken, appContext.globalDataCache);
      const _allThemesPromise = apiGetThemes(accessToken, appContext.globalDataCache);
      const _allProcessesPromise = apiGetProcesses(accessToken, appContext.globalDataCache);
      const _allObjectivesPromise = apiGetObjectives(accessToken, appContext.globalDataCache);
      const _allAssetsPromise = apiGetAssets(accessToken, appContext.globalDataCache);

      // Exclude tasks if we're dealing with any type of template.
      const _allTasksPromise = canAddTasks
        ? apiGetTasks(true, undefined, undefined, accessToken, appContext.globalDataCache)
        : Promise.resolve(new Tasks());

      const [
        _allControlsData,
        _allProcessesData,
        _allObjectivesData,
        _allThemesData,
        _allRisksData,
        _allAssetsData,
        _allTasksData,
      ] = await Promise.allSettled([
        _allControlsPromise,
        _allProcessesPromise,
        _allObjectivesPromise,
        _allThemesPromise,
        _allRisksPromise,
        _allAssetsPromise,
        _allTasksPromise,
      ]);

      const _allEntities: Entity[] = [];
      let errorMessage = '';

      errorMessage += pushFulfilledData('control', _allEntities, _allControlsData);
      errorMessage += pushFulfilledData('theme', _allEntities, _allThemesData);
      errorMessage += pushFulfilledData('objective', _allEntities, _allObjectivesData);
      errorMessage += pushFulfilledData('process', _allEntities, _allProcessesData);
      errorMessage += pushFulfilledData('risk', _allEntities, _allRisksData);
      errorMessage += pushFulfilledData('asset', _allEntities, _allAssetsData);
      errorMessage += pushFulfilledData('task', _allEntities, _allTasksData);

      if (errorMessage) appContext.setError(errorMessage);

      setAllEntities(_allEntities);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoadingPicker(false);
    }
  };

  const navigateToEntity = (entity: Entity) => {
    const url = getEntityUrl(entity);
    if (props.navigateInPlace === true) {
      history.push(url);
    } else {
      navigateToExternalUrl(url, appContext.user.tenant.azureTenantId, appContext.user.login.tenantId);
    }
  };

  const loadRelatedTask = async (taskTask: TaskTask | undefined) => {
    try {
      const task = taskTask?.task();
      if (!task) return;

      // If the task relation needs to be saved, show a warning toast and don't navigate.
      //
      if (
        areDifferent(props.task.relations, props.orgTask.relations, (a: TaskTask, b: TaskTask) => {
          return a.taskIdFrom === b.taskIdFrom && a.taskIdTo === b.taskIdTo;
        }) === true
      ) {
        toast.warning(t('task:Context.RelatedTaskWarning'));

        return;
      }

      // If we're one level deep, we don't want to allow task relation editing or removal as that may lead to conflicts with the previous window.
      // Instead, we allow users to navigate to another tab. There, they can edit the task relation without creating conflicts.
      // This also prevents infinite modal stacking.
      //
      if (props.windowLevel && props.windowLevel >= 1) {
        const url = `/tasks/${task.taskId}`;
        navigateToExternalUrl(url, appContext.user.tenant.azureTenantId, appContext.user.login.tenantId);

        return;
      }

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const relatedTaskCol = await apiGetSingleTask(task.taskId, false, accessToken, appContext.globalDataCache);
      if (relatedTaskCol && relatedTaskCol.tasks.length > 0) {
        setShowRelatedTask(relatedTaskCol.tasks[0]);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoadingPicker(false);
    }
  };

  //
  // Context
  //

  const getColumnsContext = () => {
    return [
      {
        key: 'type',
        name: t('task:Context.List.Type'),
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        isMultiline: true,
        onRender: (item?: Entity | TaskTask, index?: number, column?: IColumn) => {
          if (!item) return;
          if (item instanceof Entity) return <Text>{getEntityName(item, t as TFunction<string[]>)}</Text>;
          // in the case: if (instanceof TaskTask)
          else return <Text>{item.relationText(props.task.taskId, t as TFunction<string[]>)}</Text>;
        },
        onchange: (window: Window, ev: Event) => {},
      },
      {
        key: 'switch',
        name: t('task:Context.List.Switch'),
        minWidth: canUpdate ? 50 : 1,
        maxWidth: canUpdate ? 70 : 1,
        onRender: (item?: Entity | TaskTask, index?: number, column?: IColumn) => {
          if (!canUpdate) return null;
          if (!(item instanceof TaskTask)) return null;
          if (!canAddTasks) return null;

          return (
            <IconButton
              iconProps={switchIcon}
              onClick={() => {
                if (!item) return;

                onSwitch(item);
              }}
              styles={{ root: { height: 18 } }}
            />
          );
        },
      },
      {
        key: 'name',
        name: t('task:Context.List.Name'),
        minWidth: 100,
        maxWidth: 400,
        isResizable: true,
        isMultiline: true,
        onRender: (item?: Entity | TaskTask, index?: number, column?: IColumn) => {
          if (!item) return;

          if (props.navigateExternal) {
            return (
              <Stack horizontal tokens={globalStackTokensGapSmall}>
                <StackItem>
                  <FontIcon {...openExtIcon} style={{ fontSize: 18, color: 'blue' }} />
                </StackItem>
                <Stack.Item>
                  <Text>
                    {item instanceof TaskTask ? (
                      <TaskRelationLinkWithHoverCard task={item.task() as Task}>
                        <Link
                          title={!canAddTasks ? t('task:Context.OpenInNewTab') : undefined}
                          onClick={() => loadRelatedTask(item)}
                        >
                          {item.task()?.name}
                        </Link>
                      </TaskRelationLinkWithHoverCard>
                    ) : (
                      <Link onClick={() => navigateToEntity(item)}>{item.entityName}</Link>
                    )}
                  </Text>
                </Stack.Item>
              </Stack>
            );
          } else {
            return (
              <Text>
                {item instanceof TaskTask ? (
                  <TaskRelationLinkWithHoverCard task={item.task() as Task}>
                    <Link
                      title={!canAddTasks ? t('task:Context.OpenInNewTab') : undefined}
                      onClick={() => loadRelatedTask(item)}
                    >
                      {item.task()?.name}
                    </Link>
                  </TaskRelationLinkWithHoverCard>
                ) : (
                  <Link onClick={() => navigateToEntity(item)}>{item.entityName}</Link>
                )}
              </Text>
            );
          }
        },
      },

      {
        key: 'remove',
        name: t('task:Context.List.Remove'),
        minWidth: canUpdate ? 50 : 1,
        maxWidth: canUpdate ? 70 : 1,
        onRender: (item?: Entity | TaskTask, index?: number, column?: IColumn) => {
          if (!canUpdate) return null;
          if (item instanceof TaskTask && !canAddTasks) return null;

          return (
            <IconButton
              iconProps={deleteIcon}
              onClick={() => {
                if (!item) return;

                onRemove(item);
              }}
              styles={{ root: { height: 18 } }}
            />
          );
        },
      },
    ]
      .filter((column) => {
        return !(column.key === 'switch' && (taskRelations.length === 0 || !canAddTasks));
      })
      .filter((column) => {
        return !(column.key === 'remove' && entities.length === 0 && !canAddTasks);
      });
  };

  const onRemoveTaskRelation = (taskTask: TaskTask) => {
    const otherTaskId = taskTask.task()?.taskId ?? -1;

    setIsBusyAddingRemoving(otherTaskId);
    const newTask = props.task.clone();

    const newTaskRelations = taskRelations.filter((c) => c.taskIdFrom !== otherTaskId && c.taskIdTo !== otherTaskId);
    setTaskRelations(newTaskRelations);

    if (props.updateTask) {
      newTask.relations = newTaskRelations;
      props.updateTask(newTask);
    }
    setIsBusyAddingRemoving(0);
  };

  const onRemoveEntity = (entity: Entity) => {
    setIsBusyAddingRemoving(entity.entityId);
    const newTask = props.task.clone();

    switch (entity.typeOfEntity) {
      case EntityTypes.Requirement:
      case EntityTypes.Control: {
        newTask.controlIds = newTask.controlIds?.filter((c) => c !== entity.entityId);
        break;
      }
      case EntityTypes.Process: {
        newTask.processIds = newTask.processIds?.filter((c) => c !== entity.entityId);
        break;
      }
      case EntityTypes.Objective: {
        newTask.objectiveIds = newTask.objectiveIds?.filter((c) => c !== entity.entityId);
        break;
      }
      case EntityTypes.Risk: {
        newTask.riskIds = newTask.riskIds?.filter((c) => c !== entity.entityId);
        break;
      }
      case EntityTypes.Asset: {
        newTask.assetIds = newTask.assetIds?.filter((c) => c !== entity.entityId);
        break;
      }
    }

    //signal that the form needs to be updated/validated for the removal
    if (props.updateTaskForForm) props.updateTaskForForm(newTask, true);

    //set the removed entity as deleted in the collection
    const idx = entities.findIndex((c) => c.typeOfEntity === entity.typeOfEntity && c.entityId === entity.entityId);
    if (idx >= 0) entities[idx].isDeleted = true;

    const newEntities = entities.map((e) => e.clone());
    setEntities(newEntities);

    if (props.updateTask) {
      newTask.RelatedEntities = newEntities;
      props.updateTask(newTask);
    }
  };

  const switchTask = (taskRelation: TaskTask): TaskTask => {
    const newTaskRelation: TaskTask = new TaskTask(
      taskRelation.taskIdTo,
      taskRelation.taskIdFrom,
      taskRelation.relationship,
    );
    newTaskRelation.taskFrom = taskRelation.taskTo;
    newTaskRelation.taskTo = taskRelation.taskFrom;

    return newTaskRelation;
  };

  const onSwitch = (taskRelation: TaskTask) => {
    try {
      if (isBusyAddingRemoving) return;

      const relatedTask = taskRelation.task();
      const otherTaskId = relatedTask ? relatedTask.taskId : -1;

      setIsBusyAddingRemoving(otherTaskId);

      const newTask = props.task.clone();
      const newTaskRelations = taskRelations.map((relation) =>
        relation.task()?.taskId === otherTaskId ? switchTask(taskRelation) : relation,
      );
      setTaskRelations(newTaskRelations);

      if (props.updateTask) {
        newTask.relations = newTaskRelations;
        if (props.updateTaskForForm) props.updateTaskForForm(newTask, true);
        props.updateTask(newTask);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsBusyAddingRemoving(0);
    }
  };

  const onRemove = (item: Entity | TaskTask) => {
    try {
      if (isBusyAddingRemoving) return;
      if (item instanceof TaskTask) {
        onRemoveTaskRelation(item);
      } else {
        onRemoveEntity(item);
      }
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsBusyAddingRemoving(0);
    }
  };

  const filterEntities = (all: Entity[], related: (Entity | TaskTask)[]): Entity[] => {
    return all.filter((e) => {
      const entityIsTask = e.typeOfEntity === EntityTypes.Task;

      if (entityIsTask && e.entityId === props.task.taskId) return false;
      const foundControl = related.find((r) => {
        if (r instanceof Entity) return r.entityId === e.entityId && r.typeOfEntity === e.typeOfEntity;
        // only happens when: // if (r instanceof TaskTask)
        else return entityIsTask && (r.taskIdFrom === e.entityId || r.taskIdTo === e.entityId);
      });
      if (foundControl) return false;

      return true;
    });
  };

  const addToTask = (entities: Entity[]) => {
    const newTask = props.task.clone();
    newTask.addEntities(entities);

    if (props.updateTask) {
      newTask.RelatedEntities = entities.map((e) => e.clone());
      props.updateTask(newTask);
    }
  };

  const onAdd = (entity: Entity) => {
    try {
      if (isLoadingTaskRelations || !props.task) return false;
      if (isBusyAddingRemoving) return;

      setIsBusyAddingRemoving(entity.entityId);
      const newTask = props.task.clone();

      if (entity.typeOfEntity === EntityTypes.Task) {
        // Edge case: add task relation.
        const taskRelation: TaskTask = new TaskTask(entity.entityId, props.task.taskId, TaskRelationship.FollowUp);
        taskRelation.taskFrom = entity.originalObject;
        // taskRelation.taskTo = props.task // Right now, internal TaskTask method task() relies on only one object being set.

        const newRelations = [...taskRelations, taskRelation];
        setTaskRelations(newRelations);

        if (props.updateTask) {
          newTask.relations = newRelations;
          props.updateTask(newTask);
        }
      } else {
        // Default case: entity
        newTask.addEntity(entity);
        if (props.updateTaskForForm) props.updateTaskForForm(newTask, true);

        // If the entity was previously deleted, invert deleted status.
        const matchingEntity = entities.find(
          (e) => e.entityId === entity.entityId && e.typeOfEntity === entity.typeOfEntity,
        );
        const wasDeleted = matchingEntity?.isDeleted;
        if (wasDeleted) {
          matchingEntity.isDeleted = false;
        }
        const newEntities = wasDeleted ? [...entities] : [...entities, entity];
        setEntities(newEntities);

        if (props.updateTask) {
          newTask.RelatedEntities = newEntities;
          props.updateTask(newTask);
        }
      }
      // Update newest item to allow for scrolling to the last added item.
      setNewlyAddedItem(entity);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsBusyAddingRemoving(0);
    }
  };

  const saveTask = (task: Task, isNew: boolean, isCancelled: boolean) => {
    updateTask(task);
  };

  const removeTask = (task: Task) => {
    const newRelations = taskRelations.filter((t) => t.taskIdFrom !== task.taskId && t.taskIdTo !== task.taskId);
    setTaskRelations(newRelations);
  };

  const updateTask = (task: Task) => {
    const newRelations: TaskTask[] = [];

    for (let idx = 0; idx < taskRelations.length; idx++) {
      const t = taskRelations[idx];
      if (t.taskFrom && t.taskIdFrom === task.taskId) {
        t.taskFrom = task;
      }
      if (t.taskIdTo && t.taskIdTo === task.taskId) {
        t.taskTo = task;
      }
      newRelations.push(t);
    }

    setTaskRelations(newRelations);
  };

  // By providing consistent keys, we can correctly use animations on the right items.
  const getRelationKey = (item: Entity | TaskTask, index?: number): string => {
    const fallback = index ? index.toString() : '';
    if (!item) return fallback;
    if (item instanceof Entity) return item.entityId.toString();
    if (item instanceof TaskTask) {
      const otherTask = item.task();
      if (!otherTask) return fallback;

      return otherTask.taskId.toString();
    } else return fallback;
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill styles={{ root: { position: 'relative' } }}>
      <Stack verticalFill tokens={globalStackTokensGapSmall}>
        <Stack.Item>
          <Text styles={globalTextStylesBold} variant="medium">
            {t('task:Context.Title')}
          </Text>
        </Stack.Item>
        <Stack.Item verticalFill={true} grow styles={{ root: { minHeight: '100px', position: 'relative' } }}>
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <ShimmeredDetailsList
              className="task-context-list"
              compact
              items={sortedTaskContext}
              shimmerLines={3}
              enableShimmer={isLoadingTaskRelations}
              columns={getColumnsContext()}
              isHeaderVisible={!props.hideHeaders}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.justified}
              onRenderDetailsHeader={onRenderDetailsHeaderNoPaddingTopGlobal}
              getKey={getRelationKey}
              enableUpdateAnimations={true}
              onDidUpdate={(detailListProps) => {
                if (!newlyAddedItem || !detailListProps) return;

                const newItemKey = getRelationKey(newlyAddedItem);

                const index = sortedTaskContext.findIndex((item) => {
                  const itemKey = getRelationKey(item);

                  return itemKey === newItemKey;
                });
                if (index !== -1) {
                  const measureItem = (itemIndex: number): number => {
                    const cell = document.querySelector(`.task-context-list [data-list-index="${itemIndex}"]`);

                    if (cell) {
                      const rect = cell.getBoundingClientRect();
                      const height = rect.height;

                      return height;
                    } else return 32.2; // Fallback to a default value if the cell is not found
                  };
                  detailListProps.scrollToIndex(index, measureItem, ScrollToMode.center);
                }
              }}
            />
          </ScrollablePane>
        </Stack.Item>
        {canUpdate && (
          <Stack.Item>
            <Label styles={globalTextStylesMarginTopLabelPanel}>
              {canAddTasks ? t('task:Context.AddText') : t('task:Context.AddTextNoTask')}
            </Label>
          </Stack.Item>
        )}
        {canUpdate && (
          <Stack.Item
            grow
            styles={{
              root: {
                minHeight: 200,
              },
            }}
          >
            <EntityPicker
              className="redlab-usetiful-task-context-search"
              entities={filterEntities(allEntities, sortedTaskContext)}
              addSelectedEntity={onAdd}
              loadData={loadDataForPicker}
              isLoading={isLoadingPicker}
              isOnPanel={false}
              showHeader={false}
              isBusyAdding={isBusyAddingRemoving}
              compact={true}
              extraColumns={[
                {
                  key: 'task-startdate',
                  name: 'startdate', //dummy
                  minWidth: 100,
                  onRender: (item: Entity, index: number | undefined) => {
                    if (!item?.originalObject) return null;

                    return <Text>{toLocaleDateTimeShort(item.originalObject.startDateTime)}</Text>;
                  },
                },
                {
                  key: 'task-status',
                  name: 'status', //dummy
                  minWidth: 90,
                  onRender: (item: Entity, index: number | undefined) => {
                    if (!item?.originalObject) return null;
                    const status = appContext.globalDataCache.taskStates.get(item.originalObject.taskStateId);

                    return <Text>{status ? status?.state : ''}</Text>;
                  },
                },
              ]}
            />
          </Stack.Item>
        )}
      </Stack>
      {showRelatedTask && (
        <SingleTask
          task={showRelatedTask}
          isOpen={showRelatedTask !== undefined}
          close={() => {
            setShowRelatedTask(undefined);
          }}
          onSave={saveTask}
          onRemove={removeTask}
          onUpdate={updateTask}
          windowLevel={(props.windowLevel ?? 0) + 1}
          navigateExternal={props.navigateExternal}
        />
      )}
    </Stack>
  );
};
