import { useContext, useEffect, useState } from 'react';
import {
  ContextualMenuItemType,
  IconButton,
  IContextualMenuItem,
  IContextualMenuProps,
  IStackStyles,
  Spinner,
  SpinnerSize,
  Stack,
  Text,
} from '@fluentui/react';
import {
  configIcon,
  deleteIcon,
  globalBoxBorder,
  globalBoxBorderRadius,
  globalBoxShadow,
  globalPaddingScene,
  globalStackTokensGapMedium,
  globalTextStylesBold,
  leftArrowIcon,
  newIcon,
  rightArrowIcon,
} from 'globalStyles';
import AppContext from 'App/AppContext';
import Widget, { IWidgetCustomAction } from 'models/widget';
import WidgetRenderer from './WidgetRenderer';
import Dashboard, { DashboardSharedData } from 'models/dashboard';
import { useTranslation } from 'react-i18next';
import { apiRequest } from 'services/Auth/authConfig';
import {
  apiAddWidgetToDashboard,
  apiGetDashboardWidgets,
  apiRemoveWidgetFromDashboard,
  apiUpdateWidgetOnDashboard,
} from 'services/Api/dashboardService';
import AddWidgetPanel from './AddWidgetPanel';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import EditWidgetPanel from './EditWidgetPanel';
import PowerBIReport from 'components/PowerBI/PowerBIReport';

interface IDashboardInstanceProps {
  dashboard: Dashboard;
  readonly: boolean;
  sharedData?: DashboardSharedData;
  onRefreshSharedData?: () => Promise<void>;
}

const DashboardInstance = (props: IDashboardInstanceProps) => {
  const { t } = useTranslation(['widgets', 'translation', 'dashboard']);
  const appContext = useContext(AppContext);
  const [widgets, setWidgets] = useState<Widget[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addWidget, setAddWidget] = useState<Widget | undefined>(undefined);
  const [deleteWidget, setDeleteWidget] = useState<Widget | undefined>(undefined);
  const [configureWidget, setConfigureWidget] = useState<Widget | undefined>(undefined);
  const [currentId, setCurrentId] = useState<number | undefined>(undefined);
  const maxWidgetsPerDashboards = 8;

  useEffect(() => {
    const loadData = async () => {
      try {
        if (isLoading) return;
        if (props.dashboard.dashboardId === currentId) return;
        if (props.dashboard.url) return;

        setCurrentId(props.dashboard.dashboardId);
        setIsLoading(true);

        await appContext.globalDataCache.norms.getItems();

        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        let widgets = await apiGetDashboardWidgets(
          props.dashboard.dashboardId,
          accessToken,
          appContext.globalDataCache,
        );

        widgets.sort((a, b) => a.sortOrder - b.sortOrder);
        widgets.forEach((w) => (w.dashboard = props.dashboard));
        widgets = [...props.dashboard.widgets, ...widgets]; //add widgets to a dashboard which can already contain system widgets

        setWidgets(widgets);
      } catch (err) {
        appContext.setError(err);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dashboard]);

  const getWidgetStyle = () => {
    return {
      root: {
        border: globalBoxBorder,
        borderRadius: globalBoxBorderRadius,
        boxShadow: globalBoxShadow,
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 10,
        height: 300,
        width: 300,
      },
    };
  };

  const getAddWidgetStyle = () => {
    return {
      root: {
        boxShadow: globalBoxShadow,
        color: 'lightgrey',
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 10,
        height: 300,
        width: 300,
        selectors: {
          '&:hover': {
            cursor: 'pointer',
          },
        },
      },
    };
  };

  const stackStylesWidget: IStackStyles = {
    root: {
      paddingLeft: globalPaddingScene,
      paddingRight: globalPaddingScene,
      paddingTop: globalPaddingScene,
      paddingBottom: globalPaddingScene,
    },
  };

  const moveLeft = async (id: number) => {
    const newWidgets = [...widgets];
    const idx = newWidgets.findIndex((d) => d.widgetId === id);

    if (idx > 0) {
      const widget = newWidgets[idx];
      [newWidgets[idx - 1], newWidgets[idx]] = [newWidgets[idx], newWidgets[idx - 1]];
      setWidgets(newWidgets);

      try {
        //don't wait
        widget.sortOrder--;
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        apiUpdateWidgetOnDashboard(widget, accessToken, appContext.globalDataCache);
      } catch (err) {
        appContext.setError(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const moveRight = async (id: number) => {
    const newWidgets = [...widgets];
    const idx = widgets.findIndex((d) => d.widgetId === id);
    if (idx >= 0 && idx < newWidgets.length - 1) {
      const widget = newWidgets[idx];
      [newWidgets[idx + 1], newWidgets[idx]] = [newWidgets[idx], newWidgets[idx + 1]];
      setWidgets(newWidgets);

      try {
        //don't wait
        widget.sortOrder++;
        const accessToken = await appContext.getAccessToken(apiRequest.scopes);
        apiUpdateWidgetOnDashboard(widget, accessToken, appContext.globalDataCache);
      } catch (err) {
        appContext.setError(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const canMoveLeft = (id: number): boolean => {
    const idx = getWidgetIndexFromId(id);

    if (idx > 0) {
      const prev = widgets[idx - 1];
      if (isSystem(prev.widgetId)) return false;
    }

    return idx > 0 && !isSystem(id);
  };

  const canMoveRight = (id: number): boolean => {
    const idx = getWidgetIndexFromId(id);

    return idx >= 0 && idx < widgets.length - 1 && !isSystem(id);
  };

  const getWidgetFromId = (id: number): Widget => {
    return widgets.find((d) => d.widgetId === id) || new Widget();
  };

  const getWidgetIndexFromId = (id: number): number => {
    return widgets.findIndex((d) => d.widgetId === id);
  };

  const canAddWidget = (id: number): boolean => {
    if (widgets.length < maxWidgetsPerDashboards) {
      return !isSystem(id) || id === -1; //add is enabled on the last system widget
    }

    return false;
  };

  const isSystem = (id: number): boolean => {
    return id < 0;
  };

  const getMenuItems = (widget: Widget): IContextualMenuProps => {
    let menuProps: IContextualMenuProps = { items: [] };
    const widgetId = widget.widgetId;

    if (!isSystem(widget.widgetId) && !props.readonly) {
      menuProps = {
        items: [
          {
            key: 'add',
            data: widgetId,
            disabled: !canAddWidget(widgetId),
            iconProps: newIcon,
            text: t('dashboard:WidgetMenu.New'),
            onClick: () => setAddWidget(getWidgetFromId(widgetId)),
          },
          { key: 'd1', itemType: ContextualMenuItemType.Divider },
          {
            key: 'moveleft',
            data: widgetId,
            disabled: !canMoveLeft(widgetId),
            iconProps: leftArrowIcon,
            text: t('dashboard:WidgetMenu.MoveLeft'),
            onClick: () => moveLeft(widgetId),
          },
          {
            key: 'moveright',
            data: widgetId,
            disabled: !canMoveRight(widgetId),
            iconProps: rightArrowIcon,
            text: t('dashboard:WidgetMenu.MoveRight'),
            onClick: () => moveRight(widgetId),
          },
          { key: 'd3', itemType: ContextualMenuItemType.Divider },
          {
            key: 'delete',
            data: widgetId,
            disabled: isSystem(widgetId),
            iconProps: deleteIcon,
            text: t('dashboard:WidgetMenu.Delete'),
            onClick: () => setDeleteWidget(getWidgetFromId(widgetId)),
          },
          { key: 'd2', itemType: ContextualMenuItemType.Divider },
          {
            key: 'config',
            data: widgetId,
            disabled: isSystem(widgetId),
            iconProps: configIcon,
            text: t('dashboard:WidgetMenu.Configure'),
            onClick: () => setConfigureWidget(getWidgetFromId(widgetId)),
          },
        ],
      };
    }

    if (widget.customActions && widget.customActions.length > 0) {
      menuProps.items.push(
        ...widget.customActions.map((action, index) => {
          const customAction: IContextualMenuItem = {
            key: `custom-${widget.widgetId}-${index}`,
            data: widgetId,
            iconProps: action.iconProps,
            disabled: action.disabled,
            text: action.name,
            onClick: () => action.callback(widget),
          };

          return customAction;
        }),
      );
    }

    return menuProps;
  };

  const onEditWidget = async (widget: Widget) => {
    try {
      if (isLoading) return;
      setIsLoading(true);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      widget = await apiUpdateWidgetOnDashboard(widget, accessToken, appContext.globalDataCache);

      widget.dashboard = props.dashboard;

      const newWidgets = widgets.map((w) => (w.widgetId === widget.widgetId ? widget : w));
      setWidgets(newWidgets);
      setConfigureWidget(undefined);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onAddWidget = async (widget: Widget) => {
    try {
      if (isLoading || !addWidget) return;
      setIsLoading(true);

      widget.dashboardId = props.dashboard.dashboardId;
      widget.sortOrder = addWidget?.sortOrder + 1;

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const newWidget = await apiAddWidgetToDashboard(widget, accessToken, appContext.globalDataCache);
      newWidget.dashboard = props.dashboard;

      const idx = widgets.findIndex((w) => w.widgetId === addWidget.widgetId);
      const before = widgets.slice(0, idx + 1);
      const after = widgets.slice(idx + 1);
      const newWidgets = [...before, newWidget, ...after];

      setWidgets(newWidgets);
      setAddWidget(undefined);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteWidget = async () => {
    try {
      if (isLoading || !deleteWidget) return;
      setIsLoading(true);
      setDeleteWidget(undefined);

      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      await apiRemoveWidgetFromDashboard(deleteWidget, accessToken, appContext.globalDataCache);
      const newWidgets = widgets.filter((d) => d.widgetId !== deleteWidget.widgetId);

      setWidgets(newWidgets);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const registerCustomActions = (widget: Widget, customActions: IWidgetCustomAction[]) => {
    widget.customActions = customActions;
    setWidgets([...widgets]);
  };

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  if (props.dashboard.url) {
    return (
      <Stack styles={{ root: { height: appContext.windowHeight - 200, paddingRight: 20 } }}>
        <Stack.Item grow>
          <PowerBIReport url={props.dashboard.url} />
        </Stack.Item>
      </Stack>
    );
  }

  if (!isLoading && widgets.length === 0 && props.readonly) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Text>{t('translation:General.Notifications.NoItems')}</Text>
      </Stack>
    );
  }

  return (
    <Stack horizontal wrap styles={stackStylesWidget} tokens={globalStackTokensGapMedium}>
      {widgets.map((widget) => {
        return (
          <Stack.Item
            key={widget.widgetId}
            className={`redlab-usetiful-widget-${widget.widgetType}`}
            styles={getWidgetStyle()}
          >
            <Stack verticalFill>
              <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                  <Stack.Item grow styles={{ root: { width: 260 } }}>
                    <Text block={true} nowrap={true} styles={globalTextStylesBold}>
                      {widget.name}
                    </Text>
                  </Stack.Item>
                  {((!isSystem(widget.widgetId) && !props.readonly) ||
                    (widget.customActions && widget.customActions.length > 0)) && (
                    <Stack.Item>
                      <IconButton menuProps={getMenuItems(widget)} />
                    </Stack.Item>
                  )}
                </Stack>
              </Stack.Item>
              <Stack.Item grow>
                <WidgetRenderer
                  widget={widget}
                  onRegisterCustomActions={registerCustomActions}
                  sharedData={props.sharedData}
                  onRefreshSharedData={props.onRefreshSharedData}
                />
              </Stack.Item>
            </Stack>
          </Stack.Item>
        );
      })}
      {widgets.length < maxWidgetsPerDashboards && !props.readonly && (
        <Stack.Item styles={getAddWidgetStyle()}>
          <Stack
            className="redlab-usetiful-dashboard-add-widget"
            verticalFill
            horizontalAlign="center"
            verticalAlign="center"
            onClick={() => setAddWidget(widgets.length > 0 ? widgets[widgets.length - 1] : new Widget())}
          >
            <Stack.Item>
              <IconButton
                iconProps={newIcon}
                onClick={() => setAddWidget(widgets.length > 0 ? widgets[widgets.length - 1] : new Widget())}
              />
            </Stack.Item>
          </Stack>
        </Stack.Item>
      )}
      {addWidget !== undefined && !isLoading && (
        <AddWidgetPanel
          dashboard={props.dashboard}
          isOpen={addWidget !== undefined}
          onClose={() => setAddWidget(undefined)}
          onAdd={onAddWidget}
          allowSharedDataWidgets={props.sharedData !== undefined}
        ></AddWidgetPanel>
      )}
      {configureWidget !== undefined && !isLoading && (
        <EditWidgetPanel
          widget={configureWidget}
          isOpen={configureWidget !== undefined}
          onClose={() => setConfigureWidget(undefined)}
          onEdit={onEditWidget}
        ></EditWidgetPanel>
      )}
      <DialogYesNo
        onNo={() => {
          setDeleteWidget(undefined);
        }}
        onYes={() => {
          onDeleteWidget();
        }}
        title={t('dashboard:Dialogs.DeleteWidget.Title')}
        subText={t('dashboard:Dialogs.DeleteWidget.SubTitle')}
        hidden={deleteWidget === undefined}
      />
    </Stack>
  );
};

export default DashboardInstance;
