import { Fragment, useContext, useEffect, useState } from 'react';
import {
  DefaultButton,
  IconButton,
  Modal,
  Pivot,
  PivotItem,
  PrimaryButton,
  Separator,
  Stack,
  Text,
} from '@fluentui/react';
import {
  cancelIcon,
  globalStackTokensGapMedium,
  globalStackTokensGapSmall,
  globalStackItemStylesPaddingSmall,
  globalStackStylesHeight100,
} from 'globalStyles';
import Task from 'models/tasks/task';
import { useTranslation } from 'react-i18next';
import { TemplateTaskDetail } from './TemplateTaskDetail';
import AppContext from 'App/AppContext';
import CheckList from 'components/Checklist/Checklist';
import Joi from 'joi';
import ChecklistProgress from 'components/Checklist/ChecklistProgress';
import DialogYesNo from 'components/Dialogs/DialogYesNo';
import AuditTrail from 'components/Audit/AuditTrail';
import TaskLinkList from 'components/Links/LinkLists/TaskLinkList';
import { ToastContainer } from 'react-toastify';
import { ISingleTaskTypeProps } from '../SingleTask';
import { CheckListFollowUp } from 'components/Checklist/ChecklistFollowUp';
import { globalToastDelay } from 'globalConstants';
import ActivityTrail from 'components/Activity/ActivityTrail';
import OverlayLoader from 'components/Loading/OverlayLoader';
import { TaskContext } from '../Context/TaskContext';
import DialogConfirmDelete from 'components/Dialogs/DialogConfirmDelete';
import TaskTopTasksBar from '../TaskTopTasksBar';
import TaskTypeForm from '../Forms/TaskTypeForm';
import { SystemTaskTypes } from 'models/tasks/taskType';
import TaskWorkflows from '../TaskWorkflows';
import { TaskCheckList } from 'models/tasks/taskHelperClasses';
import { TaskDetailOwner } from './Details/TaskDetailOwner';
import { getTaskViewModalStackStyles, getTaskViewModalStyles } from '../View/TaskRenderHelpers';
import { canUpdateTaskField, TaskFieldTypes } from '../TaskAuthHelper';

interface ITemplateTaskProps extends ISingleTaskTypeProps {
  onSaveAsNewTask: () => void;
  onChangeCompletionDate: (completionDate: Date) => void;
}

const TemplateTask = (props: ITemplateTaskProps) => {
  const { t } = useTranslation(['task', 'control', 'translation', 'dateTimeComponent']);
  const appContext = useContext(AppContext);
  const [err, setErr] = useState<Joi.ValidationError | undefined>(undefined);
  const [selectedPivot, setSelectedPivot] = useState<string>(props.selectedPivot ?? 'Checklist');
  const [showRemoveDialog, setShowRemoveDialog] = useState<boolean>(false);
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);

  useEffect(() => {
    const _localizedFields = {
      assignment: t('task:Assignment.Label'),
      startDateTime: t('task:Start.Label'),
      endDateTime: t('task:End.ErrorMessage'),
      name: t('task:Name.Label'),
      deadline: t('task:Deadline.ErrorMessage'),
    };

    const result = props.task.validate(_localizedFields);
    setErr(result.error);
  }, [t, props.task]);

  useEffect(() => {
    if (appContext.isMobileView) {
      setSelectedPivot('TaskDetails');
    } else if (props.task.taskTypeId && props.hasFormFeature) {
      setSelectedPivot('TaskType');
    } else {
      setSelectedPivot('Checklist');
    }
  }, [appContext.isMobileView, appContext.windowSize, props.hasFormFeature, props.task.taskTypeId]);

  useEffect(() => {
    //Single task requests to show a specific pivot
    if (props.selectedPivot) {
      setSelectedPivot(props.selectedPivot);
    }
  }, [props.selectedPivot]);

  const validate = (fieldName: string): string | undefined => {
    if (err) {
      const fieldError = err.details.find((e) => {
        return e.context?.key === fieldName || e.message.toLowerCase().includes(fieldName.toLowerCase());
      });

      return fieldError ? fieldError.message : undefined;
    }

    return undefined;
  };

  const hasErrors = (): boolean => {
    return err ? true : false;
  };

  const cannotSaveTask = (): boolean => {
    return (props.task.taskId !== -1 && props.task.isEqual(props.orgTask)) || hasErrors();
  };

  const footer = (
    <Stack horizontal={!appContext.isMobileView} verticalAlign="end" tokens={globalStackTokensGapSmall}>
      <Stack.Item>
        <Stack horizontal tokens={globalStackTokensGapSmall}>
          <PrimaryButton
            disabled={cannotSaveTask() || props.isActionPending}
            onClick={async () => {
              props.onSave(props.task, false);
            }}
          >
            {t('translation:General.Button.Save')}
          </PrimaryButton>
          <DefaultButton
            onClick={() => {
              onCancel();
            }}
          >
            {t('translation:General.Button.Cancel')}
          </DefaultButton>
        </Stack>
      </Stack.Item>
    </Stack>
  );

  const onCancel = () => {
    if (cannotSaveTask()) {
      props.onCancel();
    } else {
      setShowCancelDialog(true);
    }
  };

  const onUpdateCheckList = (newCheckList: TaskCheckList) => {
    const copyTask = props.task.clone();
    copyTask.checkList = newCheckList;
    props.onUpdateTask(copyTask);
  };

  const onToggleTaskFollowUp = () => {
    const copyTask = props.task.clone();
    copyTask.followUp = copyTask.followUp ? false : true;
    props.onUpdateTask(copyTask);
  };

  const getTemplateTasks = () => {
    return (
      <TemplateTaskDetail
        task={props.task}
        orgTask={props.orgTask}
        onUpdate={(newTask: Task) => {
          props.onUpdateTask(newTask);
        }}
        getErrorMessage={validate}
        isActionPending={props.isActionPending}
        tags={props.tags}
        addTagToTaskState={props.addTagToTaskState}
        removeTagFromTaskState={props.removeTagFromTaskState}
        onChangeCompletionDate={props.onChangeCompletionDate}
        canUpdate={props.canUpdate}
      />
    );
  };

  const onClickForm = (taskTypeId: number) => {
    const newTask = props.task.clone();
    newTask.taskTypeId = taskTypeId > 0 ? taskTypeId : undefined;

    if (taskTypeId > 0) {
      newTask.systemTaskType =
        appContext.globalDataCache.taskTypes.get(taskTypeId).systemTaskType ?? SystemTaskTypes.None;
    } else {
      newTask.systemTaskType = SystemTaskTypes.None;
    }

    props.onUpdateTask(newTask);
    if (taskTypeId > 0) {
      setSelectedPivot('TaskType');
    } else {
      setSelectedPivot('Checklist');
    }
  };

  //
  // Main render
  //
  return (
    <Modal forceFocusInsideTrap={false} isOpen={props.isOpen} styles={getTaskViewModalStyles(props.windowLevel)}>
      <ToastContainer position="top-right" autoClose={globalToastDelay} containerId="templateTask" />
      {props.isLoading && <OverlayLoader text={t('task:Progress.Save')} />}
      <Stack
        verticalFill
        tokens={globalStackTokensGapSmall}
        styles={getTaskViewModalStackStyles(appContext.isMobileView, false)}
      >
        <Stack.Item>
          <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
            <Stack.Item>
              <Stack horizontal={!appContext.isMobileView} verticalAlign="center" tokens={globalStackTokensGapMedium}>
                <Stack.Item>
                  <Text variant="xxLarge">
                    {props.task.taskId === -1 && props.task.isSeries() && t('task:Title.Template.Series.New')}
                    {props.task.taskId === -1 && !props.task.isSeries() && t('task:Title.Template.New')}
                    {props.task.taskId !== -1 && props.task.isSeries() && t('task:Title.Template.Series.Edit')}
                    {props.task.taskId !== -1 && !props.task.isSeries() && t('task:Title.Template.Edit')}
                  </Text>
                </Stack.Item>
              </Stack>
            </Stack.Item>
            {!appContext.isMobileView && (
              <Stack.Item>
                <TaskTopTasksBar
                  task={props.task}
                  onClickForm={onClickForm}
                  onClickRemove={() => setShowRemoveDialog(true)}
                  onCreateNewTaskTemplate={props.onCreateNewTaskTemplate}
                  onSaveAsNewTask={props.onSaveAsNewTask}
                  navigateExternal={props.navigateExternal}
                  canUpdate={props.canUpdate}
                  canDelete={props.canDelete}
                  orgTask={props.orgTask}
                  onConvertChecklist={props.onConvertChecklist}
                  canSave={!hasErrors()}
                />
              </Stack.Item>
            )}
            <Stack.Item>
              <IconButton iconProps={cancelIcon} onClick={onCancel} />
            </Stack.Item>
          </Stack>
          <Stack.Item>
            <TaskDetailOwner
              orgTask={props.orgTask}
              task={props.task}
              onUpdate={props.onUpdateTask}
              canUpdate={props.canUpdate}
            />
          </Stack.Item>
          <Separator />
        </Stack.Item>
        {appContext.isMobileView && (
          <Stack.Item>
            <TaskTopTasksBar
              task={props.task}
              onClickForm={onClickForm}
              onClickRemove={() => setShowRemoveDialog(true)}
              onCreateNewTaskTemplate={props.onCreateNewTaskTemplate}
              onSaveAsNewTask={props.onSaveAsNewTask}
              navigateExternal={props.navigateExternal}
              canUpdate={props.canUpdate}
              canDelete={props.canDelete}
              orgTask={props.orgTask}
              onConvertChecklist={props.onConvertChecklist}
              canSave={!hasErrors()}
            />
          </Stack.Item>
        )}
        <Stack.Item grow>
          <Stack horizontal styles={globalStackStylesHeight100} tokens={globalStackTokensGapSmall}>
            {!appContext.isMobileView && (
              <Fragment>
                <Stack.Item styles={{ root: { width: '50%' } }}>{getTemplateTasks()}</Stack.Item>
                <Separator vertical></Separator>
              </Fragment>
            )}
            <Stack.Item styles={!appContext.isMobileView ? { root: { width: '50%' } } : { root: { width: '100%' } }}>
              <Stack verticalFill tokens={globalStackTokensGapSmall}>
                <Stack.Item>
                  <Pivot
                    selectedKey={selectedPivot}
                    onLinkClick={(item) => setSelectedPivot(item?.props.itemKey ?? '')}
                    overflowBehavior="menu"
                  >
                    {appContext.isMobileView && (
                      <PivotItem
                        className="redlab-usetiful-task-pivot-details"
                        key="TaskDetails"
                        itemKey="TaskDetails"
                        headerText={t('task:Pivot.TaskDetails')}
                      />
                    )}
                    {props.hasFormFeature && props.task.taskTypeId && props.task.taskTypeId > 0 && (
                      <PivotItem
                        className="redlab-usetiful-task-pivot-tasktype"
                        key="TaskType"
                        itemKey="TaskType"
                        headerText={appContext.globalDataCache.taskTypes.get(props.task.taskTypeId).tabName}
                      />
                    )}
                    {(!props.task.taskTypeId || props.task.taskTypeId === 0 || !props.hasFormFeature) && (
                      <PivotItem
                        className="redlab-usetiful-task-pivot-checklist"
                        key="Checklist"
                        itemKey="Checklist"
                        headerText={t('task:Pivot.Checklist')}
                      />
                    )}
                    <PivotItem
                      className="redlab-usetiful-task-pivot-context"
                      key="Context"
                      itemKey="Context"
                      headerText={t('task:Pivot.Context')}
                    />
                    <PivotItem
                      className="redlab-usetiful-task-pivot-library"
                      key="Resources"
                      itemKey="Resources"
                      headerText={t('task:Pivot.Resources')}
                    />
                    {props.hasPAFeature && props.task.isSeries() && (
                      <PivotItem
                        className="redlab-usetiful-task-pivot-workflows"
                        key="Workflows"
                        itemKey="Workflows"
                        headerText={t('task:Pivot.Workflows')}
                      />
                    )}
                    {props.task.commentTrailId > 0 && (
                      <PivotItem
                        className="redlab-usetiful-task-pivot-activity"
                        key="Activities"
                        itemKey="Activities"
                        headerText={t('task:Pivot.Activities')}
                      />
                    )}
                    {props.task.auditTrailId > 0 && (
                      <PivotItem
                        className="redlab-usetiful-task-pivot-changelog"
                        key="Audit"
                        itemKey="Audit"
                        headerText={t('task:Pivot.Audit')}
                      />
                    )}
                  </Pivot>
                </Stack.Item>
                {selectedPivot === 'TaskDetails' && (
                  <Fragment>
                    <Stack.Item grow style={{ height: appContext.isMobileView ? '40vh' : 0 }}>
                      {getTemplateTasks()}
                    </Stack.Item>
                  </Fragment>
                )}
                <TaskTypeForm
                  isOpen={selectedPivot === 'TaskType'}
                  task={props.task}
                  links={props.links}
                  compact={true}
                  readonly={true}
                  onUpdateData={props.onUpdateKPIData}
                  onSetData={props.onSetKPIData}
                  onUpdateTaskForForm={props.onUpdateTaskForForm}
                  addLinks={props.addLinkToTaskState}
                  setLinks={props.setLinkToTaskState}
                  removeLink={props.removeLinkFromTaskState}
                  showErrors={props.showFormErrors}
                  onValidate={props.onValidateForm}
                ></TaskTypeForm>
                {selectedPivot === 'Checklist' && (
                  <Fragment>
                    <Stack.Item styles={globalStackItemStylesPaddingSmall}>
                      <ChecklistProgress
                        totalChecks={props.task.checkList.items ? props.task.checkList.items.length : 0}
                        completedChecks={props.task.checkList.getCompletedCheckCount()}
                        followUp={props.task.followUp ? true : false}
                      />
                    </Stack.Item>
                    <Stack.Item grow>
                      <CheckList
                        allowFill={canUpdateTaskField(
                          props.orgTask,
                          props.canUpdate,
                          TaskFieldTypes.ChecklistFill,
                          appContext,
                        )}
                        allowEdit={canUpdateTaskField(
                          props.orgTask,
                          props.canUpdate,
                          TaskFieldTypes.ChecklistEdit,
                          appContext,
                        )}
                        checkList={props.task.checkList}
                        updateCheckList={onUpdateCheckList}
                      />
                    </Stack.Item>
                    {props.task.taskId !== -1 && props.canUpdate && (
                      <Stack.Item style={{ paddingLeft: '5px' }}>
                        <CheckListFollowUp followUp={props.task.followUp} toggle={onToggleTaskFollowUp} />
                      </Stack.Item>
                    )}
                  </Fragment>
                )}
                {selectedPivot === 'Workflows' && <TaskWorkflows task={props.task} />}
                {selectedPivot === 'Resources' && (
                  <TaskLinkList
                    taskLinks={props.links}
                    task={props.task}
                    addLinks={props.addLinkToTaskState}
                    setLinks={props.setLinkToTaskState}
                    removeLink={props.removeLinkFromTaskState}
                    navigateExternal={props.navigateExternal}
                  />
                )}
                {selectedPivot === 'Activities' && (
                  <Stack.Item grow>
                    <ActivityTrail
                      auditTrailId={props.task.auditTrailId}
                      commentTrailId={props.task.commentTrailId}
                      taskId={props.task.taskId}
                      authSchemaIds={[props.task.authSchemaId]}
                    />
                  </Stack.Item>
                )}
                {selectedPivot === 'Audit' && (
                  <Stack.Item grow>
                    <AuditTrail auditTrailId={props.task.auditTrailId} />
                  </Stack.Item>
                )}
                {selectedPivot === 'Context' && (
                  <Stack.Item grow>
                    <TaskContext
                      orgTask={props.orgTask}
                      task={props.task}
                      updateTaskForForm={props.onUpdateTaskForForm}
                      updateTask={props.onUpdateTask}
                      allowEdit={canUpdateTaskField(
                        props.orgTask,
                        props.canUpdate,
                        TaskFieldTypes.ChecklistEdit,
                        appContext,
                      )}
                      windowLevel={props.windowLevel}
                      navigateExternal={props.navigateExternal}
                    />
                  </Stack.Item>
                )}
              </Stack>
            </Stack.Item>
          </Stack>
        </Stack.Item>
        <Stack.Item>
          <Separator styles={{ root: { height: 1 } }} />
        </Stack.Item>
        <Stack.Item>{footer}</Stack.Item>
      </Stack>
      <DialogConfirmDelete
        onNo={() => {
          setShowRemoveDialog(false);
        }}
        onYes={() => {
          setShowRemoveDialog(false);
          props.onRemove();
        }}
        title={props.task.isSeries() ? t('task:Template.RemoveDialogTitle') : t('task:Remove.DialogTitle')}
        subText={props.task.isSeries() ? t('task:Template.RemoveDialogSubText') : t('task:Remove.DialogSubTitle')}
        hidden={!showRemoveDialog}
        confirmText={!props.task.isSeries() ? t('task:Remove.RemoveDialogConfirmText') : undefined}
      />
      <DialogYesNo
        onNo={() => {
          setShowCancelDialog(false);
        }}
        onYes={() => {
          setShowCancelDialog(false);

          props.onCancel();
        }}
        title={t('task:Cancel.DialogTitle')}
        subText={t('task:Cancel.DialogSubTitle')}
        hidden={!showCancelDialog}
      />
    </Modal>
  );
};

export default TemplateTask;
