import { useContext, useEffect, useState } from 'react';
import { Text, Stack, PrimaryButton, Image, IImageProps, ImageFit } from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import AppContext from 'App/AppContext';
import SceneBar from 'components/SceneBar/SceneBar';
import { apiGetDashboardScene } from 'services/Api/dashboardService';
import { apiRequest } from 'services/Auth/authConfig';
import DashboardScene from 'models/dashboardScene';
import { RouteComponentProps } from 'react-router';
import {
  globalStackTokensGapLarge,
  globalStackStylesHeight100,
  globalPaddingScene,
  globalStackItemStylesPaddingScene,
  globalStackTokensGapSmall,
  globalStackStylesPaddingScene,
} from 'globalStyles';
import { globalKB_AdminSubscription, globalKB_AdminUsers, globalNotFoundURL } from 'globalConstants';
import { isAm } from 'utils/datetime';
import { getCurrentPivotKeyFromURL, IScenePivotItemProps } from 'components/SceneBar/ScenePivot';
import DashboardCollection from 'components/Dashboard/DashboardCollection';
import { DashboardTypes } from 'models/dashboard';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import TrialWelcome from './Trial/TrialWelcome';
import WarningMessage from 'components/Notification/WarningMessage';
import { FeatureTypes, hasUserFeature } from 'services/Auth/featurePermissions';
import Config from 'services/Config/configService';
import { LearnMore } from 'components/Notification/Info';

interface IDashboardOverviewProps extends RouteComponentProps {}

const imagePropsLicenseManager: IImageProps = {
  src: `${Config.getImageURL()}/license-manager.png`,
  imageFit: ImageFit.centerContain,
  width: 128,
  height: 128,
};

const DashboardOverview = (props: IDashboardOverviewProps) => {
  const { t } = useTranslation(['translation', 'dashboard', 'activity']);
  const appContext = useContext(AppContext);
  const _sceneBaseURL = '/dashboard';
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dashboardScene, setDashboardScene] = useState<DashboardScene | undefined>(undefined);
  const history = useHistory();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    if (isLoading) {
      return;
    }

    //we must check this because the dashboard is always loaded
    //and would otherwise generate and error
    if (!appContext.isAuthenticated || !appContext.user.login.userLicensed) {
      return;
    }

    if (!hasUserFeature(appContext, FeatureTypes.Dashboard)) {
      return;
    }

    try {
      setIsLoading(true);
      appContext.showContentLoader();

      //load dashboard data
      const accessToken = await appContext.getAccessToken(apiRequest.scopes);
      const newDashboardScene = await apiGetDashboardScene(accessToken, appContext.globalDataCache);

      setDashboardScene(newDashboardScene);
    } catch (err) {
      appContext.setError(err);
    } finally {
      appContext.hideContentLoader();
      setIsLoading(false);
    }
  };

  const getTrialMsg = () => {
    if (appContext.user.login.isInTrial() && !appContext.isMobileView) {
      const days = appContext.user.login.trialDaysLeft();
      if (days === 0) {
        return t('dashboard:Greeting.Trial0');
      } else if (days === 1) {
        return t('dashboard:Greeting.Trial1');
      } else {
        return t('dashboard:Greeting.Trial', { days: days });
      }
    }

    return '';
  };

  const getTitle = () => {
    let timeGreeting = t('dashboard:Greeting.GoodMorning');
    if (!isAm()) timeGreeting = t('dashboard:Greeting.GoodAfternoon');

    return `${timeGreeting}, ${appContext.user.name} ${getTrialMsg()}`;
  };

  const getPivotItems = () => {
    const pivotItems: IScenePivotItemProps[] = [];

    if (appContext.user.login.tenantLicensed === false) {
      return pivotItems; //no tabs when tenant is not licensed
    }

    if (appContext.user.login.isInTrial()) {
      //show the trial welcome tab:
      //1. as long as the trial period is active
      //2. user is manager/admin/consultant
      const pivotTrialWelcome = {
        key: 'trialwelcome',
        text: t('dashboard:Pivot.TrialWelcome'),
        url: '/welcome',
      };
      pivotItems.push(pivotTrialWelcome);
    }

    if (appContext.user.login.trialEnded() === false) {
      const pivotMe = { key: 'me', text: t('dashboard:Pivot.ForMe'), url: '/me' };
      pivotItems.push(pivotMe);

      const pivotItemsTeamOrg: IScenePivotItemProps[] = [
        { key: 'team', text: t('dashboard:Pivot.Team'), url: '/team' },
        { key: 'org', text: t('dashboard:Pivot.Org'), url: '/org' },
      ];
      pivotItems.push(...pivotItemsTeamOrg);
    }

    return pivotItems;
  };

  const getDefaultUrl = (): string => {
    const def = appContext.user.login.isInTrial() ? 'welcome' : 'me';

    return `${_sceneBaseURL}/${def}`;
  };

  const gotoSubscription = () => {
    history.push('/admin/subscription');
  };

  //
  // Main render
  //

  //the license manager only see the scene bar
  if (!hasUserFeature(appContext, FeatureTypes.Dashboard)) {
    return (
      <Stack verticalFill styles={globalStackStylesPaddingScene} tokens={globalStackTokensGapLarge}>
        <Stack.Item>
          <SceneBar
            title={getTitle()}
            subtitle={t('dashboard:SubTitle')}
            image="dashboard.png"
            pivotItems={[]}
            selectedPivotKey={getCurrentPivotKeyFromURL(getPivotItems())}
          />
        </Stack.Item>
        <Stack verticalFill verticalAlign="center" horizontalAlign="center" tokens={globalStackTokensGapLarge}>
          <Image {...imagePropsLicenseManager} />
          <Text variant="large">{t('dashboard:LicenseManagerInfo')}</Text>
          <Stack tokens={globalStackTokensGapSmall}>
            <LearnMore learnMoreLink={globalKB_AdminUsers} text={t('dashboard:LicenseManagerInfoLinkAdminUsers')} />
            <LearnMore
              learnMoreLink={globalKB_AdminSubscription}
              text={t('dashboard:LicenseManagerInfoLinkAdminSubscription')}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack horizontal styles={globalStackStylesHeight100}>
      <Stack.Item grow>
        <Stack verticalFill styles={globalStackStylesHeight100} tokens={globalStackTokensGapLarge}>
          <Stack.Item>
            <SceneBar
              title={getTitle()}
              subtitle={t('dashboard:SubTitle')}
              image="dashboard.png"
              pivotItems={getPivotItems()}
              selectedPivotKey={getCurrentPivotKeyFromURL(getPivotItems())}
            />
          </Stack.Item>
          {!isLoading && appContext.user.login.tenantLicensed === false && (
            <Stack verticalFill tokens={globalStackTokensGapLarge} styles={globalStackItemStylesPaddingScene}>
              <Stack.Item>
                <WarningMessage message={t('dashboard:NoLicense')}></WarningMessage>
              </Stack.Item>
              <Stack.Item>
                <PrimaryButton onClick={() => gotoSubscription()}>{t('dashboard:NoLicenseAction')}</PrimaryButton>
              </Stack.Item>
            </Stack>
          )}
          {appContext.user.login.tenantLicensed === true && (
            <Stack.Item grow styles={{ root: { paddingLeft: globalPaddingScene, height: '50%' } }}>
              <Switch>
                <Route
                  path={`${_sceneBaseURL}/me`}
                  render={() => (
                    <DashboardCollection
                      dashboardScene={dashboardScene}
                      dashboardType={DashboardTypes.user}
                      onRefreshSharedData={loadData}
                    />
                  )}
                />
                <Route
                  path={`${_sceneBaseURL}/team`}
                  render={() => (
                    <DashboardCollection
                      dashboardScene={dashboardScene}
                      dashboardType={DashboardTypes.team}
                      onRefreshSharedData={loadData}
                    />
                  )}
                />
                <Route
                  path={`${_sceneBaseURL}/org`}
                  render={() => (
                    <DashboardCollection
                      dashboardScene={dashboardScene}
                      dashboardType={DashboardTypes.org}
                      onRefreshSharedData={loadData}
                    />
                  )}
                />
                <Route path={`${_sceneBaseURL}/welcome`} render={() => <TrialWelcome />} />
                <Redirect from={`${_sceneBaseURL}`} to={getDefaultUrl()}></Redirect>
                <Redirect to={globalNotFoundURL}></Redirect>
              </Switch>
            </Stack.Item>
          )}
        </Stack>
      </Stack.Item>
    </Stack>
  );
};

export default DashboardOverview;
