import React, { Fragment, useContext, useEffect, useState } from 'react';
import {
  Stack,
  DefaultButton,
  Callout,
  ICalloutContentStyles,
  ChoiceGroup,
  IChoiceGroupOption,
  Separator,
  PrimaryButton,
  CommandBarButton,
  Label,
  DirectionalHint,
  IChoiceGroupOptionProps,
  Dropdown,
  IDropdownOption,
  SelectableOptionMenuItemType,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapSmall, globalSceneBarItemStyles } from 'globalStyles';
import AppContext from 'App/AppContext';
import LocalizedDatePicker from 'components/Pickers/LocalizedDatePicker';
import {
  addDateYears,
  fromApiDateOptional,
  getAuditStartForSetting,
  getCurrentAuditYear,
  getDateTimeDiffDays,
  toApiDateOptional,
  toLocaleDateShort,
} from 'utils/datetime';
import { LocalStorageKeys, getLocalStorageData, setLocalStorageData } from 'utils/localstorage';
import { darkTheme, lightTheme } from 'globalThemes';
import { AuditYearStart } from 'models/setting';
import { getPeriodFilterEndDate, getPeriodFilterStartDate } from './PeriodFilterHelper';

export enum PeriodFilterDataOption {
  NotSet = '',
  All = 'All',
  AuditYear = 'AuditYear',
  LastWeek = 'LastWeek',
  LastMonth = 'LastMonth',
  LastQuarter = 'LastQuarter',
  LastYear = 'LastYear',
  Custom = 'Custom',
}

export interface IPeriodFilterData {
  option?: PeriodFilterDataOption;
  auditYear?: number;
  customStart?: string;
  customEnd?: string;
}

interface IPeriodFilterProps {
  storageKey: LocalStorageKeys | undefined;
  label?: string;
  showAll?: boolean;
  showOption?: boolean;
  showCurrentAuditYear?: boolean;
  period?: IPeriodFilterData;
  onUpdatePeriod?: (start: Date, end: Date, init?: boolean) => void;
  onUpdatePeriodAll?: (init?: boolean) => void;
  onUpdatePeriodOption?: (period: IPeriodFilterData) => void;
}

const PeriodFilter = (props: IPeriodFilterProps) => {
  const { t } = useTranslation(['translation', 'dateTimeComponent']);
  const appContext = useContext(AppContext);
  const [showCallOut, setShowCallOut] = useState<boolean>(false);
  const [showCustom, setShowCustom] = useState<boolean>(false);
  const [selectedOption1, setSelectedOption1] = useState<PeriodFilterDataOption | undefined>(undefined);
  const [selectedOption2, setSelectedOption2] = useState<PeriodFilterDataOption | undefined>(undefined);
  const [customStart, setCustomStart] = useState<Date | undefined>(undefined);
  const [customEnd, setCustomEnd] = useState<Date | undefined>(undefined);
  const [periodStart, setPeriodStart] = useState<Date | undefined>(undefined);
  const [periodEnd, setPeriodEnd] = useState<Date | undefined>(undefined);
  const [selectedAuditYear, setSelectedAuditYear] = useState<number | undefined>(undefined);
  const [auditDateSetting] = useState<string | undefined>(appContext.globalDataCache.settings.get(AuditYearStart));

  //
  // Effects
  //
  useEffect(() => {
    initFromCache();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.period) {
      initFromPeriod(props.period);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.period]);

  //
  // Helpers
  //
  const initFromPeriod = (data: IPeriodFilterData) => {
    let customStart: Date | undefined;
    let customEnd: Date | undefined;
    let option1: PeriodFilterDataOption = PeriodFilterDataOption.NotSet;
    let option2: PeriodFilterDataOption = PeriodFilterDataOption.NotSet;

    let auditYear = data.auditYear;
    if (auditYear === undefined) {
      //init last used audit year from storage: get the last used audit year
      const yearFromStorageRaw = getLocalStorageData(appContext, LocalStorageKeys.AuditYear);
      const yearFromStorage = yearFromStorageRaw ? Number.parseInt(yearFromStorageRaw) : undefined;
      auditYear = yearFromStorage ?? getCurrentAuditYear(auditDateSetting);
    }
    setSelectedAuditYear(auditYear);

    //determine saved options
    if (
      data.option === PeriodFilterDataOption.AuditYear ||
      data.option === PeriodFilterDataOption.Custom ||
      data.option === PeriodFilterDataOption.All
    ) {
      option2 = data.option;
    } else if (data.option) {
      option1 = data.option;
    } else {
      option2 = PeriodFilterDataOption.AuditYear;
    }

    //always set saved custom dates for when the user selects custom => show the latest saved values
    customStart = fromApiDateOptional(data.customStart);
    customEnd = fromApiDateOptional(data.customEnd);
    setShowCustom(data.option === PeriodFilterDataOption.Custom);

    //set saved/default options
    setSelectedOption1(option1);
    setSelectedOption2(option2);

    //set saved/default custom dates
    if (!customStart) customStart = addDateYears(new Date(), -1);
    if (!customEnd) customEnd = new Date();
    setCustomStart(customStart);
    setCustomEnd(customEnd);

    //calculate period
    const start = getStartDate(option1, option2, auditYear, customStart);
    const end = getEndDate(option2, auditYear, customEnd);

    //always call onUpdate with the loaded/default settings
    updatePeriod(start, end, true);
  };

  const initFromCache = () => {
    //init period from storage
    let data: IPeriodFilterData;
    let value: string | undefined = undefined;

    if (props.storageKey) {
      value = getLocalStorageData(appContext, props.storageKey);
    }

    if (value) {
      data = JSON.parse(value);
    } else {
      //no value stored yet, create default
      data = getDefaultValue();
    }

    initFromPeriod(data);
  };

  const getDefaultValue = (): IPeriodFilterData => {
    return {
      option: props.showAll ? PeriodFilterDataOption.All : PeriodFilterDataOption.AuditYear,
      auditYear: 0,
      customStart: undefined,
      customEnd: undefined,
    };
  };

  const updatePeriod = (start: Date | undefined, end: Date | undefined, init: boolean) => {
    if (
      !periodStart ||
      !periodEnd ||
      !start ||
      !end ||
      getDateTimeDiffDays(start, periodStart) !== 0 ||
      getDateTimeDiffDays(end, periodEnd) !== 0
    ) {
      setPeriodStart(start);
      setPeriodEnd(end);
      if (start && end && props.onUpdatePeriod) {
        props.onUpdatePeriod(start, end, init);
      } else if (props.showAll && props.onUpdatePeriodAll) {
        props.onUpdatePeriodAll(init);
      }
    }
  };

  const getSummary = (): string => {
    const period = props.label ?? t('translation:PeriodSelector.Period');
    if (!periodStart || !periodEnd) {
      return `${period}: ${t('translation:PeriodSelector.All')}`;
    } else {
      if (props.showOption) {
        const option = selectedOption1 || selectedOption2 || PeriodFilterDataOption.NotSet;
        if (option === PeriodFilterDataOption.AuditYear) {
          return `${period}: ${getYearText(selectedAuditYear ?? 0, auditDateSetting)}`;
        } else if (option === PeriodFilterDataOption.Custom) {
          const start = toLocaleDateShort(customStart);
          const end = toLocaleDateShort(customEnd);
          const to = t('translation:PeriodSelector.To');

          return `${period}: ${getOptionText(option)} - ${start} ${to} ${end}`;
        } else {
          return `${period}: ${getOptionText(option)}`;
        }
      } else {
        const start = toLocaleDateShort(periodStart);
        const end = toLocaleDateShort(periodEnd);
        const to = t('translation:PeriodSelector.To');

        return `${period}: ${start} ${to} ${end}`;
      }
    }
  };

  const onChange1 = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
    setSelectedOption1(option?.key as PeriodFilterDataOption);
    setSelectedOption2(undefined);
    setShowCustom(false);
  };

  const onChange2 = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
    setSelectedOption1(undefined);
    setSelectedOption2(option?.key as PeriodFilterDataOption);
    if (option?.key === PeriodFilterDataOption.Custom) {
      setCustomStart(customStart ?? periodStart);
      setCustomEnd(customEnd ?? periodEnd);
      setShowCustom(true);
    } else {
      setShowCustom(false);
    }
  };

  //
  // Audit year selection
  //
  const yearStartsInJan = (auditDateSetting: string | undefined): boolean => {
    if (!auditDateSetting) return false;
    const month = getAuditStartForSetting(1, auditDateSetting).getMonth();

    return month === 0;
  };

  const getYearText = (yearIdx: number, auditDateSetting: string | undefined) => {
    if (yearIdx === 0) {
      const currentYear = getCurrentAuditYear(auditDateSetting);
      if (yearStartsInJan(auditDateSetting)) {
        return t('tasks:TaskView.Commands.AuditYear.CurrentItemStartInJan', { year: currentYear });
      } else {
        return t('tasks:TaskView.Commands.AuditYear.CurrentItem', { year: currentYear, nextYear: currentYear + 1 });
      }
    } else {
      if (yearStartsInJan(auditDateSetting)) {
        return t('tasks:TaskView.Commands.AuditYear.ItemStartInJan', { year: yearIdx });
      } else {
        return t('tasks:TaskView.Commands.AuditYear.Item', { year: yearIdx, nextYear: yearIdx + 1 });
      }
    }
  };

  const getDefaultYearItems = (year: number): IDropdownOption[] => {
    const options: IDropdownOption[] = [];

    if (props.showCurrentAuditYear) {
      options.push(
        {
          key: 'header1',
          text: t('tasks:TaskView.Commands.AuditYear.HeaderCurrent'),
          itemType: SelectableOptionMenuItemType.Header,
        },
        {
          key: 'yearCurrent0',
          text: getYearText(0, auditDateSetting), //0 means calculate the current audit year
          data: 0,
        },
      );
    }

    options.push(
      {
        key: 'header1',
        text: t('tasks:TaskView.Commands.AuditYear.HeaderPrevNext'),
        itemType: SelectableOptionMenuItemType.Header,
      },
      {
        key: 'yearMinus1' + (year - 1).toString(),
        text: getYearText(year - 1, auditDateSetting),
        data: year - 1,
      },
      {
        key: 'yearCurrent' + year.toString(),
        text: getYearText(year, auditDateSetting),
        data: year,
      },
      {
        key: 'yearPlus1' + (year + 1).toString(),
        text: getYearText(year + 1, auditDateSetting),
        data: year + 1,
      },
      {
        key: 'header2',
        text: t('tasks:TaskView.Commands.AuditYear.HeaderAll'),
        itemType: SelectableOptionMenuItemType.Header,
      },
    );

    return options;
  };

  const getAuditYearOptions = (selectedYear: number): IDropdownOption[] => {
    const currentYear = selectedYear === 0 ? getCurrentAuditYear(auditDateSetting) : selectedYear;
    const yearMenuProps: IDropdownOption[] = [
      ...getDefaultYearItems(currentYear),
      ...Array.from({ length: 21 }, (_, i) => {
        const yearIdx: number = i + currentYear - 10;

        return {
          key: 'year' + yearIdx.toString(),
          text: getYearText(yearIdx, auditDateSetting),
          data: yearIdx,
        };
      }),
    ];

    return yearMenuProps;
  };

  const onRenderAuditYear = (
    itemProps?: IChoiceGroupOption & IChoiceGroupOptionProps,
    defaultRender?: (props?: IChoiceGroupOption & IChoiceGroupOptionProps) => JSX.Element | null,
  ): JSX.Element | null => {
    if (!defaultRender || selectedAuditYear === undefined) return null;
    if (itemProps && itemProps.key === PeriodFilterDataOption.AuditYear && itemProps.checked) {
      return (
        <Stack tokens={globalStackTokensGapSmall}>
          <Stack.Item>{defaultRender(itemProps)}</Stack.Item>
          <Stack.Item>
            <Dropdown
              selectedKey={'yearCurrent' + selectedAuditYear}
              dropdownWidth="auto"
              options={getAuditYearOptions(selectedAuditYear)}
              calloutProps={{ calloutMaxHeight: 250 }}
              onChange={(ev, option) => setSelectedAuditYear(option?.data)}
            />
          </Stack.Item>
        </Stack>
      );
    } else {
      return defaultRender(itemProps);
    }
  };

  //
  // Calc result
  //
  const getStartDate = (
    option1: PeriodFilterDataOption | undefined,
    option2: PeriodFilterDataOption | undefined,
    selectedAuditYear: number | undefined,
    customStart: Date | undefined,
  ): Date | undefined => {
    return getPeriodFilterStartDate(option1 || option2, selectedAuditYear, customStart, appContext);
  };

  const getEndDate = (
    option2: string | undefined,
    selectedAuditYear: number | undefined,
    customEnd: Date | undefined,
  ): Date | undefined => {
    return getPeriodFilterEndDate(option2, selectedAuditYear, customEnd, appContext);
  };

  const onApply = () => {
    const start = getStartDate(selectedOption1, selectedOption2, selectedAuditYear, customStart);
    const end = getEndDate(selectedOption2, selectedAuditYear, customEnd);

    updatePeriod(start, end, false);
    setShowCallOut(false);

    if (props.storageKey) {
      const data: IPeriodFilterData = {
        option: selectedOption1 || selectedOption2,
        customStart: toApiDateOptional(customStart),
        customEnd: toApiDateOptional(customEnd),
        //the audit year is stored seperately because the user expects the audit year to be remembered over different views
      };

      setLocalStorageData(appContext, props.storageKey, JSON.stringify(data));
    }

    if (selectedAuditYear && selectedOption2 === PeriodFilterDataOption.AuditYear) {
      setLocalStorageData(appContext, LocalStorageKeys.AuditYear, selectedAuditYear.toString());
    }

    if (props.onUpdatePeriodOption) {
      const data: IPeriodFilterData = {
        option: selectedOption1 || selectedOption2,
        customStart: toApiDateOptional(customStart),
        customEnd: toApiDateOptional(customEnd),
        //the audit year is returned as part of the period data because it is requested by the caller
        //use cases are widgets that store the audit year as the selected period
        auditYear: selectedAuditYear,
      };

      props.onUpdatePeriodOption(data);
    }
  };

  //
  // Options
  //
  const calloutStyle: Partial<ICalloutContentStyles> = {
    root: {
      padding: 20,
      minWidth: 350,
      maxWidth: 600,
      background: appContext.useDarkMode ? darkTheme.palette?.white : lightTheme.palette?.white,
    },
  };

  const getOptionText = (option: PeriodFilterDataOption): string => {
    //return the option text based on a switch statement
    switch (option) {
      case PeriodFilterDataOption.LastWeek:
        return t('translation:PeriodSelector.LastWeek');
      case PeriodFilterDataOption.LastMonth:
        return t('translation:PeriodSelector.LastMonth');
      case PeriodFilterDataOption.LastQuarter:
        return t('translation:PeriodSelector.LastQuarter');
      case PeriodFilterDataOption.LastYear:
        return t('translation:PeriodSelector.LastYear');
      case PeriodFilterDataOption.All:
        return t('translation:PeriodSelector.All');
      case PeriodFilterDataOption.AuditYear:
        return t('translation:PeriodSelector.AuditYear');
      case PeriodFilterDataOption.Custom:
        return t('translation:PeriodSelector.Custom');
      default:
        return '';
    }
  };

  const getOptions1 = (): IChoiceGroupOption[] => {
    return [
      { key: PeriodFilterDataOption.LastWeek, text: t('translation:PeriodSelector.LastWeek') },
      { key: PeriodFilterDataOption.LastMonth, text: t('translation:PeriodSelector.LastMonth') },
      { key: PeriodFilterDataOption.LastQuarter, text: t('translation:PeriodSelector.LastQuarter') },
      { key: PeriodFilterDataOption.LastYear, text: t('translation:PeriodSelector.LastYear') },
    ];
  };

  const getOptions2 = (): IChoiceGroupOption[] => {
    const options: IChoiceGroupOption[] = [];
    if (props.showAll) {
      options.push({ key: PeriodFilterDataOption.All, text: t('translation:PeriodSelector.All') });
    }

    options.push(
      ...[
        {
          key: PeriodFilterDataOption.AuditYear,
          text: t('translation:PeriodSelector.AuditYear'),
          onRenderField: onRenderAuditYear,
        },
        { key: PeriodFilterDataOption.Custom, text: t('translation:PeriodSelector.Custom') },
      ],
    );

    return options;
  };

  //
  // Main render
  //
  return (
    <Fragment>
      <CommandBarButton
        iconProps={{ iconName: 'Calendar' }}
        id="period-selector-callout"
        onClick={() => setShowCallOut(!showCallOut)}
        text={getSummary()}
        styles={globalSceneBarItemStyles}
      />
      <Callout
        hidden={!showCallOut}
        styles={calloutStyle}
        setInitialFocus
        gapSpace={0}
        isBeakVisible={false}
        directionalHint={DirectionalHint.bottomLeftEdge}
        target={'#period-selector-callout'}
        onDismiss={() => {
          setShowCallOut(false);
        }}
      >
        <Stack tokens={globalStackTokensGapSmall}>
          <Stack.Item>
            <Label>{props.label ?? t('translation:PeriodSelector.Period')}</Label>
          </Stack.Item>
          <Stack.Item>
            <Stack horizontal tokens={globalStackTokensGapSmall}>
              <Stack.Item>
                <ChoiceGroup selectedKey={selectedOption1} options={getOptions1()} onChange={onChange1} />
              </Stack.Item>
              <Separator vertical />
              <Stack.Item>
                <Stack tokens={globalStackTokensGapSmall}>
                  <ChoiceGroup selectedKey={selectedOption2} options={getOptions2()} onChange={onChange2} />
                  {showCustom && (
                    <Stack tokens={globalStackTokensGapSmall}>
                      <Stack.Item>
                        <LocalizedDatePicker
                          showMonthPickerAsOverlay={false}
                          showWeekNumbers={true}
                          value={customStart}
                          maxDate={customEnd}
                          onDateChange={(date: Date | undefined) => {
                            if (date) setCustomStart(date);
                          }}
                          format="short"
                          styles={{ root: { minWidth: 150 } }}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <LocalizedDatePicker
                          showMonthPickerAsOverlay={false}
                          showWeekNumbers={true}
                          value={customEnd}
                          minDate={customStart}
                          onDateChange={(date: Date | undefined) => {
                            if (date) setCustomEnd(date);
                          }}
                          format="short"
                          styles={{ root: { minWidth: 150 } }}
                        />
                      </Stack.Item>
                    </Stack>
                  )}
                </Stack>
              </Stack.Item>
            </Stack>
          </Stack.Item>
          <Separator />
          <Stack horizontal horizontalAlign="end" tokens={globalStackTokensGapSmall}>
            <Stack.Item>
              <PrimaryButton text={t('translation:General.Button.Select')} onClick={() => onApply()} />
            </Stack.Item>
            <Stack.Item>
              <DefaultButton text={t('translation:General.Button.Cancel')} onClick={() => setShowCallOut(false)} />
            </Stack.Item>
          </Stack>
        </Stack>
      </Callout>
    </Fragment>
  );
};

export default PeriodFilter;
