import React, { useContext, useEffect, useState } from 'react';
import {
  Stack,
  Text,
  PrimaryButton,
  Image,
  ImageFit,
  FontIcon,
  SharedColors,
  Spinner,
  SpinnerSize,
  ProgressIndicator,
  Separator,
  DefaultButton,
} from '@fluentui/react';
import { useTranslation } from 'react-i18next';
import { globalStackTokensGapMedium, globalStackTokensGapSmall } from 'globalStyles';
import { TenantOnboarding } from 'models/tenant';
import TrialWelcomeTimeline from './TrialWelcomeTimeline';
import Config from 'services/Config/configService';
import AppContext from 'App/AppContext';
import { apiRequest } from 'services/Auth/authConfig';
import { apiCopilotFinishOnboarding, apiCopilotFinishOnboardingProgress } from 'services/Api/copilotService';
import { CopilotOnboardingProject } from 'models/copilot/copilotOnboardingProject';
import AppError from 'utils/appError';
import { ISONorm } from 'models/norm';
import { CopilotButton } from 'components/Copilot/CopilotButtons';
import { LearnMore } from 'components/Notification/Info';
import { globalKB_ISOBasicInfo } from 'globalConstants';
import DialogConfirmDelete from 'components/Dialogs/DialogConfirmDelete';
import { hasUserDataPermission } from 'services/Auth/featurePermissions';
import { AuthSchemaLineOperation } from 'models/auth/authSchemaLine';
import { DialogOk } from 'components/Dialogs/DialogOk';
import { apiGetTenantOnboardingState } from 'services/Api/tenantService';
import { AIEnabled } from 'models/setting';

interface ITrialWelcomeAskConfirmProps {
  isRunning: boolean;
  onboardingState: TenantOnboarding;
  onPrev: (state: TenantOnboarding) => void;
  onNext: (state: TenantOnboarding) => void;
  standards: ISONorm[];
}

const TrialWelcomeAskConfirm: React.FunctionComponent<ITrialWelcomeAskConfirmProps> = (
  props: ITrialWelcomeAskConfirmProps,
) => {
  const { t } = useTranslation(['translation', 'dashboard']);
  const appContext = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [project, setProject] = useState<CopilotOnboardingProject | undefined>(undefined);
  const [showProject, setShowProject] = useState<boolean>(false);
  const [error, setError] = useState<AppError | undefined>(undefined);
  const [projectDocument, setProjectDocument] = useState<string | undefined>(undefined);
  const [isRunningTimer, setIsRunningTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  const [confirmDeactivateStandards, setConfirmDeactivateStandards] = useState<string | undefined>(undefined);
  const [hasNoPermissionsDeactivateStandards, setHasNoPermissionsDeactivateStandards] = useState<string | undefined>(
    undefined,
  );

  const introImageProps = {
    src: `${Config.getImageURL()}/green-check.png`,
    imageFit: ImageFit.centerContain,
    width: 50,
    height: 50,
  };

  useEffect(() => {
    //clean-up
    return () => {
      if (isRunningTimer) {
        clearTimeout(isRunningTimer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateIsRunning = async () => {
    //Check whether the back-end is still processing the configuration
    try {
      const isRunning = async (): Promise<boolean> => {
        const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
        const result = await apiCopilotFinishOnboardingProgress(accessToken);

        return (result || 0) > 0;
      };

      if (isRunningTimer) {
        clearTimeout(isRunningTimer);
      }

      if (await isRunning()) {
        setIsLoading(true);
        setIsRunningTimer(setTimeout(() => updateIsRunning(), 2000));
      } else {
        //Back-end finished the configuration
        //Normally, the startConfig method gets the project data back
        //We don't have it here so we need to fetch it
        await refreshContext();
        const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
        const onboardingState = await apiGetTenantOnboardingState(accessToken);
        const project = new CopilotOnboardingProject();
        project.previewPackageInstalled = onboardingState.onboardingSteps.previewPackageInstalled;
        project.projectMilestonesCreated = onboardingState.onboardingSteps.projectMilestonesCreated;
        //other props are not needed
        setProject(project);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      setError(AppError.fromApiError(err));
    }
  };

  useEffect(() => {
    //When the isRunning prop is set, the back-end is processing the configuration
    //We need to set this view into loading state and check the progress
    if (props.isRunning) {
      setIsLoading(true);
      setIsRunningTimer(setTimeout(() => updateIsRunning(), 2000));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isRunning]);

  const applyNewState = (state: TenantOnboarding): TenantOnboarding => {
    const newOnboardingState = state.clone();
    newOnboardingState.onboardingSteps.projectDocument = projectDocument;
    if (project) {
      newOnboardingState.onboardingSteps.dashboardId = project?.dashboard?.dashboardId;
      newOnboardingState.onboardingSteps.previewPackageInstalled = project?.previewPackageInstalled;
      newOnboardingState.onboardingSteps.projectMilestonesCreated = project?.projectMilestonesCreated;
    }

    return newOnboardingState;
  };

  const checkStartConfig = () => {
    //When the new configuration means that we are de-activating standards, we need to check delete permissions and ask for confirmation
    const selectedStandards = getStandards();
    const currentStandards = appContext.globalDataCache.norms.getItemsISO().filter((n) => n.active);
    const deactivatedStandards = currentStandards.filter(
      (n) => !selectedStandards.some((s) => s.isoNormId === n.isoNormId),
    );
    if (deactivatedStandards.length > 0) {
      let userHasPermission = true;
      for (let idx = 0; idx < deactivatedStandards.length; idx++) {
        if (
          !hasUserDataPermission(appContext, [deactivatedStandards[idx].authSchemaId], AuthSchemaLineOperation.Delete)
        ) {
          userHasPermission = false;
          break;
        }
      }
      const deactivatedStandardNames = deactivatedStandards.map((n) => n.name).join(', ');
      if (userHasPermission) {
        setConfirmDeactivateStandards(deactivatedStandardNames);
      } else {
        setHasNoPermissionsDeactivateStandards(deactivatedStandardNames);
      }
    } else {
      startConfig();
    }
  };

  const startConfig = async () => {
    try {
      setIsLoading(true);
      setError(undefined);

      if (!props.isRunning) {
        setProject(undefined);

        const newSteps = props.onboardingState.onboardingSteps.clone();
        newSteps.dashboardName = t('dashboard:TrialWelcome.Goals.DashboardName');
        newSteps.dashboardWidgetTaskStatusName = t('dashboard:TrialWelcome.Goals.DashboardWidgetTaskStatusName');
        newSteps.dashboardWidgetTaskTagName = t('dashboard:TrialWelcome.Goals.DashboardWidgetTaskTagName');

        const accessToken: string = await appContext.getAccessToken(apiRequest.scopes);
        const project = await apiCopilotFinishOnboarding(newSteps, accessToken, appContext.globalDataCache);
        await refreshContext();
        setProject(project);
      }
    } catch (err) {
      setError(AppError.fromApiError(err));
    } finally {
      setIsLoading(false);
      if (isRunningTimer) {
        clearTimeout(isRunningTimer);
      }
    }
  };

  const refreshContext = async () => {
    await appContext.globalDataCache.refresh();
    appContext.globalDataCache.settings.set(AIEnabled, props.onboardingState.onboardingSteps.optInAI);
  };

  const installPreviewInstant = (): boolean => {
    //Install when ISO 27001:2022 is selected
    if (props.onboardingState.onboardingSteps.certifiedStandards?.includes(13)) {
      return true;
    }
    if (props.onboardingState.onboardingSteps.newStandards?.includes(13)) {
      //do not install the package if it is an upgrade from ISO 27001:2013
      if (!props.onboardingState.onboardingSteps.certifiedStandards?.includes(2)) {
        return true;
      }
    }
    if (props.onboardingState.onboardingSteps.certifiedOtherStandards?.replaceAll(' ', '')?.includes('ISO27001')) {
      return true;
    }
    if (props.onboardingState.onboardingSteps.newOtherStandards?.replaceAll(' ', '')?.includes('ISO27001')) {
      //do not install the package if it is an upgrade from ISO 27001:2013
      if (!props.onboardingState.onboardingSteps.certifiedStandards?.includes(2)) {
        return true;
      }
    }

    return false;
  };

  const getStandards = (): ISONorm[] => {
    if (
      props.onboardingState.onboardingSteps.certifiedStandards?.length === 0 &&
      props.onboardingState.onboardingSteps.newStandards?.length === 0
    ) {
      return [];
    }

    const standards: ISONorm[] = [];
    props.onboardingState.onboardingSteps.certifiedStandards?.forEach((standardId) => {
      const standard = props.standards.find((s) => s.isoNormId === standardId);
      if (standard) {
        standards.push(standard);
      }
    });

    props.onboardingState.onboardingSteps.newStandards?.forEach((standardId) => {
      const standard = props.standards.find((s) => s.isoNormId === standardId);
      if (standard) {
        //filter out standards that are upgrades of certified standards
        const upgrade = props.standards.find((s) => s.upgradeISONormId === standard.isoNormId);
        if (
          !upgrade ||
          !props.onboardingState.onboardingSteps.certifiedStandards?.some((id) => id === upgrade.isoNormId) ||
          upgrade.allowDowngrade === true
        ) {
          standards.push(standard);
        }
      }
    });

    return standards;
  };

  const getStandardNames = (): string => {
    const selectedStandards = getStandards();
    if (selectedStandards.length === 0) {
      return t('dashboard:TrialWelcome.Goals.Summary.NoStandards');
    }

    const standards: string[] = [];

    selectedStandards.forEach((selectedStandard) => {
      standards.push(selectedStandard.name);
    });

    return standards.join(', ');
  };

  const updateProjectDocument = (doc: string | undefined) => {
    setProjectDocument(doc);
  };

  //
  // Main render
  //
  return (
    <Stack verticalFill horizontalAlign="center" styles={{ root: { paddingRight: 20, paddingBottom: 20 } }}>
      <Stack verticalFill tokens={globalStackTokensGapMedium}>
        {!project && !error && (
          <Stack horizontal tokens={globalStackTokensGapSmall}>
            <Stack.Item>
              <Image {...introImageProps} />
            </Stack.Item>
            <Stack.Item>
              <Text variant="large">{t('dashboard:TrialWelcome.Goals.AskConfirm')}</Text>
            </Stack.Item>
          </Stack>
        )}
        <Stack.Item>
          {!project && !error && <Text variant="mediumPlus">{t('dashboard:TrialWelcome.Goals.AskConfirmInfo')}</Text>}
          {project && !error && (
            <Stack horizontal tokens={globalStackTokensGapSmall}>
              <Stack.Item>
                <Image {...introImageProps} />
              </Stack.Item>
              <Stack.Item>
                <Text variant="mediumPlus">{t('dashboard:TrialWelcome.Goals.AskConfirmSuccess')}</Text>
              </Stack.Item>
            </Stack>
          )}
          {error && (
            <Stack tokens={globalStackTokensGapSmall}>
              <Stack horizontal tokens={globalStackTokensGapSmall}>
                <Stack.Item>
                  <FontIcon iconName="StatusErrorFull" style={{ fontSize: '24px', color: SharedColors.red20 }} />
                </Stack.Item>
                <Stack.Item>
                  <Text variant="mediumPlus">{t('dashboard:TrialWelcome.Goals.AskConfirmFailure')}</Text>
                </Stack.Item>
              </Stack>
              <Stack.Item>
                <Text>{error.message}</Text>
              </Stack.Item>
            </Stack>
          )}
          {isLoading && <ProgressIndicator />}
          {!isLoading && <Separator />}
        </Stack.Item>
        {!project && (
          <Stack.Item>
            <Text>{t('dashboard:TrialWelcome.Goals.Summary.Title')}</Text>
            <ul>
              <li>{t('dashboard:TrialWelcome.Goals.Summary.Item1', { standards: getStandardNames() })}</li>
              <li>{t('dashboard:TrialWelcome.Goals.Summary.Item2')}</li>
              <li>{t('dashboard:TrialWelcome.Goals.Summary.Item3')}</li>
              {installPreviewInstant() && <li>{t('dashboard:TrialWelcome.Goals.Summary.Item4')}</li>}
            </ul>
          </Stack.Item>
        )}
        {installPreviewInstant() && (
          <Stack.Item>
            <iframe
              src={`https://player.vimeo.com/video/1055005799?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
              width={appContext.isMobileView ? 300 : 400}
              height={appContext.isMobileView ? 150 : 200}
              allow="autoplay; fullscreen; picture-in-picture"
            />
          </Stack.Item>
        )}
        {!project && (
          <Stack.Item>
            <Text>{t('dashboard:TrialWelcome.Goals.AskConfirmProgress')}</Text>
          </Stack.Item>
        )}
        <Stack horizontal tokens={globalStackTokensGapMedium} styles={{ root: { paddingTop: 30 } }}>
          {!project && (
            <Stack.Item>
              <DefaultButton
                disabled={isLoading}
                text={t('dashboard:TrialWelcome.Goals.ButtonPrev')}
                onClick={() => props.onPrev(applyNewState(props.onboardingState))}
              />
            </Stack.Item>
          )}
          <Stack.Item>
            <PrimaryButton
              disabled={isLoading}
              text={project ? t('translation:General.Button.Continue') : t('translation:General.Button.Start')}
              onClick={() => {
                if (project) {
                  props.onNext(applyNewState(props.onboardingState));
                } else {
                  checkStartConfig();
                }
              }}
            >
              {isLoading && <Spinner size={SpinnerSize.small} styles={{ root: { paddingLeft: 5 } }} />}
            </PrimaryButton>
          </Stack.Item>
          {props.onboardingState.onboardingSteps.optInAI && (
            <Stack.Item>
              <CopilotButton
                onClick={() => setShowProject(true)}
                text={t('dashboard:TrialWelcome.Goals.CreateProjectPlan')}
                tooltip=""
                isPrimary={isLoading}
              />
              {showProject && (
                <TrialWelcomeTimeline
                  isOpen={showProject}
                  onClose={() => setShowProject(false)}
                  onboardingState={props.onboardingState}
                  onUpdateProjectDocument={updateProjectDocument}
                />
              )}
            </Stack.Item>
          )}
          {!props.onboardingState.onboardingSteps.optInAI && (
            <Stack.Item>
              <LearnMore
                learnMoreLink={globalKB_ISOBasicInfo}
                text={t('dashboard:TrialWelcome.Goals.AskConfirmBasicInfoLink')}
              />
            </Stack.Item>
          )}
        </Stack>
      </Stack>
      {confirmDeactivateStandards !== undefined && (
        <DialogConfirmDelete
          hidden={confirmDeactivateStandards === undefined}
          title={t('dashboard:TrialWelcome.Goals.AskConfirmDeactivateStandardsTitle')}
          subText={t('dashboard:TrialWelcome.Goals.AskConfirmDeactivateStandardsInfo', {
            names: confirmDeactivateStandards,
          })}
          onYes={() => {
            setConfirmDeactivateStandards(undefined);
            startConfig();
          }}
          onNo={() => setConfirmDeactivateStandards(undefined)}
        />
      )}
      {hasNoPermissionsDeactivateStandards !== undefined && (
        <DialogOk
          hidden={hasNoPermissionsDeactivateStandards === undefined}
          title={t('dashboard:TrialWelcome.Goals.AskConfirmDeactivateStandardsTitle')}
          subText={t('dashboard:TrialWelcome.Goals.AskConfirmDeactivateNoPermissionsInfo', {
            names: hasNoPermissionsDeactivateStandards,
          })}
          onOk={() => {
            setHasNoPermissionsDeactivateStandards(undefined);
          }}
        />
      )}
    </Stack>
  );
};

export default TrialWelcomeAskConfirm;
