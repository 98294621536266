import { SubscriptionTypes } from 'utils/subscription';
import { StoreMgmtStates } from './adminTenant';
import { Settings } from './setting';
import { isEmpty } from 'utils/string';

export const LeadSources_Instant27001 = '7a2561e1-c2f4-496f-8983-360704dd1bce';
export const LeadSources_CAN = '56e4347c-073c-4882-b5b2-9fd9a838c763';
export const LeadSourceInfosentry = '3da37c7b-d723-47b0-a597-5d96c162eec9';
export const LeadSources: string[] = [LeadSources_Instant27001, LeadSources_CAN, LeadSourceInfosentry];

export default class Tenant {
  id: string;

  parentId?: string;

  topLevelParentTenantId: string;

  azureTenantId?: string;

  name: string;

  created?: Date;

  totalLicenses: number;

  usedUserLicenses: number;

  usedMgMtLicenses: number;

  numberOfUsers: number;

  userLicenses: number;

  mgMtLicenses: number;

  storeMgmtState: number;

  onboardingState?: TenantOnboarding;

  subscriptionType: number;

  settings?: Settings;

  timeZoneCode?: string;

  currentUserLicensed?: boolean;

  appLogo?: Blob;

  constructor(id: string, name: string) {
    this.id = id;
    this.topLevelParentTenantId = id; //default same as id
    this.name = name;
    this.totalLicenses = 0;
    this.usedUserLicenses = 0;
    this.usedMgMtLicenses = 0;
    this.numberOfUsers = 0;
    this.userLicenses = 0;
    this.mgMtLicenses = 0;
    this.storeMgmtState = StoreMgmtStates.Customer;
    this.subscriptionType = SubscriptionTypes.Basic;
  }

  getUsedLicenses(): number {
    return this.usedUserLicenses + this.usedMgMtLicenses;
  }

  getFreeLicenses(): number {
    return this.totalLicenses - this.getUsedLicenses();
  }

  getFreeUserLicenses(): number {
    return this.userLicenses - this.usedUserLicenses;
  }

  getFreeMgMtLicenses(): number {
    return this.mgMtLicenses - this.usedMgMtLicenses;
  }

  static isDescendentOf(child: Tenant, parentId: string | undefined, orgUnits: Tenant[]): boolean {
    if (parentId && child.parentId) {
      if (child.parentId === parentId) return true;
      let nextParent: Tenant | undefined = child;

      nextParent = orgUnits.find((o) => o.id === nextParent?.parentId);
      if (!nextParent) return false;

      return this.isDescendentOf(nextParent, parentId, orgUnits);
    }

    return false;
  }

  clone() {
    const newTenant = new Tenant(this.id, this.name);
    newTenant.parentId = this.parentId;
    newTenant.topLevelParentTenantId = this.topLevelParentTenantId;
    newTenant.azureTenantId = this.azureTenantId;
    newTenant.totalLicenses = this.totalLicenses;
    newTenant.usedUserLicenses = this.usedUserLicenses;
    newTenant.usedMgMtLicenses = this.usedMgMtLicenses;
    newTenant.usedUserLicenses = this.usedUserLicenses;
    newTenant.numberOfUsers = this.numberOfUsers;
    newTenant.userLicenses = this.userLicenses;
    newTenant.mgMtLicenses = this.mgMtLicenses;
    newTenant.storeMgmtState = this.storeMgmtState;
    newTenant.subscriptionType = this.subscriptionType;

    return newTenant;
  }
}

export class TenantOnboarding {
  packageIds: string[];

  leadSource?: string;

  timeZoneOffset?: number;

  onboardingSteps: TenantOnboardingSteps;

  constructor() {
    this.packageIds = [];
    this.onboardingSteps = new TenantOnboardingSteps();
  }

  clone() {
    const newTenantOnboarding = new TenantOnboarding();
    newTenantOnboarding.packageIds = [...this.packageIds];
    newTenantOnboarding.leadSource = this.leadSource;
    newTenantOnboarding.timeZoneOffset = this.timeZoneOffset;
    newTenantOnboarding.onboardingSteps = this.onboardingSteps.clone();

    return newTenantOnboarding;
  }
}

export class TenantOnboardingSteps {
  currentStep: number | undefined;

  languageIsSet: boolean | undefined;

  certifiedStandards: number[] | undefined;

  certifiedOtherStandards: string | undefined;

  newStandards: number[] | undefined;

  newOtherStandards: string | undefined;

  currentSystem: number | undefined;

  currentOtherSystem: string | undefined;

  currentSystemImprovement: string | undefined;

  wantsToCertify: boolean | undefined;

  startDate: Date | undefined;

  endDate: Date | undefined;

  projectDocument: string | undefined;

  dashboardName: string | undefined;

  dashboardWidgetTaskStatusName: string | undefined;

  dashboardWidgetTaskTagName: string | undefined;

  optInAI: boolean = false;

  dashboardId: number | undefined;

  previewPackageInstalled: boolean | undefined;

  projectMilestonesCreated: boolean | undefined;

  isCertified = (): boolean | undefined => {
    if (!this.certifiedStandards) return undefined;

    return this.certifiedStandards.length > 0 || !isEmpty(this.certifiedOtherStandards);
  };

  needNewStandards = (): boolean | undefined => {
    if (!this.newStandards) return undefined;

    return this.newStandards.length > 0 || !isEmpty(this.newOtherStandards);
  };

  clone() {
    const newTenantOnboardingSteps = new TenantOnboardingSteps();
    newTenantOnboardingSteps.certifiedStandards = this.certifiedStandards;
    newTenantOnboardingSteps.certifiedOtherStandards = this.certifiedOtherStandards;
    newTenantOnboardingSteps.newStandards = this.newStandards;
    newTenantOnboardingSteps.newOtherStandards = this.newOtherStandards;
    newTenantOnboardingSteps.wantsToCertify = this.wantsToCertify;
    newTenantOnboardingSteps.currentSystem = this.currentSystem;
    newTenantOnboardingSteps.currentOtherSystem = this.currentOtherSystem;
    newTenantOnboardingSteps.currentSystemImprovement = this.currentSystemImprovement;
    newTenantOnboardingSteps.startDate = this.startDate;
    newTenantOnboardingSteps.endDate = this.endDate;
    newTenantOnboardingSteps.projectDocument = this.projectDocument;
    newTenantOnboardingSteps.dashboardName = this.dashboardName;
    newTenantOnboardingSteps.dashboardWidgetTaskStatusName = this.dashboardWidgetTaskStatusName;
    newTenantOnboardingSteps.dashboardWidgetTaskTagName = this.dashboardWidgetTaskTagName;
    newTenantOnboardingSteps.languageIsSet = this.languageIsSet;
    newTenantOnboardingSteps.optInAI = this.optInAI;
    newTenantOnboardingSteps.dashboardId = this.dashboardId;
    newTenantOnboardingSteps.previewPackageInstalled = this.previewPackageInstalled;
    newTenantOnboardingSteps.projectMilestonesCreated = this.projectMilestonesCreated;
    newTenantOnboardingSteps.currentStep = this.currentStep;
    
    return newTenantOnboardingSteps;
  }
}
