import Task from 'models/tasks/task';
import { globalKanbanSortGap } from 'globalConstants';
import AppError from 'utils/appError';
import { IAppContext } from 'App/AppContext';
import { apiUpdateTaskKanbanState, apiUpdateTaskKanbanAssignee } from 'services/Api/taskService';
import { apiRequest } from 'services/Auth/authConfig';
import Risk from 'models/risk';
import { apiUpdateRiskKanbanState } from 'services/Api/riskService';

export declare interface ISortableKanbanItem {
  sortOrder: number;
}

export declare interface IKanbanSortProps<IKanbanItem = ISortableKanbanItem> {
  appContext: IAppContext;
  itemList: IKanbanItem[];
  sourceIndex: number; //set this to -1 to indicate that this is a new item in this list
  targetIndex: number;
  updateItem: (
    appContext: IAppContext,
    item: IKanbanItem,
    statusField?: 'state' | 'assignee',
  ) => Promise<IKanbanItem | undefined>;
}

export const getKanbanSortOrder = async <T extends ISortableKanbanItem>(props: IKanbanSortProps<T>): Promise<number> => {
  // calculate the new sort order.
  // determine the sort order of the tasks above and below the new task position
  // new task sort order is the average of those
  // corner cases:
  // - when new tasks position is at the top, sort order above = 0
  // - when new tasks position is at the bottom, sort order above = sort order of last item. In that case add value of globalKanbanSortGap
  // - when there are no tasks in the list, initialize to globalKanbanSortGap
  // - when there is no 'room' left, re-calculate the whole list and update all tasks
  const { appContext, itemList, sourceIndex, targetIndex, updateItem } = props;
  let newSortOrder: number = -1;

  if (itemList.length === 0) {
    //Initialize
    newSortOrder = globalKanbanSortGap;
  } else {
    const taskListWithoutSource = [...itemList];
    if (sourceIndex !== -1) {
      taskListWithoutSource.splice(sourceIndex, 1);
    }
    if (targetIndex >= taskListWithoutSource.length) {
      //move to end
      const sortOrderTaskAbove = taskListWithoutSource[taskListWithoutSource.length - 1].sortOrder;
      newSortOrder = sortOrderTaskAbove + globalKanbanSortGap;
    } else {
      //move to top or in between
      const sortOrderTaskAbove = targetIndex > 0 ? taskListWithoutSource[targetIndex - 1].sortOrder : 0;
      const sortOrderTaskBelow = taskListWithoutSource[targetIndex].sortOrder;
      if (sortOrderTaskBelow - sortOrderTaskAbove <= 1) {
        //no room for the new item. we need to recalculate the sort otder of all items in the list
        //this is an expensive operation as all items except the moved one need to be updated in the database
        if (itemList.length > 900) {
          //corner case: when we have a list with more than 900 items, the max sortOrder will be larger than Number.MAX_SAFE_INTEGER
          throw new AppError('sortOrder will be larger than Number.MAX_SAFE_INTEGER');
        }

        for (let i = 0; i < taskListWithoutSource.length; i++) {
          const add = i < targetIndex ? 0 : 1;
          taskListWithoutSource[i].sortOrder = (i + 1 + add) * globalKanbanSortGap;
          await updateItem(appContext, itemList[i], 'state');
        }

        newSortOrder = (targetIndex + 1) * globalKanbanSortGap;
      } else {
        newSortOrder = Math.floor((sortOrderTaskBelow + sortOrderTaskAbove) / 2);
      }
    }
  }

  if (newSortOrder > Number.MAX_SAFE_INTEGER) {
    throw new AppError('sortOrder is larger than Number.MAX_SAFE_INTEGER');
  }

  return newSortOrder;
};

export const updateTaskKanban = async (
  appContext: IAppContext,
  task: Task,
  statusField?: 'state' | 'assignee',
): Promise<Task | undefined> => {
  try {
    const accessToken = await appContext.getAccessToken(apiRequest.scopes);

    switch (statusField) {
      case 'state':
        return await apiUpdateTaskKanbanState(task, accessToken);
      case 'assignee':
        return await apiUpdateTaskKanbanAssignee(task, accessToken);
    }
  } catch (err) {
    appContext.setError(err);

    return task;
  }
};

export const updateRiskKanban = async (appContext: IAppContext, risk: Risk): Promise<Risk | undefined> => {
  try {
    const accessToken = await appContext.getAccessToken(apiRequest.scopes);

    return await apiUpdateRiskKanbanState(risk, accessToken);
  } catch (err) {
    appContext.setError(err);

    return risk;
  }
};
