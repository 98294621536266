import { TMCERequest } from 'components/Text/RichTextEditorMCETypes';

export enum CopilotConversionItemType {
  User = 0,
  Copilot = 1,
}

export enum CopilotManagerPromptType {
  Custom = 0,
  Context = 1,
  General = 2,
}

export enum CopilotManagerGeneralPromptType {
  SummarizeContent = 1,
  ImproveWriting = 2,
  SimplifyLanguage = 3,
  ExpandUpon = 4,
}

export enum CopilotErrorType {
  MaxTokens = 1,
}

export class CopilotConversation {
  id?: string;

  request?: CopilotConversationItem;

  thread: CopilotConversationItem[];

  constructor() {
    this.thread = [];
  }

  hasData = (): boolean => {
    return this.thread.length > 0;
  };

  setRequest = (request: TMCERequest) => {
    this.request = new CopilotConversationItem();
    this.request.question = request.query;
    this.request.context = request.context;
    this.setPromptTypes(request);
  };

  private setPromptTypes = (request: TMCERequest) => {
    if (!this.request) return;
    switch (request.query) {
      case 'FillInField':
        this.request.promptType = CopilotManagerPromptType.Context;
        break;
      case 'SummarizeContent':
        this.request.promptType = CopilotManagerPromptType.General;
        this.request.generalPromptType = CopilotManagerGeneralPromptType.SummarizeContent;
        break;
      case 'ImproveWriting':
        this.request.promptType = CopilotManagerPromptType.General;
        this.request.generalPromptType = CopilotManagerGeneralPromptType.ImproveWriting;
        break;
      case 'SimplifyLanguage':
        this.request.promptType = CopilotManagerPromptType.General;
        this.request.generalPromptType = CopilotManagerGeneralPromptType.SimplifyLanguage;
        break;
      case 'ExpandUpon':
        this.request.promptType = CopilotManagerPromptType.General;
        this.request.generalPromptType = CopilotManagerGeneralPromptType.ExpandUpon;
        break;
      default:
        this.request.promptType = CopilotManagerPromptType.Custom;
        break;
    }
  };
}

export class CopilotConversationBaseItem {
  question?: string;

  context?: string;

  promptType?: CopilotManagerPromptType;

  generalPromptType?: CopilotManagerGeneralPromptType;

  response?: string;
}

export class CopilotConversationItem extends CopilotConversationBaseItem {
  id?: string;

  type: CopilotConversionItemType;

  date?: Date;

  userId?: string;

  constructor() {
    super();
    this.type = CopilotConversionItemType.User;
  }
}
