import React, { Fragment, FunctionComponent } from 'react';
import {
  ScrollablePane,
  ScrollbarVisibility,
  Stack,
  Spinner,
  SpinnerSize,
  Persona,
  PersonaSize,
  ComboBox,
  IComboBoxOption,
  IComboBox,
  IPersonaStyles,
  Text,
  IconButton,
  List,
} from '@fluentui/react';

import AppContext from 'App/AppContext';
import Task from 'models/tasks/task';
import { addDateDays, getDateTimeDiffMinute, toLocaleDateTimeMedium } from 'utils/datetime';
import SingleTask from 'components/Tasks/SingleTask';
import { toNameInitial } from 'utils/string';
import { globalStackTokensGapMedium } from 'globalStyles';
import { useTranslation } from 'react-i18next';
import { IWidgetConfigRendererProps } from '../WidgetConfigRenderer';
import { sortOnDate } from 'utils/sorting';
import { SkypeCircleCheckIcon } from 'components/Checklist/CheckListStyles';
import { IWidgetRendererProps } from '../WidgetRenderer';
import DateDividerListItem, {
  DateDivider,
  DataGroup,
  insertDividersAndGroupsInList,
  DateComparer,
} from 'components/Utils/ListItemDateDividers';

export class WidgetMyUpcomingTasksConfig {
  period: number;

  constructor() {
    this.period = 7;
  }

  load(raw: string | undefined) {
    if (raw) {
      try {
        const newRawConfig = JSON.parse(raw);
        this.period = newRawConfig.period ?? 7;
      } catch {
        //ignore
      }
    }
  }

  clone(): WidgetMyUpcomingTasksConfig {
    const newConfig = new WidgetMyUpcomingTasksConfig();
    newConfig.period = this.period;

    return newConfig;
  }
}

interface IWidgetMyUpcomingTasksProps extends IWidgetRendererProps {}

const WidgetMyUpcomingTasks: FunctionComponent<IWidgetMyUpcomingTasksProps> = (props: IWidgetMyUpcomingTasksProps) => {
  const { t } = useTranslation(['translation', 'widgets']);
  const appContext = React.useContext(AppContext);
  const [tasks, setTasks] = React.useState<Task[]>([]);
  const [tasksDisplay, setTasksDisplay] = React.useState<(Task | DateDivider | DataGroup<Task>)[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [selectedTask, setSelectedTask] = React.useState<Task | undefined>(undefined);

  React.useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.widget, props.sharedData]);

  React.useEffect(() => {
    if (tasks && tasks?.length > 0) {
      const newData = insertDividersAndGroupsInList(tasks, getCompareDate, undefined, undefined);
      setTasksDisplay(newData);
    }
     
  }, [tasks]);

  const loadConfig = (): WidgetMyUpcomingTasksConfig => {
    let newRawConfig: WidgetMyUpcomingTasksConfig = new WidgetMyUpcomingTasksConfig();
    if (props.widget.widgetConfig) {
      newRawConfig = JSON.parse(props.widget.widgetConfig);
    }

    return newRawConfig;
  };

  const loadData = async (refresh?: boolean) => {
    try {
      if (props.onRefreshSharedData && props.widget.dashboard && refresh) {
        await props.onRefreshSharedData();

        return;
      }

      if (isLoading) return;
      setIsLoading(true);

      const config = loadConfig();
      const taskCol = props.sharedData?.myTasks ?? [];
      
      const date = addDateDays(new Date(), config.period);
      const filteredTasks = taskCol
        .filter((t) => {
          const start = t.startDateTime;

          return (
            t.completed === undefined &&
            getDateTimeDiffMinute(start, new Date()) > 0 &&
            getDateTimeDiffMinute(start, date) < 0
          );
        })
        .sort((a, b) => sortOnDate(a.startDateTime, b.startDateTime));

      setTasks(filteredTasks);
    } catch (err) {
      appContext.setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getCompareDate: DateComparer<Task> = (a) => {
    return a.startDateTime;
  };

  const personaStyle: Partial<IPersonaStyles> = {
    root: {
      marginBottom: 5,
      selectors: {
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  };

  const saveTask = async (task: Task, isNew: boolean, isCancelled: boolean) => {
    if (!isCancelled) {
      await loadData(true);
    }
    setSelectedTask(undefined);
  };

  const removeTask = async (task: Task) => {
    setSelectedTask(undefined);
    await loadData(true);
  };

  const updateTask = async (task: Task) => {};

  //
  // Main render
  //
  if (isLoading) {
    return (
      <Stack verticalFill horizontalAlign="center" verticalAlign="center">
        <Spinner size={SpinnerSize.large} />
      </Stack>
    );
  }

  return (
    <Stack verticalFill styles={{ root: { paddingTop: 5 } }}>
      {tasks?.length === 0 && (
        <Fragment>
          <Stack verticalFill horizontalAlign="center" verticalAlign="center">
            <Stack.Item>
              <IconButton iconProps={SkypeCircleCheckIcon} />
            </Stack.Item>
          </Stack>
          <Stack.Item>
            <Text>{t('widgets:MyUpcomingTasks.NoItems')}</Text>
          </Stack.Item>
        </Fragment>
      )}
      {tasks?.length > 0 && (
        <Stack.Item grow styles={{ root: { position: 'relative' } }}>
          <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
            <List
              items={tasksDisplay}
              onRenderCell={(task) => {
                if (!task) return null;

                if (task instanceof DateDivider) {
                  return <DateDividerListItem {...task} />;
                }

                if (task instanceof DataGroup) {
                  return null;
                }

                return (
                  <Persona
                    size={PersonaSize.size40}
                    text={task.name}
                    imageInitials={toNameInitial(appContext.globalDataCache.users.get(task.userId).name)}
                    secondaryText={toLocaleDateTimeMedium(task.startDateTime)}
                    onClick={() => {
                      if (!task) return;

                      setSelectedTask(task);
                    }}
                    styles={personaStyle}
                  />
                );
              }}
            />
          </ScrollablePane>
        </Stack.Item>
      )}
      {selectedTask && (
        <SingleTask
          task={selectedTask}
          isOpen={true}
          close={() => {
            setSelectedTask(undefined);
          }}
          onSave={saveTask}
          onRemove={removeTask}
          onUpdate={updateTask}
        />
      )}
    </Stack>
  );
};

export default WidgetMyUpcomingTasks;

//
// Config
//

interface IWidgetConfigMyUpcomingTasksProps extends IWidgetConfigRendererProps {}

export const WidgetConfigMyUpcomingTasks: FunctionComponent<IWidgetConfigMyUpcomingTasksProps> = (
  props: IWidgetConfigMyUpcomingTasksProps,
) => {
  const { t } = useTranslation(['widgets', 'translation']);

  const loadConfig = (): WidgetMyUpcomingTasksConfig => {
    let newRawConfig: WidgetMyUpcomingTasksConfig = new WidgetMyUpcomingTasksConfig();
    if (props.widget.widgetConfig) {
      newRawConfig = JSON.parse(props.widget.widgetConfig);
    }

    return newRawConfig;
  };

  const getConfig = (period: number): WidgetMyUpcomingTasksConfig => {
    const config = new WidgetMyUpcomingTasksConfig();
    config.period = period;

    return config;
  };

  const getCurrentOption = (): string => {
    const config = loadConfig();

    return config.period.toString();
  };

  const updateConfig = (period: number) => {
    const config = getConfig(period);
    props.onUpdateConfig(JSON.stringify(config), true);
  };

  const getOptions = (): IComboBoxOption[] => {
    const items: IComboBoxOption[] = [];

    const comboOption1: IComboBoxOption = {
      key: '7',
      text: t('widgets:MyUpcomingTasks.Config.Period.Option1'),
    };
    items.push(comboOption1);

    const comboOption2: IComboBoxOption = {
      key: '14',
      text: t('widgets:MyUpcomingTasks.Config.Period.Option2'),
    };
    items.push(comboOption2);

    const comboOption3: IComboBoxOption = {
      key: '30',
      text: t('widgets:MyUpcomingTasks.Config.Period.Option3'),
    };
    items.push(comboOption3);

    const comboOption4: IComboBoxOption = {
      key: '90',
      text: t('widgets:MyUpcomingTasks.Config.Period.Option4'),
    };
    items.push(comboOption4);

    const comboOption5: IComboBoxOption = {
      key: '180',
      text: t('widgets:MyUpcomingTasks.Config.Period.Option5'),
    };
    items.push(comboOption5);

    return items;
  };

  return (
    <Stack verticalFill tokens={globalStackTokensGapMedium}>
      <Stack.Item>
        <ComboBox
          defaultSelectedKey={getCurrentOption()}
          options={getOptions()}
          label={t('widgets:MyUpcomingTasks.Config.Period.Label')}
          useComboBoxAsMenuWidth
          onChange={(
            event: React.FormEvent<IComboBox>,
            option?: IComboBoxOption | undefined,
            index?: number | undefined,
            value?: string | undefined,
          ) => {
            if (option) {
              updateConfig(option.key as number);
            }
          }}
        />
      </Stack.Item>
    </Stack>
  );
};
