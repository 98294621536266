import Widget, { WidgetTypes } from './../../models/widget';
import Dashboard, { DashboardTypes } from 'models/dashboard';
import DashboardScene, { NormCoverage, UserTaskStat } from 'models/dashboardScene';
import DashboardSceneDTO from 'models/dto/dashboardSceneDTO';
import http from './httpService';
import AppError from 'utils/appError';
import {
  mapFromDashboard,
  mapFromDashboardScene,
  mapFromNormCoverage,
  mapFromUserTaskStats,
  mapFromWidget,
  mapFromWidgets,
  mapToDashboard,
  mapToWidget,
  mapFromDashboards,
} from 'mappings/dashboardMapping';
import Activity, { ActivityType } from 'models/activity';
import ActivityDTO from 'models/dto/activityDTO';
import { mapFromActivities } from 'mappings/activityMapping';

import GlobalDataCache from 'models/globalDataCache/globalDataCache';

import RiskState from 'models/riskState';
import { mapFromRiskStates } from 'mappings/riskMapping';
import RiskStateDTO from 'models/dto/riskStateDTO';
import Tag from 'models/tag';
import { mapFromTaskStates } from 'mappings/taskMapping';
import { TaskStateDTO } from 'models/dto/tasks/taskDTO';
import KPIGraphDataDTO, { KPIGraphDataConfigDTO } from 'models/dto/kpi/kpiGraphDataDTO';
import KPIGraphData from 'models/kpi/kpiGraphData';
import { mapFromKPIGraphData } from 'mappings/kpiMapping';
import IdListDTO from 'models/dto/IdListDTO';
import { Widget61DTO } from 'models/dto/widgets/widget61DTO';
import ResourceLink from 'models/resourceLink';
import { mapToResourceLink } from 'mappings/linkMapping';
import WidgetDTO from 'models/dto/widgetDTO';
import DashboardDTO from 'models/dto/dashboardDTO';
import TagDTO from 'models/dto/tagDTO';
import { mapFromTags } from 'mappings/tagMapping';
import { Widget63DTO, WidgetLibraryReadStatsConfigViewType } from 'models/dto/widgets/widget63DTO';
import { TaskState } from 'models/tasks/taskHelperClasses';
import { toApiDateOptional } from 'utils/datetime';

export async function apiGetDashboardScene(accessToken: string, cache: GlobalDataCache): Promise<DashboardScene> {
  try {
    const ar = await http.get<DashboardSceneDTO>(`/dashboards`, http.getRequestConfig(accessToken));

    return mapFromDashboardScene(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetActivitiesForDashboard(
  activityTypes: ActivityType[],
  skip: number,
  count: number,
  accessToken: string,
): Promise<Activity[]> {
  try {
    const idListDto = new IdListDTO(activityTypes.map((s) => s.toString()));
    const ar = await http.post<ActivityDTO[]>(
      `/dashboards/activities?skip=${skip}&count=${count}`,
      idListDto,
      http.getRequestConfig(accessToken),
    );

    return mapFromActivities(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiDismissActivity(activityId: number, accessToken: string): Promise<void> {
  try {
    await http.post(`/activities/dismiss/${activityId}`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiDismissAllActivities(activityTypes: ActivityType[], accessToken: string): Promise<void> {
  try {
    const idListDto = new IdListDTO(activityTypes.map((s) => s.toString()));
    await http.post(`/activities/dismiss/all`, idListDto, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetDashboardsForType(
  type: DashboardTypes,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Dashboard[]> {
  try {
    const ar = await http.get<DashboardDTO[]>(`/dashboards/type/${type}`, http.getRequestConfig(accessToken));

    if (!ar || !ar.data || ar.data.length === 0) {
      return [];
    }

    return mapFromDashboards(ar.data, cache);
  } catch (err) {
    const appErr = AppError.fromApiError(err);
    if (appErr.debug?.includes('Authorization_IdentityNotFound')) {
      //this happens when the app cannot retrieve group membership
      //this can happen when the app is not authorized yet
      return [];
    } else {
      throw appErr;
    }
  }
}

export async function apiGetDashboardWidgets(
  dashboardId: number,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Widget[]> {
  try {
    const ar = await http.get<WidgetDTO[]>(`/dashboards/${dashboardId}/widgets`, http.getRequestConfig(accessToken));

    return mapFromWidgets(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddDashboard(
  dashboard: Dashboard,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Dashboard> {
  try {
    const dashboardDTO = mapToDashboard(dashboard);
    const ar = await http.post(`/dashboards`, dashboardDTO, http.getRequestConfig(accessToken));

    return mapFromDashboard(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiDeleteDashboard(dashboard: Dashboard, accessToken: string): Promise<void> {
  try {
    await http.post(`/dashboards/delete/${dashboard.dashboardId}`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateDashboard(
  dashboard: Dashboard,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Dashboard> {
  try {
    const dashboardDTO = mapToDashboard(dashboard);
    const ar = await http.put<DashboardDTO>(`/dashboards`, dashboardDTO, http.getRequestConfig(accessToken));

    return mapFromDashboard(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiAddWidgetToDashboard(
  widget: Widget,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Widget> {
  try {
    const widgetDTO = mapToWidget(widget);
    const ar = await http.post<WidgetDTO>(`/widgets`, widgetDTO, http.getRequestConfig(accessToken));

    return mapFromWidget(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiUpdateWidgetOnDashboard(
  widget: Widget,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<Widget> {
  try {
    const widgetDTO = mapToWidget(widget);
    const ar = await http.put<WidgetDTO>(`/widgets`, widgetDTO, http.getRequestConfig(accessToken));

    return mapFromWidget(ar.data, cache);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiRemoveWidgetFromDashboard(
  widget: Widget,
  accessToken: string,
  cache: GlobalDataCache,
): Promise<void> {
  try {
    await http.post(`/widgets/delete/${widget.widgetId}`, null, http.getRequestConfig(accessToken));
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetWidgetDataNormCoverage(
  isoNormId: number,
  tags: Tag[],
  accessToken: string,
): Promise<NormCoverage> {
  try {
    const filterStr = `tags@${tags.map((t) => t.tagId.toString()).join(',')}`;
    const ar = await http.get<NormCoverage>(
      `/widgets/${WidgetTypes.NormCoverage}/data/${isoNormId}?filter=${filterStr}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromNormCoverage(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetWidgetDataNormCoverageForUser(
  isoNormId: number,
  tags: Tag[],
  userId: string | undefined,
  accessToken: string,
): Promise<NormCoverage> {
  try {
    const filterStr = `tags@${tags.map((t) => t.tagId.toString()).join(',')}`;
    const ar = await http.get<NormCoverage>(
      `/widgets/${WidgetTypes.NormCoverage}/data/${isoNormId}/user/${userId}?filter=${filterStr}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromNormCoverage(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetWidgetDataCustomNormCoverage(
  normId: number,
  tags: Tag[],
  accessToken: string,
): Promise<NormCoverage> {
  try {
    const filterStr = `tags@${tags.map((t) => t.tagId.toString()).join(',')}`;
    const ar = await http.get<NormCoverage>(
      `/widgets/${WidgetTypes.CustomNormCoverage}/data/${normId}?filter=${filterStr}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromNormCoverage(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetWidgetDataCustomNormCoverageForUser(
  normId: number,
  tags: Tag[],
  userId: string | undefined,
  accessToken: string,
): Promise<NormCoverage> {
  try {
    const filterStr = `tags@${tags.map((t) => t.tagId.toString()).join(',')}`;
    const ar = await http.get<NormCoverage>(
      `/widgets/${WidgetTypes.CustomNormCoverage}/data/${normId}/user/${userId}?filter=${filterStr}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromNormCoverage(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetWidgetDataOpenTasksPerUser(
  tags: Tag[],
  overDeadline: boolean,
  start: Date | undefined,
  end: Date | undefined,
  accessToken: string,
): Promise<UserTaskStat[]> {
  try {
    let filterStr = '';
    if (tags.length > 0) filterStr += `tags@${tags.map((t) => t.tagId.toString()).join(',')}`;
    if (overDeadline) filterStr += (filterStr.length > 0 ? ';' : '') + 'overDeadline';
    if (start) filterStr += (filterStr.length > 0 ? ';' : '') + `start@${toApiDateOptional(start)}`;
    if (end) filterStr += (filterStr.length > 0 ? ';' : '') + `end@${toApiDateOptional(end)}`;

    const ar = await http.get<UserTaskStat[]>(
      `/widgets/${WidgetTypes.OpenTasksPerUser}/data?filter=${filterStr}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromUserTaskStats(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetWidgetDataTaskTag(
  tags: Tag[],
  showOpenTasks: boolean,
  start: Date | undefined,
  end: Date | undefined,
  accessToken: string,
): Promise<Tag[]> {
  try {
    let filterStr = `tags@${tags.map((t) => t.tagId.toString()).join(',')}${showOpenTasks ? ';taskStatus@1,2,5' : ''}`;
    if (start) filterStr += `;start@${toApiDateOptional(start)}`;
    if (end) filterStr += `;end@${toApiDateOptional(end)}`;
    const ar = await http.get<TagDTO[]>(
      `/widgets/${WidgetTypes.TaskTag}/data?filter=${filterStr}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTags(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetWidgetDataTaskStatus(
  tags: Tag[],
  useAndForTagGroups: boolean,
  start: Date | undefined,
  end: Date | undefined,
  accessToken: string,
): Promise<TaskState[]> {
  try {
    let filterStr = `tags@${tags.map((t) => t.tagId.toString()).join(',')}`;
    if (useAndForTagGroups) filterStr += ';useAndForTagGroups';
    if (start) filterStr += `;start@${toApiDateOptional(start)}`;
    if (end) filterStr += `;end@${toApiDateOptional(end)}`;
    const ar = await http.get<TaskStateDTO[]>(
      `/widgets/${WidgetTypes.TaskStatus}/data?filter=${filterStr}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromTaskStates(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetWidgetDataRiskStatus(tags: Tag[], accessToken: string): Promise<RiskState[]> {
  try {
    const filterStr = `tags@${tags.map((t) => t.tagId.toString()).join(',')}`;
    const ar = await http.get<RiskStateDTO[]>(
      `/widgets/${WidgetTypes.RiskStatus}/data?filter=${filterStr}`,
      http.getRequestConfig(accessToken),
    );

    return mapFromRiskStates(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetWidgetDataAzureDefenderSecureScore(
  accessToken: string,
  subscriptionId: string,
): Promise<number> {
  try {
    const ar = await http.get<number>(
      `/widgets/${WidgetTypes.AzureDefenderSecureScore}/data?subscriptionId=${subscriptionId}`,
      http.getRequestConfig(accessToken),
    );

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetWidgetDataSingleKPI(
  graphConfig: KPIGraphDataConfigDTO,
  accessToken: string,
): Promise<KPIGraphData> {
  try {
    const ar = await http.post<KPIGraphDataDTO>(
      `/widgets/${WidgetTypes.SingleKPI}/data`,
      graphConfig,
      http.getRequestConfig(accessToken),
    );

    return mapFromKPIGraphData(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetWidgetDataSingleKPILastPeriod(
  graphConfig: KPIGraphDataConfigDTO,
  accessToken: string,
): Promise<KPIGraphData> {
  try {
    const ar = await http.post<KPIGraphDataDTO>(
      `/widgets/${WidgetTypes.SingleKPILastPeriod}/data`,
      graphConfig,
      http.getRequestConfig(accessToken),
    );

    return mapFromKPIGraphData(ar.data);
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetWidgetDataLibraryReadResponse(
  link: ResourceLink,
  activityId: number | undefined,
  accessToken: string,
): Promise<Widget61DTO> {
  try {
    const dto = new Widget61DTO();
    dto.activityId = activityId;
    dto.link = mapToResourceLink(link, true);

    const ar = await http.post<Widget61DTO>(
      `/widgets/${WidgetTypes.LibraryReadResponse}/data`,
      dto,
      http.getRequestConfig(accessToken),
    );

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}

export async function apiGetWidgetDataLibraryReadStats(
  viewMode: WidgetLibraryReadStatsConfigViewType,
  listId: number,
  accessToken: string,
): Promise<Widget63DTO> {
  try {
    const dto = new Widget63DTO();
    dto.viewMode = viewMode;
    dto.listId = listId;

    const ar = await http.post<Widget63DTO>(
      `/widgets/${WidgetTypes.LibraryReadStats}/data`,
      dto,
      http.getRequestConfig(accessToken),
    );

    return ar.data;
  } catch (err) {
    throw AppError.fromApiError(err);
  }
}
