import Risk from 'models/risk';
import { CopilotConversation } from './copilotConversation';

export enum CopilotDataRiskField {
  None = 0,
  Name = 1,
  Threats = 2,
  Controls = 3,
  Consequences = 4,
  Likelihood = 5,
  Impact = 6,
  Treatment = 7,
}

export class CopilotRisk {
  risk: Risk;

  field: CopilotDataRiskField;

  conversation: CopilotConversation;

  constructor() {
    this.risk = new Risk();
    this.field = CopilotDataRiskField.None;
    this.conversation = new CopilotConversation();
  }
}

export interface ICopilotRiskControls {
  controls: ICopilotRiskControl[];
}

export interface ICopilotRiskControl {
  id: number;
  code: string;
  name: string;
}
